import React from 'react';
import { StyledComponentProps } from 'styled-components';
import styled, { css } from 'styled-components/macro';

import { Color, Gutter } from '../../gfx/constants';
import { media } from '../../services/media';

interface ViewProps {
	paddingTop?: number;
	paddingLeftRight?: number;
	paddingBottom?: number;
	noUnderlineForLinks?: boolean;
}

interface ListHeaderProps {
	hasNoBorder?: boolean;
	hasFixedHeight?: boolean;
	marginBottom?: boolean;
}

interface ErrorProps {
	alignRight?: boolean;
	center?: boolean;
	customColor?: boolean;
	customLinkColor?: string;
}

interface DescriptionProps {
	left?: boolean;
}

const StyledView = styled.div<ViewProps>`
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	width: 100%;
	height: auto;
	max-width: 1200px;
	padding: ${({ paddingTop = 64, paddingLeftRight = 20, paddingBottom = 65 }) =>
		`${paddingTop}px ${paddingLeftRight}px ${paddingBottom}px`};

	a {
		text-decoration: ${({ noUnderlineForLinks }) => noUnderlineForLinks && 'none'};
	}

	nav {
		width: 100%;
	}

	${media.fromTablet`
		padding: ${({ paddingTop = 64, paddingLeftRight = 20, paddingBottom = 65 }: ViewProps) =>
			`${paddingTop}px ${paddingLeftRight}px ${paddingBottom}px `};
	`}

	${media.fromDesktop`
		padding: ${({ paddingTop = 64, paddingLeftRight = 40, paddingBottom = 65 }: ViewProps) =>
			`${paddingTop}px ${paddingLeftRight}px ${paddingBottom}px`};
	`}

	${media.maxMobile`
		padding-top: 40px;
	`}
`;

const View = (props: StyledComponentProps<'div', ViewProps, ViewProps, never>) => <StyledView {...props} />;

View.ListHeader = styled.div<ListHeaderProps>`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	width: 100%;
	position: relative;
	border-bottom: 1px solid rgba(128, 128, 128, 0.3);
	padding-bottom: 10px;
	align-items: center;

	${(props) =>
		props.hasFixedHeight &&
		css`
			min-height: 130px;
			align-items: flex-end;
		`};

	${(props) =>
		props.hasNoBorder &&
		css`
			border-bottom: none;
		`};

	${(props) =>
		props.marginBottom &&
		css`
			margin-bottom: 28px;
		`};

	${media.maxTable`
		margin: 0;
	`}
`;

View.Error = styled.div<ErrorProps>`
	font-family: PrimaryMedium;
	color: ${Color.RED_2};
	font-size: 14px;
	padding-bottom: 10px;
	height: 35px;
	max-width: 80vh;
	padding-top: 10px;
	margin-bottom: 10px;

	${(props) =>
		props.alignRight &&
		css`
			margin-left: auto;
			float: right;
		`};

	${(props) =>
		props.center &&
		css`
			text-align: center;
		`};

	a {
		color: white;
	}

	${(props) =>
		props.customLinkColor &&
		css`
			a {
				color: ${props.customLinkColor};
				font-family: PrimaryMedium;
				font-size: 14px;
			}
		`}
`;

View.Description = styled.p<DescriptionProps>`
	max-width: 100%;
	margin-bottom: 60px;
	margin-top: 0;
	color: ${Color.GRAY};
	font-family: Secondary;
	font-size: 16px;

	a {
		color: ${Color.ORANGE};
	}

	${(props) =>
		props.left &&
		css`
			width: 100%;
		`};
`;

View.NoItemsFound = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-top: 20vh;
	max-width: 100%;

	div {
		font-size: 0; /* remove white-space from svg wrapper */
	}

	svg {
		margin-bottom: ${Gutter.MEDIUM};
	}

	p {
		opacity: 0.75;
		margin: 0;
		max-width: 100%;
		color: #181a38;
		font-size: 15px;
		line-height: 19px;
		text-align: center;
	}
`;

View.NotificationWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 100%;
`;

export default View;
