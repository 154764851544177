import * as qs from 'query-string';
import * as React from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';

import NotFoundView from '../../components/not-found/NotFound';
import { RoutesUrls } from '../../constants';
import isUsingIEBrowser from '../../services/is-using-IE-browser';
import { saveUtmDataToLocalStorage, UtmData } from '../../services/save-utm-data-to-local-storage';
import { RouteProps } from '../../typings';
import { AppRouteProps } from '../App';
import DocumentationView from '../public/documentation/DocumentationView';
import LoginView from '../public/login/LoginView';

import EmailVerified from './email-verified/EmailVerified';
import EmailInvoiceExpired from './invoice-expired/InvoiceExpired';
import NotSupportedView from './not-supported/NotSupportedView';
import PasswordRecoveryView from './password-recovery/PasswordRecoveryView';
import RegisterInviteView from './register-invite/RegisterInviteView';
import ResetPasswordView from './reset-password/ResetPasswordView';
import SignUpView from './sign-up/SignUpView';
import EscrowBuyerView from './escrow/escrowBuyerView';
import ExternalInvoiceView from './external-invoice/ExternalInvoiceView';
import MerchantLimitExceeded from '../invoices/MerchantLimitExceeded';

// Ensure backwards compatibility with old referral links
const renderReferralCodeRedirect = (props: RouteProps<AppRouteProps>) => (
	<Redirect
		from={`${RoutesUrls.SIGN_UP}/:referralCode?`}
		to={{
			...props.location,
			pathname: `${RoutesUrls.SIGN_UP_CREATE_ACCOUNT}/${
				props.match.params.referralCode ? props.match.params.referralCode : ''
			}`,
		}}
	/>
);

const PublicView = (props: RouteProps) => {
	// IE is not supported
	if (isUsingIEBrowser(navigator.userAgent)) {
		return <NotSupportedView />;
	}

	// Save UTM marketing data to local storage
	if (props.location) {
		saveUtmDataToLocalStorage(qs.parse(props.location.search) as unknown as UtmData);
	}

	return (
		<Switch>
			<Route exact path={RoutesUrls.DOCUMENTATION} component={DocumentationView} />
			<Route exact path={RoutesUrls.LOGIN} component={LoginView} />
			<Redirect exact from="/" to={RoutesUrls.LOGIN} />
			<Route exact path={`${RoutesUrls.SIGN_UP_CREATE_ACCOUNT}/:referralCode?`} component={SignUpView} />
			<Route exact path={`${RoutesUrls.SIGN_UP_FILL_DETAILS}`} component={SignUpView} />
			<Route exact path={`${RoutesUrls.SIGN_UP}/:referralCode?`} render={renderReferralCodeRedirect} />
			<Route exact path={`${RoutesUrls.REGISTER_INVITE}/:inviteId?`} component={RegisterInviteView} />
			<Route exact path={RoutesUrls.PASSWORD_RECOVERY} component={PasswordRecoveryView} />
			<Route exact path={`${RoutesUrls.RESET_PASSWORD}/:token?`} component={ResetPasswordView} />
			<Route exact path={RoutesUrls.EMAIL_VERIFIED} component={EmailVerified} />
			<Route exact path={`${RoutesUrls.EMAIL_INVOICE_EXPIRED}/:emailInvoiceId?`} component={EmailInvoiceExpired} />
			<Route exact path={`${RoutesUrls.MERCHANT_LIMIT_EXCEEDED}`} component={MerchantLimitExceeded} />
			<Route exact path={`${RoutesUrls.ESCROW_BUYER_DETAILS}/:escrowId?`} component={EscrowBuyerView} />
			<Route exact path={`${RoutesUrls.EXTERNAL_INVOICE}/:externalInvoiceId?`} component={ExternalInvoiceView} />
			<Route path="*" component={NotFoundView} />
		</Switch>
	);
};

export default withRouter(PublicView);
