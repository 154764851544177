import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { Error } from '../../../../../components/react-hook-form/HookFieldsStyle';
import { Escrow } from '../../../../../api/escrowApi';
import Button from '../../../../../components/button/Button';
import {
	EscrowSupportModalWrapper,
	ModalButtonWrapper,
} from '../../../../../components/escrow-state-display/EscrowStateDisplayStyle';
import Modal from '../../../../../components/modal/Modal';
import HookTextAreaField from '../../../../../components/react-hook-form/HookTextAreaField';
import ReactHookForm from '../../../../../components/react-hook-form/ReactHookFormStyle';
import { useStoreActions } from '../../../../../services/store';

interface Props {
	escrow: Escrow;
	handleCloseModal: () => void;
}

interface OpenDisputeEscrowFields {
	description: string;
}

export default function EscrowOpenDisputeModal(props: Props) {
	const { escrow, handleCloseModal } = props;

	const [errorMessage, setErrorMessage] = useState('');
	const [isLoading, setIsLoading] = useState(false);

	const { openDisputeEscrow } = useStoreActions((actions) => ({
		...actions.escrow,
	}));

	const schema = Yup.object().shape({
		description: Yup.string()
			.required('Problem description required')
			.trim('Description cannot be left empty of filled with only spaces'),
	});

	const defaultValues = {
		description: '',
	};

	const methods = useForm<OpenDisputeEscrowFields>({
		resolver: yupResolver(schema),
		defaultValues: defaultValues,
	});

	const handleEscrowOpenDispute = async (input: OpenDisputeEscrowFields) => {
		if (input.description && input.description.length === 0) {
			return;
		}

		setIsLoading(true);
		const response = await openDisputeEscrow({
			escrowId: escrow.id,
			token: escrow.sellerParty.token,
			reason: input.description,
		});
		setIsLoading(false);

		if (response.success) {
			window.location.reload();
			toast.success('Dispute has been opened by the seller');
			return;
		}

		setErrorMessage(
			'Something went wrong, please try again. If problem persists, try adding contacting dagpay support directly',
		);
	};

	return (
		<Modal
			shouldCloseOnOverlayClick={true}
			isOpen={true}
			ariaHideApp={false}
			onRequestClose={handleCloseModal}
			small
			hasCloseButton
		>
			<EscrowSupportModalWrapper>
				<FormProvider {...methods}>
					<ReactHookForm onSubmit={methods.handleSubmit(handleEscrowOpenDispute)} id="escrowSupportModal">
						<h2>Open dispute</h2>
						<p>
							If the buyer hasn’t released the payment yet, please contact the buyer first for more info. However, if
							the buyer hasn’t been responding for a while, you may open a dispute.
						</p>
						<HookTextAreaField
							label="Description"
							name="description"
							placeholder="Describe the reason for the dispute"
							rows={3}
						/>
						<span>The response will be sent to your email</span>
						<ModalButtonWrapper>
							<Button.Secondary white onClick={handleCloseModal}>
								Cancel
							</Button.Secondary>
							<Button.Secondary type="submit" green disabled={isLoading}>
								Send
							</Button.Secondary>
						</ModalButtonWrapper>
					</ReactHookForm>
				</FormProvider>
				<Error>{errorMessage}</Error>
			</EscrowSupportModalWrapper>
		</Modal>
	);
}
