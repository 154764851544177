export default function trimStringCenter(str: string, len: number) {
	// too short to trim
	// compensate later added ellipses
	if (str.length <= len + 3) {
		return str;
	}

	const half = Math.floor(len / 2);

	const start = str.substr(0, half);
	const end = str.substr(str.length - half, half);

	return `${start}...${end}`;
}
