import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router';

import { useStoreActions, useStoreState } from '../../services/store';

function LogOutView() {
	const [isLoggedOut, setIsLoggedOut] = useState(false);

	const { activeViewer } = useStoreState((state) => ({
		activeViewer: state.viewer.activeViewer,
	}));

	const { logout } = useStoreActions((actions) => ({
		...actions.viewer,
	}));

	useEffect(() => {
		const fetchLogout = async () => {
			await logout();
		};
		fetchLogout();

		if (activeViewer === null) {
			setIsLoggedOut(true);
		}
	}, [activeViewer, logout]);

	if (isLoggedOut) {
		return <Redirect to="/" />;
	}

	return null;
}

export default LogOutView;
