import { Rates } from '../api/ratesApi';
import { Currency, ESCROW_FEE } from '../constants';
import floatToMicro from './float-to-micro';
import microToFloat from './micro-to-float';
import round from './round';
import { roundCoinAmount } from './round-coin-amount';

interface CurrencyPairInfo {
	totalCoinAmount: number;
	totalCurrencyAmount: number;
	secondaryCurrency: Currency;
	rate: number;
	fee: number;
}

interface Props {
	currencyAmount: number | null;
	currency: Currency;
	activeViewerBaseCurrency: Currency;
	rates: Rates | null;
	escrowFee?: number;
}

const getCurrencyPairInfo = (props: Props): CurrencyPairInfo => {
	const { currencyAmount, currency, escrowFee = ESCROW_FEE, rates, activeViewerBaseCurrency } = props;

	const secCur = getSecondaryCurrency(currency, activeViewerBaseCurrency);
	const fee = currencyAmount ? roundCoinAmount(escrowFee) : 0;

	if (!rates || !currencyAmount) {
		return { totalCoinAmount: 0, totalCurrencyAmount: 0, secondaryCurrency: secCur, rate: 0, fee };
	}

	let totalCoins;
	let totalFiat;

	const microFee = floatToMicro(escrowFee);

	if (currency === Currency.DAG) {
		// use activeViwer base currency for comparison, if its dag, use EUR rate
		const rate = activeViewerBaseCurrency === Currency.DAG ? rates.EUR : rates[activeViewerBaseCurrency as keyof Rates];
		const microCoins = floatToMicro(currencyAmount);

		totalCoins = microToFloat(microCoins + microFee);
		totalFiat = round(totalCoins * rate);

		return {
			totalCoinAmount: totalCoins,
			totalCurrencyAmount: totalFiat,
			secondaryCurrency: secCur,
			rate,
			fee,
		};
	}

	const rate = rates[currency as keyof Rates];
	const microCoins = floatToMicro(currencyAmount / rate);
	totalCoins = microToFloat(microCoins + microFee);
	totalFiat = round(totalCoins * rate);

	return { totalCoinAmount: totalCoins, totalCurrencyAmount: totalFiat, secondaryCurrency: secCur, rate, fee };
};

function getSecondaryCurrency(currency: Currency, activeViewerBaseCurrenyc: Currency): Currency {
	return currency === Currency.DAG
		? activeViewerBaseCurrenyc === Currency.DAG
			? Currency.EUR
			: activeViewerBaseCurrenyc
		: currency;
}

export default getCurrencyPairInfo;
