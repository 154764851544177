import React from 'react';
import styled, { css } from 'styled-components/macro';

import { Color } from '../../gfx/constants';

interface Props {
	coinAmount: number;
}

export const DagAmount = styled.div<{ notSet: boolean }>`
	line-height: 18px;
	font-family: PrimaryMedium;

	span {
		color: ${Color.GREEN_3};
		font-family: PrimaryMedium;

		${(props) =>
			props.notSet &&
			css`
				color: ${Color.ORANGE_INFO};
				font-family: Primary;
			`}
	}
`;

export default function ColoredCustomerLimit(props: Props) {
	const { coinAmount } = props;

	return (
		<DagAmount notSet={!coinAmount}>
			{coinAmount} <span>{coinAmount ? 'DAG' : 'Not set'}</span>
		</DagAmount>
	);
}
