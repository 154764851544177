import { FormikFieldState } from 'formik-fields';
import * as React from 'react';

import { FieldTypes } from '../../constants';

import { CheckboxMark, CheckboxWrap, StyledCheckbox } from './CheckboxStyle';

interface Props {
	tabIndex?: number;
	children?: React.ReactNode;
	className?: string;
	checked?: boolean;
	defaultChecked?: boolean;
	formikField: FormikFieldState<string | boolean>;
	disabled?: boolean;
	isAlignedLeft?: boolean;
	isCentered?: boolean;
	error?: string;
	name?: string;
	onChange?(event: React.ChangeEvent<HTMLInputElement>): void;
}

export default function Checkbox(props: Props) {
	const {
		children,
		defaultChecked,
		formikField,
		disabled = false,
		isCentered = false,
		onChange,
		checked,
		tabIndex,
	} = props;

	return (
		<CheckboxWrap isCentered={isCentered}>
			<StyledCheckbox
				type={FieldTypes.CHECKBOX}
				onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
					if (onChange) {
						return onChange(event);
					}

					return formikField.setValue(formikField.value ? false : true);
				}}
				defaultChecked={defaultChecked}
				disabled={disabled}
				checked={checked ? checked : formikField.value === true}
				name={formikField.name}
			/>
			<CheckboxMark
				tabIndex={tabIndex}
				onKeyPress={(event: React.KeyboardEvent) => {
					if (event.key === 'Enter') {
						return formikField.setValue(formikField.value ? false : true);
					}
				}}
			/>
			{children}
		</CheckboxWrap>
	);
}
