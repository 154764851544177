import { ErrorMessage } from '@hookform/error-message';
import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Phone from 'react-phone-number-input';
import styled from 'styled-components/macro';

import { media } from '../../services/media';

import { Border, Error, FieldWrapper, Label } from './HookFieldsStyle';

interface Props {
	name: string;
	label?: string;
	value?: string;
	disabled?: boolean;
}

const StyledPhone = styled(Phone)`
	width: 100%;
	font-size: 16px;

	${media.maxSize481`
		& > div {
			height: 35px;
			min-height: 35px;
		}
	`}
`;

export default function HookPhoneField(props: Props) {
	const { name, label, value, disabled } = props;
	const { control } = useFormContext();

	return (
		<FieldWrapper>
			<Label>{label}</Label>
			<Border>
				<Controller
					as={StyledPhone}
					onChange={(e: any) => e}
					name={name}
					control={control}
					placeholder="+xxx xxxx xxxx"
					defaultValue={value}
					disabled={disabled}
				/>
			</Border>
			<Error>
				<ErrorMessage name={name} />
			</Error>
		</FieldWrapper>
	);
}
