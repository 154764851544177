import * as React from 'react';
import ReactModal from 'react-modal';
import styled from 'styled-components/macro';

import { Color, Gutter } from '../../gfx/constants';
import { H2 } from '../../gfx/globals';
import { media } from '../../services/media';
import { StyledPanel } from '../panel/Panel';

interface ModalProps extends ReactModal.Props {
	children: JSX.Element | JSX.Element[];
	small?: boolean;
	medium?: boolean;
	hasCloseButton?: boolean;
	backgroundColor?: Color;
	borderColor?: Color;
	closeButtonColor?: Color;
}

interface ContentProps extends ModalSize {
	backgroundColor?: Color;
	borderColor?: Color;
}

interface ModalSize {
	small?: boolean;
	medium?: boolean;
}

const Content = styled.div<ContentProps>`
	width: 100%;
	padding: 32px 30px;
	border: ${(props) => (props.borderColor ? `1px solid ${props.borderColor}` : '1px solid rgba(220, 221, 224, 0.5)')};
	border-radius: 4px;
	background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : Color.WHITE)};
	margin: ${Gutter.SMALL};
	max-width: ${(props) => (props.small ? '362px' : props.medium ? '500px' : '624px')};
	max-height: calc(100% - 2 * ${Gutter.SMALL});
	overflow: auto;
	box-shadow: none;
	position: relative;
`;

const Buttons = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	margin-top: 25px;

	button {
		margin: 5px;
	}
`;

const Wrapper = styled(ReactModal)`
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	padding: 50px;
	outline: none;
	overflow-y: hidden !important;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: transparent;

	${media.maxDesktop`
		padding: 0;
	`}

	${media.fromDesktop`
		display: flex;
		align-items: center;
		justify-content: center;
	`}

	${H2} {
		text-align: center;
	}

	p {
		font-size: 14px;
	}

	label {
		color: #2d354a;
		font-family: PrimaryMedium;
		font-size: 15px;
		font-weight: 500;
		line-height: 18px;
	}

	${StyledPanel} {
		${media.fromDesktop`
			max-width: 100%;
			width: 690px;
			transform: none !important;
			top: auto !important;
			left: auto !important;
		`}
	}
`;

export const CloseButton = styled.div<{ closeButtonColor?: Color }>`
	height: 24px;
	width: 24px;
	position: absolute;
	right: 18px;
	top: 18px;
	cursor: pointer;

	:hover {
		background: rgba(255, 255, 255, 0.21);
		border-radius: 50%;
	}

	:before {
		position: absolute;
		width: 12px;
		height: 2px;
		content: '';
		transform: rotate(-45deg) translate(-3.2px, 12px);
		font-size: 12px;
		background: ${(props) => (props.closeButtonColor ? props.closeButtonColor : 'gray')};
		border-radius: 0.5px;
	}
	:after {
		position: absolute;
		width: 12px;
		height: 2px;
		content: '';
		transform: rotate(45deg) translate(12px, 3.2px);
		font-size: 12px;
		background: ${(props) => (props.closeButtonColor ? props.closeButtonColor : 'gray')};
		border-radius: 0.5px;
	}
`;

const Modal = (props: ModalProps) => {
	const x = props.onRequestClose;

	return (
		<Wrapper {...props}>
			<Content
				small={props.small}
				medium={props.medium}
				backgroundColor={props.backgroundColor}
				borderColor={props.borderColor}
			>
				{props.hasCloseButton && x && (
					<CloseButton closeButtonColor={props.closeButtonColor} onClick={(e: React.MouseEvent) => x(e)}></CloseButton>
				)}
				{props.children}
			</Content>
		</Wrapper>
	);
};

Modal.Wrapper = Wrapper;
Modal.Content = Content;
Modal.Buttons = Buttons;

export default Modal;
