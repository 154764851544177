import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import ReactSVG from 'react-svg';

import { RoutesUrls, Scopes } from '../../constants';
import { RouteProps } from '../../typings';
import Button from '../button/Button';
import WithPermission from '../with-permission/WithPermission';
import { InvoiceButtonWrapper, DropdownContainer, DropdownOption } from './CreateInvoiceBlockStyle';

function CreateInvoiceBlock(props: RouteProps) {
	const [isOpened, setIsOpened] = useState(false);
	const [activationButtonRef] = useState(React.createRef<HTMLDivElement>());
	const [dropdownRef] = useState(React.createRef<HTMLDivElement>());

	useEffect(() => {
		const toggleDropdown = (event: MouseEvent) => {
			const clickedElement = event.target;

			if (!activationButtonRef || !activationButtonRef.current || !(clickedElement instanceof Node)) {
				return;
			}

			// clicked outside, close
			if (
				(dropdownRef &&
					dropdownRef.current &&
					!activationButtonRef.current.contains(clickedElement) &&
					!dropdownRef.current.contains(clickedElement)) ||
				(dropdownRef &&
					dropdownRef.current &&
					!dropdownRef.current.contains(clickedElement) &&
					activationButtonRef.current.contains(clickedElement))
			) {
				setIsOpened(false);

				return;
			}

			// open only, when closed and clicked activation button
			if (!isOpened && activationButtonRef.current.contains(clickedElement)) {
				setIsOpened(true);

				return;
			}
		};

		document.addEventListener('mousedown', toggleDropdown);

		return () => {
			document.removeEventListener('mousedown', toggleDropdown);
		};
	}, [dropdownRef, activationButtonRef, isOpened]);

	const handleRedirectToCreateEmailInvoice = () => {
		props.history.push(RoutesUrls.EMAIL_INVOICES_DETAIL);
	};

	const handleRedirectToCreateRecurringInvoice = () => {
		props.history.push(RoutesUrls.RECURRING_INVOICES_DETAIL);
	};

	return (
		<InvoiceButtonWrapper>
			<WithPermission
				permissions={[
					Scopes.RECURRING_INVOICES,
					Scopes.EMAIL_INVOICES,
					Scopes.CREATE_EMAIL_INVOICE,
					Scopes.CREATE_RECURRING_INVOICE,
				]}
			>
				<Button.Secondary marginBottom={4} green ref={activationButtonRef}>
					Create new
				</Button.Secondary>
				{isOpened && (
					<DropdownContainer ref={dropdownRef}>
						<WithPermission permissions={[Scopes.EMAIL_INVOICES, Scopes.CREATE_EMAIL_INVOICE]}>
							<DropdownOption onClick={handleRedirectToCreateEmailInvoice}>
								<ReactSVG src="/files/svg/invoice/Invoice.svg" />
								Invoice
							</DropdownOption>
							<WithPermission permissions={[Scopes.RECURRING_INVOICES, Scopes.CREATE_RECURRING_INVOICE]}>
								<DropdownOption onClick={handleRedirectToCreateRecurringInvoice}>
									<ReactSVG src="/files/svg/invoice/Recurring.svg" />
									Recurring Invoice
								</DropdownOption>
							</WithPermission>
						</WithPermission>
					</DropdownContainer>
				)}
			</WithPermission>
		</InvoiceButtonWrapper>
	);
}

export default withRouter(CreateInvoiceBlock);
