import { Action, action, thunk, Thunk, ThunkOn, thunkOn } from 'easy-peasy';

import {
	createWalletApi,
	CreateWalletRequest,
	deleteWalletApi,
	DeleteWalletRequest,
	getWalletApi,
	GetWalletRequest,
	getWalletsApi,
	updateWalletApi,
	UpdateWalletRequest,
	Wallet,
	WalletWithUsageDate,
} from '../api/walletApi';
import handleFetch from '../services/handle-fetch';
import { ApiArrayPayload, ApiGetRequest, Response, ResponseArray } from '../typings';

import { StoreModel } from '.';

export interface WalletModel {
	// state
	wallets: ApiArrayPayload<WalletWithUsageDate> | null;

	// actions
	saveWallets: Action<WalletModel, ApiArrayPayload<WalletWithUsageDate> | null>;
	clearWallets: Action<WalletModel>;

	// thunks
	getWallets: Thunk<WalletModel, ApiGetRequest, {}, StoreModel, ResponseArray<WalletWithUsageDate>>;
	getWallet: Thunk<WalletModel, GetWalletRequest, {}, StoreModel, Response<Wallet>>;
	createWallet: Thunk<WalletModel, CreateWalletRequest, {}, StoreModel, Response<Wallet>>;
	updateWallet: Thunk<WalletModel, UpdateWalletRequest, {}, StoreModel, Response<Wallet>>;
	deleteWallet: Thunk<WalletModel, DeleteWalletRequest, {}, StoreModel, Response<Wallet>>;

	// listeners
	listeners: ThunkOn<WalletModel, {}, StoreModel>;
}

const wallet: WalletModel = {
	// state
	wallets: null,

	// actions
	saveWallets: action((state, payload) => {
		state.wallets = payload;
	}),
	clearWallets: action((state) => {
		state.wallets = null;
	}),

	// thunks
	getWallets: thunk(async (actions, payload) => {
		actions.clearWallets();

		const result = await handleFetch<ApiArrayPayload<WalletWithUsageDate>>(getWalletsApi(payload));

		actions.saveWallets(result.payload);

		return result;
	}),
	getWallet: thunk(async (actions, payload) => {
		return await handleFetch<Wallet>(getWalletApi(payload));
	}),
	createWallet: thunk(async (actions, payload) => {
		return await handleFetch<Wallet>(createWalletApi(payload));
	}),
	updateWallet: thunk(async (actions, payload) => {
		return await handleFetch<Wallet>(updateWalletApi(payload));
	}),
	deleteWallet: thunk(async (actions, payload) => {
		const result = await handleFetch<Wallet>(deleteWalletApi(payload));
		//only refresh the list from database if the wallet was successfully deleted
		if (result && result.success) {
			actions.getWallets({});
		}
		return result;
	}),

	listeners: thunkOn(
		(actions, storeActions) => [actions.deleteWallet, actions.createWallet, actions.updateWallet],
		(actions, payload) => {
			actions.getWallets({});
		},
	),
};

export default wallet;
