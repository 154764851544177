import axios from 'restyped-axios';
import { Currency } from '../constants';
import { ApiResponse } from '../typings';

export const api = axios.create<ExternalInvoiceApi>();

export interface ExternalInvoiceApi {
	'/external-invoices/:externalInvoiceId': {
		GET: {
			response: ApiResponse<ExternalInvoice>;
		};
	};
	'/external-invoices/cancel': {
		POST: {
			body: CancelExternalInvoiceRequest;
			response: ApiResponse<ExternalInvoice>;
		};
	};
	'/external-invoices/expire': {
		POST: {
			body: ExpireExternalInvoiceRequest;
			response: ApiResponse<ExternalInvoice>;
		};
	};
	'/external-invoices/': {
		PUT: {
			body: UpdateExternalInvoiceRequest;
			response: ApiResponse<ExternalInvoice>;
		};
	};
}

export interface UpdateExternalInvoiceRequest {
	externalInvoiceId: string;
	paymentCurrency: string | null;
	customerName: string | null;
	customerEmail: string | null;
}

export interface ExternalInvoice {
	id: string;
	receivingWallet: string;
	issuer: string;
	receiver: string;
	status: ExternalInvoiceStatus;
	baseAmount: string;
	baseCurrency: Currency | ExternalInvoiceCurrency;
	paymentCurrency: ExternalInvoiceCurrency;
	paymentAmount: string;
	eurAmount: number;
	coinAmount: number;
	customerEmail: string | null;
	customerName: string | null;
	product: string | null;
	statusUrl: string;
	updatedDate: string;
	createdDate: string;
	expiryDate: string;
	payViewUrl: string;
	validForSeconds: number;
	qrCodeUrl: string;
}

export enum ExternalInvoiceStatus {
	PENDING = 'PENDING',
	PAID = 'PAID',
	EXPIRED = 'EXPIRED',
	CANCELLED = 'CANCELLED',
	FAILED = 'FAILED',
	UNDERPAID = 'UNDERPAID',
	OVERPAID = 'OVERPAID',
	PAID_EXPIRED = 'PAID_EXPIRED',
	UNDERPAID_EXPIRED = 'UNDERPAID_EXPIRED',
	OVERPAID_EXPIRED = 'OVERPAID_EXPIRED',
	PAID_CANCELLED = 'PAID_CANCELLED',
	UNDERPAID_CANCELLED = 'UNDERPAID_CANCELLED',
	OVERPAID_CANCELLED = 'OVERPAID_CANCELLED',
}

export enum ExternalInvoiceCurrency {
	BTC = 'BTC',
	BCH = 'BCH',
	ETH = 'ETH',
	LTC = 'LTC',
	USDT = 'USDT',
	USDC = 'USDC',
}

export interface GetExternalInvoiceRequest {
	externalInvoiceId: string;
}

export type CancelExternalInvoiceRequest = GetExternalInvoiceRequest;

export type ExpireExternalInvoiceRequest = GetExternalInvoiceRequest;

export const getExternalInvoiceApi = async (params: GetExternalInvoiceRequest) => {
	const response = await api.get<'/external-invoices/:externalInvoiceId'>(
		`/external-invoices/${params.externalInvoiceId}`,
	);

	return response.data;
};

export const cancelExternalInvoiceApi = async (params: CancelExternalInvoiceRequest) => {
	const response = await api.post('/external-invoices/cancel', { ...params });

	return response.data;
};

export const expireExternalInvoiceApi = async (params: ExpireExternalInvoiceRequest) => {
	const response = await api.post('/external-invoices/expire', { ...params });

	return response.data;
};

export const updateExternalInvoiceApi = async (params: UpdateExternalInvoiceRequest) => {
	const response = await api.put('/external-invoices/', { ...params });

	return response.data;
};
