import styled, { keyframes } from 'styled-components/macro';
import { Color } from '../../gfx/constants';
import { media } from '../../services/media';

export const CopyQrInfo = styled.div`
	display: flex;
	align-items: center;
	position: relative;
	margin-top: 18px;
	font-size: 14px;
	line-height: 16px;
	font-weight: 500;
	text-decoration: underline;
	cursor: pointer;

	& > div {
		margin-left: 10px;
	}

	svg {
		position: absolute;
		top: -1px;
	}

	${media.maxSize800`
		left: 45px;
	`}
	${media.maxMobile`
		left: 15px;
	`}
`;

export const fadeAnimation = keyframes`
   0% { opacity: 0; }
   15% { opacity: 1; }
   85% { opacity: 1; }
   100% { opacity: 0; }
`;

export const PopUpWrapper = styled.div`
	position: absolute;
	top: 30px;
	left: -60px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 20px;
	height: 35px;
	background-color: ${Color.ORANGE};
	border-radius: 4px;
	color: white;
	animation: ${fadeAnimation};
	animation-duration: 2s;
	animation-iteration-count: 1;
	pointer-events: none;
	white-space: nowrap;

	&:before {
		content: '';
		position: absolute;
		top: -8px;
		right: 100px;
		border-style: solid;
		border-width: 0 7px 9px 7px;
		border-color: transparent transparent ${Color.ORANGE} transparent;
	}

	/* adjust svg to center */
	div,
	div div {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	svg {
		position: relative;
		margin-right: 10px;
	}

	${media.maxMobile`
		top: -50px;

		&:before {
		content: '';
		position: absolute;
		top: 35px;
		right: 100px;
		border-style: solid;
		border-width: 9px 7px 0 7px;
		border-color: ${Color.ORANGE} transparent transparent transparent;
	}
	`}
`;

export const CopiedMessage = styled.div`
	font-family: Primary;
	font-size: 12px;
	line-height: 16px;
`;
