import { CFormikProps, FormikFields as FormikForm, FormikFieldsState } from 'formik-fields';
import React, { useRef } from 'react';
import Fade from 'react-reveal';
import ReactSVG from 'react-svg';
import * as Yup from 'yup';

import { InvoiceState, InvoiceTypes } from '../../../api/invoiceApi';
import Button, { ButtonTypes } from '../../../components/button/Button';
import Field from '../../../components/field/Field';
import Form from '../../../components/form/Form';
import { FieldTypes } from '../../../constants';
import { isInvoicePaid } from '../../../services/is-invoice-paid';
import {
	BoxHeader,
	CompleteArrow,
	DagcoinCardButton,
	DescriptionText,
	PayContent,
	PayContentBox,
	PaymentReceiptFocussableTitle,
	PaymentSentHeaderText,
	QrCode,
	QrWrap,
} from '../InvoiceViewStyle';
import { InvoiceViewTplProps } from '../InvoiceViewTpl';

import CopyQrInfoButton from '../../../components/copy-qr-info/CopyQrInfoButton';
import { DagcoinCardContent } from './DagcoinCard/DagcoinCardContent';
import InvoicePaymentProgress from './InvoicePaymentProgress';
import { ReturnToMerchantBlock } from './ReturnToMerchantBlock';

export type InvoiceContentBlockProps = Pick<
	InvoiceViewTplProps,
	| 'invoiceState'
	| 'invoice'
	| 'isReceiptSent'
	| 'isRepeatableSentActive'
	| 'emailReceiptInputValue'
	| 'handleSendReceipt'
	| 'onEmailReceiptFieldChange'
	| 'handleReceiptRepeatableSent'
	| 'location'
	| 'history'
>;

export interface EmailReceiptRequestFields {
	to: string;
}

const WAITING_FOR_CONFIRMATION_TEXT =
	'You may now close this window or enter your email address below to receive a receipt when payment is confirmed.';

const WAITING_FOR_CONFIRMATION_MERCHANT_TEXT =
	'If you do not wish to wait for confirmation, you may navigate back to the merchant using the button below.';

const CANCELLED_TEXT = 'Please return to merchant and start the payment process again.';

const CANCELLED_ESCROW_TEXT = 'Please return to escrow deal details and accept the deal again to proceed with payment';

const EXPIRED_TEXT = 'Please return to merchant and start the payment process again.';

const FAILED_TEXT =
	'Please check your DagWallet to see if any funds were transferred. If yes, please contact the merchant to resolve the issue manually.';

const CONFIRMED_TEXT = 'You may now close this window or enter your email address below to receive a receipt.';

const CONFIRMED_MERCHANT_TEXT =
	'If you do not wish to wait for confirmation, you may navigate back to the merchant using the button below';

export default function InvoiceContentBlock(props: InvoiceContentBlockProps) {
	const { invoice } = props;
	const payContentRef = useRef<HTMLDivElement | null>(null);

	if (!invoice) {
		return null;
	}
	const getStatus = () => {
		const { invoiceState } = props;

		switch (invoiceState) {
			case InvoiceState.PAID:
				return (
					<PaymentSentHeaderText isConfirmed>
						Payment sent and <span>Confirming ...</span> <span>Confirmed</span>
					</PaymentSentHeaderText>
				);

			case InvoiceState.WAITING_FOR_CONFIRMATION:
				return (
					<PaymentSentHeaderText>
						Payment sent and <span>Confirming...</span>
					</PaymentSentHeaderText>
				);

			case InvoiceState.EXPIRED:
				return (
					<PaymentSentHeaderText>
						Payment <span>expired!</span>
					</PaymentSentHeaderText>
				);

			case InvoiceState.FAILED:
				return (
					<PaymentSentHeaderText isFailed>
						Payment <span>failed!</span>
					</PaymentSentHeaderText>
				);

			case InvoiceState.CANCELLED:
				if (invoice.type === InvoiceTypes.ESCROW) {
					return (
						<PaymentSentHeaderText isFailed>
							Escrow payment <span>cancelled!</span>
						</PaymentSentHeaderText>
					);
				}
				return (
					<PaymentSentHeaderText isFailed>
						Payment <span>cancelled!</span>
					</PaymentSentHeaderText>
				);

			default:
				return '';
		}
	};

	const getPendingContent = () => {
		const { invoice } = props;

		const isCardPayment = /card-payment/.exec(props.location.pathname);

		return !invoice.atmOnly && isCardPayment && payContentRef ? (
			<DagcoinCardContent {...props} parentContainerRef={payContentRef} />
		) : (
			<>
				<BoxHeader>Pay invoice</BoxHeader>
				<DescriptionText>Scan QR code with your DagWallet to proceed with payment</DescriptionText>
				<QrWrap>
					<QrCode>
						<img alt="QR code" src={invoice ? invoice.qrCodeUrl : ''} />
						{/* <QrScanner /> */}
					</QrCode>
					{invoice.type !== InvoiceTypes.POS && (
						<CopyQrInfoButton
							qrInfo={invoice.id}
							label="Copy Payment ID"
							successMessage="Payment ID copied to clipboard"
						/>
					)}
				</QrWrap>
				{!invoice.atmOnly && (
					<DagcoinCardButton
						type={'button'}
						onClick={() => props.history.push(`${props.location.pathname}/card-payment`)}
					>
						Pay with Dagcoin card
					</DagcoinCardButton>
				)}
			</>
		);
	};

	const getDescriptionText = () => {
		const { invoice, invoiceState } = props;

		if (invoiceState === InvoiceState.CANCELLED && invoice.type === InvoiceTypes.ESCROW) {
			return CANCELLED_ESCROW_TEXT;
		}
		if (invoiceState === InvoiceState.FAILED && invoice.type !== InvoiceTypes.MERCHANT) {
			return FAILED_TEXT;
		}
		if (invoiceState === InvoiceState.CANCELLED && invoice.type !== InvoiceTypes.MERCHANT) {
			return CANCELLED_TEXT;
		}

		if (invoiceState === InvoiceState.EXPIRED && invoice.type !== InvoiceTypes.MERCHANT) {
			return EXPIRED_TEXT;
		}

		if (invoiceState === InvoiceState.WAITING_FOR_CONFIRMATION && invoice.type !== InvoiceTypes.MERCHANT) {
			return WAITING_FOR_CONFIRMATION_TEXT;
		}

		if (invoiceState === InvoiceState.WAITING_FOR_CONFIRMATION && invoice.type === InvoiceTypes.MERCHANT) {
			return WAITING_FOR_CONFIRMATION_MERCHANT_TEXT;
		}

		if (isInvoicePaid(invoiceState) && invoice.type !== InvoiceTypes.MERCHANT) {
			return CONFIRMED_TEXT;
		}

		if (isInvoicePaid(invoiceState) && invoice.type === InvoiceTypes.MERCHANT) {
			return CONFIRMED_MERCHANT_TEXT;
		}

		return '';
	};

	const renderReceiptForm = () => (
		fields: FormikFieldsState<EmailReceiptRequestFields>,
		_fieldActions: CFormikProps<EmailReceiptRequestFields>,
	) => {
		const {
			invoiceState,
			emailReceiptInputValue,
			isRepeatableSentActive,
			isReceiptSent,
			handleReceiptRepeatableSent,
		} = props;

		return (
			<Form.Receipt autoComplete="off">
				<Field
					autoFocus={isInvoicePaid(invoiceState) && emailReceiptInputValue !== ''}
					type={FieldTypes.EMAIL}
					formikField={fields.to}
					placeholder="Email address"
					addonNodeWithOwnStyling={
						<>
							<PaymentReceiptFocussableTitle>Email Address</PaymentReceiptFocussableTitle>
							<Button.SendReceipt
								type={ButtonTypes.SUBMIT}
								sent={isReceiptSent && !isRepeatableSentActive}
								onClick={handleReceiptRepeatableSent}
								onAnimationEnd={handleReceiptRepeatableSent}
							>
								{isReceiptSent && !isRepeatableSentActive ? (
									<>
										Sent
										<CompleteArrow>
											<ReactSVG src="/files/svg/invoice/CompleteArrow.svg" />
										</CompleteArrow>
									</>
								) : (
									<>
										Send
										<ReactSVG src="/files/svg/invoice/SendArrow.svg" />
									</>
								)}
							</Button.SendReceipt>
						</>
					}
				/>
			</Form.Receipt>
		);
	};

	const validateReceiptForm = (values: EmailReceiptRequestFields) => {
		const { onEmailReceiptFieldChange } = props;
		onEmailReceiptFieldChange(values.to);
	};

	const getReceiptForm = () => {
		const { invoiceState, emailReceiptInputValue } = props;

		return (
			<FormikForm<EmailReceiptRequestFields>
				fields={{
					to: {
						initialValue: isInvoicePaid(invoiceState) ? emailReceiptInputValue : '',
					},
				}}
				validationSchema={Yup.object<EmailReceiptRequestFields>().shape({
					to: Yup.string().email('Invalid email').required('Email is required'),
				})}
				validate={validateReceiptForm}
				onSubmit={props.handleSendReceipt}
				render={renderReceiptForm()}
			/>
		);
	};

	const getWaitingForConfirmationContent = () => {
		const { invoice, invoiceState } = props;

		return (
			<>
				<InvoicePaymentProgress invoiceState={invoiceState} />
				{getStatus()}
				<DescriptionText paddingTop="9px">{getDescriptionText()}</DescriptionText>
				{invoice.type !== InvoiceTypes.MERCHANT && getReceiptForm()}
				<ReturnToMerchantBlock invoice={invoice} invoiceState={invoiceState} />
			</>
		);
	};

	const isReceiptFormVisible = () => {
		const { invoice, invoiceState } = props;

		if (
			invoice.type !== InvoiceTypes.MERCHANT &&
			(isInvoicePaid(invoiceState) || invoiceState === InvoiceState.WAITING_FOR_CONFIRMATION)
		) {
			return true;
		}

		return false;
	};

	const getSuccessContent = () => {
		const { isReceiptSent, invoiceState, invoice } = props;

		if (!invoice) {
			return null;
		}

		return (
			<>
				<InvoicePaymentProgress invoiceState={invoiceState} isConfirmed isAnimationDisabled={isReceiptSent} />
				{getStatus()}
				<DescriptionText paddingTop="9px">{getDescriptionText()}</DescriptionText>
				{isReceiptFormVisible() && getReceiptForm()}
				<ReturnToMerchantBlock invoice={invoice} invoiceState={invoiceState} />
			</>
		);
	};

	const getContent = () => {
		const { invoiceState, invoice } = props;

		if (invoiceState === InvoiceState.PENDING) {
			// send google tag manager data
			window.dataLayer.push({
				event: 'transaction_initiated',
				category: 'transaction',
				transactionTotal: invoice.coinAmount,
			});

			return getPendingContent();
		}

		if (invoiceState === InvoiceState.WAITING_FOR_CONFIRMATION) {
			return getWaitingForConfirmationContent();
		}

		if (isInvoicePaid(invoiceState)) {
			// send google tag manager data
			window.dataLayer.push({
				event: 'transaction_completed',
				category: 'transaction',
				transactionTotal: invoice.coinAmount,
			});

			return getSuccessContent();
		}

		const userCanContactMerchant = invoiceState === InvoiceState.FAILED && invoice.type !== InvoiceTypes.MERCHANT;

		return (
			<>
				<InvoicePaymentProgress
					invoiceState={invoiceState}
					isError={
						invoiceState === InvoiceState.FAILED ||
						invoiceState === InvoiceState.CANCELLED ||
						invoiceState === InvoiceState.EXPIRED
					}
				/>
				{getStatus()}
				<DescriptionText paddingTop="9px">{getDescriptionText()}</DescriptionText>
				{isReceiptFormVisible() && getReceiptForm()}
				{userCanContactMerchant && (
					<Button.Pay href={`mailto: ${invoice.merchantEmail}`} transparent>
						<ReactSVG src="/files/svg/invoice/Mail.svg" />
						Contact merchant
					</Button.Pay>
				)}
				<ReturnToMerchantBlock invoice={invoice} invoiceState={invoiceState} />
			</>
		);
	};

	return (
		<PayContent ref={payContentRef}>
			<Fade>
				<PayContentBox>{getContent()}</PayContentBox>
			</Fade>
		</PayContent>
	);
}
