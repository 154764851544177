import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

import Loader from '../../../components/loader/Loader';
import { RoutesUrls } from '../../../constants';
import { useStoreActions, useStoreState } from '../../../services/store';
import { RouteProps, RouteSchema } from '../../../typings';

import DocumentationViewTpl from './DocumentationViewTpl';

export interface GroupedRoutes {
	[x: string]: RouteSchema[];
}

export type GetGroupedRoutesFn = (routes: RouteSchema[]) => GroupedRoutes;

// loads all markdown files from current directory
const markdownContext = require.context('!markdown-with-front-matter-loader!../../../../documentation/', false, /.md$/);

const markdownFiles = markdownContext.keys().reduce((files, filename) => {
	const content = markdownContext(filename).__content;

	files.set(filename, content);

	return files;
}, new Map<string, string>());

// Filter out unneeded groups and create new grouped object
const getGroupedRoutes: GetGroupedRoutesFn = (routes) => {
	const filteredGroups = ['mock', 'test', 'admin'];

	return routes
		.filter((route) => !filteredGroups.includes(route.group))
		.reduce(
			(acc, curr) => {
				acc[curr.group] = acc[curr.group] || [];
				acc[curr.group].push(curr);

				return acc;
			},
			// tslint:disable-next-line:no-any
			{} as any,
		);
};

const DocumentationView = ({ history, location }: RouteProps) => {
	const { schema } = useStoreState((state) => ({
		schema: state.schema.schema,
	}));

	const { getSchema } = useStoreActions((actions) => ({
		...actions.schema,
	}));

	useEffect(() => {
		const fetchSchema = async () => {
			await getSchema();
		};
		fetchSchema();
	}, [getSchema]);

	if (!schema || schema.routes.length === 0) {
		return <Loader />;
	}

	const routes = getGroupedRoutes(schema.routes);

	return (
		<DocumentationViewTpl
			routes={routes}
			documentation={markdownFiles.get('./DOCUMENTATION.md')}
			isPrivate={location.pathname === RoutesUrls.DOCUMENTATION ? true : false}
			history={history}
			location={location}
		/>
	);
};

export default withRouter(DocumentationView);
