import { Action, action, thunk, Thunk, thunkOn, ThunkOn } from 'easy-peasy';

import {
	BusinessVerification,
	CombinedVerificationsInfo,
	disableVerificationNotificationApi,
	DisableVerificationNotificationRequest,
	DisableVerificationNotificationResponse,
	getIdentityVerificationApi,
	getVerificationsApi,
	IdentityVerification,
	InitiateIdentityResponse,
	initiateVerifyIdentityApi,
	InitiateVerifyIdentityRequest,
	Verifications,
	verifyBusinessAddressApi,
	verifyBusinessApi,
	verifyBusinessOwnershipApi,
	VerifyBusinessRequest,
	VerifyBusinessTierRequest,
	verifyFreelanceApi,
} from '../api/verificationApi';
import handleFetch from '../services/handle-fetch';
import { Response } from '../typings';

import { StoreModel } from '.';

export interface VerificationFormData {
	businessName: string;
	registrationNumber: string;
	vatNumber: string;
	registrationDate: Date;
	email: string;
	phone: string;
	description: string;
	address?: string;
	countryCode?: string;
	city?: string;
	county?: string;
	postalCode?: string;
	website?: string;
	registrationImage?: File[];
	operationsImage?: File[];
}
export interface FreelanceVerificationRequest {
	businessName: string;
	registrationNumber?: string;
	email: string;
	phone: string;
	description: string;
	address?: string;
	countryCode?: string;
	city?: string;
	county?: string;
	postalCode?: string;
	website?: string;
}

export interface VerificationModel {
	// state
	verifications: CombinedVerificationsInfo | null;
	parentUserVerifications: CombinedVerificationsInfo | null;
	verificationFormData: VerificationFormData | null;

	// actions
	saveVerifications: Action<VerificationModel, CombinedVerificationsInfo | null>;
	clearVerifications: Action<VerificationModel>;
	saveParentVerifications: Action<VerificationModel, CombinedVerificationsInfo | null>;
	clearParentVerifications: Action<VerificationModel>;
	saveVerificationFormData: Action<VerificationModel, VerificationFormData | null>;
	clearVerificationFormData: Action<VerificationModel>;

	// thunks
	getVerifications: Thunk<
		VerificationModel,
		{ overrideState?: boolean },
		{},
		StoreModel,
		Response<CombinedVerificationsInfo>
	>;
	getParentUserVerifications: Thunk<
		VerificationModel,
		{ overrideVerificationsState?: boolean },
		{},
		StoreModel,
		Response<CombinedVerificationsInfo>
	>;
	getIdentityVerification: Thunk<VerificationModel, undefined, {}, StoreModel, Response<IdentityVerification>>;
	verifyBusiness: Thunk<VerificationModel, VerifyBusinessRequest, {}, StoreModel, Response<BusinessVerification>>;

	verifyBusinessAddress: Thunk<
		VerificationModel,
		VerifyBusinessTierRequest,
		{},
		StoreModel,
		Response<BusinessVerification>
	>;
	verifyBusinessOwnership: Thunk<
		VerificationModel,
		VerifyBusinessTierRequest,
		{},
		StoreModel,
		Response<BusinessVerification>
	>;
	verifyFreelance: Thunk<
		VerificationModel,
		FreelanceVerificationRequest,
		{},
		StoreModel,
		Response<BusinessVerification>
	>;
	initiateVerifyIdentity: Thunk<
		VerificationModel,
		InitiateVerifyIdentityRequest,
		{},
		StoreModel,
		Response<InitiateIdentityResponse>
	>;
	disableVerificationNotification: Thunk<
		VerificationModel,
		DisableVerificationNotificationRequest,
		{},
		StoreModel,
		Response<DisableVerificationNotificationResponse>
	>;
	// listeners
	listeners: ThunkOn<VerificationModel, {}, StoreModel>;
}

const verification: VerificationModel = {
	// state
	verifications: null,
	parentUserVerifications: null,
	verificationFormData: null,

	// actions
	saveVerifications: action((state, payload) => {
		state.verifications = payload;
	}),
	clearVerifications: action((state) => {
		state.verifications = null;
	}),
	saveParentVerifications: action((state, payload) => {
		state.parentUserVerifications = payload;
	}),
	clearParentVerifications: action((state) => {
		state.parentUserVerifications = null;
	}),
	saveVerificationFormData: action((state, payload) => {
		state.verificationFormData = payload;
	}),
	clearVerificationFormData: action((state) => {
		state.verificationFormData = null;
	}),

	// thunks
	getVerifications: thunk(async (actions, { overrideState = true }) => {
		if (overrideState) {
			actions.clearVerifications();
		}

		const result = await handleFetch<Verifications>(getVerificationsApi());

		const userPayload = result.payload && result.payload.user;

		if (overrideState) {
			actions.saveVerifications(userPayload);
		}

		return { ...result, payload: userPayload };
	}),
	//
	getParentUserVerifications: thunk(async (actions, { overrideVerificationsState = false }) => {
		// Clear previous parent state
		actions.clearParentVerifications();

		// Clear previous user state
		if (overrideVerificationsState) {
			actions.clearVerifications();
		}

		// fetch new payload and pick parent info
		const result = await handleFetch<Verifications>(getVerificationsApi());
		const parentPayload = result.payload && result.payload.parentUser;

		// when parent verification is needed instead of user verifications info
		if (overrideVerificationsState) {
			actions.saveVerifications(parentPayload);
		}

		// save into parent state for later use
		actions.saveParentVerifications(parentPayload);

		return { ...result, payload: parentPayload };
	}),
	getIdentityVerification: thunk(async (_actions) => {
		return await handleFetch<IdentityVerification>(getIdentityVerificationApi());
	}),
	verifyBusiness: thunk(async (_actions, payload) => {
		return await handleFetch<BusinessVerification>(verifyBusinessApi(payload));
	}),

	verifyFreelance: thunk(async (_actions, payload) => {
		return await handleFetch<BusinessVerification>(verifyFreelanceApi(payload));
	}),
	verifyBusinessAddress: thunk(async (_actions, payload) => {
		return await handleFetch<BusinessVerification>(verifyBusinessAddressApi(payload));
	}),
	verifyBusinessOwnership: thunk(async (_actions, payload) => {
		return await handleFetch<BusinessVerification>(verifyBusinessOwnershipApi(payload));
	}),
	initiateVerifyIdentity: thunk(async (actions, payload) => {
		const result = await handleFetch<InitiateIdentityResponse>(initiateVerifyIdentityApi(payload));
		actions.getVerifications({});

		return result;
	}),

	disableVerificationNotification: thunk(async (actions, payload) => {
		const result = await handleFetch<DisableVerificationNotificationResponse>(
			disableVerificationNotificationApi(payload),
		);
		actions.getVerifications({});

		return result;
	}),

	// listeners
	listeners: thunkOn(
		(actions, storeActions) => [
			actions.verifyBusiness,
			actions.verifyFreelance,
			actions.verifyBusinessAddress,
			actions.verifyBusinessOwnership,
			actions.initiateVerifyIdentity,
		],
		(actions, payload) => {
			actions.getVerifications({});
		},
	),
};

export default verification;
