import React from 'react';

import { ExtendedInvitedUser } from '../../api/inviteApi';
import Button from '../button/Button';
import { EscrowSupportModalWrapper, ModalButtonWrapper } from '../escrow-state-display/EscrowStateDisplayStyle';
import Modal from '../modal/Modal';

interface Props extends ReactModal.Props {
	activeIndex: number;
	activeUser?: ExtendedInvitedUser;
	title: string;
	notification: string;
	notificationStrong: string;
	actionButtonTitle: string;
	red?: boolean;
	green?: boolean;
	handleClose(index: number): void;
	handleAction: () => void;
}

export default function ActionModal(props: Props) {
	const {
		activeIndex,
		activeUser,
		title,
		notification,
		notificationStrong,
		actionButtonTitle,
		handleClose,
		handleAction,
		red,
		green,
		...rest
	} = props;

	return (
		<Modal small hasCloseButton onRequestClose={() => handleClose(activeIndex)} ariaHideApp={false} {...rest}>
			<EscrowSupportModalWrapper>
				<h2>{title}</h2>
				<p>
					{notification}
					<strong>{notificationStrong}</strong>
				</p>
				<ModalButtonWrapper>
					<Button.Secondary white onClick={() => handleClose(activeIndex)}>
						Cancel
					</Button.Secondary>
					<Button.Secondary green={green} red={red} onClick={handleAction}>
						{actionButtonTitle}
					</Button.Secondary>
				</ModalButtonWrapper>
			</EscrowSupportModalWrapper>
		</Modal>
	);
}
