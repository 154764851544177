import { FormikFieldState } from 'formik-fields';
import * as React from 'react';
import styled from 'styled-components/macro';

import { media } from '../../services/media';
import { StyledInputMixin } from '../field/FieldStyle';

interface Props {
	formikField: FormikFieldState<string>;
	tabIndex?: number;
	placeholder: string;
}

const StyledTextArea = styled.textarea`
	${StyledInputMixin}
	display: block;
	resize: vertical;
	border: none;
	padding: 14px 18px;
	${media.maxDesktop`
		min-width: 0;
	`}

	&:disabled {
		background: #eeeff2;
	}

	&::-ms-clear,
	&::-ms-reveal {
		display: none;
	}

	input {
		flex-grow: 1;
	}
`;

export default function TextArea(props: Props) {
	const { formikField, tabIndex, placeholder } = props;

	const blurSelectInput = (_e: React.FocusEvent) => {
		if (props.formikField) {
			props.formikField.handleBlur();
		}
	};

	const changeInput = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		if (e.target.value && props.formikField) {
			props.formikField.setValue(e.target.value, true);
		}
	};

	return (
		<StyledTextArea
			name={formikField.name}
			onChange={changeInput}
			onBlur={blurSelectInput}
			tabIndex={tabIndex}
			placeholder={placeholder}
		/>
	);
}
