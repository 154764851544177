import styled from 'styled-components/macro';
import { Grid, Cell } from '../../../components/flex-grid/FlexGrid';
import { Color } from '../../../gfx/constants';
import { media } from '../../../services/media';

export const EscrowViewWrapper = styled.div`
	min-height: 100vh;
	width: 100%;
	overflow-y: auto;
	background: ${Color.GRAY_1};
	box-sizing: border-box;
	display: flex;
`;

export const EscrowBuyerWrapper = styled.div`
	max-width: 1200px;
	width: 100%;
	align-items: center;
	justify-content: center;
	margin: auto;
	${media.maxSize800`
		width: 100%;
		padding: 20px;
		margin: auto;
		flex-direction: column;
		padding: 22px 26px;
		${Grid} > ${Cell}:last-child {
				border-top: 1px solid ${Color.GRAY_5};
		}
	`}
`;

export const TimelineHeader = styled.h1`
	font-family: primaryMedium;
	color: ${Color.GRAY_9};
	font-size: 18px;
	line-height: 21px;
`;

export const HeaderWrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	svg {
		width: 110px;
	}
	span {
		a,
		a:visited {
			font-size: 14px;
			color: ${Color.GRAY_9};
			font-family: primary;
			line-height: 35px;
		}
	}
`;

export const TimeLineWrapper = styled.div`
	width: 100%;
	padding: 30px;
	${media.maxSize800`
		justify-content: flex-end;
		padding: 30px 0;
	`}
`;

export const EscrowStatusWrapper = styled.div`
	background: ${Color.GRAY_3};
	border: 1px solid ${Color.GRAY_5};
	padding: 22px 64px;
	border-radius: 4px;
	font-family: secondary;
	font-size: 14px;
	margin: 12px 0;
	display: flex;
	span {
		font-size: 16px;
		font-family: primaryMedium;
	}
`;
export const EscrowStatusButtons = styled.div`
	display: flex;
	button {
		margin: 5px;
	}
	${media.maxSize800`
		justify-content: flex-end;
	`}
`;
export const EscrowStatusContext = styled.div`
	width: 50%;
	overflow: none;
	position: relative;
	h2 {
		font-size: 16px;
		font-family: primaryMedium;
		line-height: 21px;
	}
	svg {
		position: absolute;
		left: -34px;
	}
	${media.maxSize800`
		width: 100%;
		padding-bottom: 44px;
		padding-left: 34px;
		svg {
			left: 0;
		}
	`}
`;

export const AmountField = styled.div`
	${media.maxSize800`
		display:flex;
		justify-content: space-between;

	`}
`;
