import { Rates } from '../api/ratesApi';
import { Currency } from '../constants';

interface Props {
	currencyAmount: string | number;
	rates: Rates | null;
	currency: Currency | null;
}

export default function getCalculatedInvoiceCoinSubtotal(props: Props) {
	const { currencyAmount, rates, currency } = props;

	const floatCurrencyAmount = parseFloat(currencyAmount.toString());
	let conversionRate = 0;
	let dagAmount = 0;

	if (rates && currency === Currency.DAG) {
		dagAmount = roundCoinAmount(floatCurrencyAmount);

		return dagAmount;
	}

	if (rates && currency !== Currency.DAG) {
		if (currency !== null) {
			conversionRate = rates[currency as keyof Rates];
		}
		dagAmount = roundCoinAmount(floatCurrencyAmount / conversionRate);

		return dagAmount;
	}

	return null;
}

function roundCoinAmount(coinAmount: number) {
	return microToFloat(floatToMicro(coinAmount));
}

function microToFloat(microValue: number): number {
	return microValue / 1000000;
}
function floatToMicro(floatValue: number): number {
	return Math.floor(floatValue * 1000000);
}
