import { FieldOptionProps } from '../components/field/Field';
import { BASE_CURRENCIES, Currency } from '../constants';

interface Props {
	current: Currency;
}

export function getBaseCurrencyOptions(props: Props) {
	const { current } = props;

	return BASE_CURRENCIES.reduce((acc, curr, index) => {
		acc[index] = { label: curr, value: curr, isInitialSelected: curr === current.toString() };

		return acc;
	}, [] as FieldOptionProps[]);
}
