import axios from 'restyped-axios';

import { BusinessType, Currency, BusinessTier } from '../constants';
import { ApiResponse } from '../typings';

import { User } from './userApi';
export const api = axios.create<AuthApi>();

export interface AuthApi {
	'/authentication/login': {
		POST: {
			body: LoginRequest;
			response: ApiResponse<User>;
		};
	};

	'/viewer': {
		GET: {
			response: ApiResponse<User>;
		};
	};

	'/viewer/statistics': {
		GET: {
			body: ViewerStatisticsRequest;
			response: ApiResponse<StatisticsResponse>;
		};
	};

	'/authentication/logout': {
		POST: {
			body: {};
			response: ApiResponse<User>;
		};
	};

	'/authentication/is-reset-password-token-valid': {
		POST: {
			query: IsResetPasswordTokenValidRequest;
			response: ApiResponse<IsResetPasswordTokenValidResponse>;
		};
	};

	'/authentication/send-reset-password': {
		POST: {
			body: ResetPasswordRequest;
			response: ApiResponse<ResetPasswordResponse>;
		};
	};

	'/authentication/resend-email-verification': {
		POST: {
			response: ApiResponse<User>;
		};
	};

	'/authentication/resend-login-code': {
		POST: {
			body: ResetPasswordRequest;
			response: ApiResponse<ResetPasswordResponse>;
		};
	};

	'/authentication/handle-reset-password': {
		POST: {
			response: ApiResponse<User>;
		};
	};

	'/authentication/enable-authenticator': {
		POST: {
			response: ApiResponse<AuthenticatorResponse>;
		};
	};
}

export interface IsResetPasswordTokenValidRequest {
	token: string;
}

export interface IsResetPasswordTokenValidResponse {
	isTokenValid: boolean;
}

export interface ResetPasswordRequest {
	email: string;
}

export interface HandleResetPasswordRequest {
	password: string;
	token: string;
}

export interface ViewerStatisticsRequest {
	startDate?: string;
	endDate?: string;
	unit?: string;
}

export interface ResetPasswordResponse {
	email: string;
}

export interface AuthenticatorResponse {
	loginSecret: string;
	authenticatorQrCodeUrl: string;
}

export interface LoginRequest {
	email: string;
	password: string;
	loginCode: string | number;
	remember: string | boolean;
	initialLogin?: boolean;
}

export interface Balance {
	dag: number;
	base: number;
}

export interface GraphData {
	startDate: string;
	endDate: string;
	dag: number;
	base: number;
}

export interface StatisticsResponse {
	baseCurrency: Currency;
	balance: Balance;
	grossVolume: Balance;
	successfulPaymentCount: number;
	graphData: GraphData[];
}
export interface BusinessTierLimitsResponse {
	baseCurrency: Currency;
	businessType: BusinessType;
	tier: BusinessTier;
	volumeLimit: {
		eur: number | null;
		dag: number | null;
		base: number | null;
	};
	monthlyGrossVolume: {
		eur: number;
		dag: number;
		base: number;
	};
	monthlyTotalVolume: {
		eur: number;
		dag: number;
		base: number;
	};
}

export const loginApi = async (params: LoginRequest) => {
	const response = await api.post('/authentication/login', {
		...params,
	});

	return response.data;
};

export const viewerApi = async () => {
	const response = await api.get('/viewer');

	return response.data;
};

export const viewerStatisticsApi = async (params: ViewerStatisticsRequest) => {
	const response = await api.get('/viewer/statistics', {
		params: { ...params },
	});

	return response.data;
};
export const viewerLimitsApi = async () => {
	const response = await api.get('/viewer/tierlimits');

	return response.data;
};

export const logoutApi = async () => {
	const response = await api.post('/authentication/logout');

	return response.data;
};

export const resetPasswordApi = async (params: ResetPasswordRequest) => {
	const response = await api.post('/authentication/send-reset-password', {
		...params,
	});

	return response.data;
};

export const resendEmailNotificationApi = async () => {
	const response = await api.post('/authentication/resend-email-verification');

	return response.data;
};

export const resendLoginCodeApi = async (params: ResetPasswordRequest) => {
	const response = await api.post('/authentication/resend-login-code', {
		...params,
	});

	return response.data;
};

export const isResetPasswordTokenValidApi = async (params: IsResetPasswordTokenValidRequest) => {
	const response = await api.get('/authentication/is-reset-password-token-valid', {
		params: { ...params },
	});

	return response.data;
};

export const handleResetPasswordApi = async (params: HandleResetPasswordRequest) => {
	const response = await api.post('/authentication/handle-reset-password', {
		...params,
	});

	return response.data;
};

export const enableAuthenticatorApi = async () => {
	const response = await api.post('/authentication/enable-authenticator');

	return response.data;
};
