import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { keyframes, StyledComponentProps } from 'styled-components';
import styled, { css } from 'styled-components/macro';

import { Color, Shadow } from '../../gfx/constants';
import { media } from '../../services/media';

export enum ButtonTypes {
	BUTTON = 'button',
	SUBMIT = 'submit',
}

interface ButtonProps {
	red?: boolean;
	centered?: boolean;
	transparent?: boolean;
	// let submit to trigger validation, but appear as disabled
	isDisabled?: boolean;
	alignedRight?: boolean;
	noBorder?: boolean;
	underLined?: boolean;
	color?: Color;
	backgroundColor?: Color;
	marginTop?: number | string;
	marginBottom?: number | string;
	fontSize?: number;
	lineHeight?: number;
	fontWeight?: number | 'normal';
}

interface PayProps {
	transparent?: boolean;
}

interface SendReceiptProps {
	sent?: boolean;
}

interface SecondaryProps {
	orange?: boolean;
	green?: boolean;
	red?: boolean;
	white?: boolean;
	dark?: boolean;
	transparent?: boolean;
	marginBottom?: number;
	align?: 'flex-start' | 'center' | 'flex-end';
}

const increaseWidthKeyframes = keyframes`
   4% { min-width: 85px; }
   50% { min-width: 100%; }
   80% { min-width: 100%; }
   100% { min-width: 85px; }
`;

const increaseWidthAnimation = css`
	${increaseWidthKeyframes}
	animation-duration: 2s;
`;

const ButtonMixin = css`
	white-space: nowrap;
	outline: none;
	color: white;
	border: none;
	display: flex;
	display: -webkit-flex;
	justify-content: center;
	align-self: flex-end;
	align-items: center;
	position: relative;
	height: 36px;
	padding: 9px 16px;
	cursor: pointer;
	font-family: PrimaryMedium;
	font-size: 16px;
	font-weight: 500;
	line-height: 19px;
	text-align: center;
	text-decoration: none;
	border-radius: 4px;
	border: none;
	background-color: ${Color.GREEN_3};

	svg {
		margin-right: 10px;
	}

	&:hover {
		box-shadow: ${Shadow.GREEN};
	}
`;

const StyledButton = styled.button<ButtonProps>`
	${ButtonMixin}

	${(props) =>
		props.red &&
		css`
			background-color: ${Color.RED_ERROR};

			&:hover {
				box-shadow: none;
			}
		`};

	${(props) =>
		props.isDisabled &&
		css`
			color: ${Color.GRAY_TEXT};
			cursor: auto;
			background-color: ${Color.GRAY_BUTTON};

			&:hover {
				box-shadow: none;
			}
		`};

	${(props) =>
		props.transparent &&
		css`
			color: ${Color.GRAY_TEXT};
			box-shadow: none;
			border: 1px solid gainsboro;
			background-color: transparent;

			&:hover {
				box-shadow: none;
			}
		`};

	${(props) =>
		props.centered &&
		css`
			margin-left: auto;
			margin-right: auto;
		`};

	${(props) =>
		props.alignedRight &&
		css`
			margin-left: auto;
		`};

	${(props) =>
		props.noBorder &&
		css`
			border: 0;
		`};

	${(props) =>
		props.underLined &&
		css`
			text-decoration: underline;
		`};

	${(props) =>
		props.color &&
		css`
			color: ${props.color};
		`};

	${(props) =>
		props.backgroundColor &&
		css`
			background-color: ${props.backgroundColor};

			&:hover {
				box-shadow: none;
			}
		`};

	${(props) =>
		props.marginTop &&
		css`
			margin-top: ${typeof props.marginTop === 'string' ? props.marginTop : `${props.marginTop}px`};
		`};

	${(props) =>
		props.marginBottom &&
		css`
			margin-bottom: ${typeof props.marginBottom === 'string' ? props.marginBottom : `${props.marginBottom}px`};
		`};

	${(props) =>
		props.fontSize &&
		css`
			font-size: ${props.fontSize}px;
		`};

	${(props) =>
		props.lineHeight &&
		css`
			line-height: ${props.lineHeight}px;
		`};

	${(props) =>
		props.fontWeight &&
		css`
			font-weight: ${props.fontWeight};
		`};
`;

const Secondary = styled.button<SecondaryProps>`
	${ButtonMixin}
	background-color: ${Color.GRAY_BUTTON};
	color: ${Color.GRAY};

	&:hover {
		box-shadow: ${Shadow.GRAY};
	}

	:disabled {
		background-color: ${Color.GRAY_BUTTON};
		color: ${Color.GRAY};

		:hover {
			background-color: ${Color.GRAY_BUTTON};
			color: ${Color.GRAY};
			box-shadow: none;
			cursor: initial;
		}
	}

	${(props) =>
		props.align &&
		css`
			align-self: ${props.align};
		`}

	${(props) =>
		props.marginBottom &&
		css`
			margin-bottom: ${props.marginBottom}px;
		`};

	${(props) =>
		props.transparent &&
		css`
			background-color: inherit;
			color: ${Color.GRAY_8};

			g {
				fill: ${Color.GRAY_TEXT};
			}

			&:hover {
				box-shadow: none;
				color: ${Color.ORANGE};

				g {
					fill: ${Color.ORANGE};
				}
			}
		`};

	${(props) =>
		props.green &&
		css`
			background-color: ${Color.GREEN_3};
			color: ${Color.WHITE};

			&:hover {
				box-shadow: ${Shadow.GREEN};
				background-color: ${Color.GREEN_2};
			}
		`};

	${(props) =>
		props.orange &&
		css`
			background-color: ${Color.ORANGE};
			color: ${Color.WHITE};

			&:hover {
				box-shadow: ${Shadow.ORANGE};
				background-color: ${Color.ORANGE_HOVER};
			}
		`};

	${(props) =>
		props.red &&
		css`
			background-color: ${Color.RED_2};
			color: ${Color.WHITE};

			&:hover {
				box-shadow: ${Shadow.RED};
				background-color: ${Color.RED_3};
			}
		`};

	${(props) =>
		props.white &&
		css`
			border: 1px solid ${Color.GRAY_5};
			background-color: ${Color.WHITE};
			color: ${Color.GRAY_8};
			box-shadow: ${Shadow.LIGHT};

			&:hover {
				box-shadow: ${Shadow.WHITE};
				background-color: ${Color.GRAY_0};
			}
		`};

	${(props) =>
		props.dark &&
		css`
			border: 1px solid ${Color.BLUE_9};
			background-color: ${Color.BLUE_9};
			color: ${Color.WHITE};

			&:hover {
				box-shadow: ${Shadow.BLUE};
				background-color: ${Color.BLUE_8};
			}
		`};
`;

const MobileNavLink = styled(NavLink)`
	border: 1px solid #ffffff;
	border-radius: 7px;
	box-shadow: 0 6px 5px 0 rgba(0, 0, 0, 0.1);
	color: #fff;
	cursor: pointer;
	display: inline-block;
	font-size: 15px;
	font-family: PrimaryMedium;
	line-height: 20px;
	margin-top: 20px;
	min-width: 180px;
	padding: 14px;
	text-align: center;
	text-decoration: none;
`;

const Pay = styled.a<PayProps>`
	${ButtonMixin}
	background-color: ${Color.GREEN_3};
	font-size: 14px;
	margin-left: auto;
	margin-right: auto;
	min-width: 213px;

	&:hover {
		background-color: ${Color.GREEN_HOVER};
	}

	${(props) =>
		props.transparent &&
		css`
			background-color: transparent;
			color: ${Color.BLUE_9};
			border: 1px solid #e6e6e6;
			margin-bottom: 10px;

			&:hover {
				background-color: transparent;
				box-shadow: none;
			}
		`};
`;

const SendReceipt = styled.button<SendReceiptProps>`
	border: 1px solid #ff9109;
	top: 0;
	color: white;
	font-weight: 400;
	padding-left: 2vh;
	padding-top: 2px;
	background-color: #ff9109;
	border-radius: 20px;
	margin-top: 0px;
	height: 40px;
	position: absolute;
	right: 0px;
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 85px;

	span {
		padding-left: 5px;
	}

	&:hover {
		background-color: #ff9109;
		border-bottom: 1px solid #ff9109;
	}

	${media.maxTablet`
		margin-top: 0;
	`}

	${(props) =>
		props.sent &&
		css`
			animation: ${increaseWidthAnimation};
		`};
`;

const Button = (props: StyledComponentProps<'button', ButtonProps, ButtonProps, never>) => (
	<StyledButton {...props}>{props.children}</StyledButton>
);

Button.Secondary = Secondary;
Button.MobileNavLink = MobileNavLink;
Button.Pay = Pay;
Button.SendReceipt = SendReceipt;

export default Button;
