import { FastField as FieldFormik, FormikActions } from 'formik';
import { CFormikProps, FormikFields as FormikForm, FormikFieldsState } from 'formik-fields';
import React, { useEffect, useRef, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import * as Yup from 'yup';
import { BusinessTierLimitsResponse } from '../../../../api/authApi';

import { Invoice } from '../../../../api/invoiceApi';
import { Rates } from '../../../../api/ratesApi';
import Button, { ButtonTypes } from '../../../../components/button/Button';
import Field from '../../../../components/field/Field';
import Form from '../../../../components/form/Form';
import Loader from '../../../../components/loader/Loader';
import Modal from '../../../../components/modal/Modal';
import Notification from '../../../../components/notification/Notification';
import Select from '../../../../components/select/Select';
import View from '../../../../components/view/View';
import {
	AtmPaymentsDefault,
	BASE_CURRENCIES,
	Currency,
	FieldIconTypes,
	FieldTypes,
	RoutesUrls,
	UserRole,
} from '../../../../constants';
import { H1, H2 } from '../../../../gfx/globals';
import { getBaseCurrencyOptions } from '../../../../services/get-base-currency-options';
import getDefaultWallet from '../../../../services/get-default-wallet';
import { getWalletOptions } from '../../../../services/get-wallet-options';
import { getServerValidationErrors } from '../../../../services/input-error-utils';
import isParentVerified from '../../../../services/is-parent-verified';
import isUserVerified from '../../../../services/is-user-verified';
import isWalletAdded from '../../../../services/is-wallet-added';
import { useStoreActions, useStoreState } from '../../../../services/store';
import { RouteProps } from '../../../../typings';
import {
	AtmPaymentsNotification,
	LimitNotificationText,
	PosButtonColumn,
	PosModalTitleWrapper,
	PosModalWrapper,
} from './PosCheckoutViewStyle';

export interface PosFields {
	paymentId: string;
	currency: Currency;
	walletId: string | null;
	currencyAmount: string | number;
	acceptAtmCoins: boolean;
}

const posValidationSchema = Yup.object<PosFields>().shape({
	currencyAmount: Yup.number()
		.typeError('Must be a number with a dot as a decimal separator')
		.min(0.000001, 'Minimum amount of 0.000001 is required')
		.max(10000000, 'Maximum amount of 10000000 is allowed')
		.required('Amount is required'),
	currency: Yup.mixed().oneOf(BASE_CURRENCIES),
	walletId: Yup.string().nullable(true).required('Please choose the wallet'),
	paymentId: Yup.string().max(128, 'Custom ID cannot be longer than 128 characters').notRequired(),
	acceptAtmCoins: Yup.mixed().notRequired(),
});

function PosCheckoutView(props: RouteProps) {
	const [isActionLoading, setIsActionLoading] = useState(false);
	const [dagloypaltyPayment, setDagloyaltyPayment] = useState(false);
	const [isModalOpened, setIsModalOpened] = useState(false);
	const [viewerLimits, setViewerLimits] = useState<BusinessTierLimitsResponse | null>(null);
	let viewRef: React.MutableRefObject<any> = useRef<HTMLDivElement | null>(null);
	const appElement = document.getElementById('root')!;

	const { wallets, activeViewer, rates } = useStoreState((state) => ({
		activeViewer: state.viewer.activeViewer,
		wallets: state.wallet.wallets,
		rates: state.rates.rates,
	}));

	const { createPosInvoice, getViewer, getWallets, getRates, getViewerBusinessLimits } = useStoreActions((actions) => ({
		...actions.invoice,
		...actions.viewer,
		...actions.wallet,
		...actions.rates,
	}));

	useEffect(() => {
		const fetchViewerLimits = async () => {
			if (!viewerLimits) {
				setViewerLimits((await getViewerBusinessLimits()).payload);
			}
		};
		const fetchActiveViewer = async () => {
			await getViewer();
		};

		const fetchWallets = async () => {
			await getWallets({});
		};
		const fetchRates = async () => {
			await getRates();
		};
		if (!activeViewer) {
			fetchActiveViewer();
		}

		if (!wallets) {
			fetchWallets();
		}
		if (!viewerLimits) {
			fetchViewerLimits();
		}
		if (!rates) {
			fetchRates();
		}
	}, [activeViewer, wallets, viewerLimits, getRates, getViewer, getViewerBusinessLimits, getWallets, rates]);

	const toggleModal = () => {
		setIsModalOpened(!isModalOpened);
	};

	if (!activeViewer || !wallets || !viewerLimits || !rates) {
		return <Loader />;
	}

	const walletAdded = isWalletAdded();
	const isVerified = isUserVerified();
	const parentVerified = isParentVerified();

	const isTeamMember =
		activeViewer.role === UserRole.TEAM_MEMBER || activeViewer.role === UserRole.ADVANCED_TEAM_MEMBER;
	const isOwner = activeViewer.role === UserRole.OWNER;
	const isUserReadyForInvoice = walletAdded && isVerified && parentVerified;

	const getMainForm = () => (fields: FormikFieldsState<PosFields>, fieldActions: CFormikProps<PosFields>) => {
		const isWalletSelectVisible = activeViewer.role === UserRole.OWNER || activeViewer.role === UserRole.ADMIN;
		// TODO: later should allow admins as well
		const viewerCanConfigureDagloyaltyPayments =
			activeViewer.role === UserRole.OWNER; /* || activeViewer.role === UserRole.ADMIN */

		return (
			<>
				{wallets && wallets.items.length > 0 && (
					<Form.Pos autoComplete="off" id="posCheckout">
						{activeViewer.hasActiveAtm && (
							<div style={{ marginBottom: '30px' }}>
								<AtmPaymentsNotification color={activeViewer.atmPaymentsDefault}>
									Dagloyalty payments
									{activeViewer.atmPaymentsDefault === AtmPaymentsDefault.ENABLED && <span> Enabled </span>}
									{activeViewer.atmPaymentsDefault === AtmPaymentsDefault.ASK && <span> Ask </span>}
									{activeViewer.atmPaymentsDefault === AtmPaymentsDefault.OFF && <span> Off </span>}
								</AtmPaymentsNotification>
								<Notification>
									<Link to={RoutesUrls.SETTINGS} target="_blank">
										{viewerCanConfigureDagloyaltyPayments ? 'Configure' : 'View'}
									</Link>{' '}
									Dagloyalty payment settings
								</Notification>
							</div>
						)}
						<Form.Column>
							{isWalletSelectVisible && (
								<Field
									type={FieldTypes.SELECT}
									formikField={fields.walletId}
									title="Receiving wallet"
									placeholder="Choose wallet"
									options={getWalletOptions(wallets)}
									iconType={FieldIconTypes.WALLET}
									tabIndex={1}
								/>
							)}
							<Field formikField={fields.paymentId} noAddonIcon optional title="Custom ID or reference nr" />
							<Field
								formikField={fields.currencyAmount}
								placeholder="0.00"
								type={FieldTypes.NUMBER}
								iconType={FieldIconTypes.COINS}
								tabIndex={2}
								title="Amount to pay"
								addonNode={
									<FieldFormik
										name={fields.currency}
										component={Select}
										noAddonIcon
										options={getBaseCurrencyOptions({ current: activeViewer.baseCurrency })}
										formikField={fields.currency}
									/>
								}
								addOnNodeSize={90}
							/>
							{viewerLimits && viewerLimits.volumeLimit.dag !== null && (
								<Notification>
									Remaining monthly volume until limit{' '}
									<LimitNotificationText
										error={
											(viewerLimits.volumeLimit.dag - viewerLimits.monthlyTotalVolume.dag) *
												rates[fields.currency.value as keyof Rates] -
												parseFloat(fields.currencyAmount.value as string) <
											0
										}
									>
										{(
											(viewerLimits.volumeLimit.dag - viewerLimits.monthlyTotalVolume.dag) *
												rates[fields.currency.value as keyof Rates] -
											(parseFloat(fields.currencyAmount.value as string)
												? parseFloat(fields.currencyAmount.value as string)
												: 0)
										).toFixed(2)}{' '}
										<strong>{fields.currency.value}</strong>
									</LimitNotificationText>
								</Notification>
							)}
							{activeViewer.hasActiveAtm &&
								viewRef !== null &&
								activeViewer.atmPaymentsDefault === AtmPaymentsDefault.ASK &&
								fieldActions.isValid && (
									<Modal
										appElement={appElement}
										isOpen={isModalOpened}
										ariaHideApp={false}
										onRequestClose={() => toggleModal()}
										small
										hasCloseButton
									>
										<PosModalWrapper>
											<PosModalTitleWrapper>
												<H2>Choose type of payment</H2>
												Accept dagcoins bought from a Dagcoin ATM or V-ATM, or regular dagcoins
											</PosModalTitleWrapper>
											<PosButtonColumn>
												<Button.Secondary
													green
													type="submit"
													form="posCheckout"
													onClick={() => setDagloyaltyPayment(true)}
												>
													Dagloyalty payment
												</Button.Secondary>
												<Button.Secondary white type="submit" form="posCheckout">
													Regular payment
												</Button.Secondary>
											</PosButtonColumn>
										</PosModalWrapper>
									</Modal>
								)}
						</Form.Column>
						{activeViewer.hasActiveAtm && activeViewer.atmPaymentsDefault === AtmPaymentsDefault.ASK && (
							<Button
								centered
								isDisabled={!fieldActions.isValid}
								onClick={(_e) => (fieldActions.isValid ? setIsModalOpened(true) : false)}
								type={ButtonTypes.BUTTON}
							>
								Checkout
							</Button>
						)}
						{isActionLoading ? (
							<Loader />
						) : (
							(activeViewer.atmPaymentsDefault !== AtmPaymentsDefault.ASK || !activeViewer.hasActiveAtm) && (
								<Button centered isDisabled={!fieldActions.isValid} type={ButtonTypes.SUBMIT}>
									Checkout
								</Button>
							)
						)}
					</Form.Pos>
				)}
				{wallets && wallets.items.length === 0 && (
					<Notification>
						Please <Link to={RoutesUrls.WALLETS_DETAIL}> add the wallet</Link> to generate new invoice.
					</Notification>
				)}
			</>
		);
	};

	const handleCreateInvoice = async (input: PosFields, formActions: FormikActions<PosFields>) => {
		if (!input.walletId) {
			return formActions.setFieldError('walletId', 'Please choose the wallet');
		}
		const currencyAmount = parseFloat(typeof input.currencyAmount === 'string' ? input.currencyAmount : '0');

		const atmPayment =
			activeViewer.atmPaymentsDefault === AtmPaymentsDefault.ENABLED
				? true
				: activeViewer.atmPaymentsDefault === AtmPaymentsDefault.ASK
				? dagloypaltyPayment
				: false;
		setIsActionLoading(true);
		const result = await createPosInvoice({
			...input,
			atmOnly: atmPayment,
			walletId: input.walletId,
			paymentId: input.paymentId || '',
			currencyAmount,
		});

		if (result.success && result.payload) {
			props.history.push(`/invoices/pay/${result.payload.id}`);
			setIsActionLoading(false);
			return;
		}

		if (result.error) {
			setIsActionLoading(false);
			const serverValidationErrors = getServerValidationErrors<Invoice>(result);
			// setErrorMessage(serverValidationErrors.errorMessage);
			for (var key in serverValidationErrors.errors) {
				// console.log({ key: key, error: serverValidationErrors.errors[key] });
				formActions.setFieldError(key, serverValidationErrors.errors[key]);
			}
			return;
		}
	};

	const defaultWallet = getDefaultWallet(wallets);

	return (
		<View>
			<H1 center>POS quick checkout</H1>
			<View.Description>Generate invoice for quick point of sale checkout</View.Description>
			<View.NotificationWrapper>
				{!walletAdded && (
					<Notification left>
						Please <Link to={RoutesUrls.WALLETS_DETAIL}> add the wallet</Link> to create new checkout.
					</Notification>
				)}
				{!isTeamMember && !isVerified && (
					<Notification left>
						Your identity or business is not verified, you can create new checkout after process is completed.
					</Notification>
				)}
			</View.NotificationWrapper>
			{!isOwner && !parentVerified && <Notification>Dagpay account access limited</Notification>}
			{isUserReadyForInvoice && (
				<>
					<FormikForm<PosFields>
						fields={{
							currency: { initialValue: activeViewer.baseCurrency },
							paymentId: { initialValue: '' },
							walletId: {
								initialValue: defaultWallet && defaultWallet.id,
							},
							currencyAmount: { initialValue: '' },
							acceptAtmCoins: { initialValue: false },
						}}
						validationSchema={posValidationSchema}
						onSubmit={handleCreateInvoice}
						render={getMainForm()}
					/>
				</>
			)}
		</View>
	);
}

export default withRouter(PosCheckoutView);
