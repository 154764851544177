import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { WalletAddressBalance } from '../../../../../../api/customerLimitApi';
import { Invoice } from '../../../../../../api/invoiceApi';
import Button from '../../../../../../components/button/Button';
import { FieldOptionProps } from '../../../../../../components/field/Field';
import Modal from '../../../../../../components/modal/Modal';
import Notification from '../../../../../../components/notification/Notification';
import HookSelectField from '../../../../../../components/react-hook-form/hook-select-field/HookSelectField';
import HookBaseField from '../../../../../../components/react-hook-form/HookBaseField';
import ReactHookForm from '../../../../../../components/react-hook-form/ReactHookFormStyle';
import View from '../../../../../../components/view/View';
import { Color } from '../../../../../../gfx/constants';
import { H1 } from '../../../../../../gfx/globals';
import { useStoreActions } from '../../../../../../services/store';

interface Props {
	transaction: Invoice;
	handleCloseModal: () => void;
}

interface CreateLimitModalFields {
	addressList: FieldOptionProps[];
	name: string;
	dailyLimit: string;
	monthlyLimit: string;
}

export default function CreateLimitModal(props: Props) {
	const { transaction, handleCloseModal } = props;

	const [errorMessage, setErrorMessage] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [balanceOptions, setBalanceOptions] = useState<FieldOptionProps[]>([]);

	const { createCustomerLimit, getCustomerLimitBalance } = useStoreActions((actions) => ({
		...actions.customerLimit,
	}));

	const schema = Yup.object<CreateLimitModalFields>().shape({
		addressList: Yup.array<FieldOptionProps>().min(1, 'Please choose at least one address'),
		name: Yup.string().required('Name is required'),
		// TODO: validation against e power x value
		dailyLimit: Yup.mixed().notRequired(),
		monthlyLimit: Yup.mixed().notRequired(),
	});

	const defaultValues: CreateLimitModalFields = {
		addressList: [],
		name: '',
		dailyLimit: '',
		monthlyLimit: '',
	};

	const methods = useForm<CreateLimitModalFields>({
		resolver: yupResolver(schema),
		defaultValues: defaultValues,
		shouldFocusError: false,
	});

	useEffect(() => {
		const fetchAddressBalances = async () => {
			const buyerWalletAddressList = transaction.senderAddresses.split(',');
			const existingBalances: WalletAddressBalance = {};

			for (const walletAddress of buyerWalletAddressList) {
				const balanceResponse = await getCustomerLimitBalance({ walletAddress });

				if (
					balanceResponse.payload &&
					(balanceResponse.payload.dailyLimit.limit || balanceResponse.payload.monthlyLimit.limit)
				) {
					existingBalances[walletAddress] = balanceResponse.payload;
				}
			}

			const existingBalanceKeys = Object.keys(existingBalances);

			const getWalletAddressLimitOptions = (): FieldOptionProps[] => {
				return buyerWalletAddressList.map((key) => {
					// not limited address
					if (!existingBalanceKeys.includes(key)) {
						return {
							label: key,
							value: key,
						};
					}

					const balance = existingBalances[key];

					const toolTipMessage = !!balance
						? `daily: ${balance.dailyLimit.limit ? `${balance.dailyLimit.limit} DAG` : 'not limited'}, monthly: ${
								balance.monthlyLimit.limit ? `${balance.monthlyLimit.limit} DAG` : 'not limited'
						  }`
						: undefined;

					return {
						label: key,
						value: key,
						isDisabled: !!balance,
						toolTipMessage,
					};
				});
			};

			setBalanceOptions(getWalletAddressLimitOptions());
		};

		fetchAddressBalances();
	}, [getCustomerLimitBalance, transaction.senderAddresses]);

	const handleCreateLimit = async (input: CreateLimitModalFields) => {
		const dailyLimit = parseFloat(input.dailyLimit);
		const monthlyLimit = parseFloat(input.monthlyLimit);
		const dailyIsNan = isNaN(dailyLimit);
		const monthlyIsNan = isNaN(monthlyLimit);

		// at least one limit must be provided
		if (dailyIsNan && monthlyIsNan) {
			setErrorMessage('At least one of the limits should be provided');
			return;
		}

		setIsLoading(true);
		const response = await createCustomerLimit({
			name: input.name,
			dailyLimit: dailyIsNan ? undefined : dailyLimit,
			monthlyLimit: monthlyIsNan ? undefined : monthlyLimit,
			walletAddressList: input.addressList.map((option) => option.value.toString()),
		});
		setIsLoading(false);

		if (response.success) {
			toast.success('Customer wallet limit(s) created');
			handleCloseModal();

			return;
		}

		setErrorMessage(
			'Something went wrong, please try again. If problem persists, try adding customer wallet limit under Customer Limits in Settings.',
		);
	};

	const someNotDisabled = () => {
		return balanceOptions.some((option) => !option.isDisabled);
	};

	return (
		<Modal hasCloseButton ariaHideApp={false} isOpen={true} onRequestClose={handleCloseModal}>
			<FormProvider {...methods}>
				<ReactHookForm onSubmit={methods.handleSubmit(handleCreateLimit)}>
					<H1 center>Create customer wallet limit(s)</H1>
					<Notification>
						Wallet address(es) to be limited. Limits can be edited under Customer Limits in Settings.
					</Notification>
					<HookSelectField
						label="Wallets"
						name="addressList"
						options={balanceOptions}
						tabIndex={1}
						selectedColor={Color.GRAY_1}
						focusedColor={Color.GRAY_0}
						isMulti
						closeMenuOnSelect={false}
					/>
					<HookBaseField label="Limit name" name="name" placeholder="Limit name" />
					<HookBaseField label="Daily limit" name="dailyLimit" placeholder="Limit in DAG" type="number" />
					<HookBaseField label="Monthly limit" name="monthlyLimit" placeholder="Limit in DAG" type="number" />
					{errorMessage.length > 0 && <View.Error center>{errorMessage}</View.Error>}
					<Modal.Buttons>
						<Button.Secondary white onClick={handleCloseModal}>
							Cancel
						</Button.Secondary>
						<Button.Secondary
							type="submit"
							green
							isDisabled={isLoading || !someNotDisabled()}
							disabled={isLoading || !someNotDisabled()}
						>
							Create limit
						</Button.Secondary>
					</Modal.Buttons>
				</ReactHookForm>
			</FormProvider>
		</Modal>
	);
}
