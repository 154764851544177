import axios from 'restyped-axios';
import { ApiArrayPayload, ApiGetRequest, ApiResponse } from '../typings';
export const api = axios.create<CustomerLimitApi>();

export interface CustomerLimitApi {
	'/customer-limits': {
		GET: {
			body: ApiGetRequest;
			response: ApiResponse<ApiArrayPayload<CustomerLimit>>;
		};

		POST: {
			body: CreateCustomerLimitRequest;
			response: ApiResponse<CustomerLimit>;
		};

		PUT: {
			body: UpdateCustomerLimitRequest;
			response: ApiResponse<CustomerLimit>;
		};
	};

	'/customer-limits/:customerLimitId': {
		GET: {
			response: ApiResponse<CustomerLimit>;
		};

		DELETE: {
			body: DeleteCustomerLimitRequest;
			response: ApiResponse<CustomerLimit>;
		};
	};

	'/customer-limits/balance/:walletAddress': {
		GET: {
			response: ApiResponse<CustomerLimitBalance>;
		};
	};
}

export interface DeleteCustomerLimitRequest {
	customerLimitId: string;
}

export interface CreateCustomerLimitRequest {
	walletAddressList: string[];
	name?: string;
	dailyLimit?: number;
	monthlyLimit?: number;
}

export interface GetCustomerLimitRequest {
	customerLimitId: string;
}

export interface GetCustomerLimitBalanceRequest {
	walletAddress: string;
}

export interface CustomerLimitBalance {
	dailyLimit: Balance;
	monthlyLimit: Balance;
}

export interface WalletAddressBalance {
	[key: string]: CustomerLimitBalance;
}
export interface Balance {
	limit: number | null;
	spend: number;
	free: number | null;
}

export interface UpdateCustomerLimitRequest {
	idList: string[];
	name?: string;
	dailyLimit?: number;
	monthlyLimit?: number;
}

export interface CustomerLimit {
	id: string;
	creatorId: string;
	name: string | null;
	walletAddress: string;
	dailyLimit: number;
	monthlyLimit: number;
	isDeleted: boolean;
	updatedDate: string;
}

export const createCustomerLimitApi = async (params: CreateCustomerLimitRequest) => {
	const response = await api.post('/customer-limits', {
		...params,
	});

	return response.data;
};

export const updateCustomerLimitApi = async (params: UpdateCustomerLimitRequest) => {
	const response = await api.put('/customer-limits', {
		...params,
	});

	return response.data;
};

export const deleteCustomerLimitApi = async (params: DeleteCustomerLimitRequest) => {
	const response = await api.delete<'/customer-limits/:customerLimitId'>(`/customer-limits/${params.customerLimitId}`);

	return response.data;
};

export const getCustomerLimitsApi = async (params: ApiGetRequest) => {
	const response = await api.get('/customer-limits', {
		params: { ...params },
	});

	return response.data;
};

export const getCustomerLimitApi = async (params: GetCustomerLimitRequest) => {
	const response = await api.get(`/customer-limits/${params.customerLimitId}`);

	return response.data;
};

export const getCustomerLimiBalancetApi = async (params: GetCustomerLimitBalanceRequest) => {
	const response = await api.get(`/customer-limits/balance/${params.walletAddress}`);

	return response.data;
};
