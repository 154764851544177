import styled, { css } from 'styled-components/macro';

import { Error, FieldWrapper } from '../../../../../../components/react-hook-form/HookFieldsStyle';
import { HookFormColumn } from '../../../../../../components/react-hook-form/ReactHookFormStyle';
import { Color } from '../../../../../../gfx/constants';
import { media } from '../../../../../../services/media';

export const SwipexEmailError = styled.span`
	height: 19px;
	font-family: Secondary;
	font-size: 14px;
	letter-spacing: 0px;
	line-height: 23px;
	color: ${Color.RED_ERROR};
`;

export const SwipexEmail = styled.div<{ error: boolean }>`
	${Error} {
		${(props) =>
			!props.error &&
			css`
				display: none;
			`}
	}

	${FieldWrapper} {
		margin-bottom: 5px;
	}
`;

export const NotificationWrapper = styled.div`
	align-self: flex-start;
`;

export const InformationSection = styled.div`
	display: flex;
	justify-content: space-between;

	${HookFormColumn} {
		margin-right: 0;
	}

	${media.fromDesktop`
		${HookFormColumn}{
			width: calc(50% - 50px / 2);
		}
	`}

	${media.maxNewInvoiceTable`
		flex-direction: column;
		align-items: center;

		${HookFormColumn}{
			width: 100%;
		}
	`}
`;
