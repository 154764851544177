import { Action, action, thunk, Thunk } from 'easy-peasy';

import {
	getAccountLinkInfoApi,
	GetLinkRequest,
	linkSwipexAccountApi,
	LinkSwipexAccountRequest,
	SwipexAccountLink,
} from '../api/swipexApi';
import handleFetch from '../services/handle-fetch';
import { Response } from '../typings';

import { StoreModel } from '.';

export interface SwipexModel {
	// state
	accountLinkInfo: SwipexAccountLink | null;

	// actions
	saveLinkInfo: Action<SwipexModel, SwipexAccountLink | null>;
	clearLinkInfo: Action<SwipexModel>;

	// thunks
	createAccountLink: Thunk<SwipexModel, LinkSwipexAccountRequest, {}, StoreModel, Response<SwipexAccountLink>>;
	getAccountLinkInfo: Thunk<SwipexModel, GetLinkRequest, {}, StoreModel, Response<SwipexAccountLink>>;
	// getAccountLinkStatus: Thunk<CountryModel, undefined, {}, StoreModel, Response<Country[]>>;
}

const swipex: SwipexModel = {
	// state
	accountLinkInfo: null,

	// actions
	saveLinkInfo: action((state, payload) => {
		state.accountLinkInfo = payload;
	}),
	clearLinkInfo: action((state) => {
		state.accountLinkInfo = null;
	}),

	// thunks
	createAccountLink: thunk(async (actions, payload) => {
		return await handleFetch<SwipexAccountLink>(linkSwipexAccountApi(payload));
	}),
	getAccountLinkInfo: thunk(async (actions, payload) => {
		actions.clearLinkInfo();

		const result = await handleFetch<SwipexAccountLink>(getAccountLinkInfoApi(payload));

		actions.saveLinkInfo(result.payload);

		return result;
	}),
};

export default swipex;
