import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { FieldOptionProps } from '../../../components/field/Field';
import Loader from '../../../components/loader/Loader';
import Logo from '../../../components/logo/Logo';
import ReactHookForm from '../../../components/react-hook-form/ReactHookFormStyle';
import { BusinessType, RoutesUrls, Validations } from '../../../constants';
import { getCountryOptions } from '../../../services/get-country-options';
import { useStoreActions, useStoreState } from '../../../services/store';
import { RouteProps } from '../../../typings';
import Login from '../login/LoginStyle';

import AccountTypeStep from './AccountTypeStep';
import FillDetailsStep from './FillDetailsStep';
import SignUp from './SignUpViewStyle';

export interface SignUpFields {
	email: string;
	password: string;
	firstName: string;
	companyName: string;
	countryCode: FieldOptionProps | null;
	lastName: string;
	referralCode: string;
	agreement: boolean;
	termsAgreement: boolean;
	businessType: BusinessType | null;
}

interface RouteParams {
	referralCode: string;
}

interface UtmInput {
	utm_source: string;
	utm_medium: string;
	utm_campaign: string;
	utm_term: string;
	utm_content: string;
	clickId: string;
}

function SignUpView(props: RouteProps<RouteParams>) {
	const [errorElement, setErrorElement] = useState<JSX.Element | null>(null);
	const [isActionLoading, setIsActionLoading] = useState(false);

	const { countries } = useStoreState((state) => ({
		countries: state.country.countries,
	}));

	const { getCountries, signUp, createUtm, getViewer } = useStoreActions((actions) => ({
		...actions.country,
		...actions.user,
		...actions.utm,
		...actions.viewer,
	}));

	const defaultValues = {
		businessType: null,
		email: '',
		agreement: false,
		companyName: '',
		countryCode: null,
		firstName: '',
		lastName: '',
		referralCode: props.match.params.referralCode || '',
		termsAgreement: false,
	};

	const schema = Yup.object().shape({
		companyName: Validations.COMPANY_NAME_IS_REQUIRED,
		email: Validations.INVALID_EMAIL,
		password: Validations.PASSWORD_NOT_SECURE,
		firstName: Validations.FIRST_NAME_IS_REQUIRED,
		lastName: Validations.LAST_NAME_IS_REQUIRED,
		countryCode: Yup.mixed().nullable(true).required('Country is required'),
		referralCode: Validations.REFERRAL_CODE_NOT_LONG_ENOUGH,
		termsAgreement: Validations.AGREEMENT_CHECKED,
		agreement: Validations.AGREEMENT_CHECKED,
	});

	const methods = useForm<SignUpFields>({
		defaultValues: defaultValues,
		resolver: yupResolver(schema),
		shouldUnregister: false,
		shouldFocusError: false,
		mode: 'onChange',
	});

	useEffect(() => {
		const fetchCountries = async () => {
			await getCountries();
		};
		fetchCountries();
	}, [getCountries]);

	if (!countries || isActionLoading) {
		return <Loader />;
	}

	const renderAccountTypeStep = () => () =>
		<AccountTypeStep errorElement={errorElement} setErrorElement={setErrorElement} />;

	const renderFillDetailsStep = () => () =>
		<FillDetailsStep errorElement={errorElement} countries={getCountryOptions(countries)} />;

	const saveUtmData = async () => {
		const utmData = localStorage.getItem('utmData');
		let parsedUtmData: Partial<UtmInput> = {};

		if (utmData !== null) {
			parsedUtmData = JSON.parse(utmData);

			const isEmpty = Object.values(parsedUtmData).every((value) => value === null || value === '');

			if (!isEmpty) {
				await createUtm({
					source: parsedUtmData.utm_source ? parsedUtmData.utm_source : null,
					medium: parsedUtmData.utm_medium ? parsedUtmData.utm_medium : null,
					content: parsedUtmData.utm_content ? parsedUtmData.utm_content : null,
					campaign: parsedUtmData.utm_campaign ? parsedUtmData.utm_campaign : null,
					term: parsedUtmData.utm_term ? parsedUtmData.utm_term : null,
					clickId: parsedUtmData.clickId ? parsedUtmData.clickId : null,
				});
			}
		}
	};

	const trimWhitespaces = (input: SignUpFields) => {
		return {
			...input,
			firstName: input.firstName.trim(),
			lastName: input.lastName.trim(),
			companyName: input.companyName.trim(),
		};
	};

	const handleUserSignUp = async (input: SignUpFields) => {
		const trimmedInput = trimWhitespaces(input);
		const { agreement, termsAgreement, countryCode, referralCode, ...newInput } = trimmedInput;
		setIsActionLoading(true);

		const response = await signUp({
			...newInput,
			referralCode: referralCode === '' ? undefined : referralCode,
			countryCode: countryCode && typeof countryCode.value === 'string' ? countryCode.value : '',
		});

		setIsActionLoading(false);

		if (response.success) {
			await getViewer();

			props.history.push(RoutesUrls.PRIVATE);
			toast.success('You are signed up successfully.');
			await saveUtmData();

			return;
		}

		if (
			response.validationErrors.length !== 0 &&
			response.validationErrors.find((error) => error.params[0] === 'unique-email')
		) {
			methods.setError('email', { message: 'Email is already taken', type: 'manual' });
			toast.error('Email already in use');
			return;
		}

		if (response.error) {
			toast.error(<>Something went wrong, please try again</>);
		}
	};

	return (
		<SignUp>
			<FormProvider {...methods}>
				<ReactHookForm onSubmit={methods.handleSubmit(handleUserSignUp)} id="signUp">
					<Login.LogoWrapper>
						<Logo hasWhiteBackground />
					</Login.LogoWrapper>
					<Switch>
						<Redirect from={RoutesUrls.SIGN_UP} exact to={RoutesUrls.SIGN_UP_CREATE_ACCOUNT} />
						<Route path={RoutesUrls.SIGN_UP_CREATE_ACCOUNT} render={renderAccountTypeStep()} />
						<Route path={RoutesUrls.SIGN_UP_FILL_DETAILS} render={renderFillDetailsStep()} />
					</Switch>
				</ReactHookForm>
			</FormProvider>
		</SignUp>
	);
}

export default withRouter(SignUpView);
