import * as React from 'react';
import { Link } from 'react-router-dom';
import ReactSVG from 'react-svg';

import { AppPublicUrls } from '../../constants';

interface Props {
	to?: string;
	hasWhiteBackground?: boolean;
	notClickable?: boolean;
}

export default function Logo(props: Props) {
	const { to, hasWhiteBackground, notClickable } = props;

	const isLive = process.env.REACT_APP_WP_PUBLIC_URL === AppPublicUrls.LIVE;

	let logoPath = isLive ? '/files/svg/logos/DagPayLiveDarkBack_new.svg' : '/files/svg/logos/DagPayLogoTest.svg';

	if (hasWhiteBackground) {
		logoPath = isLive ? '/files/svg/logos/DagPayLiveWhiteBack_new.svg' : '/files/svg/logos/DagPayLogoTestWhiteBack.svg';
	}

	if (to) {
		return (
			<Link to={to}>
				<ReactSVG src={logoPath} />
			</Link>
		);
	}

	if (notClickable) {
		return <ReactSVG src={logoPath} />;
	}

	return (
		<a href={process.env.REACT_APP_WP_PUBLIC_URL ? process.env.REACT_APP_WP_PUBLIC_URL : '/'}>
			<ReactSVG src={logoPath} />
		</a>
	);
}
