import * as React from 'react';
import ReactSVG from 'react-svg';

import { InvoiceState } from '../../../api/invoiceApi';
import paymentConfirm from '../../../gfx/images/paymentConfirm.png';
import paymentError from '../../../gfx/images/paymentError.png';
import paymentSuccess from '../../../gfx/images/paymentSuccess.png';
import paymentWaitingForConfirmation from '../../../gfx/images/paymentWaitingForConfirmation.png';
import { PaymentWaitingImage, StyledBackgroundSvg, StyledProgressSvg } from '../InvoiceViewStyle';

interface Props {
	isConfirmed?: boolean;
	isError?: boolean;
	isAnimationDisabled?: boolean;
	invoiceState: InvoiceState;
}

export default function InvoicePaymentProgress(props: Props) {
	const getContentIcon = (invoiceState: InvoiceState) => {
		const isErrored =
			invoiceState === InvoiceState.CANCELLED ||
			invoiceState === InvoiceState.FAILED ||
			invoiceState === InvoiceState.EXPIRED;

		if (invoiceState === InvoiceState.WAITING_FOR_CONFIRMATION) {
			return <PaymentWaitingImage src={paymentWaitingForConfirmation} webp={paymentWaitingForConfirmation} />;
		}

		if (invoiceState === InvoiceState.PAID || invoiceState === InvoiceState.PAID_EXPIRED) {
			return <PaymentWaitingImage src={paymentSuccess} webp={paymentSuccess} />;
		}

		if (isErrored) {
			return <PaymentWaitingImage src={paymentError} webp={paymentError} />;
		}

		return <PaymentWaitingImage src={paymentConfirm} webp={paymentConfirm} />;
	};

	return (
		<>
			<StyledProgressSvg
				isConfirmed={props.isConfirmed}
				isError={props.isError}
				isAnimationDisabled={props.isAnimationDisabled}
			>
				<ReactSVG src="/files/svg/invoice/ProgressLine.svg" />{' '}
			</StyledProgressSvg>
			<StyledBackgroundSvg
				isConfirmed={props.isConfirmed}
				isError={props.isError}
				isAnimationDisabled={props.isAnimationDisabled}
			>
				<ReactSVG src="/files/svg/invoice/BackgroundLineAndIcons.svg" />
				{getContentIcon(props.invoiceState)}
			</StyledBackgroundSvg>
		</>
	);
}
