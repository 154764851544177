import axios from 'restyped-axios';
import { ApiResponse } from '../typings';
export const api = axios.create<UtmApi>();

export interface UtmApi {
	'/utm': {
		POST: {
			body: CreateUtmRequest;
			response: ApiResponse<Utm>;
		};
	};
}

export interface CreateUtmRequest {
	source: string | null;
	medium: string | null;
	campaign: string | null;
	term: string | null;
	content: string | null;
	clickId: string | null;
}

export interface Utm {
	id: string;
	userId: string;
	source: string;
	medium: string;
	campaign: string;
	term: string;
	content: string;
	clickId: string;
}

export const createUtmApi = async (params: CreateUtmRequest) => {
	const response = await api.post('/utm', {
		...params,
	});

	return response.data;
};
