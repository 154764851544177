import React from 'react';
import { StatisticsResponse } from '../../../../../../api/authApi';
import Loader from '../../../../../../components/loader/Loader';
import { Currency } from '../../../../../../constants';
import round from '../../../../../../services/round';
import Statistics from '../StatisticsBlockStyle';

interface Props {
	statistics: StatisticsResponse | null;
	baseCurrency: Currency;
}

interface UnitBlockProps {
	amount: number | null;
	title: string;
	currency?: Currency;
	isDag?: boolean;
	isCurrentValue?: boolean;
}

const DataUnits = (props: Props) => {
	const { statistics, baseCurrency } = props;

	return (
		<Statistics.UnitsWrapper>
			<UnitBlock amount={statistics && statistics.grossVolume.dag} title="Gross volume" currency={Currency.DAG} isDag />
			<UnitBlock amount={statistics && statistics.successfulPaymentCount} title="Payments received" />
			<UnitBlock
				amount={statistics && statistics.grossVolume.base}
				title="Current value in your local currency"
				currency={baseCurrency}
				isCurrentValue
			/>

			{/* <Statistics.AllTimeValueWrapper isClicked={isClicked}>
				<Statistics.UnitBlock>
					{statistics ? (
						<>
							<Statistics.AllTimeValue>
								<Statistics.UnitAmount>
									000.00 <Statistics.UnitCurrency>{baseCurrency}</Statistics.UnitCurrency>
								</Statistics.UnitAmount>
								<Statistics.AllTimeValueIcon onClick={() => setIsClicked(!isClicked)}>
									<ReactSVG src={isClicked ? DashboardIconPath.FAIL : DashboardIconPath.INFO} />
								</Statistics.AllTimeValueIcon>
							</Statistics.AllTimeValue>
							<Statistics.UnitTitle>All time value</Statistics.UnitTitle>
						</>
					) : (
						<Loader />
					)}
				</Statistics.UnitBlock>

				<Statistics.AllTimeValueExtendedMessage>
					Total value of your dagcoins at the time of transaction
				</Statistics.AllTimeValueExtendedMessage>
			</Statistics.AllTimeValueWrapper> */}
		</Statistics.UnitsWrapper>
	);
};

const UnitBlock = ({ amount, title, currency, isDag, isCurrentValue }: UnitBlockProps) => (
	<Statistics.UnitBlock isCurrentValueBlock={isCurrentValue}>
		{amount !== null ? (
			<>
				<Statistics.UnitAmount>
					{isCurrentValue && currency !== Currency.DAG ? round(amount, 2) : amount}{' '}
					{currency && <Statistics.UnitCurrency isDag={isDag}>{currency}</Statistics.UnitCurrency>}
					{isCurrentValue && (
						<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M17 7H21M21 7V11M21 7L13 15L9 11L3 17" stroke="#00BB68" strokeWidth="2" />
						</svg>
					)}
				</Statistics.UnitAmount>
				<Statistics.UnitTitle>{title}</Statistics.UnitTitle>
			</>
		) : (
			<Loader />
		)}
	</Statistics.UnitBlock>
);

export default DataUnits;
