import ReactTooltip from 'react-tooltip';
import styled from 'styled-components/macro';
import { Color } from '../../gfx/constants';
import { media } from '../../services/media';

export const StyledReactTooltip = styled(ReactTooltip)`
	border-radius: 4px;
	font-family: Primary;
	font-size: 14px;
	line-height: 20px;
	padding: 12px;
	background-color: ${Color.GRAY_9} !important;
	color: ${Color.WHITE} !important;
	max-width: 90vw;
	pointer-events: auto !important;

	&.place-top {
		::after {
			border-top-color: ${Color.GRAY_9} !important;
		}
	}

	&.place-right {
		::after {
			border-right-color: ${Color.GRAY_9} !important;
		}
	}

	&.place-bottom {
		::after {
			border-bottom-color: ${Color.GRAY_9} !important;
		}
	}

	&.place-left {
		::after {
			border-left-color: ${Color.GRAY_9} !important;
		}
	}

	&.success {
		background-color: ${Color.GREEN_2} !important;

		&.place-top {
			::after {
				border-top-color: ${Color.GREEN_2} !important;
			}
		}

		&.place-right {
			::after {
				border-right-color: ${Color.GRAY_9} !important;
			}
		}

		&.place-bottom {
			::after {
				border-bottom-color: ${Color.GRAY_9} !important;
			}
		}

		&.place-left {
			::after {
				border-left-color: ${Color.GRAY_9} !important;
			}
		}
	}

	&.warning {
		background-color: ${Color.YELLOW} !important;

		&.place-top {
			::after {
				border-top-color: ${Color.YELLOW} !important;
			}
		}

		&.place-right {
			::after {
				border-right-color: ${Color.YELLOW} !important;
			}
		}

		&.place-bottom {
			::after {
				border-bottom-color: ${Color.YELLOW} !important;
			}
		}

		&.place-left {
			::after {
				border-left-color: ${Color.YELLOW} !important;
			}
		}
	}

	&.info {
		background-color: ${Color.BLUE_2} !important;

		&.place-top {
			::after {
				border-top-color: ${Color.BLUE_2} !important;
			}
		}

		&.place-right {
			::after {
				border-right-color: ${Color.BLUE_2} !important;
			}
		}

		&.place-bottom {
			::after {
				border-bottom-color: ${Color.BLUE_2} !important;
			}
		}

		&.place-left {
			::after {
				border-left-color: ${Color.BLUE_2} !important;
			}
		}
	}

	&.error {
		background-color: ${Color.RED_2} !important;

		&.place-top {
			::after {
				border-top-color: ${Color.RED_2} !important;
			}
		}

		&.place-right {
			::after {
				border-right-color: ${Color.RED_2} !important;
			}
		}

		&.place-bottom {
			::after {
				border-bottom-color: ${Color.RED_2} !important;
			}
		}

		&.place-left {
			::after {
				border-left-color: ${Color.RED_2} !important;
			}
		}
	}
	& a,
	a :visited {
		color: ${Color.BLUE_1};
		text-decoration: underline;
	}

	${media.maxTablet`
		margin: 20px;
	`}
`;
