import * as qs from 'query-string';
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';

import { ITEMS_PER_PAGE } from '../../constants';
import { RouteProps } from '../../typings';
import { StyledPager } from './PagerStyle';

interface Props extends RouteProps {
	totalItems: number;
	visiblePages?: number;
	currentPage?: number;
	totalPages: number;
	onChange(newPage: number): void;
}

function Pager(props: Props) {
	const smallScreenPageAmount = 3;
	const mediumScreenPageAmount = 6;
	const largeScreenPageAmount = 15;
	const [visiblePages, setVisiblePages] = useState(mediumScreenPageAmount);

	const { page } = qs.parse(props.location.search);
	const { totalPages, currentPage, totalItems } = props;

	useEffect(() => {
		const changeVisiblePages = (_event: Event) => {
			if (visiblePages !== smallScreenPageAmount && window.innerWidth < 500) {
				setVisiblePages(smallScreenPageAmount);
				return;
			}

			if (visiblePages !== mediumScreenPageAmount && window.innerWidth > 500 && window.innerWidth < 1024) {
				setVisiblePages(mediumScreenPageAmount);
				return;
			}

			if (visiblePages !== largeScreenPageAmount && window.innerWidth > 1024) {
				setVisiblePages(largeScreenPageAmount);
				return;
			}

			return;
		};

		window.addEventListener('resize', changeVisiblePages);

		return () => window.removeEventListener('resize', changeVisiblePages);
	}, [visiblePages]);

	if (page && typeof page !== 'string') {
		return null;
	}

	if (totalItems < ITEMS_PER_PAGE) {
		return null;
	}

	const generatePagerLink = (page: number) => {
		const { search } = props.location;

		const additionalSearchParams = qs.parse(search);

		const stringifiedInput = qs.stringify({ ...additionalSearchParams, page });

		return `${props.match.path}?${stringifiedInput}`;
	};

	const handlePageChanged = (newPage: number) => {
		const { onChange } = props;

		onChange(newPage);

		const link = generatePagerLink(newPage);
		props.history.push(link);
	};

	const onPageChangeClick = (newPage: number) => {
		handlePageChanged(newPage + 1);
	};

	return (
		<StyledPager
			current={page ? parseInt(page, 10) - 1 : currentPage ? currentPage - 1 : 0}
			total={totalPages}
			visiblePages={visiblePages}
			onPageChanged={onPageChangeClick}
		/>
	);
}

export default withRouter(Pager);
