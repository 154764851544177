import styled, { css } from 'styled-components/macro';

import { Color, Shadow } from '../../../../gfx/constants';
import PrimaryMedium from '../../../../gfx/fonts/Roboto-Medium.ttf';
import { H2 } from '../../../../gfx/globals';
import { media } from '../../../../services/media';

export const GoBack = styled.button`
	width: 60px;
	height: 21px;
	position: relative;
	padding: 0;
	margin-top: 24px;
	text-decoration: underline;
	color: #0b132b;
	font-size: 16px;
	line-height: 21px;
	font-family: ${PrimaryMedium};
	border: none;
	background-color: transparent;
	cursor: pointer;
`;

export const ContentWrapper = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;

	.react-reveal {
		animation-duration: 400ms !important;

		${media.maxMobile`
		width: 225px;
		max-width: 225px;
		`}
	}
`;

export const DagcoinCardWrapper = styled.div`
	position: relative;
	display: flex;
	height: 186px;
	width: 328px;
	justify-content: center;
	margin: 0 0 24px;

	${media.maxMobile`
		width: 225px;
		height: 130px;
		`}
`;

export const DagcoinCard = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
	background: ${Color.BLUE_8};
	border-radius: 12px;
	box-shadow: 0 10px 36px 0 rgba(21, 35, 72, 0.4);
	padding: 20px;

	${media.maxMobile`
		padding: 15px;

		`}
`;

export const CardHeader = styled.div`
	position: relative;
	display: flex;
	height: 32px;
	width: 100%;
	align-items: flex-start;
	margin-bottom: 41px;

	${media.maxMobile`
		height: 25px;
	margin-bottom: 22px;

		`}
`;

export const CardIcon = styled.div`
	position: relative;
	display: flex;
	height: 100%;
	width: 91px;

	${media.maxMobile`
		width: 65px;

		`}

	div div svg {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
	}
`;

export const CardNumberWrapper = styled.div<{ cardError: boolean }>`
	position: relative;
	display: flex;
	height: 33px;
	width: 100%;
	margin-bottom: 16px;
	justify-content: space-between;
	align-items: center;

	${media.maxMobile`
		height: 25px;
	margin-bottom: 10px;

		`}
	${(props) =>
		props.cardError &&
		css`
			background-color: ${Color.RED_2};
			border-radius: 4px;
		`}
`;

export const CardNumber = styled.div<{ currentValue: string; zeros: string; showFocus: boolean }>`
	position: relative;
	text-align: center;
	font-size: 24px;
	line-height: 32px;
	padding: 0 5px 0;
	border-radius: 4px;
	border: 1px solid transparent;

	${media.maxMobile`
	font-size: 16px;
	line-height: 21px;

		`}

	${(props) =>
		props.showFocus &&
		css`
			border: 1px solid ${Color.GREEN_3};
		`}
		::before {
		content: '';
		color: white;
	}
	::after {
		content: '0000';
		color: ${Color.GRAY_5};
		opacity: 0.45;
	}

	${(props) =>
		css`
			::before {
				content: '${props.currentValue}';
			}
			::after {
				content: '${props.zeros}';
			}
		`}
`;

export const CardStatusWrapper = styled.div`
	position: relative;
	display: flex;
	height: 28px;
	width: 100%;
	align-items: center;
	justify-content: center;

	${media.maxMobile`
	height: 20px;

		`}
`;

export const StatusMessage = styled.div`
	position: relative;
	height: 100%;
	color: white;
	font-size: 14px;
	line-height: 16px;
	padding-top: 4px;
	margin-right: 7px;

	${media.maxMobile`
	font-size: 12px;
	line-height: 14px;

		`}
`;

export const StatusIcon = styled.div`
	position: relative;
	height: 100%;

	div div svg {
		width: 16px;
	}

	${media.maxMobile`
	div div svg{
	 	width: 14px;
	}
		`}

	div div svg path {
		fill: ${Color.RED_ERROR};
	}
`;

export const CardInfoWrapper = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	height: 249px;
	width: 100%;
	align-items: center;

	${media.maxMobile`

	height: 345px;

		`}
`;

export const InfoFieldsWrapper = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 328px;
	align-items: flex-start;

	${media.maxMobile`

	width: 100%;

		`}
`;

export const PinCodeFieldWrapper = styled.div`
	position: relative;
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;

	${media.maxMobile`
		margin-top: 17px;
		flex-direction: column;
		align-items: flex-start;
	`}
`;

export const ConfirmButton = styled.button<{ isDisabled: boolean }>`
	position: relative;
	height: 48px;
	width: 113px;
	border-radius: 4px;
	outline: none;
	border: none;
	background-color: ${Color.BLUE_8};
	color: white;
	font-family: ${PrimaryMedium};
	text-align: center;
	font-size: 16px;
	line-height: 21px;
	cursor: pointer;

	:hover {
		box-shadow: ${Shadow.BLUE};
	}

	${(props) =>
		props.isDisabled &&
		css`
			color: ${Color.GRAY_TEXT}
			cursor: auto;
			background-color: ${Color.GRAY_BUTTON};

			&:hover { box-shadow: none; }
	`}

	${media.maxMobile`
		margin-top: 17px;
		width: 100%;
		border-radius: 0 25px;
	`}
`;

export const CardPinCodeWrapper = styled.div`
	position: relative;
	display: flex;
	height: 48px;
	width: 170px;
	align-items: center;
	border: 1px solid purple;

	input {
		padding: 0;
		height: 48px;
		max-width: 35px;
		width: 35px;
	}
`;

export const ModalTitleWrapper = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;

	${H2} {
		color: ${Color.RED_2};
		margin-right: 8px;
	}

	div div svg path {
		fill: ${Color.RED_2};
	}
`;

export const ModalContentWrapper = styled.div`
	width: 100%;
	height: 100%;
	position: relative;
	box-sizing: border-box;
	text-align: left;
	font-family: secondary;
	font-size: 14px;
	line-height: 21px;

	h2 {
		font-size: 16px;
		font-family: primaryMedium;
		line-height: 21px;
	}
	span {
		text-align: right;
		font-family: secondary;
		font-size: 14px;
		line-height: 21px;
		margin-bottom: 20px;
	}
`;

export const InfoTitle = styled.div`
	position: relative;
	height: 26px;
	width: 243px;
	color: #0b132b;
	font-size: 20px;
	line-height: 26px;
	text-align: center;
	font-family: ${PrimaryMedium};
	margin-bottom: 18px;

	${media.maxMobile`
		width: 100%;
		font-size: 18px;
	`}
`;

export const ModalButtonWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	button {
		margin-left: 14px;
	}
`;
