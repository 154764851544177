import React, { useState } from 'react';
import { BusinessVerificationFormDescription, DropzoneSection } from './VerifyStyle';
import { Redirect, withRouter } from 'react-router-dom';
import Notification from '../../../components/notification/Notification';
import ReactSVG from 'react-svg';
import HookDropZoneField from '../../../components/react-hook-form/HookDropzoneField';
import { RouteProps } from '../../../typings';
import Form from '../../../components/form/Form';
import ReactHookForm, { RowPlacement, WhiteHookForm } from '../../../components/react-hook-form/ReactHookFormStyle';
import { PreviousWrapper } from '../../public/sign-up/SignUpViewStyle';
import { GoBackText } from '../../public/sign-up/FillDetailsStep';
import Button, { ButtonTypes } from '../../../components/button/Button';
import { ColumnedLeftCenterAlignedFlex, GoBackLink, H1, JustifiedRightFlex } from '../../../gfx/globals';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { validateMaxFileSize } from '../../../services/validate-max-file-size';
import { validateFileType } from '../../../services/validate-file-type';
import View from '../../../components/view/View';
import { toast } from 'react-toastify';
import { useStoreActions, useStoreState } from '../../../services/store';
import FormStepper from '../../../components/form-stepper/FormStepper';
import Loader from '../../../components/loader/Loader';
import { BusinessType, RoutesUrls, VerificationState } from '../../../constants';
import RejectionReason from '../../../components/rejection-reason/RejectionReason';
import { getRejectReasonStringArray } from './VerifyBusinessView';
const schema = Yup.object().shape({
	addressImage: Yup.mixed()
		.required('Business address proof is required')
		.test('does-not-exceed-size', `File size should be more than 1Kb and not larger than 5Mb`, validateMaxFileSize)
		.test('has-invalid-type', `File type is not accepted`, validateFileType),
});
export interface VerifyBusinessFields {
	addressImage: File[];
}

interface Props extends RouteProps {
	errorElement: JSX.Element | null;
	setErrorElement(element: JSX.Element | null): void;
}

function VerifyBusinessAddressView(props: Props) {
	const [isActionLoading, setIsActionLoading] = useState<boolean>(false);

	const { verifyBusinessAddress } = useStoreActions((actions) => ({
		...actions.verification,
	}));

	// import from '../../../services/store'
	const { verifications, activeViewer } = useStoreState((state) => ({
		verifications: state.verification.verifications,
		activeViewer: state.viewer.activeViewer,
	}));

	const methods = useForm<VerifyBusinessFields>({
		resolver: yupResolver(schema),
		shouldFocusError: true,
		mode: 'onChange',
	});

	const rejectionReason =
		verifications && verifications.tiers && verifications.tiers.TIER3 && verifications.tiers.TIER3.rejectionReason
			? verifications.tiers.TIER3.rejectionReason
			: '';

	const handleVerifyBusinessAddress = async (e: React.FormEvent<HTMLInputElement>) => {
		// Remove image properties from input
		const { addressImage } = methods.getValues();

		// Construct form data
		const formData = new FormData();

		addressImage.forEach((file) => {
			formData.append('address', file);
		});

		setIsActionLoading(true);
		const response = await verifyBusinessAddress({ data: formData });

		if (response.success) {
			setIsActionLoading(false);
			verifications?.tiers.TIER1?.state === VerificationState.APPROVED
				? props.history.push(RoutesUrls.VERIFY_BUSINESS_TIERS)
				: props.history.push(RoutesUrls.PRIVATE);
			toast.success('Business details and proof submitted');
			return;
		}

		if (response.error) {
			setIsActionLoading(false);
			toast.error(<>Something went wrong, please try again</>);
			return false;
		}
	};

	//redirect to dashboard if user is not eligible for TIER 2 application
	if (
		activeViewer?.businessType !== BusinessType.BUSINESS ||
		!verifications ||
		!(!!verifications.tiers.TIER1 && verifications.tiers.TIER1.state !== VerificationState.REJECTED)
	) {
		return <Redirect to={RoutesUrls.PRIVATE} />;
	}

	return (
		<View>
			<>
				<H1 center>Business verification - Tier 2 (up to 10 000€ / mo)</H1>
				<FormStepper
					currentStep={3}
					steps={[
						{
							name: 'Business details',
						},
						{
							name: 'Business proof',
						},
						{
							name: 'Business address proof',
						},
					]}
				/>
				<BusinessVerificationFormDescription>
					To process transaction volumes up to 10 000 EUR per month, you must submit a corporate document showing the
					address of the registered identity of the business in the jurisdiction where the company operates. Note that
					we only accept scanned image(s) of an original paper document.{' '}
					<a
						href="http://help.dagpay.io/en/articles/5591451-company-legal-entity-business-verification"
						target="_blank"
						rel="noopener noreferrer"
					>
						Learn more about business verification requirements.
					</a>{' '}
				</BusinessVerificationFormDescription>
			</>
			{rejectionReason && <RejectionReason reasons={getRejectReasonStringArray(rejectionReason)} />}
			<FormProvider {...methods}>
				<WhiteHookForm onSubmit={methods.handleSubmit(handleVerifyBusinessAddress)}>
					<Form.Separator>
						<ReactSVG src="/files/svg/private/Upload.svg" />
						Upload business address proof document(s)
					</Form.Separator>
					<Notification left>Please provide the following documents:</Notification>
					<DropzoneSection>
						<Form.Column withMargin>
							<HookDropZoneField name="addressImage" label="Proof of business address" maxFiles={3} multiple />
						</Form.Column>
						<ColumnedLeftCenterAlignedFlex flex={1}>
							<Notification>
								<p>
									Please submit <strong>at least one (1)</strong> document with your
									<br /> business address visible on it:
								</p>
								<p>
									- For example, an invoice or utility bill including
									<br />
									the full company address
								</p>
								<p>
									- Document should <strong>not be older than 3 months</strong>
								</p>
							</Notification>
						</ColumnedLeftCenterAlignedFlex>
					</DropzoneSection>
					<ReactHookForm.Row placement={RowPlacement.SPACE_BETWEEN}>
						<PreviousWrapper>
							{verifications.tiers.TIER1.state === VerificationState.APPROVED && (
								<GoBackLink to={RoutesUrls.PRIVATE}>
									<ReactSVG src="/files/svg/backArrow.svg" />
									<GoBackText>Back</GoBackText>
								</GoBackLink>
							)}
						</PreviousWrapper>
						<JustifiedRightFlex>
							{verifications.tiers.TIER1.state !== VerificationState.APPROVED && (
								<PreviousWrapper>
									<GoBackLink to={RoutesUrls.PRIVATE}>Skip submitting business address proof</GoBackLink>
								</PreviousWrapper>
							)}
							{isActionLoading ? (
								<Loader />
							) : (
								<Button.Secondary green type="submit">
									Submit
								</Button.Secondary>
							)}
						</JustifiedRightFlex>
					</ReactHookForm.Row>
				</WhiteHookForm>
			</FormProvider>
		</View>
	);
}

export default withRouter(VerifyBusinessAddressView);
