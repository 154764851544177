import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { BusinessVerification } from '../../../api/verificationApi';
import FormStepper from '../../../components/form-stepper/FormStepper';
import Loader from '../../../components/loader/Loader';
import { WhiteHookForm } from '../../../components/react-hook-form/ReactHookFormStyle';
import RejectionReason from '../../../components/rejection-reason/RejectionReason';
import View from '../../../components/view/View';
import {
	TIER_2_LIMIT,
	RoutesUrls,
	TIER_1_LIMIT,
	Validations,
	VerificationState,
	BusinessType,
} from '../../../constants';
import Notification from '../../../components/notification/Notification';
import { H1 } from '../../../gfx/globals';
import getFormData from '../../../services/get-form-data';
import { getServerValidationErrors } from '../../../services/input-error-utils';
import { useStoreActions, useStoreState } from '../../../services/store';
import { validateFileType } from '../../../services/validate-file-type';
import { validateMaxFileSize } from '../../../services/validate-max-file-size';
import { RouteProps } from '../../../typings';
import { BusinessVerificationFormDescription } from './VerifyStyle';
import ReactHookForm, {
	HookFormColumn,
	HookFormSection,
	RowPlacement,
	SectionTitle,
} from '../../../components/react-hook-form/ReactHookFormStyle';
import { GoBack, GoBackText } from '../../public/sign-up/FillDetailsStep';
import { PreviousWrapper } from '../../public/sign-up/SignUpViewStyle';
import Button, { ButtonTypes } from '../../../components/button/Button';
import { DropzoneSection } from './VerifyStyle';
import Form from '../../../components/form/Form';
import HookDropZoneField from '../../../components/react-hook-form/HookDropzoneField';
import { ColumnedLeftCenterAlignedFlex } from '../../../gfx/globals';
import styled from 'styled-components';
import HookBaseField from '../../../components/react-hook-form/HookBaseField';
import HookDatePicker from '../../../components/react-hook-form/HookDatePicker';
import HookPhoneField from '../../../components/react-hook-form/HookPhoneField';
import ReactSVG from 'react-svg';
import HookSelectField from '../../../components/react-hook-form/hook-select-field/HookSelectField';
import { getCountryOptions } from '../../../services/get-country-options';
import { FieldOptionProps } from '../../../components/field/Field';
import dayjs from 'dayjs';

export type HandleDocumentChangeFn = (e: React.ChangeEvent<HTMLSelectElement>) => void;

export interface VerifyBusinessFields {
	businessName: string;
	registrationNumber: string;
	vatNumber: string;
	registrationDate: Date | null;
	email: string;
	phone: string;
	address: string;
	countryCode: FieldOptionProps | null;
	city: string;
	county: string;
	postalCode: string;
	description: string;
	website: string;
	registrationImage: File[];
	operationsImage: File[];
}
const legalEntitySchema = Yup.object<VerifyBusinessFields>().shape({
	businessName: Yup.string()
		.required('Business name is required')
		.max(100, 'The maximum length is 100 characters')
		.trim('Field cannot be left empty or filled with spaces')
		.strict(true),
	registrationNumber: Yup.string()
		.required('Registration number is required')
		.max(100, 'The maximum length is 100 characters')
		.trim('Field cannot be left empty or filled with spaces')
		.strict(true),
	registrationDate: Yup.date()
		.required('Registration date is required')
		.max(dayjs().endOf('day').toDate(), 'Registration date cannot be in the future')
		.typeError('Business registration date must be a valid date'),
	email: Validations.INVALID_EMAIL,
	phone: Validations.INVALID_PHONE_NUMBER,
	vatNumber: Yup.string()
		.notRequired()
		.max(100, 'The maximum length is 100 characters')
		.test('only-spaces', 'VAT number must contain characters', (value) => (value ? value.trim().length !== 0 : true)),
	description: Yup.string()
		.required('Description is required')
		.max(100, 'The maximum length is 100 characters')
		.trim('Field cannot be left empty or filled with spaces')
		.strict(true),
	address: Yup.lazy(() => {
		return Yup.string()
			.max(255, 'The maximum length is 255 characters')
			.trim('Field cannot be left empty or filled with spaces')
			.when('website', {
				is: (website) => !website || website.length === 0,
				then: Yup.string().required('you must fill in address or website'),
				otherwise: Yup.string().notRequired(),
			});
	}),
	countryCode: Yup.mixed().nullable(true).required('Country is required'),
	city: Yup.string()
		.required('City is required')
		.max(100, 'The maximum length is 100 characters')
		.trim('Field cannot be left empty or filled with spaces')
		.strict(true),
	county: Yup.string()
		.required('County is required')
		.max(100, 'The maximum length is 100 characters')
		.trim('Field cannot be left empty or filled with spaces')
		.strict(true),
	postalCode: Yup.string()
		.required('Postal code is required')
		.max(100, 'The maximum length is 100 characters')
		.trim('Field cannot be left empty or filled with spaces')
		.strict(true),
	website: Yup.string()
		.notRequired()
		.max(100, 'The maximum length is 100 characters')
		.trim('Field cannot be left empty or filled with spaces'),
	registrationImage: Yup.mixed()
		.required('Please provide required registration documents')
		.test('does-not-exceed-size', `File size should be more than 1Kb and not larger than 5Mb`, validateMaxFileSize)
		.test('has-invalid-type', `File type is not accepted`, validateFileType),
	operationsImage: Yup.mixed().notRequired(),
});

const StepFormSection = styled.div<{ hidden?: boolean }>`
	opacity: ${(props) => (props.hidden ? 0 : 1)};
`;

export const getRejectReasonStringArray = (rejectReason: string) => {
	let rejectReasonArray: string[] = new Array();
	//try to parse json and return array
	try {
		const rejectReasonObject = JSON.parse(rejectReason);
		const rejectReasonArray = Object.values(rejectReasonObject) as string[];
		return rejectReasonArray;
	} catch (_e) {
		rejectReasonArray.push(rejectReason);
		return rejectReasonArray;
	}
};

function VerifyBusinessView(props: RouteProps) {
	const [errorMessage, setErrorMessage] = useState('');
	const [errorElement, setErrorElement] = useState<JSX.Element | null>(null);
	const [isActionLoading, setIsActionLoading] = useState(false);
	const [step, setStep] = useState(1);

	const { verifications, viewer, countries } = useStoreState((state) => ({
		verifications: state.verification.verifications,
		viewer: state.viewer.activeViewer,
		countries: state.country.countries,
	}));

	const { verifyBusiness, getCountries } = useStoreActions((actions) => ({
		...actions.verification,
		...actions.country,
	}));

	const defaultValues = {
		businessName: '',
		registrationNumber: '',
		registrationDate: null,
		vatNumber: '',
		email: '',
		phone: '',
		description: '',
		address: '',
		countryCode: null,
		city: '',
		county: '',
		postalCode: '',
		registrationImage: [],
		operationsImage: [],
	};

	const rejectionReason =
		verifications && verifications.business && verifications.business.state === VerificationState.REJECTED
			? verifications.business.rejectionReason
			: '';

	const methods = useForm<VerifyBusinessFields>({
		resolver: yupResolver(legalEntitySchema),
		defaultValues: defaultValues,
		shouldFocusError: true,
		mode: 'onChange',
	});

	const { trigger, errors } = methods;

	const onNext = async () => {
		if (
			await trigger([
				'businessName',
				'registrationNumber',
				'vatNumber',
				'registrationDate',
				'email',
				'phone',
				'address',
				'countryCode',
				'city',
				'county',
				'postalCode',
				'description',
			])
		) {
			//Check FREELANCE mandatory fields AND/OR  one of mandatory physical/website address
			if (
				!errors.businessName &&
				!errors.registrationNumber &&
				!errors.registrationDate &&
				!errors.email &&
				!errors.phone &&
				!errors.vatNumber &&
				!errors.description &&
				!errors.address
			) {
				setErrorMessage('');
				setStep(2);
			}
		}
	};

	const handleVerifyBusiness = async (e: React.FormEvent<HTMLInputElement>) => {
		e.preventDefault();
		// Prepare modified data
		await trigger(['operationsImage', 'registrationImage']);
		if (Object.keys(errors).length > 0) {
			setErrorElement(<p>{JSON.stringify(errors).toString()}</p>);
			return false;
		}
		// Remove image properties from input
		const { operationsImage, registrationImage, countryCode, registrationDate, ...updatedInput } = methods.getValues();

		const isoRegistrationDate = registrationDate ? registrationDate.toISOString() : '';
		const countryCodeInput = countryCode && typeof countryCode.value === 'string' ? countryCode.value : '';
		// Construct form data
		// tslint:disable-next-line:no-any
		const formData = getFormData<any>({
			...updatedInput,
			countryCode: countryCodeInput,
			registrationDate: isoRegistrationDate,
		});
		registrationImage.forEach((file) => {
			formData.append('registration', file);
		});

		setIsActionLoading(true);
		const response = await verifyBusiness({ data: formData });

		if (response.success) {
			setIsActionLoading(false);
			props.history.push(RoutesUrls.VERIFY_BUSINESS_ADDRESS_DOCUMENTS);
			toast.success('Business details verification is sent');

			return;
		}

		if (response.error) {
			setIsActionLoading(false);
			const serverValidationErrors = getServerValidationErrors<BusinessVerification>(response);
			setErrorMessage(serverValidationErrors.errorMessage);
			toast.error(<>Something went wrong, please try again</>);
		}
	};

	useEffect(() => {
		const fetchCountries = async () => {
			await getCountries();
		};
		fetchCountries();
	}, [getCountries]);

	if (!countries || !viewer) {
		return <Loader />;
	}

	if (viewer && viewer.businessType !== BusinessType.BUSINESS) {
		toast.error('Wrong account type');
		props.history.push(RoutesUrls.VERIFY_FREELANCE);
	}

	return (
		<View>
			<>
				<H1 center>
					Business verification - Tier {step === 3 ? '2' : '1'} (up to {step === 3 ? TIER_2_LIMIT : TIER_1_LIMIT} € /
					mo)
				</H1>
				<FormStepper
					currentStep={step}
					steps={[
						{
							name: 'Business details',
						},
						{
							name: 'Business proof',
						},
						{
							name: 'Business address proof',
						},
					]}
				/>
				<BusinessVerificationFormDescription>
					To verify your business, you must at a minimum enter your company details, in order to process transaction
					volumes up to 1 000 EUR per month. To increase the approved volume, you must also submit corporate documents
					showing the registered identity of the business in the jurisdiction where the company operates.{' '}
					<a
						href="http://help.dagpay.io/en/articles/5591451-company-legal-entity-business-verification"
						target="_blank"
						rel="noopener noreferrer"
					>
						Learn more about business verification requirements.
					</a>
					.
				</BusinessVerificationFormDescription>
			</>

			{rejectionReason && <RejectionReason reasons={getRejectReasonStringArray(rejectionReason)} />}

			<FormProvider {...methods}>
				<WhiteHookForm onSubmit={handleVerifyBusiness}>
					<StepFormSection hidden={step !== 1}>
						<SectionTitle>
							<ReactSVG src="/files/svg/private/CompanyDetails.svg" />
							Company details
						</SectionTitle>

						<HookFormSection>
							<HookFormColumn>
								<HookBaseField
									name="businessName"
									label="Legal name of business"
									placeholder="Legal name of company/business"
									tabIndex={1}
								/>
								{/* REPLACE WITH DATE FIELD <HookBaseField name="businessName" label="Business registration date" placeholder="DD/MM/YYYY" tabIndex={3} /> */}

								<HookDatePicker name="registrationDate" label="Business registration date" maxDate={new Date()} />
								<HookPhoneField name="phone" label="Phone number" />
							</HookFormColumn>
							<HookFormColumn>
								<HookBaseField
									name="registrationNumber"
									label="Company registration number"
									placeholder="0000000"
									tabIndex={2}
								/>
								<HookBaseField name="email" label="Email" placeholder="info@example.com" tabIndex={4} />
								<HookBaseField name="vatNumber" label="VAT number" placeholder="0000000" optionalBubble tabIndex={6} />
							</HookFormColumn>
						</HookFormSection>
						<HookBaseField
							name="description"
							label="Nature of business"
							placeholder="Describe your business in a few words"
							tabIndex={7}
						/>
						<Notification box>
							Depending on the nature of your business, please provide your business operating address details (for
							brick-and-mortar type business) and website (if applies)
						</Notification>
						<HookFormSection margin={'50px 0'}>
							<HookFormColumn>
								<SectionTitle>
									<ReactSVG src="/files/svg/private/BusinessAddress.svg" />
									Business address
								</SectionTitle>{' '}
								{/* replace with address field??? */}
								<HookBaseField
									name="address"
									label="Address"
									placeholder="Street address, house/apartment nr"
									tabIndex={8}
								/>
								<HookBaseField name="city" label="City/town" placeholder="Name of city or town" tabIndex={9} />
								<HookBaseField name="postalCode" label="Postal code" placeholder="Postal code" tabIndex={10} />
								<HookSelectField
									label="Country"
									name="countryCode"
									options={getCountryOptions(countries)}
									isSearchable
									tabIndex={11}
								/>
								<HookBaseField name="county" label="Province, Region or County" placeholder="County" tabIndex={10} />
							</HookFormColumn>
							<HookFormColumn>
								<SectionTitle>
									<ReactSVG src="/files/svg/private/BusinessWebsite.svg" />
									Business website
								</SectionTitle>{' '}
								<HookBaseField
									name="website"
									label="Address of website"
									placeholder="www.example.com"
									tabIndex={10}
									optionalBubble={viewer.businessType === BusinessType.BUSINESS}
								/>
							</HookFormColumn>
						</HookFormSection>
						<ReactHookForm.Row placement={RowPlacement.SPACE_BETWEEN}>
							<PreviousWrapper>
								<GoBack type={ButtonTypes.BUTTON} onClick={props.history.goBack}>
									<ReactSVG src="/files/svg/backArrow.svg" />
									<GoBackText>Back</GoBackText>
								</GoBack>
							</PreviousWrapper>
							<Button type="button" onClick={onNext}>
								Next
							</Button>
						</ReactHookForm.Row>
					</StepFormSection>

					<StepFormSection hidden={step !== 2 || viewer.businessType !== BusinessType.FREELANCE}>
						<SectionTitle>
							<ReactSVG src="/files/svg/private/Upload.svg" />
							Upload proof business operations
						</SectionTitle>
						<Notification left>Please provide the following documents:</Notification>
						<DropzoneSection>
							<Form.Column withMargin>
								<HookDropZoneField name="operationsImage" maxFiles={3} multiple />
							</Form.Column>
							<ColumnedLeftCenterAlignedFlex flex={1}>
								<Notification>
									<p>
										Please submit <strong>at least one (1)</strong> of the following
									</p>
									<H1>[TODO]</H1>
								</Notification>
							</ColumnedLeftCenterAlignedFlex>
						</DropzoneSection>
						<ReactHookForm.Row placement={RowPlacement.SPACE_BETWEEN}>
							<PreviousWrapper>
								<GoBack type={ButtonTypes.BUTTON} onClick={() => setStep(1)}>
									<ReactSVG src="/files/svg/backArrow.svg" />
									<GoBackText>Back</GoBackText>
								</GoBack>
							</PreviousWrapper>
							<Button.Secondary green type="submit">
								Submit
							</Button.Secondary>
						</ReactHookForm.Row>
					</StepFormSection>
					<StepFormSection hidden={step !== 2 || viewer.businessType !== BusinessType.BUSINESS}>
						<SectionTitle>
							<ReactSVG src="/files/svg/private/Upload.svg" />
							Upload business proof document(s)
						</SectionTitle>
						<Notification left>Please provide the following documents:</Notification>
						<DropzoneSection>
							<Form.Column withMargin>
								<HookDropZoneField name="registrationImage" maxFiles={3} multiple />
							</Form.Column>
							<ColumnedLeftCenterAlignedFlex flex={1}>
								<Notification>
									<p>
										Please submit <strong>at least one (1)</strong> of the following
										<br /> goverment issued business registration documents:
									</p>
									<p>- officially issued business license/registration</p>
									<p>- officially registered Articles of Incorporation</p>
								</Notification>
							</ColumnedLeftCenterAlignedFlex>
						</DropzoneSection>
						<ReactHookForm.Row placement={RowPlacement.SPACE_BETWEEN}>
							<PreviousWrapper>
								<GoBack type={ButtonTypes.BUTTON} onClick={() => setStep(1)}>
									<ReactSVG src="/files/svg/backArrow.svg" />
									<GoBackText>Back</GoBackText>
								</GoBack>
							</PreviousWrapper>
							{isActionLoading ? (
								<Loader />
							) : (
								<Button.Secondary green type="submit">
									Submit
								</Button.Secondary>
							)}
						</ReactHookForm.Row>
					</StepFormSection>
					<ReactHookForm.Error>{errorElement}</ReactHookForm.Error>
				</WhiteHookForm>
			</FormProvider>
			<div>{errorMessage}</div>
		</View>
	);
}
export default withRouter(VerifyBusinessView);
