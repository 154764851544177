import { ErrorMessage, Field as FieldFormik } from 'formik';
import { FormikFieldState } from 'formik-fields';
import React, { useState } from 'react';
import { ReactDatePickerProps } from 'react-datepicker';
import ReactSVG from 'react-svg';
import { ReactTagsProps } from 'react-tag-input';

import { FieldIconTypes, FieldTypes, iconPaths } from '../../constants';
import { numberOrTextType } from '../../services/number-or-text-type';
import BaseInput from '../base-input/BaseInput';
import DecimalInput from '../decimal-input/DecimalInput';
import Checkbox from '../checkbox/Checkbox';
import DatePicker from '../datepicker/Datepicker';
import DropZoneComponent from '../dropzone/Dropzone';
import MultiSelectField from '../multi-select-field/MultiSelectField';
import PasswordProgress, { PasswordStrength } from '../password-progress/PasswordProgress';
import PhoneInput from '../phone-input/PhoneInput';
import ReactTagInput from '../react-tag-input/ReactTagInput';
import Select from '../select/Select';
import TextArea from '../textarea/TextArea';

import StyledField from './FieldStyle';

export interface FieldOptionProps {
	label: string | JSX.Element;
	value: string | number;
	isDisabled?: boolean;
	isDefault?: boolean;
	toolTipMessage?: string;
	isInitialSelected?: boolean;
}

export interface MultiSelectFilter {
	value: string;
	label: string;
	checked: boolean;
	hidden: boolean;
}

export type IconPaths = { [K in keyof typeof FieldIconTypes]: string };

interface FieldCustomProps {
	type?: FieldTypes;
	iconType?: FieldIconTypes;
	passwordStrength?: PasswordStrength;
	options?: FieldOptionProps[];
	error?: string;
	children?: React.ReactNode;
	autoFocus?: boolean;
	height?: string;
	optional?: boolean;
	addonNode?: React.ReactNode;
	addonNodeWithOwnStyling?: React.ReactNode;
	leftWrapper?: string;
	marginTop?: string;
	delimeters?: number[];
	isCentered?: boolean;
	rightWrapper?: string;
	formikField: FormikFieldState<string | MultiSelectFilter[] | boolean | number | null | File[] | Date>;
	noAddonIcon?: boolean;
	hasErrorField?: boolean;
	addOnNodeSize?: number;
	rightNode?: React.ReactNode | undefined;
	leftNode?: React.ReactNode | undefined;
	hasNodes?: boolean;
	customColor?: boolean;
	tagPositionBottom?: boolean;
	addOnNodeArrowUp?: boolean;
	searchBar?: boolean;
	tabIndex?: number;
	decimalPlaces?: number;
	fieldLength?: number;
	maxFiles?: number;
}

interface FieldWrapProps {
	fieldWrapClassName?: string;
	securityView?: boolean;
}

export type FieldProps = React.InputHTMLAttributes<HTMLInputElement> &
	React.SelectHTMLAttributes<HTMLSelectElement> &
	React.TextareaHTMLAttributes<HTMLTextAreaElement> &
	Partial<ReactDatePickerProps> &
	Partial<ReactTagsProps> &
	FieldCustomProps &
	FieldWrapProps;

const Field = (props: FieldProps) => {
	const {
		title,
		type = FieldTypes.TEXT,
		hasErrorField = true,
		optional,
		name,
		tags,
		handleDelete: handleDeleteTag,
		className,
		securityView,
		customColor,
		addOnNodeSize,
		rightWrapper,
		leftWrapper,
		passwordStrength,
		rightNode,
		leftNode,
		height,
		addonNode,
		addonNodeWithOwnStyling,
		noAddonIcon,
		iconType,
		marginTop,
		tagPositionBottom,
		addOnNodeArrowUp,
		...rest
	} = props;

	const isTagField = type === FieldTypes.TAG;

	const [isPasswordVisible, setIsPasswordVisible] = useState(false);

	const fieldHasAddonIcon = () =>
		type !== FieldTypes.PHONE &&
		type !== FieldTypes.CHECKBOX &&
		type !== FieldTypes.TEXTAREA &&
		type !== FieldTypes.DROPZONE &&
		!noAddonIcon;

	const fieldHasErrorField = () => hasErrorField && type !== FieldTypes.DROPZONE;

	const renderField = () => (
		<StyledField
			addOnNodeSize={`${addOnNodeSize}px`}
			autoWidth={leftWrapper !== '' || rightWrapper !== ''}
			noAddonIcon={noAddonIcon || leftWrapper !== '' || rightWrapper !== ''}
			marginTop={marginTop}
			hasError={props.formikField.error && props.formikField.isTouched ? true : false}
			isInline={leftWrapper !== '' || rightWrapper !== ''}
			hasNodes={leftNode !== undefined && rightNode !== undefined}
			onBlur={(e) => (e.currentTarget.scrollLeft = 0)}
		>
			{leftNode}
			{leftWrapper}
			<StyledField.InputWrapper
				height={height}
				hasError={props.formikField.error && props.formikField.isTouched ? true : false}
				hasNoBorder={type === FieldTypes.CHECKBOX || type === FieldTypes.DROPZONE}
			>
				{fieldHasAddonIcon() && (
					<StyledField.AddonIcon
						disabled={props.disabled}
						hasError={props.formikField.error && props.formikField.isTouched ? true : false}
					>
						{iconType ? <ReactSVG src={iconPaths[iconType]} /> : null}
					</StyledField.AddonIcon>
				)}

				{getField()}

				{type === 'password' && (
					<StyledField.PasswordIcon onMouseDown={() => setIsPasswordVisible(!isPasswordVisible)}>
						{isPasswordVisible ? (
							<ReactSVG src="/files/svg/icons/PasswordInvisible.svg" />
						) : (
							<ReactSVG src="/files/svg/icons/PasswordVisible.svg" />
						)}
					</StyledField.PasswordIcon>
				)}
				{addonNode && <StyledField.AddonNode arrowUp={addOnNodeArrowUp}>{addonNode}</StyledField.AddonNode>}
				{addonNodeWithOwnStyling}
			</StyledField.InputWrapper>
			{rightWrapper}
			{rightNode}
		</StyledField>
	);

	function getField() {
		switch (type) {
			case FieldTypes.MULTISELECT:
				return <FieldFormik component={MultiSelectField} {...rest} />;
			case FieldTypes.TEXTAREA:
				return <FieldFormik component={TextArea} {...rest} />;
			case FieldTypes.SELECT:
				return <FieldFormik component={Select} {...rest} />;
			case FieldTypes.DATE:
				return <FieldFormik component={DatePicker} {...rest} />;
			case FieldTypes.PHONE:
				return <FieldFormik component={PhoneInput} {...rest} />;
			case FieldTypes.DROPZONE:
				return <FieldFormik component={DropZoneComponent} {...rest} />;
			case FieldTypes.TAG:
				return <FieldFormik component={ReactTagInput} {...rest} />;
			case FieldTypes.CHECKBOX:
				return <FieldFormik component={Checkbox} {...rest} />;
			case FieldTypes.NUMBER:
				return <FieldFormik component={BaseInput} type={numberOrTextType()} step="any" {...rest} />;
			case FieldTypes.DECIMAL:
				return <FieldFormik component={DecimalInput} type={numberOrTextType()} step="any" {...rest} />;
			default:
				return (
					<FieldFormik
						type={isPasswordVisible ? FieldTypes.TEXT : type}
						component={BaseInput}
						isPassword={type === FieldTypes.PASSWORD}
						{...rest}
					/>
				);
		}
	}

	return (
		<StyledField.Wrapper hasErrors={fieldHasErrorField()} className={className} securityView={securityView}>
			{title && (
				<StyledField.Title htmlFor={name} isCentered={type === FieldTypes.DROPZONE}>
					{title}{' '}
					{props.formikField.name === 'password' && <PasswordProgress.Title passwordStrength={passwordStrength} />}
					{optional && <StyledField.Optional isAbsolute>Optional</StyledField.Optional>}
				</StyledField.Title>
			)}

			{tagPositionBottom && renderField()}
			{isTagField && (
				<StyledField.Tags>
					{tags &&
						tags.map((tag, index) => (
							<StyledField.Tag key={index} tagPositionBottom={tagPositionBottom}>
								{tag.text}
								{handleDeleteTag && <StyledField.DeleteTag onClick={() => handleDeleteTag(index)} />}
							</StyledField.Tag>
						))}
				</StyledField.Tags>
			)}
			{!tagPositionBottom && renderField()}

			{fieldHasErrorField() && (
				<StyledField.Error customColor={customColor}>
					<ErrorMessage name={props.formikField.name} />
				</StyledField.Error>
			)}
			{props.formikField.name === 'password' && <PasswordProgress passwordStrength={passwordStrength} />}
		</StyledField.Wrapper>
	);
};

export default Field;
