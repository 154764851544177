import * as React from 'react';
import ReactSVG from 'react-svg';

import { NavSidebarIcons, RoutesUrls } from '../../constants';
import { hasPermission } from '../../services/has-permission';
import { privateRoutes } from '../../views/private/routes';
import Logo from '../logo/Logo';

import {
	PrivateSidebarIcon,
	PrivateSidebarLogo,
	PrivateSidebarNavigation,
	PrivateSidebarNavLink,
	PrivateSidebarVersion,
	StickyWrapper,
	StyledPrivateSidebar,
} from './PrivateSidebarStyle';

export const getIcon = (iconName: NavSidebarIcons) => {
	switch (iconName) {
		case NavSidebarIcons.DASHBOARD:
			return <ReactSVG src="/files/svg/private/sidebar/Dashboard.svg" />;
		case NavSidebarIcons.POS:
			return <ReactSVG src="/files/svg/private/sidebar/Pos.svg" />;
		case NavSidebarIcons.EMAIL_INVOICE:
			return <ReactSVG src="/files/svg/private/sidebar/EmailInvoice.svg" />;
		case NavSidebarIcons.TRANSACTIONS:
			return <ReactSVG src="/files/svg/private/sidebar/Transactions.svg" />;
		case NavSidebarIcons.ESCROW:
			return <ReactSVG src="/files/svg/private/sidebar/Escrow.svg" />;
		case NavSidebarIcons.MERCHANT_TOOLS:
			return <ReactSVG src="/files/svg/private/sidebar/MerchantTools.svg" />;
		case NavSidebarIcons.SETTINGS:
			return <ReactSVG src="/files/svg/private/sidebar/Settings.svg" />;
		case NavSidebarIcons.DOCUMENTATION:
			return <ReactSVG src="/files/svg/private/sidebar/Documentation.svg" />;
		default:
			return <div />;
	}
};

export default function PrivateSidebar() {
	const privateSidebarLinks = privateRoutes.filter(
		(privateRoute) => privateRoute.navSidebarTitle && hasPermission(privateRoute.permissions),
	);

	return (
		<StyledPrivateSidebar>
			<StickyWrapper>
				<PrivateSidebarLogo>
					<Logo to="/private" />
				</PrivateSidebarLogo>
				<PrivateSidebarNavigation>
					{privateSidebarLinks.map((link) => (
						<PrivateSidebarNavLink
							exact={link.url !== RoutesUrls.PRIVATE ? false : true}
							key={link.url}
							to={link.url}
							target={link.openInNewTab ? '_blank' : ''}
						>
							{link.icon && <PrivateSidebarIcon>{getIcon(link.icon)}</PrivateSidebarIcon>}
							{link.navSidebarTitle}
						</PrivateSidebarNavLink>
					))}
				</PrivateSidebarNavigation>
			</StickyWrapper>
			<PrivateSidebarVersion>
				{process.env.REACT_APP_VERSION ? `v. ${process.env.REACT_APP_VERSION}` : ''}
			</PrivateSidebarVersion>
		</StyledPrivateSidebar>
	);
}
