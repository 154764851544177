import * as React from 'react';
import { Link, withRouter } from 'react-router-dom';
import ReactSVG from 'react-svg';
import styled from 'styled-components/macro';
import { Color } from '../../gfx/constants';
import { H1 } from '../../gfx/globals';
import { RouteProps } from '../../typings';
import Header from '../header/Header';

export const StyledNotFoundView = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	margin: auto;
	padding-top: 3%;
	width: 100%;

	svg {
		margin-bottom: 53px;
	}
	h1 {
		font-size: 42px;
		line-height: 49px;
		margin-bottom: 0;
	}
	a {
		color: ${Color.GREEN_3};
	}
`;

function NotFoundPrivateView(props: RouteProps) {
	return (
		<>
			<StyledNotFoundView>
				<ReactSVG src="/files/svg/notFound.svg" />
				<H1 center>Page not found</H1>
				<p>Oops, we could not find the page that you were looking for!</p>
				<Link to="/">Back to dashboard</Link>
			</StyledNotFoundView>
		</>
	);
}

export default withRouter(NotFoundPrivateView);
