import dayjs from 'dayjs';
import React from 'react';

import { Error } from '../react-hook-form/HookFieldsStyle';

import { Checkbox, CheckboxWrap, DayTitle, Row, TimePicker, TimeWrapper, Title, Wrap } from './OpeningHoursStyle';

export enum DayOfWeek {
	Monday = 'Monday',
	Tuesday = 'Tuesday',
	Wednesday = 'Wednesday',
	Thursday = 'Thursday',
	Friday = 'Friday',
	Saturday = 'Saturday',
	Sunday = 'Sunday',
}

export interface DayInfo {
	day: DayOfWeek;
	from: string | null;
	to: string | null;
	isClosed: boolean;
}

// TODO: can query from database and take last atm as default
export const openingHoursDefault: DayInfo[] = [
	{
		day: DayOfWeek.Monday,
		from: null,
		to: null,
		isClosed: true,
	},
	{
		day: DayOfWeek.Tuesday,
		from: null,
		to: null,
		isClosed: true,
	},
	{
		day: DayOfWeek.Wednesday,
		from: null,
		to: null,
		isClosed: true,
	},
	{
		day: DayOfWeek.Thursday,
		from: null,
		to: null,
		isClosed: true,
	},
	{
		day: DayOfWeek.Friday,
		from: null,
		to: null,
		isClosed: true,
	},
	{
		day: DayOfWeek.Saturday,
		from: null,
		to: null,
		isClosed: true,
	},
	{
		day: DayOfWeek.Sunday,
		from: null,
		to: null,
		isClosed: true,
	},
];

export interface OpeningHoursProps {
	state: DayInfo[];
	setState: React.Dispatch<React.SetStateAction<DayInfo[]>>;
	title?: string;
	errorMessage?: string;
	disabled?: boolean;
}

export const OpeningHours = (props: OpeningHoursProps) => {
	const { state, setState, title, errorMessage, disabled } = props;

	const handleBoxChanged = (_event: React.ChangeEvent<HTMLInputElement>, dayInfo: DayInfo, index: number) => {
		const stateCopy = state.slice();
		stateCopy[index].isClosed = !dayInfo.isClosed;

		if (stateCopy[index].isClosed) {
			stateCopy[index].from = null;
			stateCopy[index].to = null;
		} else {
			stateCopy[index].from = '00:00';
			stateCopy[index].to = '00:00';
		}

		setState(stateCopy);
	};

	const handleTimeChanged = (date: Date, dayInfo: DayInfo, index: number, type: 'from' | 'to') => {
		const stateCopy = state.slice();
		const dateAsString = dayjs(date).format('HH:mm');

		type === 'from' ? (stateCopy[index].from = dateAsString) : (stateCopy[index].to = dateAsString);

		setState(stateCopy);
	};

	const renderDayRow = (dayInfo: DayInfo, index: number) => {
		return (
			<Row key={`row-${index}`}>
				<CheckboxWrap>
					<Checkbox
						type="checkbox"
						checked={!dayInfo.isClosed}
						onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleBoxChanged(event, dayInfo, index)}
						disabled={disabled}
					/>
					<DayTitle>{dayInfo.day}</DayTitle>
				</CheckboxWrap>
				<TimeWrapper style={{ justifySelf: 'center' }}>
					<TimePicker
						placeholderText="Closed"
						selected={dayInfo.from ? dayjs(dayInfo.from, 'HH:mm').toDate() : null}
						disabled={dayInfo.isClosed || disabled}
						onChange={(date: Date) => (date ? handleTimeChanged(date, dayInfo, index, 'from') : null)}
						showTimeSelect
						showTimeSelectOnly
						timeIntervals={60}
						timeCaption=""
						dateFormat="HH:mm"
						timeFormat="HH:mm"
					/>
				</TimeWrapper>
				<TimeWrapper style={{ justifySelf: 'end' }}>
					<TimePicker
						placeholderText="Closed"
						selected={dayInfo.to ? dayjs(dayInfo.to, 'HH:mm').toDate() : null}
						disabled={dayInfo.isClosed || disabled}
						onChange={(date: Date) => (date ? handleTimeChanged(date, dayInfo, index, 'to') : null)}
						showTimeSelect
						showTimeSelectOnly
						timeIntervals={60}
						timeCaption=""
						dateFormat="HH:mm"
						timeFormat="HH:mm"
					/>
				</TimeWrapper>
			</Row>
		);
	};

	return (
		<Wrap>
			{title && <Title>{title}</Title>}
			{state.map((dayInfo, index) => renderDayRow(dayInfo, index))}
			<Error>{errorMessage}</Error>
		</Wrap>
	);
};
