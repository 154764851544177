import React, { useEffect, useState } from 'react';
import { ActionMeta, InputActionMeta, ValueType } from 'react-select';
import AsyncSelect from 'react-select/async';
// import { ActionMeta, InputActionMeta, ValueType } from 'react-select/lib/types';
import ClipLoader from 'react-spinners/ClipLoader';

import { GeocodeResult } from '../../api/geoApi';
import { Color } from '../../gfx/constants';
import { useStoreActions } from '../../services/store';
import { FieldOptionProps } from '../field/Field';
import { Border, Error, FieldWrapper, Label } from '../react-hook-form/HookFieldsStyle';

import { customReactSelectStyles, LoaderWrapper } from './GeocoderStyle';

export interface GeocoderProps {
	formattedAddress: string | null;
	handleSetFormattedAddress: (formattedAddress: string) => void;
	title?: string;
	placeholder?: string;
	tabIndex?: number;
	errorMessage?: string;
	disabled?: boolean;
}

export const Geocoder = (props: GeocoderProps) => {
	const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
	const [inputValue, setInputValue] = useState<string | undefined>(undefined);
	const [selected, setOption] = useState<FieldOptionProps | undefined>(undefined);

	const { handleSetFormattedAddress, title, placeholder, tabIndex, errorMessage, formattedAddress, disabled } = props;

	useEffect(() => {
		// set input value if provided
		if (inputValue == null && formattedAddress !== null) {
			setInputValue(formattedAddress);
		}
	}, [formattedAddress, inputValue]);

	const { getGeocodeResult } = useStoreActions((actions) => ({
		...actions.geo,
	}));

	const getFormattedOptions = (payload: GeocodeResult[]): FieldOptionProps[] => {
		const formattedList = payload.map((result) => {
			return { label: result.formattedAddress, value: result.formattedAddress };
		});

		setTimer(null);

		return formattedList;
	};

	const requestGeocoding = async (inputValue: string, callback: any) => {
		const geocodeResult = await getGeocodeResult({ address: inputValue });

		if (geocodeResult.error || !geocodeResult.payload) {
			return [];
		}

		// options callback to display selectable dropdown
		callback(getFormattedOptions(geocodeResult.payload));
	};

	const loadOptions = (inputValue: string, callback: any) => {
		// clear any timers when user is still writing
		if (timer) {
			clearTimeout(timer);
		}

		// wait to user stop writing, then request geocoding
		setTimer(setTimeout(() => requestGeocoding(inputValue, callback), 800));
	};

	// let parent know about selected value
	const handleOptionSelected = (option: ValueType<FieldOptionProps>, actionMeta: ActionMeta<any>) => {
		if (actionMeta.action === 'select-option' && option && 'value' in option && typeof option.value === 'string') {
			handleSetFormattedAddress(option.value);
			setOption(option);
			setInputValue('');
		}
	};

	const handleOnInputChange = (newValue: string, actionMeta: InputActionMeta) => {
		if (actionMeta.action === 'input-change') {
			setInputValue(newValue);
		}
	};

	return (
		<FieldWrapper>
			<Label>{title}</Label>
			<Border>
				<AsyncSelect
					loadOptions={loadOptions}
					cacheOptions
					onChange={handleOptionSelected}
					tabIndex={tabIndex ? tabIndex.toString() : '0'}
					placeholder={placeholder}
					inputValue={inputValue || ''}
					value={selected}
					onInputChange={handleOnInputChange}
					isDisabled={disabled}
					styles={customReactSelectStyles}
				/>
				{timer && (
					<LoaderWrapper>
						<ClipLoader color={Color.GREEN_3} sizeUnit={'px'} size={19} />
					</LoaderWrapper>
				)}
			</Border>
			<Error>{errorMessage}</Error>
		</FieldWrapper>
	);
};
