import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { withRouter } from 'react-router';
import Button, { ButtonTypes } from '../../../../components/button/Button';
import PreviousLink from '../../../../components/previous-link/PreviousLink';
import ReactHookForm, {
	HookFormColumn,
	HookFormSection,
} from '../../../../components/react-hook-form/ReactHookFormStyle';
import View from '../../../../components/view/View';
import { Currency, RoutesUrls } from '../../../../constants';
import { Color } from '../../../../gfx/constants';
import { JustifiedRightFlex, H1, H3, HorizontalLine } from '../../../../gfx/globals';
import { RouteProps } from '../../../../typings';
import { InformationSection } from '../merchant-tools/vatm/detail/VatmDetailStyle';
import * as Yup from 'yup';
import HookBaseField from '../../../../components/react-hook-form/HookBaseField';
import HookTextAreaField from '../../../../components/react-hook-form/HookTextAreaField';
import HookSelectField from '../../../../components/react-hook-form/hook-select-field/HookSelectField';
import HookAddonSelectField from '../../../../components/react-hook-form/hook-addon-fields/HookAddonSelectField';
import { useStoreActions, useStoreState } from '../../../../services/store';
import Loader from '../../../../components/loader/Loader';
import { toast } from 'react-toastify';
import { getWalletOptions } from '../../../../services/get-wallet-options';
import { FieldOptionProps } from '../../../../components/field/Field';
import PanelNew, { PanelSectionType } from '../../../../components/panel-new/PanelNew';
import {
	NumberFieldWrapper,
	EscrowTotalWrapper,
	TotalItem,
	DealTotalText,
	CoinItem,
	CurrencyItem,
	CurrencyPairWrapper,
} from './EscrowNewDealViewStyle';
import { yupResolver } from '@hookform/resolvers/yup';
import { getBaseCurrencyOptions } from '../../../../services/get-base-currency-options';
import getCurrencyPairInfo from '../../../../services/getEscrowCurrencyPairTotal';
import { startCase, toLower } from 'lodash';
import { getServerValidationErrors } from '../../../../services/input-error-utils';
import Notification from '../../../../components/notification/Notification';
import { BusinessTierLimitsResponse } from '../../../../api/authApi';
import { LimitNotificationText } from '../pos-checkout/PosCheckoutViewStyle';
import { Rates } from '../../../../api/ratesApi';

export interface EscrowNewDealFields {
	fullName: string;
	email: string;
	product: string;
	productNotes?: string | null;
	shippingInfo: string;
	estimatedDealDuration: number;
	amountToPay: number | null;
	sellerWalletId: FieldOptionProps | null;
	currency: FieldOptionProps;
}

const schema = Yup.object<EscrowNewDealFields>().shape({
	fullName: Yup.string()
		.required(`Buyer's full name is required`)
		.max(100)
		.trim('Full name cannot be left empty or filled with spaces'),
	email: Yup.string()
		.email('Invalid email')
		.max(255, 'The maximum length is 255 characters')
		.required('Email is required'),
	product: Yup.string()
		.required('Product name is required')
		.trim('Field cannot be left empty or filled with spaces')
		.max(100, 'Product name should not be longer than 100 characters'),
	productNotes: Yup.string().notRequired().trim('Field cannot be left empty or filled with spaces'),
	shippingInfo: Yup.string()
		.required('Shipping info is required')
		.trim('Field cannot be left empty or filled with spaces')
		.strict(true),
	estimatedDealDuration: Yup.number()
		.required('Estimated deal duration is required')
		.typeError('Must be positive full number')
		.positive('Estimated deal duration cannot be negative')
		.integer('Must be a full number'),
	amountToPay: Yup.number()
		.typeError('Must be a number with a dot as a decimal separator')
		.min(0.000001, 'Minimum amount of 0.000001 is required')
		.max(10000000, 'Maximum amount of 10000000 is allowed')
		.test('maxDigitsAfterDecimal', 'Maximum 6 decimal places', (number) => Number.isInteger(number * 10 ** 6))
		.required('Amount is required'),
	sellerWalletId: Yup.object<FieldOptionProps>().typeError('Please choose the wallet'),
	currency: Yup.mixed(),
});

const EscrowNewDealView = (props: RouteProps) => {
	const [isActionLoading, setIsActionLoading] = useState(false);
	const [walletOptions, setWalletOptions] = useState<FieldOptionProps[] | null>(null);
	const [viewerLimits, setViewerLimits] = useState<BusinessTierLimitsResponse | null>(null);

	const { wallets, activeViewer, rates } = useStoreState((state) => ({
		activeViewer: state.viewer.activeViewer,
		wallets: state.wallet.wallets,
		rates: state.rates.rates,
	}));

	const defaultValues: EscrowNewDealFields = {
		fullName: '',
		email: '',
		product: '',
		shippingInfo: '',
		sellerWalletId: wallets ? getWalletOptions(wallets).filter((w) => w.isDefault)[0] : null,
		estimatedDealDuration: 7,
		amountToPay: null,
		currency: {
			label: activeViewer ? activeViewer.baseCurrency : 'DAG',
			value: activeViewer ? activeViewer.baseCurrency : 'DAG',
		},
	};

	const methods = useForm<EscrowNewDealFields>({
		defaultValues: defaultValues,
		resolver: yupResolver(schema),
		mode: 'onSubmit',
	});

	const { getViewer, getWallets, createEscrow, getRates, getViewerBusinessLimits } = useStoreActions((actions) => ({
		...actions.escrow,
		...actions.viewer,
		...actions.wallet,
		...actions.rates,
	}));

	useEffect(() => {
		const fetchWallets = async () => {
			await getWallets({});
		};
		if (!wallets) {
			fetchWallets();
		}

		if (wallets) {
			setWalletOptions(getWalletOptions(wallets));
		}
	}, [wallets, getWallets]);

	useEffect(() => {
		const fetchActiveViewer = async () => {
			await getViewer();
		};

		if (!activeViewer) {
			fetchActiveViewer();
		}
	}, [activeViewer, getViewer, getViewerBusinessLimits]);

	useEffect(() => {
		const fetchRates = async () => {
			await getRates();
		};

		if (!rates) {
			fetchRates();
		}
	}, [rates, getRates]);

	useEffect(() => {
		const fetchViewerLimits = async () => {
			setViewerLimits((await getViewerBusinessLimits()).payload);
		};
		if (!viewerLimits) {
			fetchViewerLimits();
		}
	}, [getViewerBusinessLimits, viewerLimits]);

	const watchAmountToPay = methods.watch().amountToPay;
	useEffect(() => {}, [watchAmountToPay]);

	const handleOnSubmit = async (input: EscrowNewDealFields) => {
		setIsActionLoading(true);
		const { sellerWalletId, productNotes } = input;

		const result = await createEscrow({
			product: input.product,
			productNotes: productNotes && productNotes.length > 0 ? productNotes : null,
			estimatedDealDurationDays: input.estimatedDealDuration,
			buyerEmail: input.email,
			buyerFullName: startCase(toLower(input.fullName)),
			shippingInfo: input.shippingInfo,
			price: input.amountToPay,
			currency: Currency[input.currency.value as keyof typeof Currency],
			sellerWalletId: sellerWalletId
				? sellerWalletId.value.toString()
				: (walletOptions && walletOptions.filter((w) => w.isDefault)[0].value.toString()) || '',
		});
		setIsActionLoading(false);

		if (!result || result.error) {
			const serverValidationErrors = getServerValidationErrors(result);
			for (var key in serverValidationErrors.errors) {
				methods.setError(key, { type: 'validate', message: serverValidationErrors.errors[key] });
			}
			toast.error('Escrow creation failed');
			return;
		}

		toast.success(`Escrow created successfully.`);
		props.history.push(RoutesUrls.ESCROW_DEALS);
	};

	if (!activeViewer || !wallets || !walletOptions || !rates || !viewerLimits) {
		return <Loader />;
	}

	const currencyField = methods.watch().currency;
	const currencyPairTotal = getCurrencyPairInfo({
		currency:
			currencyField && typeof currencyField.value === 'string' ? (currencyField.value as Currency) : Currency.DAG,
		currencyAmount: methods.getValues().amountToPay,
		rates,
		activeViewerBaseCurrency: activeViewer.baseCurrency,
	});

	return (
		<View>
			<H1>Create new escrow deal</H1>
			<PanelNew>
				<PanelNew.Section first white panelType={PanelSectionType.FORM}>
					<FormProvider {...methods}>
						<ReactHookForm onSubmit={methods.handleSubmit(handleOnSubmit)} autoComplete="off">
							<H3>Buyer details</H3>
							<HorizontalLine />
							<InformationSection>
								<HookFormColumn>
									<HookBaseField label="Full name" name="fullName" placeholder="Buyer name" tabIndex={1} />
								</HookFormColumn>
								<HookFormColumn>
									<HookBaseField label="Email" name="email" placeholder="Buyer email" tabIndex={2} />
								</HookFormColumn>
							</InformationSection>
							<H3>Escrow deal details</H3>
							<HorizontalLine />
							<InformationSection>
								<HookFormColumn>
									<HookTextAreaField label="Product" name="product" rows={3} tabIndex={3} />
								</HookFormColumn>
								<HookFormColumn>
									<HookTextAreaField label="Product notes" name="productNotes" rows={3} tabIndex={4} optionalPrivate />
								</HookFormColumn>
							</InformationSection>
							<InformationSection>
								<HookFormColumn>
									<HookBaseField label="Shipping info" name="shippingInfo" tabIndex={5} />
									<NumberFieldWrapper>
										<HookBaseField
											label="Amount to pay"
											name="amountToPay"
											placeholder="0"
											addonNode={
												<HookAddonSelectField
													name="currency"
													options={getBaseCurrencyOptions({ current: activeViewer.baseCurrency })}
													tabIndex={6}
													selectedColor={Color.GRAY_2}
													focusedColor={Color.GRAY_0}
													isSearchable
												/>
											}
											addonSize={90}
											tabIndex={7}
										/>

										<HookBaseField
											label="Estimated deal duration"
											name="estimatedDealDuration"
											addonNode={'Days'}
											addonSize={64}
											tabIndex={8}
											tooltipContent={
												<span>
													How long do you estimate the
													<br /> deal to take place (including
													<br /> shipping, etc.) once the buyer
													<br /> has accepted the deal.
												</span>
											}
										/>
									</NumberFieldWrapper>
									{viewerLimits && viewerLimits.volumeLimit.dag !== null && (
										<Notification>
											Remaining monthly volume until limit{' '}
											<LimitNotificationText
												error={
													viewerLimits.volumeLimit.dag -
														viewerLimits.monthlyTotalVolume.dag -
														currencyPairTotal.totalCoinAmount <
													0
												}
											>
												{(
													(viewerLimits.volumeLimit.dag -
														viewerLimits.monthlyTotalVolume.dag -
														currencyPairTotal.totalCoinAmount) *
													rates[currencyField.value as keyof Rates]
												).toFixed(2)}{' '}
												<strong>{currencyField.label}</strong>
											</LimitNotificationText>
										</Notification>
									)}
								</HookFormColumn>
								<HookFormColumn>
									<HookSelectField
										label="Wallet"
										name="sellerWalletId"
										options={walletOptions}
										tabIndex={6}
										selectedColor={Color.GRAY_2}
										focusedColor={Color.GRAY_0}
										isSearchable
									/>
								</HookFormColumn>
							</InformationSection>
							<HookFormSection>
								<JustifiedRightFlex>
									<View.Error customLinkColor={Color.RED_ERROR}>{/* {errorMessage} */}</View.Error>
								</JustifiedRightFlex>
							</HookFormSection>
						</ReactHookForm>
					</FormProvider>
				</PanelNew.Section>
				<PanelNew.Section middle gray separateWithBorder>
					<EscrowTotalWrapper>
						Escrow Deal Total
						<TotalItem>
							<DealTotalText>Total</DealTotalText>
							<CurrencyPairWrapper>
								<CoinItem>{currencyPairTotal.totalCoinAmount} DAG</CoinItem>
								<CurrencyItem>
									{currencyPairTotal.totalCurrencyAmount} {currencyPairTotal.secondaryCurrency}
								</CurrencyItem>
							</CurrencyPairWrapper>
						</TotalItem>
						<TotalItem>
							<DealTotalText>
								Transaction cost <br />
								(included)
							</DealTotalText>
							<CoinItem>{currencyPairTotal.fee} DAG</CoinItem>
						</TotalItem>
					</EscrowTotalWrapper>
				</PanelNew.Section>
				<PanelNew.Section last gray panelType={PanelSectionType.BUTTON}>
					<PreviousLink title="Back" to={RoutesUrls.ESCROW_DEALS} />
					<PanelNew.ActionButtonsWrapper>
						<Button.Secondary
							green
							disabled={isActionLoading || Object.keys(methods.formState.errors).length > 0}
							type={ButtonTypes.BUTTON}
							tabIndex={9}
							onClick={methods.handleSubmit(handleOnSubmit)}
						>
							{!isActionLoading ? 'Create escrow deal' : <Loader size={20} color={Color.WHITE} width={169} />}
						</Button.Secondary>
					</PanelNew.ActionButtonsWrapper>
				</PanelNew.Section>
			</PanelNew>
		</View>
	);
};

export default withRouter(EscrowNewDealView);
