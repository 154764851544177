import { FormikFieldState } from 'formik-fields';
import * as React from 'react';
import Phone from 'react-phone-number-input';
import styled from 'styled-components/macro';

interface Props {
	formikField: FormikFieldState<string>;
	disableError?: boolean;
	tabIndex?: number;
	placeholder: string;
}

const StyledPhone = styled(Phone)`
	width: 100%;
`;

export default function PhoneInput(props: Props) {
	const { formikField, tabIndex, placeholder } = props;

	const blurSelectInput = (_e: React.FocusEvent) => {
		if (props.formikField) {
			props.formikField.handleBlur();
		}
	};

	const changeSelectInput = (value: string) => {
		if (props.formikField) {
			props.formikField.setValue(value);
		}
	};

	return (
		<StyledPhone
			name={formikField.name}
			error={formikField.isTouched && formikField.error ? 'dummy' : ''}
			onChange={changeSelectInput}
			onBlur={blurSelectInput}
			tabIndex={tabIndex}
			indicateInvalid
			value={formikField.value}
			placeholder={placeholder}
		/>
	);
}
