import { Action, action, thunk, Thunk, thunkOn, ThunkOn } from 'easy-peasy';

import {
	Escrow,
	getEscrowApi,
	GetEscrowRequest,
	getEscrowsApi,
	GetEscrowsRequest,
	CreateEscrowRequest,
	createEscrowApi,
	DeclineEscrowRequest,
	AcceptEscrowRequest,
	declineEscrowApi,
	acceptEscrowApi,
	MarkAsReceivedEscrowRequest,
	MarkAsShippedEscrowRequest,
	markAsReceivedEscrowApi,
	markAsShippedEscrowApi,
	OpenDisputeEscrowRequest,
	ResolveDisputeEscrowRequest,
	OpenDisputeEscrowApi,
	ResolveDisputeEscrowApi,
	CancelEscrowRequest,
	cancelEscrowApi,
} from '../api/escrowApi';
import handleFetch from '../services/handle-fetch';
import { ApiArrayPayload, Response, ResponseArray } from '../typings';

import { StoreModel } from '.';

export interface EscrowModel {
	// state
	escrow: Escrow | null;
	escrows: ApiArrayPayload<Escrow> | null;

	// actions
	clearEscrow: Action<EscrowModel>;
	clearEscrows: Action<EscrowModel>;

	saveEscrow: Action<EscrowModel, Escrow | null>;
	saveEscrows: Action<EscrowModel, ApiArrayPayload<Escrow> | null>;

	// thunks
	declineEscrow: Thunk<EscrowModel, DeclineEscrowRequest, {}, StoreModel, Response<Escrow>>;
	cancelEscrow: Thunk<EscrowModel, CancelEscrowRequest, {}, StoreModel, Response<Escrow>>;
	acceptEscrow: Thunk<EscrowModel, AcceptEscrowRequest, {}, StoreModel, Response<Escrow>>;

	markEscrowAsReceived: Thunk<EscrowModel, MarkAsReceivedEscrowRequest, {}, StoreModel, Response<Escrow>>;
	markEscrowAsShipped: Thunk<EscrowModel, MarkAsShippedEscrowRequest, {}, StoreModel, Response<Escrow>>;

	openDisputeEscrow: Thunk<EscrowModel, OpenDisputeEscrowRequest, {}, StoreModel, Response<Escrow>>;
	resolveDisputeEscrow: Thunk<EscrowModel, ResolveDisputeEscrowRequest, {}, StoreModel, Response<Escrow>>;

	getEscrows: Thunk<EscrowModel, GetEscrowsRequest, {}, StoreModel, ResponseArray<Escrow>>;
	getEscrow: Thunk<EscrowModel, GetEscrowRequest, {}, StoreModel, Response<Escrow>>;

	createEscrow: Thunk<EscrowModel, CreateEscrowRequest, {}, StoreModel, Response<Escrow>>;

	// listeners
	listeners: ThunkOn<EscrowModel, {}, StoreModel>;
}

const escrow: EscrowModel = {
	// state
	escrow: null,
	escrows: null,

	// actions
	clearEscrow: action((state) => {
		state.escrow = null;
	}),

	clearEscrows: action((state) => {
		state.escrows = null;
	}),

	saveEscrows: action((state, payload) => {
		state.escrows = payload;
	}),

	saveEscrow: action((state, payload) => {
		state.escrow = payload;
	}),

	// thunks
	createEscrow: thunk(async (_actions, payload) => {
		return await handleFetch<Escrow>(createEscrowApi(payload));
	}),

	declineEscrow: thunk(async (_actions, payload) => {
		return await handleFetch<Escrow>(declineEscrowApi(payload));
	}),

	cancelEscrow: thunk(async (_actions, payload) => {
		return await handleFetch<Escrow>(cancelEscrowApi(payload));
	}),

	acceptEscrow: thunk(async (_actions, payload) => {
		return await handleFetch<Escrow>(acceptEscrowApi(payload));
	}),

	markEscrowAsReceived: thunk(async (_actions, payload) => {
		return await handleFetch<Escrow>(markAsReceivedEscrowApi(payload));
	}),

	openDisputeEscrow: thunk(async (_actions, payload) => {
		return await handleFetch<Escrow>(OpenDisputeEscrowApi(payload));
	}),

	resolveDisputeEscrow: thunk(async (_actions, payload) => {
		return await handleFetch<Escrow>(ResolveDisputeEscrowApi(payload));
	}),

	markEscrowAsShipped: thunk(async (_actions, payload) => {
		return await handleFetch<Escrow>(markAsShippedEscrowApi(payload));
	}),

	getEscrows: thunk(async (actions, payload) => {
		actions.clearEscrows();

		const result = await handleFetch<ApiArrayPayload<Escrow>>(getEscrowsApi(payload));

		actions.saveEscrows(result.payload);

		return result;
	}),
	getEscrow: thunk(async (actions, payload) => {
		actions.clearEscrow();
		const result = await handleFetch<Escrow>(getEscrowApi(payload));

		actions.saveEscrow(result.payload);

		return result;
	}),

	// listeners
	listeners: thunkOn(
		(actions, _storeActions) => [
			actions.acceptEscrow,
			actions.declineEscrow,
			actions.markEscrowAsShipped,
			actions.markEscrowAsReceived,
		],
		(actions, target) => {
			actions.getEscrow({ escrowId: target.payload.escrowId });
		},
	),
};

export default escrow;
