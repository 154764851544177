import axios from 'restyped-axios';
import { ApiResponse } from '../typings';
export const api = axios.create<RatesApi>();

export interface RatesApi {
	'/rates': {
		GET: {
			response: ApiResponse<Rates>;
		};
	};
}

export interface Rates {
	DAG: number;
	BTC: number;
	USD: number;
	EUR: number;
}

export const getRatesApi = async () => {
	const res = await api.get('/rates');

	return res.data;
};
