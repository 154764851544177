import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { withRouter } from 'react-router';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { CustomerLimit } from '../../../../api/customerLimitApi';
import Button, { ButtonTypes } from '../../../../components/button/Button';
import Modal from '../../../../components/modal/Modal';
import HookBaseField from '../../../../components/react-hook-form/HookBaseField';
import ReactHookForm from '../../../../components/react-hook-form/ReactHookFormStyle';
import View from '../../../../components/view/View';
import { Validations } from '../../../../constants';
import { H1 } from '../../../../gfx/globals';
import { getServerValidationErrors } from '../../../../services/input-error-utils';
import { useStoreActions } from '../../../../services/store';
import { RouteProps } from '../../../../typings';

interface RouteParams {
	id?: string;
}

export interface CustomerLimitFields {
	name: string;
	walletAddress: string;
	dailyLimit: string;
	monthlyLimit: string;
}

export interface CustomerLimitDetailViewProps {
	handleCloseModal: () => void;
	customerLimit: CustomerLimit | null;
}

type Props = CustomerLimitDetailViewProps & RouteProps<RouteParams>;

function CustomerLimitDetailView(props: Props) {
	const [errorMessage, setErrorMessage] = useState('');
	const [isLoading, setIsLoading] = useState(false);

	const { customerLimit, handleCloseModal } = props;
	const isUpdateModal = customerLimit !== null;

	const { createCustomerLimit, updateCustomerLimit } = useStoreActions((actions) => ({
		...actions.customerLimit,
	}));

	const schema = Yup.object().shape({
		name: Yup.string()
			.required('Name is required')
			.trim('Field cannot be left empty or filled with spaces')
			.strict(true)
			.max(100, 'Name of limit should not be longer than 100 characters'),
		walletAddress: Validations.INVALID_WALLET_ADDRESS,
		// TODO: validation against e power x value
		dailyLimit: Yup.string().notRequired(),
		monthlyLimit: Yup.string().notRequired(),
	});

	const defaultValues = {
		name: customerLimit?.name || '',
		walletAddress: customerLimit?.walletAddress || '',
		dailyLimit: customerLimit?.dailyLimit?.toString() || '',
		monthlyLimit: customerLimit?.monthlyLimit?.toString() || '',
	};

	const methods = useForm<CustomerLimitFields>({
		resolver: yupResolver(schema),
		defaultValues: defaultValues,
		shouldFocusError: false,
	});

	const handleSaveCustomerLimit = async (input: CustomerLimitFields) => {
		const dailyLimit = parseFloat(input.dailyLimit);
		const monthlyLimit = parseFloat(input.monthlyLimit);
		const dailyIsNan = isNaN(dailyLimit);
		const monthlyIsNan = isNaN(monthlyLimit);

		// at least one limit must be provided
		if (dailyIsNan && monthlyIsNan) {
			setErrorMessage('At least one of the limits should be provided');
			return;
		}

		// create one if not provided
		if (!customerLimit) {
			setIsLoading(true);
			const response = await createCustomerLimit({
				name: input.name,
				walletAddressList: [input.walletAddress],
				dailyLimit: dailyIsNan ? undefined : dailyLimit,
				monthlyLimit: monthlyIsNan ? undefined : monthlyLimit,
			});
			setIsLoading(false);

			if (response.success) {
				handleCloseModal();
				toast.success('Customer wallet limit created');

				return;
			}

			const serverValidationErrors = getServerValidationErrors<CustomerLimit[]>(response);

			if (serverValidationErrors.errors && serverValidationErrors.errors.walletAddress) {
				methods.setError('walletAddress', {
					message: 'Limit to provided wallet address has been already created',
					type: 'manual',
				});

				return;
			}

			return setErrorMessage('Something went wrong, please try again');
		}

		// update existing
		const response = await updateCustomerLimit({
			...input,
			dailyLimit: dailyIsNan ? undefined : dailyLimit,
			monthlyLimit: monthlyIsNan ? undefined : monthlyLimit,
			idList: [customerLimit.id],
		});

		if (response.success) {
			handleCloseModal();
			toast.success(`Customer wallet limit successfully ${isUpdateModal ? 'updated' : 'created'}.`);

			return;
		}

		const serverValidationErrors = getServerValidationErrors<CustomerLimit[]>(response);

		if (serverValidationErrors.errors) {
			setErrorMessage(serverValidationErrors.errorMessage);

			return;
		}

		return setErrorMessage('Something went wrong, please try again');
	};

	return (
		<Modal
			hasCloseButton
			shouldCloseOnOverlayClick={true}
			ariaHideApp={false}
			isOpen={true}
			onRequestClose={handleCloseModal}
		>
			<FormProvider {...methods}>
				<ReactHookForm onSubmit={methods.handleSubmit(handleSaveCustomerLimit)}>
					<H1 center>{`${isUpdateModal ? 'Update' : 'Create'} customer wallet limit`}</H1>
					<HookBaseField label="Limit name" name="name" placeholder="Limit name" />
					<HookBaseField label="Customers DagWallet address" name="walletAddress" placeholder="Wallet address" />
					<HookBaseField label="Daily limit" name="dailyLimit" placeholder="Limit in DAG" type="number" />
					<HookBaseField label="Monthly limit" name="monthlyLimit" placeholder="Limit in DAG" type="number" />
					{errorMessage.length > 0 && <View.Error center>{errorMessage}</View.Error>}
					<Modal.Buttons>
						<Button.Secondary white onClick={handleCloseModal}>
							Cancel
						</Button.Secondary>
						<Button.Secondary type={ButtonTypes.SUBMIT} green isDisabled={isLoading} disabled={isLoading}>
							{`${isUpdateModal ? 'Update' : 'Create'} limit`}
						</Button.Secondary>
					</Modal.Buttons>
				</ReactHookForm>
			</FormProvider>
		</Modal>
	);
}

export default withRouter(CustomerLimitDetailView);
