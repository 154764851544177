import { Action, action, thunk, Thunk } from 'easy-peasy';

import { GeocodeResult, getGeocodeResultApi, GetGeocodeResultRequest } from '../api/geoApi';
import handleFetch from '../services/handle-fetch';
import { Response } from '../typings';

import { StoreModel } from '.';

export interface GeoModel {
	// state
	geoCodeResult: GeocodeResult[] | null;

	// actions
	saveGeoCodeResult: Action<GeoModel, GeocodeResult[] | null>;
	clearGeoCodeResult: Action<GeoModel>;

	// thunks
	getGeocodeResult: Thunk<GeoModel, GetGeocodeResultRequest, {}, StoreModel, Response<GeocodeResult[]>>;
}

const geo: GeoModel = {
	// state
	geoCodeResult: null,

	// actions
	saveGeoCodeResult: action((state, payload) => {
		state.geoCodeResult = payload;
	}),
	clearGeoCodeResult: action((state) => {
		state.geoCodeResult = null;
	}),

	// thunks
	getGeocodeResult: thunk(async (actions, payload) => {
		actions.clearGeoCodeResult();
		const result = await handleFetch<GeocodeResult[]>(getGeocodeResultApi(payload));
		actions.saveGeoCodeResult(result.payload);

		return result;
	}),
};

export default geo;
