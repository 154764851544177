import TypedAxios from 'restyped-axios';
import { ApiResponse } from '../typings';

export const api = TypedAxios.create<DagCardApi>();

export interface DagCardApi {
	'/dagcard/init': {
		POST: {
			body: DagcardInitPaymentRequest;
			response: ApiResponse<DagcardInitPaymentResponse>;
		};
	};
	'/dagcard/pin-confirm': {
		PUT: {
			body: ConfirmPaymentRequest;
			response: ApiResponse<ConfirmPaymentResponse>;
		};
	};
}

export interface DagcardInitPaymentRequest {
	cardNo: string;
	invoiceId: string;
	invoiceDagTotal: number;
	txType: DagcardTxType;
}

export enum DagcardTxType {
	ONLINE = 'ONLINE',
	STORE = 'STORE',
}

export interface DagcardInitPaymentResponse {
	nonce: string;
}

export interface ConfirmPaymentRequest {
	cardNo: string;
	invoiceId: string;
	signedNonce: string;
}

export interface ConfirmPaymentResponse {
	message: string;
}

export const dagCardInitPaymentApi = async (params: DagcardInitPaymentRequest) => {
	const response = await api.post('/dagcard/init', {
		...params,
	});

	return response.data;
};

export const dagCardPinConfirmPaymentApi = async (params: ConfirmPaymentRequest) => {
	const response = await api.put('/dagcard/pin-confirm', {
		...params,
	});

	return response.data;
};
