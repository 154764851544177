import styled from 'styled-components/macro';

export const ContentWrap = styled.div`
	margin: auto;
`;

export const StatusSvgWrap = styled.div`
	display: flex;
	justify-content: center;
	margin-bottom: 33px;
`;

export const LoaderWrap = styled.div`
	display: flex;
	height: 100%;
	width: 100%;
	justify-content: center;
	align-items: center;
`;
