import { createStore, createTypedHooks, EasyPeasyConfig } from 'easy-peasy';

import model, { StoreModel } from '../models';

const { useStoreActions, useStoreState } = createTypedHooks<StoreModel>();

export { useStoreActions, useStoreState };

const store = createStore<StoreModel, EasyPeasyConfig>(model);

export default store;
