import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

import { Color, Gutter } from '../../gfx/constants';
import { H2 } from '../../gfx/globals';
import { media } from '../../services/media';

export interface PanelProps {
	size?: number;
}

export const StyledPanel = styled.div`
	width: 100%;
	margin: 1% 0% 1% 0%;
	padding: 50px 15px;
	border: 1px solid rgba(220, 221, 224, 0.5);
	border-radius: 4px;
	background-color: #fff;
	box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12);

	${media.fromTablet`
		padding: 50px ${Gutter.SMALL};
	`}

	${media.fromDesktop`
		padding: 50px;
	`}
`;

const Icon = styled.div<PanelProps>`
	margin-right: 20px;

	${media.maxDesktop`
	padding: 0;
`}

	${media.fromTablet`
	margin: 0 ${Gutter.LARGE} 0 calc(${Gutter.LARGE} / 2);
`}

svg {
		width: ${({ size }) => (size ? `${size}px` : '42px')};
		height: ${({ size }) => (size ? `${size}px` : '42px')};
		fill: ${Color.GREEN_3};
	}

	> div div {
		display: flex;
	}
`;

const InfoPanel = styled.div`
	width: 100%;
	margin: 1% 0% 1% 0%;
	border: none;
	border-radius: 4px;
	background-color: #fff;
	box-shadow: 0 2px 6px 0 rgb(224, 224, 224);
	display: table;
	position: relative;
	padding: 15px 22px;
	height: 0; /* FF fix to help calculate table height based on min-height alone  */
	min-height: 114px;

	${Icon} {
		display: table-cell;
		vertical-align: middle;
	}

	${H2} {
		margin: 0 0 10px;
	}
`;

const InfoWrapper = styled.div`
	display: table-cell;
	position: relative;
	vertical-align: middle;

	${media.fromDesktop`
		padding-left: ${Gutter.SMALL};
	`}
`;

interface Props {
	infoPanel?: boolean;
	children: React.ReactNode;
}

const Panel = (props: Props) => {
	const { infoPanel, children } = props;

	return infoPanel ? (
		<InfoPanel>
			<InfoWrapper>{children}</InfoWrapper>
		</InfoPanel>
	) : (
		<StyledPanel>{children}</StyledPanel>
	);
};

Panel.LinkWrapper = styled(Link)`
	width: 100%;
`;

Panel.AnchorWrapper = styled.a`
	width: 100%;
`;

Panel.Text = styled.p`
	margin: 0;
	color: ${Color.GRAY};
	font-family: Secondary;
	font-size: 15px;
	line-height: 20px;
`;

Panel.SectionWrapper = styled.div`
	padding-left: ${Gutter.SMALL};
	display: table-cell;
	vertical-align: middle;

	${media.fromDesktop`
	padding-left: ${Gutter.LARGE};
`}
`;

Panel.TitleWrap = styled.div`
	display: flex;
`;

Panel.Tag = styled.span`
	margin-left: 10px;
	top: 0;
	padding: 5px 15px;
	background: #fde9ce;
	border-radius: 18px;
	color: ${Color.ORANGE};
	font-size: 12px;
	font-family: PrimaryMedium;
`;

Panel.TagWrap = styled.span`
	margin-bottom: 10px;
`;

Panel.Icon = Icon;

export default Panel;
