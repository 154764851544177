import { Action, action, thunkOn, ThunkOn, Thunk, thunk } from 'easy-peasy';

import {
	createCustomerLimitApi,
	CreateCustomerLimitRequest,
	CustomerLimit,
	CustomerLimitBalance,
	deleteCustomerLimitApi,
	getCustomerLimiBalancetApi,
	getCustomerLimitApi,
	GetCustomerLimitBalanceRequest,
	GetCustomerLimitRequest,
	getCustomerLimitsApi,
	updateCustomerLimitApi,
	UpdateCustomerLimitRequest,
} from '../api/customerLimitApi';
import handleFetch from '../services/handle-fetch';
import { ApiArrayPayload, ApiGetRequest, Response, ResponseArray } from '../typings';

import { StoreModel } from '.';

export interface CustomerLimitModel {
	// state
	limit: CustomerLimit | null;
	customerLimitList: ApiArrayPayload<CustomerLimit> | null;

	// actions
	saveCustomerLimitList: Action<CustomerLimitModel, ApiArrayPayload<CustomerLimit> | null>;
	clearCustomerLimitList: Action<CustomerLimitModel>;
	saveLimit: Action<CustomerLimitModel, CustomerLimit | null>;
	clearLimit: Action<CustomerLimitModel>;

	// thunks
	getCustomerLimitList: Thunk<CustomerLimitModel, ApiGetRequest, {}, StoreModel, ResponseArray<CustomerLimit>>;
	getCustomerLimit: Thunk<CustomerLimitModel, GetCustomerLimitRequest, {}, StoreModel, Response<CustomerLimit>>;
	getCustomerLimitBalance: Thunk<
		CustomerLimitModel,
		GetCustomerLimitBalanceRequest,
		{},
		StoreModel,
		Response<CustomerLimitBalance>
	>;
	createCustomerLimit: Thunk<CustomerLimitModel, CreateCustomerLimitRequest, {}, StoreModel, Response<CustomerLimit[]>>;
	updateCustomerLimit: Thunk<CustomerLimitModel, UpdateCustomerLimitRequest, {}, StoreModel, Response<CustomerLimit[]>>;
	deleteCustomerLimit: Thunk<CustomerLimitModel, GetCustomerLimitRequest, {}, StoreModel, Response<CustomerLimit>>;

	// listeners
	listeners: ThunkOn<CustomerLimitModel, {}, StoreModel>;
}

const customerLimit: CustomerLimitModel = {
	// state
	limit: null,
	customerLimitList: null,

	// actions
	saveCustomerLimitList: action((state, payload) => {
		state.customerLimitList = payload;
	}),
	clearCustomerLimitList: action((state) => {
		state.customerLimitList = null;
	}),
	saveLimit: action((state, payload) => {
		state.limit = payload;
	}),
	clearLimit: action((state) => {
		state.limit = null;
	}),

	// thunks
	getCustomerLimitList: thunk(async (actions, payload) => {
		actions.clearCustomerLimitList();

		const result = await handleFetch<ApiArrayPayload<CustomerLimit>>(getCustomerLimitsApi(payload));

		actions.saveCustomerLimitList(result.payload);

		return result;
	}),
	getCustomerLimit: thunk(async (actions, payload) => {
		actions.clearLimit();

		const result = await handleFetch<CustomerLimit>(getCustomerLimitApi(payload));

		actions.saveLimit(result.payload);

		return result;
	}),
	getCustomerLimitBalance: thunk(async (actions, payload) => {
		return await handleFetch<CustomerLimitBalance>(getCustomerLimiBalancetApi(payload));
	}),
	createCustomerLimit: thunk(async (actions, payload) => {
		return await handleFetch<CustomerLimit[]>(createCustomerLimitApi(payload));
	}),
	updateCustomerLimit: thunk(async (actions, payload) => {
		return await handleFetch<CustomerLimit[]>(updateCustomerLimitApi(payload));
	}),
	deleteCustomerLimit: thunk(async (actions, payload) => {
		return await handleFetch<CustomerLimit>(deleteCustomerLimitApi(payload));
	}),

	// listeners
	listeners: thunkOn(
		(actions, storeActions) => [actions.createCustomerLimit, actions.updateCustomerLimit, actions.deleteCustomerLimit],
		(actions, payload) => {
			actions.getCustomerLimitList({});
		},
	),
};

export default customerLimit;
