import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { withRouter } from 'react-router';
import ReactSVG from 'react-svg';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { ResetPasswordResponse } from '../../../api/authApi';
import Loader from '../../../components/loader/Loader';
import Logo from '../../../components/logo/Logo';
import HookBaseField from '../../../components/react-hook-form/HookBaseField';
import ReactHookForm, { RowPlacement, TitleInfoPosition } from '../../../components/react-hook-form/ReactHookFormStyle';
import { Validations } from '../../../constants';
import { getServerValidationErrors } from '../../../services/input-error-utils';
import { useStoreActions } from '../../../services/store';
import { RouteProps } from '../../../typings';
import Login from '../login/LoginStyle';

const toastMessage =
	'If the entered email address is associated with an account, you will receive an email with instructions for resetting your password';

interface PasswordRecoveryFields {
	email: string;
}

function PasswordRecoveryView(props: RouteProps) {
	const [errorMessage, setErrorMessage] = useState('');
	const [isActionLoading, setIsActionLoading] = useState(false);

	const { resetPassword } = useStoreActions((actions) => ({
		...actions.viewer,
	}));

	const schema = Yup.object().shape({
		email: Validations.INVALID_EMAIL,
	});

	const defaultValues = {
		email: '',
	};

	const methods = useForm<PasswordRecoveryFields>({
		resolver: yupResolver(schema),
		defaultValues: defaultValues,
		shouldFocusError: false,
		mode: 'onChange',
	});

	const handlePasswordRecovery = async (input: PasswordRecoveryFields) => {
		await methods.trigger();

		setIsActionLoading(true);
		const response = await resetPassword({ ...input });
		setIsActionLoading(false);

		if (response.success) {
			props.history.push('/');
			toast.success(toastMessage);

			return;
		}

		const serverValidationErrors = getServerValidationErrors<ResetPasswordResponse>(response);

		if (serverValidationErrors.errors && serverValidationErrors.errors.email) {
			props.history.push('/');
			toast.success(toastMessage);

			return;
		}

		if (response.error) {
			setErrorMessage('Something went wrong, please try again');

			return;
		}
	};

	return (
		<Login>
			<Login.Left>
				<FormProvider {...methods}>
					<ReactHookForm onSubmit={methods.handleSubmit(handlePasswordRecovery)}>
						<Login.LogoWrapper>
							<Logo hasWhiteBackground />
						</Login.LogoWrapper>
						<ReactHookForm.Title infoPosition={TitleInfoPosition.BOTTOM}>
							Forgot Password?
							<ReactHookForm.Info>Enter email address used for registration</ReactHookForm.Info>
						</ReactHookForm.Title>
						<ReactHookForm.Fields direction="column">
							<HookBaseField name="email" label="Email" placeholder="Enter your E-mail address" />
						</ReactHookForm.Fields>
						<ReactHookForm.Row placement={RowPlacement.RIGHT}>
							{isActionLoading ? (
								<Loader />
							) : (
								<ReactHookForm.Button type="submit">
									Send
									<ReactSVG src="/files/svg/icons/HookButtonArrow.svg" />
								</ReactHookForm.Button>
							)}
						</ReactHookForm.Row>
						<ReactHookForm.Error>{errorMessage}</ReactHookForm.Error>
					</ReactHookForm>
				</FormProvider>
			</Login.Left>
			<Login.Right>
				<Login.Image />
				<Login.TextWrapper>
					<Login.Welcome>Recover password</Login.Welcome>
					<Login.Text>Don't worry, happens to the best of us.</Login.Text>
				</Login.TextWrapper>
			</Login.Right>
		</Login>
	);
}

export default withRouter(PasswordRecoveryView);
