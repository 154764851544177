import React, { useEffect } from 'react';

import { UserRole } from '../../constants';
import { useStoreActions, useStoreState } from '../../services/store';
import Loader from '../loader/Loader';

export default function ViewerDataProvider({ children }: { children: JSX.Element }) {
	const { verifications, wallets, activeViewer, parentUserVerifications } = useStoreState((state) => ({
		verifications: state.verification.verifications,
		parentUserVerifications: state.verification.parentUserVerifications,
		wallets: state.wallet.wallets,
		activeViewer: state.viewer.activeViewer,
	}));

	const { getVerifications, getWallets, getParentUserVerifications } = useStoreActions((actions) => ({
		...actions.verification,
		...actions.wallet,
	}));

	useEffect(() => {
		const isTeamMember =
			activeViewer?.role === UserRole.ADVANCED_TEAM_MEMBER || activeViewer?.role === UserRole.TEAM_MEMBER;

		async function fetchData() {
			// pending admin has limited permissions
			if (activeViewer && activeViewer.role !== UserRole.PENDING_ADMIN) {
				await getWallets({ itemsPerPage: 100 });
			}

			if (isTeamMember) {
				// Saves parent verification info to verifications and parentVerifications
				await getParentUserVerifications({ overrideVerificationsState: true });
				await getWallets({});

				return;
			}

			await Promise.all([getVerifications({}), getParentUserVerifications({})]);
		}
		fetchData();
	}, [activeViewer, getWallets, getVerifications, getParentUserVerifications]);

	if (!activeViewer) {
		return null;
	}

	if ((!parentUserVerifications && !verifications) || (activeViewer.role !== UserRole.PENDING_ADMIN && !wallets)) {
		return <Loader />;
	}

	return children;
}
