import axios from 'restyped-axios';
import { Currency } from '../constants';

import { ApiArrayPayload, ApiGetRequest, ApiResponse } from '../typings';

export const api = axios.create<EscrowApi>();

export interface EscrowApi {
	'/escrows': {
		GET: {
			query: GetEscrowsRequest;
			response: ApiResponse<ApiArrayPayload<Escrow>>;
		};
	};

	'/escrows/dagpay-create': {
		POST: {
			query: CreateEscrowRequest;
			response: ApiResponse<Escrow>;
		};
	};

	'/escrows/:escrowId': {
		GET: {
			query: ApiGetRequest;
			response: ApiResponse<Escrow>;
		};
	};

	'/escrows/decline': {
		POST: {
			body: DeclineEscrowRequest;
			response: ApiResponse<Escrow>;
		};
	};

	'/escrows/cancel': {
		POST: {
			body: CancelEscrowRequest;
			response: ApiResponse<Escrow>;
		};
	};

	'/escrows/dagpay-accept': {
		POST: {
			body: AcceptEscrowRequest;
			response: ApiResponse<Escrow>;
		};
	};

	'/escrows/get-invoice/:escrowId': {
		GET: {
			response: ApiResponse<Escrow>;
		};
	};

	'/escrows/mark-as-received': {
		POST: {
			body: MarkAsReceivedEscrowRequest;
			response: ApiResponse<Escrow>;
		};
	};

	'/escrows/mark-as-shipped': {
		POST: {
			body: MarkAsShippedEscrowRequest;
			response: ApiResponse<Escrow>;
		};
	};

	'/escrows/dispute/open': {
		POST: {
			body: OpenDisputeEscrowRequest;
			response: ApiResponse<Escrow>;
		};
	};

	'/escrows/dispute/resolve': {
		POST: {
			body: ResolveDisputeEscrowRequest;
			response: ApiResponse<Escrow>;
		};
	};
}

export enum EscrowPartyRole {
	SELLER = 'SELLER',
	BUYER = 'BUYER',
}

export enum EscrowPlatformType {
	DAGPAY = 'Dagpay',
	DAGMARKET = 'Dagmarket',
}

export interface CreateEscrowRequest {
	product: string;
	productNotes: string | null;
	estimatedDealDurationDays: number;
	buyerEmail: string;
	buyerFullName: string;
	shippingInfo: string;
	price: number | null;
	currency: Currency;
	sellerWalletId: string;
}

export interface GetEscrowsRequest extends ApiGetRequest {
	userId: string;
	role?: EscrowPartyRole;
}

export interface GetEscrowRequest {
	escrowId: string;
}

export interface MarkAsReceivedEscrowRequest {
	escrowId: string;
	buyerToken: string;
}

export interface MarkAsShippedEscrowRequest {
	escrowId: string;
	sellerToken: string;
}

export interface OpenDisputeEscrowRequest {
	escrowId: string;
	reason: string;
	token: string;
}

export interface ResolveDisputeEscrowRequest {
	escrowId: string;
	resolution: string;
	token: string;
}

export interface AcceptEscrowRequest {
	escrowId: string;
	buyerToken: string;
	skipPayments?: boolean;
}

export interface DeclineEscrowRequest {
	escrowId: string;
	buyerToken: string;
}
export interface CancelEscrowRequest {
	escrowId: string;
	sellerToken: string;
}

export const getEscrowsApi = async (params: GetEscrowsRequest) => {
	const response = await api.get('/escrows', {
		params: { ...params },
	});

	return response.data;
};

export const getEscrowApi = async (params: GetEscrowRequest) => {
	const response = await api.get<'/escrows/:escrowId'>(`/escrows/${params.escrowId}`);

	return response.data;
};

export const createEscrowApi = async (params: CreateEscrowRequest) => {
	const response = await api.post(`/escrows/dagpay-create`, {
		...params,
	});

	return response.data;
};

export const declineEscrowApi = async (params: DeclineEscrowRequest) => {
	const response = await api.post('/escrows/decline', {
		...params,
	});

	return response.data;
};

export const cancelEscrowApi = async (params: CancelEscrowRequest) => {
	const response = await api.post('/escrows/cancel', {
		...params,
	});

	return response.data;
};

export const acceptEscrowApi = async (params: AcceptEscrowRequest) => {
	const response = await api.post('/escrows/dagpay-accept', {
		...params,
	});

	return response.data;
};

export const getEscrowInvoiceApi = async (params: GetEscrowRequest) => {
	const response = await api.get<'/escrows/get-invoice/:escrowId'>(`/escrows/get-invoice/${params.escrowId}`);

	return response.data;
};

export const markAsReceivedEscrowApi = async (params: MarkAsReceivedEscrowRequest) => {
	const response = await api.post('/escrows/mark-as-received', {
		...params,
	});

	return response.data;
};

export const markAsShippedEscrowApi = async (params: MarkAsShippedEscrowRequest) => {
	const response = await api.post('/escrows/mark-as-shipped', {
		...params,
	});

	return response.data;
};

export const OpenDisputeEscrowApi = async (params: OpenDisputeEscrowRequest) => {
	const response = await api.post('/escrows/dispute/open', {
		...params,
	});

	return response.data;
};

export const ResolveDisputeEscrowApi = async (params: ResolveDisputeEscrowRequest) => {
	const response = await api.post('/escrows/dispute/resolve', {
		...params,
	});

	return response.data;
};
export interface EscrowStatusEstimation {
	status: EscrowStatus;
	date: string;
	estimated: boolean;
}
export interface EscrowDealEstimation {
	escrowCreated: EscrowStatusEstimation;
	escrowAccepted: EscrowStatusEstimation;
	invoicePaid: EscrowStatusEstimation;
	markedAsShipped: EscrowStatusEstimation;
	markedAsReceived: EscrowStatusEstimation;
	fundsReleased: EscrowStatusEstimation;
	escrowCompleted: EscrowStatusEstimation;
}

export enum EscrowStatus {
	ESCROW_CREATED = 'ESCROW_CREATED',
	ESCROW_DECLINED = 'ESCROW_DECLINED',
	ESCROW_CANCELLED = 'ESCROW_CANCELLED',
	ESCROW_ACCEPTED = 'ESCROW_ACCEPTED',
	INVOICE_CREATED = 'INVOICE_CREATED',
	INVOICE_PAID = 'INVOICE_PAID',
	MARKED_AS_SHIPPED = 'MARKED_AS_SHIPPED',
	MARKED_AS_RECEIVED = 'MARKED_AS_RECEIVED',
	FUNDS_RELEASED = 'FUNDS_RELEASED',
	ESCROW_COMPLETED = 'ESCROW_COMPLETED',
	DISPUTE_OPENED = 'DISPUTE_OPENED',
	DISPUTE_RESOLVED = 'DISPUTE_RESOLVED',
	ESCROW_EXPIRED = 'ESCROW_EXPIRED',
}
export interface EscrowParty {
	email: string;
	fullName: string;
	id: string;
	token: string;
	userId: string;
	role: EscrowPartyRole;
}
export enum EscrowDisputeStatus {
	PENDING = 'PENDING',
	RESOLVED = 'RESOLVED',
}
export interface EscrowDispute {
	id: string;
	escrowParty: EscrowParty;
	releaseToParty: EscrowParty;
	status: EscrowDisputeStatus;
	reason: string;
	resolution?: string;
	supportDeskUrl: string;
}
export interface Escrow {
	id: string;
	buyerParty: EscrowParty;
	sellerParty: EscrowParty;
	product: string;
	productNotes?: string;
	estimatedDealDurationDays: number;
	dealEstimationStatuses: EscrowDealEstimation;
	escrowDetailsUrl: string;
	shippingInfo: string;
	price: number;
	coinAmount: number;
	currency: Currency;
	transactionFee: number;
	status: EscrowStatus;
	daysLeft: number;
	escrowInvoiceUrl?: string;
	dispute: EscrowDispute;
	releaseDuration: number;
}
