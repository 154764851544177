import Hex from 'crypto-js/enc-hex';
import { yupResolver } from '@hookform/resolvers/yup';
import HmacSHA512 from 'crypto-js/hmac-sha512';
import React, { useEffect, useReducer, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form/dist';
import Fade from 'react-reveal';
import ReactSVG from 'react-svg';
import * as Yup from 'yup';
import { DagcardTxType } from '../../../../api/dagCardApi';

import Button, { ButtonTypes } from '../../../../components/button/Button';
import CardNumberField from '../../../../components/credit-card-number-field/CreditCardNumberField';
import Modal from '../../../../components/modal/Modal';
import PinCodeField from '../../../../components/pin-code-field/PinCodeField';
import {
	CardStates,
	DAGCOIN_CARD_NUMBER_LENGTH,
	DAGCOIN_CARD_NUMBER_PART_LENGTH,
	DAGCOIN_CARD_PIN_CODE_LENGTH,
} from '../../../../constants';
import getCardStateInfo, { CardStateOptions, ShowModalStates } from '../../../../services/get-card-state-info';
import { useStoreActions } from '../../../../services/store';
import { InvoiceContentBlockProps } from '../InvoiceContentBlock';

import {
	CardHeader,
	CardIcon,
	CardInfoWrapper,
	CardNumber,
	CardNumberWrapper,
	CardStatusWrapper,
	ConfirmButton,
	ContentWrapper,
	DagcoinCard,
	DagcoinCardWrapper,
	GoBack,
	InfoFieldsWrapper,
	InfoTitle,
	ModalButtonWrapper,
	ModalContentWrapper,
	PinCodeFieldWrapper,
	StatusMessage,
} from './DagcoinCardContentStyle';

export interface DagcoinCardFields {
	cardNumber: string;
	pinCode: string;
}

type Action = {
	type: 'update' | 'reset';
	payload?: State;
};

interface State {
	cardState: CardStates;
	isModalOpen: boolean;
}

interface DagcoinCardContentProps extends InvoiceContentBlockProps {
	parentContainerRef: React.MutableRefObject<any>;
}

const initialState = { cardState: CardStates.DEFAULT, isModalOpen: false };

const reducer: React.Reducer<State, Action> = (state, { type, payload }) => {
	// need optimization since actions can be called many times in one second
	if (JSON.stringify(state) === JSON.stringify(payload)) {
		return state;
	}

	switch (type) {
		case 'update':
			return { ...state, ...payload };
		case 'reset':
			return initialState;

		default:
			return state;
	}
};

export function DagcoinCardContent(props: DagcoinCardContentProps) {
	const [cardNumber, setCardNumber] = useState('');
	const [cardNumberHasFocus, setCardNumberHasFocus] = useState(false);
	const [moveFocusToPinCode, setMoveFocusToPinCode] = useState(false);
	const [moveFocusToConfirm, setMoveFocusToConfirm] = useState(false);
	const [state, dispatch] = useReducer(reducer, initialState);

	const { dagCardInitPayment, dagCardPinConfirmPayment } = useStoreActions((actions) => ({
		...actions.dagCard,
	}));

	const { cardState, isModalOpen } = state;

	const schema = Yup.object().shape({
		cardNumber: Yup.string()
			.test(
				'correctLength',
				'Card number should be 16 digits long',
				(value) => value.split(/\s+/).join('').length === DAGCOIN_CARD_NUMBER_LENGTH,
			)
			.test('isNumeric', 'Should contain only digits', (value) => /\D+/.exec(value.split(/\s+/).join('')) === null),

		pinCode: Yup.string()
			.test(
				'correctLength',
				'Pin code should be 4 digits long',
				(value) => value.length === DAGCOIN_CARD_PIN_CODE_LENGTH,
			)
			.test('isNumeric', 'Should contain only digits', (value) => /\D+/.exec(value) === null),
	});

	const defaultValues: DagcoinCardFields = {
		cardNumber: '',
		pinCode: '',
	};

	const methods = useForm<DagcoinCardFields>({
		resolver: yupResolver(schema),
		defaultValues: defaultValues,
		shouldFocusError: false,
		shouldUnregister: false,
	});

	// first render move focus to card number field
	useEffect(() => {
		const cardNumberField = document.getElementById('cardNumber');

		if (cardNumberField) {
			cardNumberField.focus();
		}
	}, []);

	// move focus to next step, if ready
	useEffect(() => {
		const pinCodeField = document.getElementById('pinCode');
		const confirmButton = document.getElementById('confirmButton');
		const activeElement = document.activeElement;

		// focus to pin code field
		if (
			moveFocusToPinCode &&
			pinCodeField &&
			activeElement !== pinCodeField &&
			cardNumber.length === DAGCOIN_CARD_NUMBER_LENGTH
		) {
			pinCodeField.focus();

			return;
		}

		// focus to confirm button
		if (
			moveFocusToConfirm &&
			confirmButton &&
			pinCodeField &&
			pinCodeField === activeElement &&
			cardNumber.length === DAGCOIN_CARD_NUMBER_LENGTH
		) {
			confirmButton.focus();

			return;
		}

		// clear
		if (cardNumber.length !== DAGCOIN_CARD_NUMBER_LENGTH && cardState !== CardStates.DEFAULT) {
			dispatch({
				type: 'reset',
			});
		}
	}, [moveFocusToPinCode, moveFocusToConfirm, cardNumber, cardState]);

	const onSubmit = async (values: any) => {
		if (cardState === CardStates.CONFIRMING || !values.pinCode) {
			return;
		}

		dispatch({
			type: 'update',
			payload: {
				cardState: CardStates.CONFIRMING,
				isModalOpen: false,
			},
		});

		try {
			const initPaymentResponse = await dagCardInitPayment({
				cardNo: cardNumber,
				invoiceId: props.invoice.id,
				invoiceDagTotal: props.invoice.coinAmount,
				txType: DagcardTxType.ONLINE,
			});

			if (
				initPaymentResponse.error &&
				initPaymentResponse.validationErrors &&
				initPaymentResponse.validationErrors.length > 0
			) {
				throw new Error(`${initPaymentResponse.validationErrors[0].message}`);
			}

			if (!initPaymentResponse || !initPaymentResponse.payload || !initPaymentResponse.payload.nonce) {
				throw new Error('failed to initiate payment');
			}

			const confirmPaymentResponse = await dagCardPinConfirmPayment({
				cardNo: cardNumber,
				invoiceId: props.invoice.id,
				signedNonce: Hex.stringify(HmacSHA512(initPaymentResponse.payload.nonce, values.pinCode)),
			});

			if (
				confirmPaymentResponse.error &&
				confirmPaymentResponse.validationErrors &&
				confirmPaymentResponse.validationErrors.length > 0
			) {
				throw new Error(`${confirmPaymentResponse.validationErrors[0].message}`);
			}

			if (!confirmPaymentResponse || !confirmPaymentResponse.payload) {
				throw new Error('Something went wrong with confirmation');
			}

			dispatch({
				type: 'update',
				payload: {
					cardState: CardStates.SUCCESS,
					isModalOpen: false,
				},
			});
		} catch (error) {
			const stateInfo = getCardStateInfo(error);

			dispatch({
				type: 'update',
				payload: {
					cardState: stateInfo.cardState,
					isModalOpen: ShowModalStates.includes(stateInfo.cardState),
				},
			});
		}
	};

	const handleSetCardNumber = (value: string) => {
		setCardNumber(value);
	};

	const handleSetMoveFocusToPinCode = (value: boolean) => {
		setMoveFocusToPinCode(value);
	};

	const handleSetCardNumberHasFocus = (value: boolean) => {
		setCardNumberHasFocus(value);
	};

	const handleSetMoveFocusToConfirm = (value: boolean) => {
		setMoveFocusToConfirm(value);
	};

	const getCardInfoFields = () => {
		return (
			<FormProvider {...methods}>
				<form onSubmit={methods.handleSubmit(onSubmit)} autoComplete="off">
					<CardInfoWrapper>
						<InfoTitle>Enter card number and PIN</InfoTitle>
						<InfoFieldsWrapper>
							<CardNumberField
								methods={methods}
								name="cardNumber"
								title="Dagcoin card number"
								setCardNumber={handleSetCardNumber}
								setMoveFocus={handleSetMoveFocusToPinCode}
								setFieldHasFocus={handleSetCardNumberHasFocus}
							/>
							<PinCodeFieldWrapper>
								<PinCodeField
									methods={methods}
									title="Pin code"
									name="pinCode"
									setMoveFocus={handleSetMoveFocusToConfirm}
								/>
								<ConfirmButton
									id="confirmButton"
									isDisabled={
										cardNumber.length !== DAGCOIN_CARD_NUMBER_LENGTH ||
										!moveFocusToConfirm ||
										(methods.errors.cardNumber !== undefined && methods.errors.cardNumber.message !== undefined) ||
										methods.errors.pinCode !== undefined
									}
									type="submit"
								>
									Confirm
								</ConfirmButton>
							</PinCodeFieldWrapper>
						</InfoFieldsWrapper>
					</CardInfoWrapper>
				</form>
			</FormProvider>
		);
	};

	const getCardNumberFillingProcess = () => {
		const groupsOfFour = cardNumber.match(/.{1,4}/g) || [];

		const maxGroupAmount = DAGCOIN_CARD_NUMBER_LENGTH / DAGCOIN_CARD_NUMBER_PART_LENGTH;
		const hasFewGroups = groupsOfFour.length !== maxGroupAmount;

		// add groups for correct display
		if (hasFewGroups) {
			const addAmount = maxGroupAmount - groupsOfFour.length;

			for (let i = 0; i < addAmount; i++) {
				groupsOfFour.push('');
			}
		}

		const focusGroupList = [];

		return groupsOfFour.map((group, index) => {
			if (group.length >= 0 && group.length < DAGCOIN_CARD_NUMBER_PART_LENGTH) {
				focusGroupList.push(index);
			}

			return (
				<CardNumber
					currentValue={group}
					zeros={'0'.repeat(DAGCOIN_CARD_NUMBER_PART_LENGTH - group.length)}
					key={`group-${index}`}
					showFocus={cardNumberHasFocus && focusGroupList.length === 1}
				/>
			);
		});
	};

	const getCardStatus = () => {
		const message = CardStateOptions[cardState].title;

		return <StatusMessage>{message}</StatusMessage>;
	};

	const handleModalClose = () => {
		dispatch({
			type: 'update',
			payload: {
				cardState: cardState,
				isModalOpen: false,
			},
		});
	};

	const getCardStatusModal = () => {
		const appElement = document.getElementById('root');

		if (props.parentContainerRef.current && appElement) {
			return (
				<Modal
					appElement={appElement}
					parentSelector={() => {
						// error on window resize, temporary fix
						// https://github.com/reactjs/react-modal/issues/769#issuecomment-533326866
						return props.parentContainerRef.current || { removeChild: () => {} };
					}}
					style={{ overlay: { position: 'absolute' } }}
					ariaHideApp={false}
					isOpen={isModalOpen}
					onRequestClose={handleModalClose}
					small
					hasCloseButton
				>
					<ModalContentWrapper>
						<h2>{CardStateOptions[cardState].title}</h2>
						<p>{CardStateOptions[cardState].content}</p>

						<ModalButtonWrapper>
							<Button.Secondary white onClick={handleModalClose}>
								Dismiss
							</Button.Secondary>
						</ModalButtonWrapper>
					</ModalContentWrapper>
				</Modal>
			);
		}

		return null;
	};

	return (
		<ContentWrapper>
			<Fade>
				<DagcoinCardWrapper>
					<DagcoinCard>
						<CardHeader>
							<CardIcon>
								<ReactSVG src="/files/svg/logos/DagcoinWhiteLogoSquared.svg" />
							</CardIcon>
						</CardHeader>
						<CardNumberWrapper cardError={cardState !== CardStates.DEFAULT && ShowModalStates.includes(cardState)}>
							{getCardNumberFillingProcess()}
						</CardNumberWrapper>

						<CardStatusWrapper>{cardState && getCardStatus()}</CardStatusWrapper>
					</DagcoinCard>
				</DagcoinCardWrapper>

				{getCardInfoFields()}
				<GoBack type={ButtonTypes.BUTTON} onClick={props.history.goBack}>
					Back
				</GoBack>
			</Fade>
			{getCardStatusModal()}
		</ContentWrapper>
	);
}
