import { thunk, Thunk } from 'easy-peasy';

import { createUtmApi, CreateUtmRequest, Utm } from '../api/utmApi';
import handleFetch from '../services/handle-fetch';
import { Response } from '../typings';

import { StoreModel } from '.';

export interface UtmModel {
	createUtm: Thunk<UtmModel, CreateUtmRequest, {}, StoreModel, Response<Utm>>;
}

const utm: UtmModel = {
	// thunks
	createUtm: thunk(async (actions, payload) => {
		const result = await handleFetch<Utm>(createUtmApi(payload));

		return result;
	}),
};

export default utm;
