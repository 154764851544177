import * as React from 'react';
import ReactDatePicker from 'react-datepicker';
import styled from 'styled-components/macro';
import { Controller, useFormContext } from 'react-hook-form';
import { Border, Error, FieldWrapper, Label } from './HookFieldsStyle';
import { StyledInputMixin } from '../field/FieldStyle';
import { ErrorMessage } from '@hookform/error-message';
import InputMask from 'react-input-mask';
import dayjs from 'dayjs';

const StyledTimePicker = styled(ReactDatePicker)`
	${StyledInputMixin};
`;

interface Props {
	name: string;
	label?: string;
	isMulti?: boolean;
	tabIndex?: number;
	isSearchable?: boolean;
	defaultValue?: Date | null;
	noErrorElement?: boolean;
	dateFormat?: string;
	disabled?: boolean;
	value?: Date;
	maxDate?: Date;
	size?: number;
}

export default function HookTimeField(props: Props) {
	const { name, label, value, maxDate, size, ...rest } = props;
	const { control } = useFormContext();

	const parseDate = (date: string) => {
		if (dayjs(date).isValid()) {
			return dayjs(date).toDate();
		}
	};

	return (
		<FieldWrapper size={size}>
			<Label>{label}</Label>
			<Border size={size}>
				<Controller
					control={control}
					name={name}
					valueName="selected" // DateSelect value's name is selected
					defaultValue={value}
					render={({ onChange, value, ref }) => (
						<StyledTimePicker
							dateFormat="HH:mm"
							timeFormat="HH:mm"
							showTimeSelect
							showTimeSelectOnly
							selected={value ? parseDate(value) : null}
							onChange={(date: string) => onChange(date)}
							timeIntervals={30}
							placeholderText="HH:mm"
							customInput={<InputMask mask="99:99" maskChar={null} placeholder="HH:mm" inputRef={ref} />}
							{...rest}
						/>
					)}
				/>
			</Border>
			<Error>
				<ErrorMessage name={name} />
			</Error>
		</FieldWrapper>
	);
}
