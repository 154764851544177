import * as React from 'react';
import { ErrorMessage } from '@hookform/error-message';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components/macro';

import { media } from '../../services/media';

import { Error, FieldWrapper, Label, LabelLink } from './HookFieldsStyle';
import { Color } from '../../gfx/constants';

interface Props {
	name: string;
	placeholder?: string;
	tabIndex?: number;
	label?: string;
	optional?: boolean;
	optionalPrivate?: boolean;
	optionalBubble?: boolean;
	value?: string;
	readOnly?: boolean;
	addonNode?: React.ReactNode;
	subLabel?: React.ReactNode;
	rows?: number;
	disabled?: boolean;
	labelLink?: string;
	labelLinkText?: string;
	onChange?: React.ChangeEventHandler;
}

const StyledTextArea = styled.textarea`
	background: transparent;
	background-color: transparent;
	background-clip: padding-box;
	box-shadow: none;
	font-family: Secondary;
	font-size: 16px;
	outline: none;
	width: 100%;
	line-height: 21px;
	padding: 13px 16px;
	display: block;
	resize: vertical;
	border: 1px solid ${Color.GRAY_5};
	border-radius: 4px;
	scrollbar-width: 5px;

	${media.maxDesktop`
		min-width: 0;
	`}

	&:disabled {
		background: #eeeff2;
	}

	&::-ms-clear,
	&::-ms-reveal {
		display: none;
	}

	input {
		flex-grow: 1;
	}
`;
export function HookTextAreaField(props: Props) {
	const {
		name,
		placeholder,
		label,
		optional,
		optionalPrivate,
		optionalBubble,
		value,
		readOnly,
		disabled,
		labelLink,
		labelLinkText = 'Learn more',
		rows,
		tabIndex,
		onChange,
	} = props;
	const { register } = useFormContext();

	return (
		<FieldWrapper>
			<Label optionalPrivate={optionalPrivate} optional={optional} optionalBubble={optionalBubble}>
				{label}
				{labelLink && (
					<LabelLink href={labelLink} target="_blank">
						{labelLinkText}
					</LabelLink>
				)}
			</Label>
			<StyledTextArea
				name={name}
				ref={register}
				spellCheck={false}
				placeholder={placeholder}
				value={value}
				rows={rows || 1}
				readOnly={readOnly}
				onBlur={(e: React.ChangeEvent<HTMLInputElement>) => (e.currentTarget.scrollLeft = 0)}
				disabled={disabled}
				onChange={onChange}
				tabIndex={tabIndex}
			/>
			<Error>
				<ErrorMessage name={name} />
			</Error>
		</FieldWrapper>
	);
}

export default HookTextAreaField;
