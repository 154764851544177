import { Form as BasicForm, FormikActions } from 'formik';
import { CFormikProps, FormikFields as Form, FormikFieldsState } from 'formik-fields';
import * as React from 'react';
import { withRouter } from 'react-router';

import { EnvironmentType } from '../../../../../../api/environmentApi';
import Button, { ButtonTypes } from '../../../../../../components/button/Button';
import PreviousLink from '../../../../../../components/previous-link/PreviousLink';
import RadioSelector from '../../../../../../components/radio-selector/RadioSelector';
import View from '../../../../../../components/view/View';
import { H1 } from '../../../../../../gfx/globals';
import { RouteProps } from '../../../../../../typings';

import {
	CreateNewEnvironmentView,
	EnvironmentButtonWrapper,
	EnvironmentRadioGroup,
	EnvironmentRadioSelector,
	EnvironmentTypeIcon,
} from './EnvironmentDetailStyle';

interface EnvironmentTypeFields {
	integrationType: EnvironmentType | null;
}

function EnvironmentTypeChoosingView(props: RouteProps) {
	const getMainForm =
		() => (fields: FormikFieldsState<EnvironmentTypeFields>, _fieldActions: CFormikProps<EnvironmentTypeFields>) =>
			(
				<BasicForm>
					<EnvironmentRadioGroup name={fields.integrationType.name} wrapper={EnvironmentRadioSelector}>
						<RadioSelector
							label="WooCommerce"
							value={EnvironmentType.WOOCOMMERCE}
							isActive={fields.integrationType.value === EnvironmentType.WOOCOMMERCE}
							icon={
								<EnvironmentTypeIcon
									src={'/files/svg/private/merchant-tools/WooCommerce.svg'}
									isActive={fields.integrationType.value === EnvironmentType.WOOCOMMERCE}
								/>
							}
							checkIcon
							environmentViewLabel
						/>
						<RadioSelector
							label="Magento"
							value={EnvironmentType.MAGENTO}
							isActive={fields.integrationType.value === EnvironmentType.MAGENTO}
							icon={
								<EnvironmentTypeIcon
									src={'/files/svg/private/merchant-tools/Magento.svg'}
									isActive={fields.integrationType.value === EnvironmentType.MAGENTO}
								/>
							}
							checkIcon
							environmentViewLabel
						/>

						<RadioSelector
							label="OpenCart"
							value={EnvironmentType.OPENCART}
							isActive={fields.integrationType.value === EnvironmentType.OPENCART}
							icon={
								<EnvironmentTypeIcon
									src={'/files/svg/private/merchant-tools/OpenCart.svg'}
									isActive={fields.integrationType.value === EnvironmentType.OPENCART}
								/>
							}
							checkIcon
							environmentViewLabel
						/>
						<RadioSelector
							label="PrestaShop"
							value={EnvironmentType.PRESTASHOP}
							isActive={fields.integrationType.value === EnvironmentType.PRESTASHOP}
							icon={
								<EnvironmentTypeIcon
									src={'/files/svg/private/merchant-tools/PrestaShop.svg'}
									isActive={fields.integrationType.value === EnvironmentType.PRESTASHOP}
								/>
							}
							checkIcon
							environmentViewLabel
						/>
						<RadioSelector
							label="Easy Digital Downloads"
							value={EnvironmentType.EASY_DIGITAL_DOWNLOADS}
							isActive={fields.integrationType.value === EnvironmentType.EASY_DIGITAL_DOWNLOADS}
							icon={
								<EnvironmentTypeIcon
									src={'/files/svg/private/merchant-tools/EasyDigitalDownloads.svg'}
									isActive={fields.integrationType.value === EnvironmentType.EASY_DIGITAL_DOWNLOADS}
								/>
							}
							checkIcon
							environmentViewLabel
						/>
						<RadioSelector
							label="Custom"
							value={EnvironmentType.CUSTOM}
							isActive={fields.integrationType.value === EnvironmentType.CUSTOM}
							icon={
								<EnvironmentTypeIcon
									src={'/files/svg/private/merchant-tools/Custom.svg'}
									isActive={fields.integrationType.value === EnvironmentType.CUSTOM}
								/>
							}
							checkIcon
							environmentViewLabel
						/>
					</EnvironmentRadioGroup>

					<EnvironmentButtonWrapper>
						<PreviousLink title="Back" to="../environments" />
						<Button isDisabled={!fields.integrationType.value} type={ButtonTypes.SUBMIT} alignedRight>
							Next
						</Button>
					</EnvironmentButtonWrapper>
					<View.Error alignRight>{fields.integrationType.error}</View.Error>
				</BasicForm>
			);

	const handleIntegrationSelection = (
		values: EnvironmentTypeFields,
		formActions: FormikActions<EnvironmentTypeFields>,
	) => {
		if (!values.integrationType) {
			return formActions.setFieldError('integrationType', 'Please choose integration type');
		}

		return props.history.push(`detail/${values.integrationType}`);
	};

	return (
		<CreateNewEnvironmentView>
			<H1 center>Create new environment</H1>
			<View.Description>
				Create a new environment and integrate it with your website to accept payments
			</View.Description>
			<View.Error alignRight>{}</View.Error>
			<Form<EnvironmentTypeFields>
				fields={{
					integrationType: { initialValue: null },
				}}
				onSubmit={handleIntegrationSelection}
				render={getMainForm()}
			/>
		</CreateNewEnvironmentView>
	);
}

export default withRouter(EnvironmentTypeChoosingView);
