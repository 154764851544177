export default function getFormData<TObject>(object: TObject): FormData {
	return Object.keys(object).reduce(
		(formData, key) => {
			// tslint:disable-next-line:no-any
			formData.append(key, object[key as keyof TObject] as any);

			return formData;
		},
		new FormData(),
	);
}
