import * as React from 'react';
import { StyledComponent } from 'styled-components';
import styled from 'styled-components/macro';

import RadioSelector from '../radio-selector/RadioSelector';

interface Props {
	name?: string;
	disabled?: boolean;
	isColumned?: boolean;
	isLeftAligned?: boolean;
	children?: React.ReactNode;
	defaultSelected?: string | null;
	className?: string;
	wrapper: StyledComponent<'div', {}, {}, never>;
	onClick?(e: React.MouseEvent<any>): void;
}

export const RadioGroup = styled.div`
	display: flex;
	justify-content: space-between;
`;

export default function RadioSelectorGroup(props: Props) {
	const { name, className, children, defaultSelected, disabled = false, onClick, wrapper: Wrapper } = props;

	return (
		<Wrapper>
			<RadioGroup className={className}>
				{React.Children.map(children, child => {
					const { value, className: childClassName, ...rest } = (child as JSX.Element).props;

					return (
						<RadioSelector
							name={name}
							className={childClassName}
							value={value}
							selected={value === defaultSelected}
							disabled={disabled}
							onClick={onClick}
							{...rest}
						/>
					);
				})}
			</RadioGroup>
		</Wrapper>
	);
}
