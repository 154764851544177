import styled from 'styled-components/macro';

import { Color } from '../../gfx/constants';

interface CheckboxWrapProps {
	isCentered: boolean;
	marginTop?: string;
}

interface CheckboxTextProps {
	isBlack?: boolean;
}

export const CheckboxContainer = styled.div`
	display: flex;
	justify-content: center;
	padding-bottom: 30px;
	padding-top: 20px;
`;

export const CheckboxText = styled.span<CheckboxTextProps>`
	color: ${({ isBlack }) => (isBlack ? '#0b132b' : 'white')};
	font-family: Secondary;
	font-size: 14px;
	line-height: 19px;

	a {
		color: ${Color.GREEN_3};
		text-decoration: underline;
	}
`;

export const CheckboxError = styled.div`
	color: ${Color.RED_2};
	font-family: Secondary;
	font-size: 13px;
	line-height: 19px;
`;

export const CheckboxWrap = styled.label<CheckboxWrapProps>`
	display: flex;
	align-items: center;
	justify-content: ${({ isCentered }) => isCentered && 'center'};
	position: relative;
	cursor: pointer;
	user-select: none;

	a {
		color: '#333';
	}
`;

export const CheckboxMark = styled.span`
	display: inline-flex;
	margin-right: 14px;
	width: 24px;
	height: 24px;
	min-width: 24px;
	min-height: 24px;
	top: 0;
	left: 0;
	background-color: #fff;
	border-radius: 4px;
	border: 1px solid gray;
`;

export const StyledCheckbox = styled.input`
	opacity: 0;
	position: absolute;
	width: 24px;
	height: 24px;
	cursor: pointer;
	&:checked + span {
		background: ${Color.GREEN_3};
		border: none;
	}

	&:disabled + span {
		background-color: #ddd;
	}

	&:not(':checked') + span {
		transition: all 0.25s;
	}

	&:checked + span::after {
		content: '';
		position: relative;
		left: 9px;
		top: 5px;
		width: 6px;
		height: 12px;
		border-style: solid;
		border-color: white;
		border-width: 0 2px 2px 0;
		transform: rotate(45deg);
		transition: all 0.25s;
	}
	&:focus + span {
		border-color: ${Color.GREEN_3};
	}
`;
