import { yupResolver } from '@hookform/resolvers/yup';
import * as owasp from 'owasp-password-strength-test';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { withRouter } from 'react-router-dom';
import Loader from 'react-spinners/ClipLoader';
import ReactSVG from 'react-svg';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { User } from '../../../../api/userApi';
import Button, { ButtonTypes } from '../../../../components/button/Button';
import CopyButton from '../../../../components/copy-button/CopyButton';
import { EscrowSupportModalWrapper } from '../../../../components/escrow-state-display/EscrowStateDisplayStyle';
import { FieldOptionProps } from '../../../../components/field/Field';
import Modal from '../../../../components/modal/Modal';
import PanelNew, { PanelSectionType } from '../../../../components/panel-new/PanelNew';
import HookSelectField from '../../../../components/react-hook-form/hook-select-field/HookSelectField';
import HookBaseField from '../../../../components/react-hook-form/HookBaseField';
import HookPasswordField from '../../../../components/react-hook-form/HookPasswordField';
import ReactHookForm, {
	HookFormColumn,
	HookFormSection,
	HookFormSeparator,
} from '../../../../components/react-hook-form/ReactHookFormStyle';
import View from '../../../../components/view/View';
import WithPermission from '../../../../components/with-permission/WithPermission';
import { Scopes, UserLoginType } from '../../../../constants';
import { ColumnedFlex, H2, Strong } from '../../../../gfx/globals';
import getAuthenticationOptions, {
	getDefaultAuthenticationOptions,
} from '../../../../services/get-authentication-options';
import { getServerValidationErrors } from '../../../../services/input-error-utils';
import { useStoreActions, useStoreState } from '../../../../services/store';
import { RouteProps } from '../../../../typings';
import { QrCode } from '../../../invoices/InvoiceViewStyle';
import {
	AuthenticatorSteps,
	AuthLinks,
	GoogleBox,
	LoginSecretBlock,
	QrBlockTitle,
	QrText,
	QrWrapper,
	StepsWrapper,
	TitleWrapper,
	VerificationCodeBlock,
} from '../SettingsStyle';

interface PasswordSettingsFields {
	currentPassword: string | null;
	newPassword: string | null;
	confirmNewPassword: string | null;
	loginType: FieldOptionProps;
	loginCode: string | null;
}

function SecurityView(props: RouteProps) {
	const [errorMessage, setErrorMessage] = useState('');
	const [loginSecret, setLoginSecret] = useState('');
	const [isAuthModalOpen, setIsAuthModalOpen] = useState<boolean>(false);
	const [authenticatorQrCodeUrl, setAuthenticatorQrCodeUrl] = useState<string | undefined>(undefined);

	const { activeViewer } = useStoreState((state) => ({
		activeViewer: state.viewer.activeViewer,
	}));

	const { enableAuthenticator, updateUser, changePassword } = useStoreActions((actions) => ({
		...actions.viewer,
		...actions.user,
	}));

	const handleCloseModal = () => {
		setLoginSecret('');
		setAuthenticatorQrCodeUrl(undefined);
		getDefaultAuthenticationOptions({ current: activeViewer ? activeViewer.loginType : UserLoginType.PASSWORD });
		setIsAuthModalOpen(false);
	};

	const getGoogleAuthModal = () => {
		return (
			<Modal
				hasCloseButton
				ariaHideApp={false}
				isOpen={!!activeViewer && activeViewer.loginType !== UserLoginType.AUTHENTICATOR}
				onRequestClose={(_e: React.MouseEvent<Element | MouseEvent> | React.KeyboardEvent<Element>) =>
					handleCloseModal()
				}
			>
				<EscrowSupportModalWrapper>
					<h2>Setup Goolge Authenticator</h2>
					<HookFormSection>
						<QrText>
							<StepsWrapper>
								<AuthenticatorSteps stepNumber={1}>
									Download and install the Google Authenticator app for your Android or iOS device.
								</AuthenticatorSteps>
								<AuthenticatorSteps stepNumber={2}>
									In the app, choose <Strong>Begin setup</Strong>.
								</AuthenticatorSteps>
								<AuthenticatorSteps stepNumber={3}>
									When prompted, select <Strong>Scan a barcode</Strong>. Use your phone's camera to scan this barcode.
								</AuthenticatorSteps>
								<AuthenticatorSteps stepNumber={4}>
									When the application is configured, type the generated code and click <Strong>Update info </Strong>
									to complete the setup:
								</AuthenticatorSteps>
							</StepsWrapper>
							<ColumnedFlex isCentered>
								<QrWrapper>
									<QrCode>
										<img alt="QR code" src={authenticatorQrCodeUrl} />
									</QrCode>
									Scan QR-code
								</QrWrapper>
							</ColumnedFlex>
							<LoginSecretBlock>
								<HookBaseField
									name="loginSecret"
									label="Can't scan QR-code? Use the code below for manual entry"
									value={loginSecret}
									disabled
									addonNode={
										<CopyButton value={loginSecret}>
											<ReactSVG src="/files/svg/private/LinkWhite.svg" />
										</CopyButton>
									}
									addonSize={56}
								/>
							</LoginSecretBlock>
							<VerificationCodeBlock>
								<HookBaseField name="loginCode" label="Google Authentication verification code" placeholder="Code" />
								<QrBlockTitle>Links to download the Google Authenticator app:</QrBlockTitle>
								<AuthLinks>
									<span>
										<a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2">
											Android Google Play
										</a>
									</span>
									<span>
										<a href="https://itunes.apple.com/us/app/google-authenticator/id388497605?mt=8">IOS App Store</a>
									</span>
								</AuthLinks>
							</VerificationCodeBlock>
						</QrText>
					</HookFormSection>
					<Button alignedRight type={ButtonTypes.SUBMIT} onClick={methods.handleSubmit(handleUserUpdate)}>
						Save
					</Button>
				</EscrowSupportModalWrapper>
			</Modal>
		);
	};

	const onLoginTypeFormChange = async (values: PasswordSettingsFields) => {
		if (!activeViewer) {
			toast.error('There is a problem with loading viewer data.');
			return false;
		}

		const { loginType: existingLoginType } = activeViewer;
		const { loginType: newLoginType } = values;

		// enable authenticator when switching to authenticator from other login type
		if (existingLoginType !== UserLoginType.AUTHENTICATOR && newLoginType.value === UserLoginType.AUTHENTICATOR) {
			try {
				const response = await enableAuthenticator();

				if (response.success && response.payload) {
					setLoginSecret(response.payload.loginSecret);
					setAuthenticatorQrCodeUrl(response.payload.authenticatorQrCodeUrl);
					setIsAuthModalOpen(true);
				}
			} catch (e) {
				e.prevent.default();
				setErrorMessage('There was en error with enabling Google Authenticator.');
				return false;
			}
		}

		// clear authenticator data when switching to other login type
		if (newLoginType.value !== UserLoginType.AUTHENTICATOR) {
			setLoginSecret('');
			setAuthenticatorQrCodeUrl(undefined);
		}
		return true;
	};

	const handleUserUpdate = async (e: React.FormEvent<HTMLFormElement>) => {
		const notFoundCode = 400;

		const { newPassword, currentPassword, loginCode, loginType } = methods.getValues();

		if (currentPassword && newPassword) {
			try {
				const response = await changePassword({ currentPassword: currentPassword, newPassword: newPassword });

				if (response.success && response.payload !== null) {
					methods.reset();
					setIsAuthModalOpen(false);
					return toast.success('Your password is changed');
				}

				if (response.error) {
					methods.setError('currentPassword', { message: 'Current password is not correct' });
					return false;
				}
			} catch (e) {
				toast.error('Changing password failed');
				return false;
			}
		}

		if (activeViewer) {
			try {
				const response = await updateUser({
					baseCurrency: activeViewer.baseCurrency,
					loginType: UserLoginType[loginType.value as keyof typeof UserLoginType],
					supportEmail: activeViewer.supportEmail,
					loginCode: loginCode ? loginCode : undefined,
					atmPaymentsDefault: activeViewer.atmPaymentsDefault,
					currentPassword: currentPassword ? currentPassword : undefined,
					newPassword: newPassword ? newPassword : undefined,
				});

				if (response.success) {
					setAuthenticatorQrCodeUrl(undefined);
					setLoginSecret('');
					toast.success('Your profile has been successfully updated');
				}

				if (response.error) {
					const serverValidationErrors = getServerValidationErrors<User>(response);

					if (response.status === notFoundCode) {
						setErrorMessage('');

						methods.setError('loginCode', { message: 'Login code is incorrect' });
					}

					setErrorMessage(serverValidationErrors.errorMessage);
				}
			} catch (e) {
				setErrorMessage('User update failed');
			}
		}
	};

	const getVerifyCodeForm = (fields: PasswordSettingsFields) => (
		<GoogleBox>
			<QrText>
				<HookBaseField name="loginCode" label="Google Authentication verification code" placeholder="Code" />
			</QrText>
		</GoogleBox>
	);

	const passwordSchema = Yup.object<PasswordSettingsFields>().shape({
		currentPassword: Yup.string().when('newPassword', {
			is: (newPassword) => !!newPassword && newPassword.length > 0,
			then: Yup.string().required('you must fill in current password'),
			otherwise: Yup.string().notRequired(),
		}),
		newPassword: Yup.lazy((value: string) => {
			if (!value) {
				return Yup.string().notRequired();
			}
			return Yup.string()
				.notOneOf([Yup.ref('currentPassword')], 'New password must be different than the Current password')
				.test('is-secure', 'Password is not secure enough', (value) => {
					if (value) {
						return owasp.test(value).strong;
					}

					return false;
				});
		}),
		loginType: Yup.mixed(),
		loginCode: Yup.lazy(() =>
			activeViewer && activeViewer.loginType === UserLoginType.AUTHENTICATOR
				? Yup.string().required()
				: Yup.string().notRequired(),
		),
		confirmNewPassword: Yup.string().when('newPassword', {
			is: (newPassword) => !!newPassword && newPassword.length > 0,
			then: Yup.string()
				.oneOf([Yup.ref('newPassword'), null], 'Passwords do not match')
				.required('Password confirmation is required'),
			otherwise: Yup.string().notRequired(),
		}),
	});

	const methods = useForm<PasswordSettingsFields>({
		defaultValues: {
			currentPassword: '',
			newPassword: '',
			confirmNewPassword: '',
			loginType: activeViewer
				? getDefaultAuthenticationOptions({ current: activeViewer.loginType })
				: { label: 'Password only', value: UserLoginType.PASSWORD },
		},
		resolver: yupResolver(passwordSchema),
		mode: 'all',
		shouldUnregister: false,
	});

	const authWatch = methods.watch('loginType');

	return (
		<>
			<TitleWrapper>
				<H2>Security</H2>
			</TitleWrapper>
			<PanelNew>
				{activeViewer ? (
					<FormProvider {...methods}>
						<ReactHookForm onSubmit={methods.handleSubmit(handleUserUpdate)} autoComplete="off">
							<PanelNew.Section first white panelType={PanelSectionType.FORM}>
								<HookFormSection>
									<HookFormColumn>
										<HookFormSeparator>Change password</HookFormSeparator>
										<HookPasswordField name="currentPassword" label="Current password" />
										<HookPasswordField name="newPassword" label="New password" />
										<HookPasswordField name="confirmNewPassword" label="Confirm password" />
									</HookFormColumn>
									<WithPermission permissions={[Scopes.AUTHENTICATION, Scopes.ENABLE_AUTHENTICATOR]}>
										{/* <HookFormColumn>{getLoginTypeForm(activeViewer)}</HookFormColumn> */}
										<HookFormColumn>
											<HookFormSeparator>Authentication</HookFormSeparator>
											<HookSelectField
												name="loginType"
												label="Authentication method"
												options={getAuthenticationOptions({ current: activeViewer.loginType })}
											/>
											{isAuthModalOpen && getGoogleAuthModal()}
											{activeViewer.loginType === UserLoginType.AUTHENTICATOR && getVerifyCodeForm(methods.getValues())}
										</HookFormColumn>
									</WithPermission>
								</HookFormSection>

								<View.Error>{errorMessage}</View.Error>
							</PanelNew.Section>
							<PanelNew.Section gray last panelType={PanelSectionType.BUTTON}>
								{UserLoginType.AUTHENTICATOR === authWatch.value &&
								activeViewer.loginType !== UserLoginType.AUTHENTICATOR ? (
									<Button
										alignedRight
										isDisabled={!methods.formState.isDirty}
										onClick={(_e) => onLoginTypeFormChange(methods.getValues())}
										type={ButtonTypes.BUTTON}
									>
										Authenticate
									</Button>
								) : (
									<Button
										alignedRight
										isDisabled={!methods.formState.isDirty || methods.formState.isSubmitting}
										disabled={!methods.formState.isDirty || methods.formState.isSubmitting}
										type={ButtonTypes.SUBMIT}
									>
										Save
									</Button>
								)}
							</PanelNew.Section>
						</ReactHookForm>
					</FormProvider>
				) : (
					<Loader />
				)}
			</PanelNew>
		</>
	);
}

export default withRouter(SecurityView);
