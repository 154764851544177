import { yupResolver } from '@hookform/resolvers/yup';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { Discount, DiscountType } from '../../../../api/discountApi';
import { UpdateSupportEmailResponse, User } from '../../../../api/userApi';
import Button, { ButtonTypes } from '../../../../components/button/Button';
import {
	EscrowSupportModalWrapper,
	ModalButtonWrapper,
} from '../../../../components/escrow-state-display/EscrowStateDisplayStyle';
import { FieldOptionProps } from '../../../../components/field/Field';
import Loader from '../../../../components/loader/Loader';
import Modal from '../../../../components/modal/Modal';
import Notification from '../../../../components/notification/Notification';
import PanelNew, { PanelSectionType } from '../../../../components/panel-new/PanelNew';
import PointExplanationList, {
	ExplanationItem,
} from '../../../../components/point-explanation-list/PointExplanationList';
import HookVerticalRadioGroup from '../../../../components/react-hook-form-vertical-radiogroup/HookVerticalRadioGroup';
import HookSelectField from '../../../../components/react-hook-form/hook-select-field/HookSelectField';
import HookBaseField from '../../../../components/react-hook-form/HookBaseField';
import ReactHookForm, {
	HookFormColumn,
	HookFormSection,
	HookFormSeparator,
} from '../../../../components/react-hook-form/ReactHookFormStyle';
import View from '../../../../components/view/View';
import WithPermission from '../../../../components/with-permission/WithPermission';
import {
	Currency,
	Scopes,
	Validations,
	AtmPaymentsDefault,
	UserRole,
	UserLoginType,
	MAX_LOGIN_CODE_LENGTH,
} from '../../../../constants';
import { Color } from '../../../../gfx/constants';
import { H2 } from '../../../../gfx/globals';
import assertUnreachable from '../../../../services/assert-unreachable';
import { getBaseCurrencyOptions } from '../../../../services/get-base-currency-options';
import getDiscount from '../../../../services/get-discount';
import { hasPermission } from '../../../../services/has-permission';
import { getServerValidationErrors } from '../../../../services/input-error-utils';
import { useStoreActions, useStoreState } from '../../../../services/store';
import { ApiResponse } from '../../../../typings';
import { AtmPaymentsNotification } from '../../merchant/pos-checkout/PosCheckoutViewStyle';
import { GoogleBox, QrText, TitleWrapper } from '../SettingsStyle';

const atmDefaultChoices = [
	{
		label: 'Enabled',
		value: AtmPaymentsDefault.ENABLED,
	},
	{
		label: 'Ask',
		value: AtmPaymentsDefault.ASK,
	},
	{
		label: 'Off',
		value: AtmPaymentsDefault.OFF,
	},
];
const discountPermissionsList = [
	Scopes.DISCOUNTS,
	Scopes.CREATE_DISCOUNT,
	Scopes.GET_DISCOUNT_LIST,
	Scopes.UPDATE_DISCOUNT,
];

export enum MerchantProfileViewModalType {
	LOGIN_CODE = 'LOGIN_CODE',
}

interface MerchantProfileFields {
	baseCurrency: FieldOptionProps;
	supportEmail: string;
	atmDiscount: number | null;
	atmPaymentsDefault: AtmPaymentsDefault;
	loginCode: string;
}

function MerchantProfileView() {
	const [errorMessage, setErrorMessage] = useState('');
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [modalType, setModalType] = useState<MerchantProfileViewModalType | null>(null);
	const [isActionLoading, setIsActionLoading] = useState(false);
	const hasDiscountPermissions = hasPermission(discountPermissionsList);

	const { activeViewer, verifications, discountList } = useStoreState((state) => ({
		activeViewer: state.viewer.activeViewer,
		verifications: state.verification.verifications,
		discountList: state.discount.discountList,
	}));

	const { updateUser, updateSupportEmail, getDiscountList, createDiscount, updateDiscount } = useStoreActions(
		(actions) => ({
			...actions.viewer,
			...actions.discount,
		}),
	);

	const merchantProfileValidationSchema = Yup.object<MerchantProfileFields>().shape({
		supportEmail: Validations.INVALID_EMAIL,
		baseCurrency: Yup.mixed().required('Please choose the currency'),
		atmDiscount: Yup.number()
			.typeError('Must be a number with a dot as a decimal separator')
			.min(0, 'Minimum discount is 0')
			.max(99, 'Maximum discount is 99')
			.nullable(true)
			.notRequired()
			.transform((value, originalValue) => {
				return originalValue === '' ? null : value;
			}),
		atmPaymentsDefault: Yup.mixed().required(),
		loginCode: Yup.string()
			.test('isRequired', 'Verification code is required', (value) => {
				if (
					/* !askLoginCode */ !isModalOpen ||
					(isModalOpen && modalType !== MerchantProfileViewModalType.LOGIN_CODE)
				) {
					return true;
				}

				if (
					/* askLoginCode && !value */ isModalOpen &&
					modalType === MerchantProfileViewModalType.LOGIN_CODE &&
					!value
				) {
					return false;
				}

				return true;
			})
			.test('isLongEnough', 'Verification code should contain 6 characters', (value) => {
				if (
					/* !askLoginCode */ !isModalOpen ||
					(isModalOpen && modalType !== MerchantProfileViewModalType.LOGIN_CODE)
				) {
					return true;
				}

				if (
					/* askLoginCode && !value */ isModalOpen &&
					modalType === MerchantProfileViewModalType.LOGIN_CODE &&
					!value
				) {
					return false;
				}

				if (
					/* askLoginCode */ (isModalOpen &&
						modalType === MerchantProfileViewModalType.LOGIN_CODE &&
						value.length > MAX_LOGIN_CODE_LENGTH) ||
					value.length < MAX_LOGIN_CODE_LENGTH
				) {
					return false;
				}

				return true;
			}),
	});

	const fetchDiscountList = useCallback(async () => {
		if (hasDiscountPermissions && !discountList) {
			await getDiscountList({});
		}
	}, [discountList, getDiscountList, hasDiscountPermissions]);

	const oldAtmDiscount = getDiscount(discountList, DiscountType.ATM);

	const defaultValues = {
		baseCurrency: { label: activeViewer?.baseCurrency, value: activeViewer?.baseCurrency },
		supportEmail: activeViewer?.supportEmail,
		atmDiscount: oldAtmDiscount && oldAtmDiscount.amount ? oldAtmDiscount.amount : 0,
		atmPaymentsDefault: activeViewer?.atmPaymentsDefault,
		loginCode: '',
	};
	const methods = useForm<MerchantProfileFields>({
		resolver: yupResolver(merchantProfileValidationSchema),
		defaultValues: defaultValues,
		shouldFocusError: true,
		shouldUnregister: false,
		mode: 'onChange',
	});

	useEffect(() => {
		fetchDiscountList();
		if (activeViewer && discountList) {
			const oldAtmDiscount = getDiscount(discountList, DiscountType.ATM);
			methods.reset({
				baseCurrency: { label: activeViewer.baseCurrency, value: activeViewer.baseCurrency },
				supportEmail: activeViewer.supportEmail,
				atmPaymentsDefault: activeViewer.atmPaymentsDefault,
				atmDiscount: oldAtmDiscount && oldAtmDiscount.amount !== 0 ? oldAtmDiscount.amount : null,
			});
		}
	}, [fetchDiscountList, activeViewer]);

	const handleCloseModal = () => {
		setIsModalOpen(false);
		setModalType(null);
	};

	const getLoginCodeModal = () => (
		<Modal
			isOpen={true}
			ariaHideApp={false}
			onRequestClose={() => {
				handleCloseModal();
			}}
			small
			hasCloseButton
		>
			<EscrowSupportModalWrapper>
				<h2>Enter Google Authenticator code to confirm</h2>
				<GoogleBox>
					<QrText>
						<HookBaseField name="loginCode" label="Login code" placeholder="Code" />
					</QrText>
				</GoogleBox>
				<ModalButtonWrapper>
					<Button.Secondary
						white
						onClick={() => {
							methods.trigger('loginCode');
							handleCloseModal();
						}}
					>
						Cancel
					</Button.Secondary>
					<Button.Secondary type={ButtonTypes.SUBMIT} form="merchantProfile" green>
						Confirm
					</Button.Secondary>
				</ModalButtonWrapper>
			</EscrowSupportModalWrapper>
		</Modal>
	);

	const renderModal = () => {
		if (!modalType || !isModalOpen) {
			return;
		}

		switch (modalType) {
			case MerchantProfileViewModalType.LOGIN_CODE:
				return getLoginCodeModal();

			default:
				return assertUnreachable(modalType, `Unhandled discriminated union member: ${JSON.stringify(modalType)}`);
		}
	};

	if (!activeViewer) {
		return <Loader />;
	}

	const handleUserUpdate = async (input: MerchantProfileFields) => {
		let updateSupportEmailResult: ApiResponse<UpdateSupportEmailResponse> | null = null;
		let updateUserResult: ApiResponse<User> | null = null;
		let atmDiscountResult: ApiResponse<Discount> | null = null;
		setIsActionLoading(true);
		// user entered new value
		if (input.atmDiscount && activeViewer.role === UserRole.OWNER) {
			// no old value, need to create one
			if (!oldAtmDiscount) {
				atmDiscountResult = await createDiscount({
					amount: typeof input.atmDiscount === 'string' ? parseFloat(input.atmDiscount) : input.atmDiscount,
					type: DiscountType.ATM,
					userId: activeViewer.id,
				});
			}

			// has old value, needs update
			if (oldAtmDiscount && input.atmDiscount !== oldAtmDiscount.amount) {
				atmDiscountResult = await updateDiscount({
					amount: typeof input.atmDiscount === 'string' ? parseFloat(input.atmDiscount) : input.atmDiscount,
					discountId: oldAtmDiscount.id,
				});
			}
		}

		if (
			(input.baseCurrency && activeViewer.baseCurrency !== input.baseCurrency.value) ||
			(input.atmPaymentsDefault !== activeViewer.atmPaymentsDefault && activeViewer.role === UserRole.OWNER)
		) {
			updateUserResult = await updateUser({
				baseCurrency: Currency[input.baseCurrency.value as keyof typeof Currency],
				loginType: activeViewer.loginType,
				atmPaymentsDefault: activeViewer.role === UserRole.OWNER ? input.atmPaymentsDefault : AtmPaymentsDefault.OFF,
				loginCode: input.loginCode !== '' ? input.loginCode : undefined,
			});
		}
		setIsActionLoading(false);
		if (input.supportEmail && activeViewer.supportEmail !== input.supportEmail) {
			updateSupportEmailResult = await updateSupportEmail({
				supportEmail: input.supportEmail,
			});
		}

		if (updateUserResult && updateUserResult.payload && updateUserResult.success) {
			toast.success('Your profile has been successfully updated');

			return;
		}

		if (updateSupportEmailResult && updateSupportEmailResult.success) {
			toast.success('Your profile has been successfully updated');

			return;
		}

		if (atmDiscountResult && atmDiscountResult.success) {
			toast.success('Your profile has been successfully updated');

			return;
		}

		if (updateUserResult && updateUserResult.error) {
			const serverValidationErrors = getServerValidationErrors<User>(updateUserResult);
			setErrorMessage(serverValidationErrors.errorMessage);

			return;
		}

		if (updateSupportEmailResult && updateSupportEmailResult.error) {
			const serverValidationErrors = getServerValidationErrors<UpdateSupportEmailResponse>(updateSupportEmailResult);
			setErrorMessage(serverValidationErrors.errorMessage);

			return;
		}

		if (atmDiscountResult && atmDiscountResult.error) {
			const serverValidationErrors = getServerValidationErrors<Discount>(atmDiscountResult);
			setErrorMessage(serverValidationErrors.errorMessage);

			return;
		}
	};

	return (
		<>
			<TitleWrapper>
				<H2>Merchant profile</H2>
			</TitleWrapper>
			{!activeViewer || !verifications || (hasDiscountPermissions && !discountList) ? (
				<Loader />
			) : (
				<PanelNew>
					<FormProvider {...methods}>
						{renderModal()}
						<ReactHookForm onSubmit={methods.handleSubmit(handleUserUpdate)} autoComplete="off">
							<PanelNew.Section first white panelType={PanelSectionType.FORM} separateWithBorder>
								<HookFormSection>
									<WithPermission permissions={[Scopes.USERS, Scopes.UPDATE_SUPPORT_EMAIL]}>
										<HookFormColumn>
											<HookFormSeparator>Support email</HookFormSeparator>
											<HookBaseField name="supportEmail" label="Support email" />
											<Notification noIcon>
												Contact information here will be displayed on your Dagpay invoices and can improve conversion
												rates and customer satisfaction
											</Notification>
										</HookFormColumn>
									</WithPermission>
									<HookFormColumn>
										<HookFormSeparator>Currency pair</HookFormSeparator>
										<HookSelectField
											label="Local currency"
											name="baseCurrency"
											options={getBaseCurrencyOptions({ current: activeViewer.baseCurrency })}
										/>
										<Notification noIcon>
											Value of your dagcoins (base currency) in your preferred local currency
										</Notification>
									</HookFormColumn>
								</HookFormSection>
								{activeViewer.hasActiveAtm && (
									<>
										<HookFormSeparator>Dagloyalty</HookFormSeparator>
										<HookFormSection>
											<HookFormColumn>
												{activeViewer.role === UserRole.OWNER && (
													<HookBaseField
														name="atmDiscount"
														label="Discount"
														addonNode="%"
														addonSize={48}
														disabled={!discountList}
													/>
												)}
												{activeViewer.role !== UserRole.OWNER && (
													<AtmPaymentsNotification color={activeViewer.atmPaymentsDefault}>
														Discount is set to<span> {oldAtmDiscount ? oldAtmDiscount.amount : '0'}% </span>
													</AtmPaymentsNotification>
												)}
												{activeViewer.role !== UserRole.OWNER && (
													<>
														<Notification noIcon>
															Discount setting can only be changed by the business owner
														</Notification>
														<ExplanationItem>
															Discount set here will affect your businesses connected with a Dagcoin ATM or V-ATM and
															applies to invoices generated via POS checkout in web and via POS app on Android
														</ExplanationItem>
													</>
												)}
												{activeViewer.role === UserRole.OWNER && (
													<Notification noIcon>
														Discount set here will affect your businesses connected with a Dagcoin ATM or V-ATM and
														applies to invoices generated via POS checkout in web and via POS app on Android
													</Notification>
												)}
											</HookFormColumn>
											{activeViewer.role === UserRole.OWNER ? (
												<HookFormColumn>
													<HookVerticalRadioGroup
														label="Payments"
														name="atmPaymentsDefault"
														selectOptions={atmDefaultChoices}
													/>
													<Notification noIcon>
														<strong>Enabled</strong> - Payments can be made only with coins purchased from Dagcoin ATM
														or V-ATM
													</Notification>
													<Notification noIcon>
														<strong>Ask</strong> - Checkout prompts you whether the generated invoice should accept only
														coins puchased from a Dagcoin ATM or V-ATM, or all dagcoins
													</Notification>
													<Notification noIcon>
														<strong>Off</strong> - Payments can be made with all dagcoins
													</Notification>
												</HookFormColumn>
											) : (
												<HookFormColumn>
													<AtmPaymentsNotification color={activeViewer.atmPaymentsDefault}>
														Dagloyalty payments are set to
														{activeViewer.atmPaymentsDefault === AtmPaymentsDefault.ENABLED && <span> Enabled </span>}
														{activeViewer.atmPaymentsDefault === AtmPaymentsDefault.ASK && <span> Ask </span>}
														{activeViewer.atmPaymentsDefault === AtmPaymentsDefault.OFF && <span> Off </span>}
													</AtmPaymentsNotification>
													<Notification noIcon>
														Dagloyalty payments settings can be changed only by the Dagpay organization account owner
													</Notification>
													<PointExplanationList>
														<ExplanationItem>
															<strong>Enabled</strong> - Payments can be made only with coins purchased from Dagcoin ATM
															or V-ATM
														</ExplanationItem>
														<ExplanationItem>
															<strong>Ask</strong> - Checkout prompts you whether the generated invoice should accept
															only coins puchased from a Dagcoin ATM or V-ATM, or all dagcoins
														</ExplanationItem>
														<ExplanationItem>
															<strong>Off</strong> - Payments can be made with all dagcoins
														</ExplanationItem>
													</PointExplanationList>
												</HookFormColumn>
											)}
										</HookFormSection>
									</>
								)}
								{errorMessage && <View.Error>{errorMessage}</View.Error>}
							</PanelNew.Section>
							<PanelNew.Section gray last panelType={PanelSectionType.BUTTON}>
								{(methods.getValues().atmPaymentsDefault !== activeViewer.atmPaymentsDefault ||
									methods.getValues().baseCurrency.value !== activeViewer.baseCurrency) &&
								activeViewer.loginType === UserLoginType.AUTHENTICATOR ? (
									<Button
										isDisabled={!methods.formState.isDirty || isActionLoading}
										disabled={!methods.formState.isDirty || isActionLoading}
										alignedRight
										type={ButtonTypes.BUTTON}
										onClick={() => {
											setIsModalOpen(true);
											setModalType(MerchantProfileViewModalType.LOGIN_CODE);
										}}
									>
										{!isActionLoading ? 'Save changes' : <Loader size={20} color={Color.WHITE} width={120.5} />}
									</Button>
								) : (
									<Button
										isDisabled={!methods.formState.isDirty || isActionLoading || methods.formState.isSubmitting}
										disabled={!methods.formState.isDirty || isActionLoading || methods.formState.isSubmitting}
										alignedRight
										type={ButtonTypes.SUBMIT}
									>
										{!isActionLoading ? 'Save changes' : <Loader size={20} color={Color.WHITE} width={120.5} />}
									</Button>
								)}
							</PanelNew.Section>
						</ReactHookForm>
					</FormProvider>
				</PanelNew>
			)}
		</>
	);
}

export default withRouter(MerchantProfileView);
