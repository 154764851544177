import * as React from 'react';
import styled from 'styled-components/macro';

import { ColumnedFlex, Strong } from '../../gfx/globals';
import View from '../view/View';

const ErrorWrap = styled.div`
	display: flex;
	flex-direction: column;
	max-width: 60%;
	margin: auto;
	justify-content: space-between;
	align-items: center;
	padding-top: 3%;
`;

const Paragraph = styled.p`
	font-family: Secondary;
`;

const ErrorView: React.SFC = () => (
	<ErrorWrap>
		<View.Error>
			<h1>Something went wrong</h1>
		</View.Error>
		<ColumnedFlex isCentered>
			<Strong>The error has been logged and our engineers are looking into it, sorry about this.</Strong>
			<Paragraph>
				{/*eslint-disable-next-line */}
				<a className="btn btn--link" href="javascript: window.location.reload()">
					Please reload the page
				</a>
			</Paragraph>
			<Paragraph>
				If problem still exists, please feel free to contact our support at{' '}
				<a href="mailto:support@dagpay.io">support@dagpay.io</a>
			</Paragraph>
		</ColumnedFlex>
	</ErrorWrap>
);

export default ErrorView;
