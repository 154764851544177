import { ErrorMessage } from '@hookform/error-message';
import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { components, OptionProps, SingleValueProps } from 'react-select';

import { Color } from '../../../gfx/constants';
import { FieldOptionProps } from '../../field/Field';

import { Border, Error, FieldWrapper, Label } from '../HookFieldsStyle';
import { StyledSelect, SingleValueWrapper, OptionWrapper } from './HookSelectFieldStyle';

interface Props {
	name: string;
	options: FieldOptionProps[];
	label?: string;
	isMulti?: boolean;
	tabIndex?: number;
	isSearchable?: boolean;
	selectedColor?: Color;
	focusedColor?: Color;
	closeMenuOnSelect?: boolean;
	noErrorElement?: boolean;
}

function HookSelectField(props: Props) {
	const {
		name,
		label,
		options,
		isMulti,
		tabIndex,
		isSearchable,
		selectedColor = Color.BLUE_5,
		focusedColor = Color.GRAY_5,
		closeMenuOnSelect,
	} = props;
	const { register, control } = useFormContext();

	const sortedOptions = defaultToBeginning(options);
	const defaultIndex = getInitialSelectedIndex(sortedOptions);
	const selectedDefaultValue = defaultIndex !== null ? sortedOptions[defaultIndex] : null;

	return (
		<FieldWrapper>
			<Label>{label}</Label>
			<Border isMulti={isMulti}>
				<Controller
					name={name}
					control={control}
					render={({ onBlur, onChange }: { onBlur: any; onChange: any }) => (
						<StyledSelect
							name={name}
							ref={register}
							options={sortedOptions}
							classNamePrefix={'Select'}
							components={{ SingleValue, Option }}
							onBlur={onBlur}
							onChange={onChange}
							isSearchable={!!isSearchable}
							isMulti={isMulti}
							tabIndex={tabIndex}
							defaultValue={selectedDefaultValue}
							selectedColor={selectedColor}
							focusedColor={focusedColor}
							closeMenuOnSelect={closeMenuOnSelect}
							// menuIsOpen={true}
						/>
					)}
				/>
			</Border>
			{!props.noErrorElement && (
				<Error>
					<ErrorMessage name={name} />
				</Error>
			)}
		</FieldWrapper>
	);
}

export function getInitialSelectedIndex(options: FieldOptionProps[]) {
	// can be -1, if not found
	const index = options.findIndex((option) => option.isInitialSelected);
	return index >= 0 ? index : null;
}

export function defaultToBeginning(options: FieldOptionProps[]) {
	const filterResult = options.filter((option) => option.isDefault);

	if (filterResult.length === 0) {
		return options;
	}

	const defaultIndex = options.indexOf(filterResult[0]);
	const optionsCopy = options.slice();
	optionsCopy.splice(defaultIndex, 1);
	optionsCopy.unshift(filterResult[0]);

	return optionsCopy;
}

// can pass default props to css, eg isDefault
export function Option(props: OptionProps<FieldOptionProps>) {
	if (props.data) {
		const data = props.data as FieldOptionProps;

		return (
			<OptionWrapper toolTipMessage={data.toolTipMessage} isDefault={!!data.isDefault}>
				<components.Option {...props} />
			</OptionWrapper>
		);
	}

	return <components.Option {...props} />;
}

export function SingleValue(props: SingleValueProps<FieldOptionProps>) {
	const { children, ...rest } = props;

	return (
		<SingleValueWrapper isDefault={!!rest.data.isDefault}>
			<components.SingleValue {...props}>{props.children}</components.SingleValue>
		</SingleValueWrapper>
	);
}

export default HookSelectField;
