import { FieldOptionProps } from '../components/field/Field';
import { Scopes, UserLoginType } from '../constants';
import { hasPermission } from './has-permission';

interface Props {
	current: UserLoginType;
}

export default function getAuthenticationOptions(props: Props): FieldOptionProps[] {
	const options = [
		{ label: 'Email verification (2FA)', value: UserLoginType.EMAIL },
		{ label: 'Password only', value: UserLoginType.PASSWORD },
	];

	if (hasPermission([Scopes.AUTHENTICATION, Scopes.ENABLE_AUTHENTICATOR])) {
		options.push({ label: 'Google Authenticator (2FA)', value: UserLoginType.AUTHENTICATOR });
	}

	return options.map((option) => (option.value === props.current ? { ...option, isInitialSelected: true } : option));
}

export function getDefaultAuthenticationOptions(props: Props): FieldOptionProps {
	const options = [
		{ label: 'Email verification (2FA)', value: UserLoginType.EMAIL },
		{ label: 'Password only', value: UserLoginType.PASSWORD },
	];

	if (hasPermission([Scopes.AUTHENTICATION, Scopes.ENABLE_AUTHENTICATOR])) {
		options.push({ label: 'Google Authenticator (2FA)', value: UserLoginType.AUTHENTICATOR });
	}
	const returnable = options.find((option) => option.value === props.current);
	return returnable ? returnable : { label: 'Password only', value: UserLoginType.PASSWORD };
}
