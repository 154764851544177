import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { withRouter } from 'react-router';
import ReactSVG from 'react-svg';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { User } from '../../../api/userApi';
import Loader from '../../../components/loader/Loader';
import Logo from '../../../components/logo/Logo';
import HookPasswordField from '../../../components/react-hook-form/HookPasswordField';
import ReactHookForm, { RowPlacement, TitleInfoPosition } from '../../../components/react-hook-form/ReactHookFormStyle';
import { Validations } from '../../../constants';
import { getServerValidationErrors } from '../../../services/input-error-utils';
import { useStoreActions } from '../../../services/store';
import { RouteProps } from '../../../typings';
import Login from '../login/LoginStyle';

interface ResetPasswordFields {
	password: string;
	confirmNewPassword: string;
}

interface RouteParams {
	token: string;
}

function ResetPasswordView({ match, history }: RouteProps<RouteParams>) {
	const [errorMessage, setErrorMessage] = useState('');
	const [isActionLoading, setIsActionLoading] = useState(false);
	const [isTokenValid, setIsTokenValid] = useState<boolean | null>(null);

	const { handleResetPassword, isResetPasswordTokenValid } = useStoreActions((actions) => ({
		...actions.viewer,
	}));

	const schema = Yup.object().shape({
		password: Validations.PASSWORD_NOT_SECURE,
		confirmNewPassword: Validations.PASSWORDS_DO_NOT_MATCH,
	});

	const defaultValues = {
		password: '',
		confirmNewPassword: '',
	};

	const methods = useForm<ResetPasswordFields>({
		resolver: yupResolver(schema),
		defaultValues: defaultValues,
		shouldFocusError: false,
		mode: 'onChange',
	});

	useEffect(() => {
		const checkIsTokenValid = async () => {
			const response = await isResetPasswordTokenValid({ token: match.params.token });

			setIsTokenValid(response.payload ? response.payload.isTokenValid : false);
		};
		checkIsTokenValid();
	}, [isResetPasswordTokenValid, match.params.token]);

	if (!match.params.token) {
		return null;
	}

	if (isActionLoading || isTokenValid === null) {
		return <Loader />;
	}

	const handlePasswordReset = async (input: ResetPasswordFields) => {
		setIsActionLoading(true);
		const response = await handleResetPassword({ password: input.password, token: match.params.token });
		setIsActionLoading(false);

		if (response.success) {
			history.push('/');
			toast.success('Your password has been reset successfully!');

			return;
		}

		if (response.error) {
			const serverValidationErrors = getServerValidationErrors<User>(response);
			setErrorMessage(serverValidationErrors.errorMessage);

			return;
		}
	};

	const handleDirectToRecovery = () => {
		history.push('/password-recovery');

		return;
	};

	return (
		<Login>
			<Login.Left>
				<FormProvider {...methods}>
					<ReactHookForm onSubmit={methods.handleSubmit(handlePasswordReset)}>
						<Login.LogoWrapper>
							<Logo hasWhiteBackground />
						</Login.LogoWrapper>
						<ReactHookForm.Title infoPosition={TitleInfoPosition.BOTTOM}>
							Reset password
							<ReactHookForm.Info>Please set your new password</ReactHookForm.Info>
						</ReactHookForm.Title>
						<ReactHookForm.Fields direction="column">
							<HookPasswordField label="New password" name="password" showPasswordStrength />
							<HookPasswordField label="Confirm new password" name="confirmNewPassword" />
						</ReactHookForm.Fields>
						<ReactHookForm.Row placement={RowPlacement.RIGHT}>
							{isTokenValid ? (
								<ReactHookForm.Button type="submit">
									Reset password
									<ReactSVG src="/files/svg/icons/HookButtonArrow.svg" />
								</ReactHookForm.Button>
							) : (
								<ReactHookForm.Button type="button" onClick={handleDirectToRecovery}>
									Generate new link
									<ReactSVG src="/files/svg/icons/HookButtonArrow.svg" />
								</ReactHookForm.Button>
							)}
						</ReactHookForm.Row>
						<ReactHookForm.Error>
							{isTokenValid ? errorMessage : 'Your password reset link has expired'}
						</ReactHookForm.Error>
					</ReactHookForm>
				</FormProvider>
			</Login.Left>
			<Login.Right>
				<Login.Image />
				<Login.TextWrapper>
					<Login.Welcome>Recover password</Login.Welcome>
					<Login.Text>Don't worry, happens to the best of us.</Login.Text>
				</Login.TextWrapper>
			</Login.Right>
		</Login>
	);
}

export default withRouter(ResetPasswordView);
