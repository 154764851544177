import React from 'react';
import { useFormContext } from 'react-hook-form';
import { withRouter } from 'react-router-dom';
import ReactSVG from 'react-svg';
import HookRadioField from '../../../components/react-hook-form/HookRadioField';
import ReactHookForm, { RowPlacement } from '../../../components/react-hook-form/ReactHookFormStyle';
import { BusinessType, RoutesUrls } from '../../../constants';
import { RouteProps } from '../../../typings';
import { SignUpFields } from './SignUpView';
import { AccountTypeHeading } from './SignUpViewStyle';

interface Props extends RouteProps {
	errorElement: JSX.Element | null;
	setErrorElement(element: JSX.Element | null): void;
}

const AccountTypeStep = (props: Props) => {
	const { history, errorElement } = props;
	const { watch, control, getValues } = useFormContext<SignUpFields>();

	const checkValidness = async () => {
		const valid: boolean = true;

		if (valid) {
			history.push(RoutesUrls.SIGN_UP_FILL_DETAILS);
		}
	};
	const accountWatch = watch('businessType', BusinessType.FREELANCE);
	return (
		<>
			<AccountTypeHeading>Choose account type</AccountTypeHeading>
			<ReactHookForm.Info>Choose account type that suits your business</ReactHookForm.Info>
			<HookRadioField
				label="Unregistered business(freelance)"
				name="businessType"
				control={control}
				marginBottom={90}
				options={[
					{
						label: 'Unregistered business (freelance)',
						value: BusinessType.FREELANCE,
						description:
							'For unregistered businesses (e.g. sole proprietors) with small transaction volumes - up to 1 000€ per month',
						isActive: getValues().businessType === BusinessType.FREELANCE,
					},
					{
						label: 'Company (legal entity)',
						value: BusinessType.BUSINESS,
						description: 'For registered companies and corporate businesses with medium to large transaction volumes',
						isActive: getValues().businessType === BusinessType.BUSINESS,
					},
				]}
			/>
			<ReactHookForm.Row placement={RowPlacement.SPACE_BETWEEN_COLUMN}>
				<ReactHookForm.Info>
					{`Already have an account? `}
					<ReactHookForm.Link to={RoutesUrls.LOGIN}>Log in</ReactHookForm.Link>
				</ReactHookForm.Info>
				<ReactHookForm.Button type="submit" onClick={async () => checkValidness()} disabled={!accountWatch}>
					Next
					<ReactSVG src="/files/svg/icons/HookButtonArrow.svg" />
				</ReactHookForm.Button>
			</ReactHookForm.Row>
			<ReactHookForm.Error>{errorElement}</ReactHookForm.Error>
		</>
	);
};

export default withRouter(AccountTypeStep);
