import { Action, action, thunk, Thunk, thunkOn, ThunkOn } from 'easy-peasy';

import {
	createEnvironmentApi,
	CreateEnvironmentRequest,
	deleteEnvironmentApi,
	DeleteEnvironmentRequest,
	Environment,
	getEnvironmentApi,
	GetEnvironmentRequest,
	getEnvironmentsApi,
	updateEnvironmentApi,
	UpdateEnvironmentRequest,
} from '../api/environmentApi';
import handleFetch from '../services/handle-fetch';
import { ApiArrayPayload, ApiGetRequest, Response, ResponseArray } from '../typings';

import { StoreModel } from '.';

export interface EnvironmentModel {
	// state
	environments: ApiArrayPayload<Environment> | null;

	// actions
	saveEnvironments: Action<EnvironmentModel, ApiArrayPayload<Environment> | null>;
	clearEnvironments: Action<EnvironmentModel>;

	// thunks
	getEnvironments: Thunk<EnvironmentModel, ApiGetRequest, {}, StoreModel, ResponseArray<Environment>>;
	getEnvironment: Thunk<EnvironmentModel, GetEnvironmentRequest, {}, StoreModel, Response<Environment>>;
	createEnvironment: Thunk<EnvironmentModel, CreateEnvironmentRequest, {}, StoreModel, Response<Environment>>;
	updateEnvironment: Thunk<EnvironmentModel, UpdateEnvironmentRequest, {}, StoreModel, Response<Environment>>;
	deleteEnvironment: Thunk<EnvironmentModel, DeleteEnvironmentRequest, {}, StoreModel, Response<Environment>>;

	// listeners
	onCreateEnvironment: ThunkOn<EnvironmentModel, {}, StoreModel>;
	onUpdateEnvironment: ThunkOn<EnvironmentModel, {}, StoreModel>;
	onDeleteEnvironment: ThunkOn<EnvironmentModel, {}, StoreModel>;
}

const environment: EnvironmentModel = {
	// state
	environments: null,

	// actions
	saveEnvironments: action((state, payload) => {
		state.environments = payload;
	}),
	clearEnvironments: action((state) => {
		state.environments = null;
	}),

	// thunks
	getEnvironments: thunk(async (actions, payload) => {
		actions.clearEnvironments();
		const result = await handleFetch<ApiArrayPayload<Environment>>(getEnvironmentsApi(payload));

		actions.saveEnvironments(result.payload);

		return result;
	}),
	getEnvironment: thunk(async (actions, payload) => {
		return await handleFetch<Environment>(getEnvironmentApi(payload));
	}),
	createEnvironment: thunk(async (actions, payload) => {
		return await handleFetch<Environment>(createEnvironmentApi(payload));
	}),
	updateEnvironment: thunk(async (actions, payload) => {
		return await handleFetch<Environment>(updateEnvironmentApi(payload));
	}),
	deleteEnvironment: thunk(async (actions, payload) => {
		return await handleFetch<Environment>(deleteEnvironmentApi(payload));
	}),

	// listeners
	onCreateEnvironment: thunkOn(
		(actions, storeActions) => actions.createEnvironment,
		(actions, payload) => {
			actions.getEnvironments({});
		},
	),
	onUpdateEnvironment: thunkOn(
		(actions, storeActions) => actions.updateEnvironment,
		(actions, payload) => {
			actions.getEnvironments({});
		},
	),
	onDeleteEnvironment: thunkOn(
		(actions, storeActions) => actions.deleteEnvironment,
		(actions, payload) => {
			actions.getEnvironments({});
		},
	),
};

export default environment;
