import { FormikFieldState } from 'formik-fields';
import * as React from 'react';
import { ReactTagsProps, WithContext as ReactTags } from 'react-tag-input';
import styled from 'styled-components/macro';

import { StyledInputMixin } from '../field/FieldStyle';

interface Props extends ReactTagsProps {
	formikField: FormikFieldState<string>;
}

const StyledReactTags = styled.span`
	width: 100%;

	.ReactTags__tags {
		width: 100%;
	}

	input {
		${StyledInputMixin};
		width: 100%;
	}

	.ReactTags__tag {
		display: none;
	}

	.ReactTags__remove {
		padding-left: 8px;
		cursor: pointer;
	}
`;

export default function ReactTagInput(props: Props) {
	const { formikField } = props;

	const handleTagInputChange = (value: string) => {
		props.formikField.setValue(value);
		props.formikField.setIsTouched(true);
	};

	return (
		<StyledReactTags>
			<ReactTags
				delimiters={[188, 32, 13]}
				allowDeleteFromEmptyInput={false}
				name={formikField.name}
				handleInputChange={handleTagInputChange}
				{...props}
			/>
		</StyledReactTags>
	);
}
