import { Action, action, thunk, Thunk, thunkOn, ThunkOn } from 'easy-peasy';

import {
	createDiscountApi,
	CreateDiscountRequest,
	Discount,
	getDiscountListApi,
	updateDiscountApi,
	UpdateDiscountRequest,
} from '../api/discountApi';
import handleFetch from '../services/handle-fetch';
import { Response } from '../typings';

import { StoreModel } from '.';

export interface DiscountModel {
	// state
	discountList: Discount[] | null;

	// actions
	saveDiscountList: Action<DiscountModel, Discount[] | null>;
	clearDiscountList: Action<DiscountModel>;

	// thunks
	getDiscountList: Thunk<DiscountModel, {}, {}, StoreModel, Response<Discount[]>>;
	createDiscount: Thunk<DiscountModel, CreateDiscountRequest, {}, StoreModel, Response<Discount>>;
	updateDiscount: Thunk<DiscountModel, UpdateDiscountRequest, {}, StoreModel, Response<Discount>>;

	// listeners
	onCreateDiscount: ThunkOn<DiscountModel, {}, StoreModel>;
	onUpdateDiscount: ThunkOn<DiscountModel, {}, StoreModel>;
}

const discount: DiscountModel = {
	// state
	discountList: null,

	// actions
	saveDiscountList: action((state, payload) => {
		state.discountList = payload;
	}),
	clearDiscountList: action((state) => {
		state.discountList = null;
	}),

	// thunks
	getDiscountList: thunk(async (actions) => {
		actions.clearDiscountList();

		const result = await handleFetch<Discount[]>(getDiscountListApi());

		actions.saveDiscountList(result.payload);

		return result;
	}),
	createDiscount: thunk(async (actions, payload) => {
		return await handleFetch<Discount>(createDiscountApi(payload));
	}),
	updateDiscount: thunk(async (actions, payload) => {
		return await handleFetch<Discount>(updateDiscountApi(payload));
	}),

	onCreateDiscount: thunkOn(
		(actions, storeActions) => actions.createDiscount,
		(actions, payload) => {
			actions.getDiscountList({});
		},
	),
	onUpdateDiscount: thunkOn(
		(actions, storeActions) => actions.updateDiscount,
		(actions, payload) => {
			actions.getDiscountList({});
		},
	),
};

export default discount;
