import React from 'react';
import { Link } from 'react-router-dom';
import styled, { css, StyledComponentProps } from 'styled-components/macro';

import { Color, Gutter } from '../../gfx/constants';
import { SectionTitleTextMixin, SubmenuTextMixin, SubtextDescriptionTextMixin } from '../../gfx/globals';
import { media } from '../../services/media';
import { AccountStatus } from '../../views/private/settings/SettingsStyle';

const fieldColumnMargin = '50px';

export enum TitleInfoPosition {
	LEFT = 'LEFT',
	RIGHT = 'RIGHT',
	TOP = 'TOP',
	BOTTOM = 'BOTTOM',
}

export enum RowPlacement {
	LEFT = 'LEFT',
	CENTER = 'CENTER',
	RIGHT = 'RIGHT',
	SPACE_BETWEEN = 'SPACE_BETWEEN',
	SPACE_BETWEEN_COLUMN = 'SPACE_BETWEEN_COLUMN',
}
interface TitleProps {
	infoPosition?: TitleInfoPosition;
}

interface FieldsProps {
	direction: string;
}

interface RowProps {
	placement: RowPlacement;
}

interface ColumnProps {
	withMargin?: boolean;
	noWidth?: boolean;
	flex?: number;
	marginBottom?: boolean;
}
interface SectionProps {
	noWrap?: boolean;
	margin?: string;
}
const HookForm = styled.form`
	display: flex;
	width: 100%;
	flex-direction: column;
	position: relative;
`;

const ReactHookForm = (props: StyledComponentProps<'form', {}, {}, never>) => (
	<HookForm {...props} autoComplete="off" />
);

export const WhiteHookForm = styled.form`
	display: flex;
	position: relative;
	flex-direction: column;
	width: 100%;
	box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12);
	border: 1px solid rgba(220, 221, 224, 0.5);
	border-radius: 4px;
	background-color: #ffffff;
	padding: 50px;
	margin: 1% 0%;

	${media.maxTable`
		padding: 20px;
	`}
`;

export const HookFormColumn = styled.div<ColumnProps>`
	display: flex;
	flex-direction: column;
	flex: ${({ flex }) => flex && flex};
	margin-right: ${({ withMargin }) => withMargin && ''};
	width: ${({ noWidth }) => (noWidth ? '' : '100%')};
	font-family: Secondary;

	${media.maxDesktop`
		margin-bottom: ${({ marginBottom }: ColumnProps) => marginBottom && '30px'};
	`}

	${media.fromDesktop`
		margin-right: ${({ withMargin }: ColumnProps) => withMargin && '50px'};
		width: ${({ withMargin }: ColumnProps) => withMargin && `calc(50% - ${fieldColumnMargin} / 2)`};
	`}
`;

export const HookFormSection = styled.div<SectionProps>`
	display: flex;
	flex-wrap: wrap;

	${(props) =>
		props.noWrap &&
		css`
			flex-wrap: nowrap;
		`};
	margin: ${(props) => (props.margin ? props.margin : 0)};

	${HookFormColumn} {
		margin-right: 0;

		${media.fromDesktop`
			width: calc(50% - ${fieldColumnMargin} / 2);
		`}

		+ ${HookFormColumn} {
			${media.fromDesktop`
				margin-left: 50px;
			`}
		}
	}
`;
export const HookFormCurrencySection = styled.div<SectionProps>`
	display: flex;
	flex-wrap: wrap;

	${(props) =>
		props.noWrap &&
		css`
			flex-wrap: nowrap;
		`};
	margin: ${(props) => (props.margin ? props.margin : 0)};

	${HookFormColumn} {
		margin-right: 0;

		${media.fromDesktop`
			width: calc(50% - ${fieldColumnMargin} / 2);
		`}
		${media.maxSize1400`
			width: 100%;
			margin-left: 0 !important;
		`}
	}
	${media.maxSize1400`
			flex-wrap: wrap;
	`}
`;
export const SectionTitle = styled.div`
	display: flex;
	align-items: center;
	position: relative;
	font-size: 16px;
	line-height: 22px;
	font-family: primaryMedium;
	color: ${Color.BLUE_7};
	padding-left: calc(36px + 10px);
	margin-bottom: calc(${Gutter.MEDIUM} + ${Gutter.MEDIUM});

	&:after {
		content: '';
		position: absolute;
		top: 100%;
		right: 0;
		margin-top: ${Gutter.MEDIUM};
		width: 100%;
		height: 1px;
		background: ${Color.GREY_BORDER};
	}

	svg {
		position: absolute;
		left: 0;
		top: 50%;
		transform: translateY(-50%);
	}
`;

export const HookFormSectionTitle = styled.h2`
	${SectionTitleTextMixin}
	margin: 0;
`;

ReactHookForm.Info = styled.div`
	font-family: Primary;
	font-size: 15px;
	letter-spacing: 0px;
	line-height: 18px;

	/* --- media --- */

	${media.maxSize481`
		font-size: 13px;
		letter-spacing: 0px;
		line-height: 15px;
	`}
`;

ReactHookForm.Title = styled.div<TitleProps>`
	margin-top: 90px;
	margin-bottom: 42px;
	font-family: PrimaryMedium;
	font-size: 26px;
	letter-spacing: 0px;
	line-height: 30px;

	${(props) =>
		props.infoPosition === TitleInfoPosition.RIGHT &&
		css`
			display: flex;
			flex: 1 0 auto;
			align-items: flex-end;
			justify-content: space-between;
		`}

	${(props) =>
		props.infoPosition === TitleInfoPosition.LEFT &&
		css`
			flex-direction: row-reverse;
		`}

	${(props) =>
		props.infoPosition === TitleInfoPosition.TOP &&
		css`
			flex-direction: column-reverse;
		`}

	${(props) =>
		props.infoPosition === TitleInfoPosition.BOTTOM &&
		css`
			flex-direction: column;
			justify-content: center;
			align-items: flex-start;
			margin-bottom: 38px;

			${ReactHookForm.Info} {
				margin-top: 18px;
			}
		`}

		span {
		color: #1cd35a;
	}

	/* --- media --- */

	${media.maxSize481`
			margin-top: 70px;
			margin-bottom: 22px;
			font-size: 20px;
			line-height: 25px;

			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: flex-start;

			${ReactHookForm.Info}{
				margin-top: 7px;
			}
	`}
`;

ReactHookForm.FieldsTitle = styled.div`
	display: flex;
	flex: 1 0 auto;
	justify-content: space-between;
	align-items: flex-end;
	margin: 0 0 38px 0;
	font-family: PrimaryMedium;
	font-size: 16px;
	line-height: 19px;
	letter-spacing: 0px;

	/* --- media --- */

	${media.maxSize800`
		flex-direction: column;
		align-items: flex-start;

		${ReactHookForm.Info}{
				margin-top: 7px;
			}
	`}
`;

ReactHookForm.Link = styled(Link)`
	font-family: Primary;
	font-size: 15px;
	letter-spacing: 0px;
	line-height: 18px;
	color: #1592e6;

	/* --- media --- */

	${media.maxSize481`
		font-size: 13px;
		letter-spacing: 0px;
		line-height: 15px;
	`}
`;

ReactHookForm.ApiLink = styled.div`
	text-decoration: underline;
	font-family: Primary;
	font-size: 15px;
	letter-spacing: 0px;
	line-height: 18px;
	color: #1592e6;
	cursor: pointer;
`;

ReactHookForm.Fields = styled.div<FieldsProps>`
	${(props) =>
		props.direction === 'row' &&
		css`
			display: grid;
			grid-template-columns: 1fr 1fr;
			column-gap: 17px;
			justify-content: space-between;
			${media.maxMobile`
				display: flex;
				flex: 1 0 auto;
				flex-direction: column;
			`}
		`}

	${(props) =>
		props.direction === 'column' &&
		css`
			display: flex;
			flex: 1 0 auto;
			flex-direction: column;
		`}
`;

ReactHookForm.Row = styled.div<RowProps>`
	display: flex;
	flex: 1 0 auto;
	position: relative;

	${(props) =>
		props.placement === RowPlacement.RIGHT &&
		css`
			justify-content: flex-end;
			padding-right: 10px;
		`}

	${(props) =>
		props.placement === RowPlacement.LEFT &&
		css`
			align-items: center;
		`}

	${(props) =>
		props.placement === RowPlacement.SPACE_BETWEEN &&
		css`
			justify-content: space-between;
			padding-right: 10px;
			align-items: center;
		`}

	${(props) =>
		props.placement === RowPlacement.SPACE_BETWEEN_COLUMN &&
		css`
			justify-content: space-between;
			padding-right: 10px;
			align-items: center;
			/* --- media --- */

			${media.maxSize481`
				flex-direction: column;
				justify-content: center;
			`}
		`}
`;

ReactHookForm.RowText = styled.div`
	display: flex;
	flex: 1 0 auto;
	align-items: center;
	font-family: Primary;
	font-size: 15px;
	letter-spacing: 0px;
	line-height: 29px;

	div {
		margin-left: 8px;
	}
`;

ReactHookForm.Error = styled.div<{ placement?: string }>`
	width: 100%;
	position: relative;
	margin-top: 43px;
	font-family: Secondary;
	font-size: 14px;
	letter-spacing: 0px;
	line-height: 23px;
	color: ${Color.RED_ERROR};
	text-align: right;

	${(props) =>
		props.placement === 'space-between' &&
		css`
			display: flex;
			justify-content: space-between;
		`}
`;

ReactHookForm.Button = styled.button`
	cursor: pointer;
	display: inline-block;
	position: relative;
	width: 212px;
	min-width: 212px;
	height: 77px;
	padding: 38px 0 18px 24px;

	background-color: #1592e6;
	transition: 0.3s;
	border-radius: 0 15px;
	border: none;
	opacity: 1;
	outline: none;
	text-decoration: none;
	text-align: left;
	color: white;
	font-family: Primary;
	font-size: 16px;
	letter-spacing: 0px;
	line-height: 22px;

	&::after {
		display: inline-block;
		position: absolute;
		width: 212px;
		min-width: 212px;
		height: 77px;
		left: 10px;
		bottom: -10px;
		z-index: -1;
		content: '';
		border: 1px dashed #1592e6;
		border-radius: 0 15px;
	}

	svg {
		position: absolute;
		top: 20px;
		right: 10px;
		width: 41px;
		height: 19px;
		transition: 0.3s;
		z-index: 1;
		color: white;
	}

	:hover,
	:focus {
		background-color: #54aeea;

		svg {
			top: 10px;
			right: 1px;
		}

		&::after {
			border-color: #54aeea;
		}
	}

	:disabled {
		cursor: initial;
		background-color: ${Color.GRAY_BUTTON};

		svg {
			top: 20px;
			right: 10px;
		}

		&::after {
			border-color: ${Color.GRAY_BUTTON};
		}
	}

	/* --- media --- */

	${media.maxTable`
		padding: 23px 0 18px 15px;
		width: 132px;
		min-width: 132px;
		height: 57px;
		font-size: 14px;
		letter-spacing: 0px;
		line-height: 18px;

		&::after {
		position: absolute;
		width: 132px;
		min-width: 132px;
		height: 57px;
		left: 7px;
		bottom: -7px;
		z-index: -1;
		content: '';
		border: 1px dashed #1592e6;
		border-radius: 0 15px;
	}

	svg {
		position: absolute;
		top: 15px;
		right: 14px;
		width: 21px;
		height: 15px;
		transition: 0.3s;
		z-index: 1;
		color: white;
	}

	:hover {
		background-color: #54aeea;

		svg {
			top: 10px;
			right: 5px;
		}
	}
	`}

	${media.maxSize481`
	margin-top: 25px;
	`}
`;

export const HookFormSeparator = styled.div`
	display: flex;
	align-items: center;
	position: relative;
	${SectionTitleTextMixin}
	margin-bottom: calc(${Gutter.MEDIUM} + ${Gutter.MEDIUM});

	&:after {
		content: '';
		position: absolute;
		top: 100%;
		right: 0;
		margin-top: ${Gutter.MEDIUM};
		width: 100%;
		height: 1px;
		background: ${Color.GREY_BORDER};
	}

	svg {
		position: absolute;
		left: 0;
		top: 50%;
		transform: translateY(-50%);
	}

	${AccountStatus} {
		${media.maxSize481`
			bottom: -23px;
		`}
	}
`;

export const HooFormCCRow = styled.div`
	width: 100%;
	display: flex;
	flex-wrap: wrap;
`;

export const AddonIcon = styled.div<{ disabled: boolean }>`
	position: relative;
	border-radius: 4px 0 0 4px;
	border-right: ${({ hasError }) => (hasError ? `1px solid ${Color.RED_2}` : `1px solid ${Color.GRAY_5}`)};
	background: ${(props) => (props.disabled ? Color.GRAY_2 : '#fff')};
	width: 48px;
	height: 100%;
	min-width: 48px;
	padding: 0 !important;

	div {
		position: absolute;
		height: 100%;
		width: 100%;

		div {
			height: 100%;
			width: 100%;
			position: absolute;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}

	svg {
		g,
		path {
			fill: ${({ hasError }) => hasError && `${Color.RED_2}`};
		}
	}
`;
export const DateNavigationHeader = styled.div`
	height: 64px;
	width: 100%;
	font-family: primary;
	font-size: 22px;
	line-height: 64px;
	color: ${Color.GRAY_9};
	background: ${Color.GRAY_2};
	border-radius: 4px 4px 0 0;
	text-align: left;
	padding-left: 24px;
	border-bottom: 1px solid ${Color.GRAY_5};
`;

export const DateNavigationWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	line-height: 20px;
	margin: 18px;
	${SubtextDescriptionTextMixin}
`;

export const DateLinkNavigationWrapper = styled.div`
	display: flex;
	label {
		position: relative;
		cursor: pointer;
		transform-origin: 0 0;
		margin: 5px;
		button {
			width: 14px;
			${SubmenuTextMixin}
			color: ${Color.GRAY_6};
		}
	}
`;

export const PreviousButtonLabel = styled.label`
	:before {
		content: '';
		width: 12px;
		right: 0px;
		top: 0;
		height: 12px;
		position: absolute;
		border-style: solid;
		border-width: 2px 0px 0px 2px;
		transform: rotate(-45deg);
		border-color: ${Color.GRAY_6};
	}
`;
export const NextButtonLabel = styled.label`
	:before {
		content: '';
		width: 12px;
		right: 0px;
		height: 12px;
		position: absolute;
		border-style: solid;
		border-width: 0px 2px 2px 0px;
		transform: rotate(-45deg);
		border-color: ${Color.GRAY_6};
	}
`;

ReactHookForm.Modal = styled.div``;

export default ReactHookForm;
