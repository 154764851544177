import React from 'react';
import { StyledReactTooltip } from './TooltipStyle';

export enum TooltipTheme {
	DEFAULT = 'default',
	SUCCESS = 'success',
	WARNING = 'warning',
	UNICORN = 'unicorn',
	INFO = 'info',
	ERROR = 'error',
}

export enum TooltipPlace {
	TOP = 'top',
	RIGHT = 'right',
	BOTTOM = 'bottom',
	LEFT = 'left',
}

export interface TooltipProps {
	content: JSX.Element | string;
	tipId: string;
	theme?: TooltipTheme;
	place?: TooltipPlace;
	delayHide?: number;
}

const Tooltip = (props: TooltipProps) => {
	const { content, tipId, theme = 'default', place = 'top', delayHide = 600 } = props;

	return (
		<StyledReactTooltip
			className={theme}
			id={tipId}
			effect="solid"
			place={place}
			delayHide={delayHide}
			getContent={() => content}
		/>
	);
};

export default Tooltip;
