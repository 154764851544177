import * as React from 'react';
import { Route, RouteProps } from 'react-router-dom';

import { Scopes } from '../../constants';
import { hasPermission } from '../../services/has-permission';
import ForbiddenView from '../forbidden/Forbidden';

interface OwnProps {
	permissions: Scopes[];
}

export interface Props extends RouteProps, OwnProps {}

const ProtectedRoute = ({ permissions, ...rest }: Props) => {
	if (hasPermission(permissions)) {
		return <Route {...rest} />;
	}

	return <ForbiddenView />;
};

export default ProtectedRoute;
