import WsRpcClient, { Json } from './ws-rpc-client';

export enum ExternalInvoiceSubscribeMethod {
	COINBASE_PAYMENT = 'COINBASE_PAYMENT',
}

export default class ExternalInvoiceRpcClient extends WsRpcClient {
	constructor(
		url: string,
		protected externalInvoiceSubscribeMethod: ExternalInvoiceSubscribeMethod,
		protected externalInvoiceId: string,
	) {
		super(url);
	}

	handleNewPayment(_invoice: Json) {}

	protected async onConnection() {
		// subscribe for invoice state change events
		await this.request('subscribe', { event: `${this.externalInvoiceSubscribeMethod}:${this.externalInvoiceId}` });
	}

	protected async handleMethod(method: string, _args: string[], params: Json) {
		// console.log({ method });
		switch (method) {
			case ExternalInvoiceSubscribeMethod.COINBASE_PAYMENT:
				if (!params.data) {
					throw new Error('Got external invoice new payment event but data is missing');
				}

				this.handleNewPayment(params.data as Json);
				break;

			default:
				console.log('got unknown rpc method');
			// throw new Error('got unknown rpc method');
		}
	}
}
