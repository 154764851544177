import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { FormikActions } from 'formik';
import { CFormikProps, FormikFields as FormikForm, FormikFieldsState } from 'formik-fields';
import { History, Location } from 'history';
import moment from 'moment';
import * as qs from 'query-string';
import React, { useEffect, useState } from 'react';
import Fade from 'react-reveal';
import { match } from 'react-router';
import { Link } from 'react-router-dom';
import ReactSVG from 'react-svg';
import * as Yup from 'yup';

import {
	ExtendedInvoiceTypes,
	Invoice,
	InvoicesOutputFormats,
	InvoiceState,
	InvoiceTypes,
} from '../../../../../api/invoiceApi';
import Button, { ButtonTypes } from '../../../../../components/button/Button';
import Field, { MultiSelectFilter } from '../../../../../components/field/Field';
import Form from '../../../../../components/form/Form';
import Loader from '../../../../../components/loader/Loader';
import WithPermission from '../../../../../components/with-permission/WithPermission';
import { FieldIconTypes, FieldTypes, InvitedUserStatus, RoutesUrls, Scopes, UserRole } from '../../../../../constants';
import { H1, NotificationRowWrapper, NotificationText, TitleWrapper } from '../../../../../gfx/globals';
import { downloadFile } from '../../../../../services/download-file';
import downloadXlsxFile from '../../../../../services/download-xlsx-file';
import { useStoreActions, useStoreState } from '../../../../../services/store';
import {
	ButtonGroup,
	CalendarGroup,
	DropdownGroup,
	ExportButtonWrapper,
	ExportSecondaryButtons,
	FilterGrid,
	FilterRow,
	SearchField,
	TransactionHeading,
} from '../TransactionViewStyle';
dayjs.extend(customParseFormat);

interface Props {
	location: Location<any>;
	setIsFilterSearchEnabled: React.Dispatch<React.SetStateAction<boolean>>;
	history: History<any>;
	match: match<{}>;
	setPage: React.Dispatch<React.SetStateAction<number>>;
	transactions: Invoice[];
	isFilterSearchEnabled: boolean;
	isExportOptionsOpened: boolean;
}

interface TransactionsFilterFields {
	startDate: Date | null;
	endDate: Date | null;
	type: MultiSelectFilter[];
	keyword: string;
	state: MultiSelectFilter[];
	users: MultiSelectFilter[];
}

interface GetMultiSelectInitialValuesProps {
	initialNameList: string[];
	queryString: string | string[] | null;
	initialValueArray?: (string | null)[][];
}

interface TransactionExportArgs {
	formatXlsx?: boolean;
}

const TransactionStatusNames: Pick<
	TransactionStatusKeys,
	'ALL' | 'PAID' | 'PENDING' | 'EXPIRED' | 'CANCELLED' | 'FAILED'
> = {
	ALL: 'All',
	PAID: 'Paid',
	PENDING: 'Pending',
	EXPIRED: 'Expired',
	CANCELLED: 'Cancelled',
	FAILED: 'Failed',
};

const TransactionTypesNames: TransactionTypesKeys = {
	ALL: 'All',
	MERCHANT: 'Payment',
	EMAIL: 'Email invoice',
	RECURRING: 'Recurring email invoice',
	POS: 'Point of sales',
	ESCROW: 'Escrow deal',
	DAGLOYALTY: 'Dagloyalty',
};

type TransactionStatusKeys = { [name in keyof typeof InvoiceState]: string };
type TransactionTypesKeys = { [name in keyof typeof InvoiceTypes | ExtendedInvoiceTypes]: string };

const transactionsFilterSchema = Yup.object<TransactionsFilterFields>().shape({
	startDate: Yup.lazy((value) => {
		if (!value) {
			return Yup.mixed().notRequired();
		}
		return Yup.date().max(new Date(Yup.ref('endDate').value || new Date()), 'start date cannot be in the future');
	}),
	endDate: Yup.lazy((value) => {
		if (!value) {
			return Yup.mixed().notRequired();
		}
		return Yup.date()
			.min(Yup.ref('startDate'), `End date must be later than starting date`)
			.max(dayjs(new Date()).endOf('day'), 'Registration date cannot be in the future');
	}),
	type: Yup.mixed().nullable(true).notRequired(),
	state: Yup.mixed().nullable(true).notRequired(),
	keyword: Yup.mixed().notRequired(),
	users: Yup.mixed().nullable(true).notRequired(),
});

// TODO: what about POS role
const userRoleMap = [UserRole.OWNER, UserRole.ADMIN, UserRole.ADVANCED_TEAM_MEMBER];

export const getFilterArray = (fieldQueryString: string | string[] | null) => {
	if (!fieldQueryString || typeof fieldQueryString !== 'string') {
		return [];
	}
	const queryStringList = fieldQueryString.split(',');

	if (queryStringList.includes('all') || queryStringList.includes('ALL')) {
		return [];
	}

	return queryStringList;
};

export default function FilterBlock(props: Props) {
	const { startDate, endDate, type, keyword, state, users } = qs.parse(props.location.search);
	const [isExportOptionsOpened, setIsExportOptionsOpened] = useState<boolean>(props.isExportOptionsOpened);

	const { invitedUsers, activeViewer, invoices } = useStoreState((state) => ({
		invoices: state.invoice.invoices,
		invitedUsers: state.invite.invitedUsers,
		activeViewer: state.viewer.activeViewer,
	}));

	const { getInvitedUsers, getInvoices } = useStoreActions((actions) => ({
		...actions.invite,
		...actions.invoice,
	}));

	useEffect(() => {
		const fetchInvitedUsers = async () => {
			await getInvitedUsers({ status: InvitedUserStatus.ACTIVE });
		};
		fetchInvitedUsers();
	}, [getInvitedUsers]);

	if (!activeViewer || !invitedUsers) {
		return <Loader />;
	}

	const hasExtendedPermissions = userRoleMap.includes(activeViewer.role);

	const getMultiSelectInitialValues = (props: GetMultiSelectInitialValuesProps) => {
		const { queryString, initialNameList, initialValueArray } = props;

		const queryFieldArray = queryString && typeof queryString === 'string' ? queryString.split(',') : [];

		const defaultInitialValues = initialNameList.map((name) => {
			let nameValuePair: (string | null)[] = [];

			// find according value name pair if exists
			if (initialValueArray) {
				nameValuePair = initialValueArray && initialValueArray.filter((value) => value[0] === name)[0];
			}

			const value = initialValueArray && name !== 'ALL' ? nameValuePair[1] : name;

			return {
				value: value || name,
				label: name.toLowerCase().replace(/_/g, ' '),
				checked: queryFieldArray.includes(value || name),
				hidden: false,
			};
		});

		return defaultInitialValues;
	};

	const getInitialTypeNames = () => {
		const typeNames = Object.keys(TransactionTypesNames);

		return typeNames.filter((name) => name !== InvoiceTypes.RECURRING);
	};

	const getInitialUserNames = () => {
		const userNames = invitedUsers.items.map(
			(user) => `${user.firstName.toLowerCase()} ${user.lastName.toLowerCase()}`,
		);

		userNames.splice(0, 0, 'ALL');

		return userNames;
	};

	const getInitialUserValueArray = () => {
		const parentId = activeViewer.parentId ? activeViewer.parentId : activeViewer.id;

		return invitedUsers.items.map((user) => {
			return [`${user.firstName.toLowerCase()} ${user.lastName.toLowerCase()}`, user.userId || parentId];
		});
	};

	const handleInputStates = (stateInput: MultiSelectFilter[]) => {
		const checkedStateValues = stateInput.filter((state) => state.checked).map((state) => state.value);

		if (checkedStateValues.includes('PAID')) {
			checkedStateValues.push('PAID_EXPIRED');
		}

		// UI should not display W_F_C do avoid confusion
		if (checkedStateValues.includes('PENDING')) {
			checkedStateValues.push('WAITING_FOR_CONFIRMATION');
		}

		return checkedStateValues.join(',');
	};

	const handleGetFilteredInvoices = async (
		input: TransactionsFilterFields,
		_formActions: FormikActions<TransactionsFilterFields>,
	) => {
		const conditionalQueryObject = {
			...(input.startDate && { startDate: moment(input.startDate, 'DD/MM/YYYY').toISOString() }),
			...(input.endDate && { endDate: moment(input.endDate, 'DD/MM/YYYY').toISOString() }),
			...(input.keyword && { keyword: input.keyword.trim() }),
			...(input.type.length && {
				type: input.type
					.filter((type) => type.checked)
					.map((type) => type.value)
					.join(','),
			}),
			...(input.state.length && {
				state: handleInputStates(input.state),
			}),
			...(input.users.length && {
				users: input.users
					.filter((userObject) => userObject.checked)
					.map((userObject) => userObject.value)
					.join(','),
			}),
		};

		props.setIsFilterSearchEnabled(true);

		const stringifiedInput = qs.stringify({
			...conditionalQueryObject,
			...(input.startDate && { startDate: moment(input.startDate).format('DD/MM/YYYY') }),
			...(input.endDate && { endDate: moment(input.endDate).format('DD/MM/YYYY') }),
		});

		props.setPage(1);
		props.history.push(`${props.match.path}?page=1&${stringifiedInput}`);
	};

	const handleTransactionsExport = async (args: TransactionExportArgs) => {
		if (invoices === null || invoices.items.length === 0) {
			return null;
		}

		const { startDate, endDate, state, type, keyword } = qs.parse(props.location.search);
		const { formatXlsx } = args;
		console.log({ startDate, endDate, state, type, keyword });
		const csvResult = await getInvoices({
			startDate: startDate ? dayjs(startDate as string, 'DD/MM/YYYY').toISOString() : undefined,
			endDate: endDate ? dayjs(endDate as string, 'DD/MM/YYYY').toISOString() : undefined,
			state: getFilterArray(state) as InvoiceState[],
			type: getFilterArray(type),
			users: getFilterArray(users),
			keyword: (keyword && keyword.toString()) || undefined,
			format: InvoicesOutputFormats.CSV,
			itemsPerPage: 1000,
		});

		if (csvResult && csvResult.payload) {
			//date to acceptable filename format
			const dateString = new Date().toLocaleDateString().split('/').join('_');
			//replace PAID_EXPIRED with PAID for simplicity
			let exportable: string = csvResult.payload.toString().replace(/PAID_EXPIRED/g, InvoiceState.PAID);
			if (formatXlsx) {
				return downloadXlsxFile(exportable, `Dagpay_transactions_${dateString}`);
			}
			return downloadFile(exportable, `Dagpay_transactions_${dateString}.csv`);
		}
	};

	const getTransactionHistoryForm =
		() =>
		(fields: FormikFieldsState<TransactionsFilterFields>, fieldActions: CFormikProps<TransactionsFilterFields>) => {
			return (
				<Form.Filters autoComplete="off">
					<FilterGrid>
						<TransactionHeading>
							<TitleWrapper>
								<H1>Transaction history</H1>
							</TitleWrapper>
							{props.transactions.length === 0 && !props.isFilterSearchEnabled ? (
								<WithPermission permissions={[Scopes.ENVIRONMENTS, Scopes.CREATE_ENVIRONMENT]}>
									<NotificationRowWrapper>
										<NotificationText>
											Add your first Dagpay integration using our{' '}
											<Link to={RoutesUrls.MERCHANT_TOOLS}>Merchant tools</Link> for collecting payments.
										</NotificationText>
									</NotificationRowWrapper>
								</WithPermission>
							) : (
								<NotificationRowWrapper>
									<NotificationText>View your transaction history</NotificationText>
								</NotificationRowWrapper>
							)}
						</TransactionHeading>

						<SearchField
							formikField={fields.keyword}
							placeholder=" Invoice No. or ID"
							iconType={FieldIconTypes.SEARCH}
							tabIndex={5}
							height="34px"
							hasErrorField={false}
						/>
						<FilterRow>
							<CalendarGroup>
								<Field
									type={FieldTypes.DATE}
									formikField={fields.startDate}
									selected={fieldActions.values.startDate}
									title="From"
									iconType={FieldIconTypes.CALENDAR}
									tabIndex={1}
									height="34px"
									hasErrorField={false}
								/>
								<Field
									type={FieldTypes.DATE}
									selected={fieldActions.values.endDate}
									formikField={fields.endDate}
									title="To"
									iconType={FieldIconTypes.CALENDAR}
									noAddonIcon
									tabIndex={2}
									height="34px"
									hasErrorField={false}
									minDate={fields.startDate.value || undefined}
									maxDate={dayjs(new Date()).endOf('day').toDate()}
								/>
							</CalendarGroup>
							<DropdownGroup>
								<Field
									type={FieldTypes.MULTISELECT}
									formikField={fields.type}
									title="Type"
									noAddonIcon
									tabIndex={3}
									height="34px"
									hasErrorField={false}
								/>
								<Field
									type={FieldTypes.MULTISELECT}
									formikField={fields.state}
									title="Status"
									noAddonIcon
									tabIndex={4}
									height="34px"
									hasErrorField={false}
								/>
								{hasExtendedPermissions && (
									<Field
										type={FieldTypes.MULTISELECT}
										formikField={fields.users}
										title="Users"
										noAddonIcon
										tabIndex={5}
										height="34px"
										hasErrorField={false}
										searchBar
									/>
								)}
							</DropdownGroup>

							<ButtonGroup>
								<Button.Secondary green type={ButtonTypes.SUBMIT} onClick={() => setIsExportOptionsOpened(false)}>
									Apply
								</Button.Secondary>
								{hasExtendedPermissions && (
									<ExportButtonWrapper>
										{isExportOptionsOpened && (
											<ExportSecondaryButtons>
												<Fade bottom>
													<Button.Secondary
														onClick={() => {
															setIsExportOptionsOpened(!isExportOptionsOpened);
															handleTransactionsExport({});
														}}
													>
														CSV (.csv)
													</Button.Secondary>
													<Button.Secondary
														onClick={() => {
															setIsExportOptionsOpened(!isExportOptionsOpened);
															handleTransactionsExport({ formatXlsx: true });
														}}
													>
														Excel (.xls)
													</Button.Secondary>
												</Fade>
											</ExportSecondaryButtons>
										)}
										<Button.Secondary
											type={ButtonTypes.BUTTON}
											transparent
											onClick={() => setIsExportOptionsOpened(!isExportOptionsOpened)}
										>
											<ReactSVG src="/files/svg/private/transactions/Export.svg" />
											Export
										</Button.Secondary>
									</ExportButtonWrapper>
								)}
							</ButtonGroup>
						</FilterRow>
					</FilterGrid>
				</Form.Filters>
			);
		};

	return (
		<FormikForm<TransactionsFilterFields>
			fields={{
				startDate: { initialValue: startDate ? dayjs(startDate.toString(), 'DD/MM/YYYY').toDate() : null },
				endDate: { initialValue: endDate ? dayjs(endDate.toString(), 'DD/MM/YYYY').toDate() : null },
				type: {
					initialValue: getMultiSelectInitialValues({
						initialNameList: getInitialTypeNames(),
						queryString: type,
					}),
				},
				keyword: { initialValue: keyword && !Array.isArray(keyword) ? keyword : '' },
				state: {
					initialValue: getMultiSelectInitialValues({
						initialNameList: Object.keys(TransactionStatusNames),
						queryString: state,
					}),
				},
				users: {
					initialValue: getMultiSelectInitialValues({
						initialNameList: getInitialUserNames(),
						queryString: users,
						initialValueArray: getInitialUserValueArray(),
					}),
				},
			}}
			validationSchema={transactionsFilterSchema}
			onSubmit={handleGetFilteredInvoices}
			render={getTransactionHistoryForm()}
		/>
	);
}
