import * as React from 'react';
import styled from 'styled-components/macro';

import { Color, Gutter } from '../../gfx/constants';

interface PointExlanationListProps {
	children: React.ReactNode;
	left?: boolean;
	center?: boolean;
	hasError?: boolean;
}

interface PointExplanationListLabelProps {
	width: number;
	right?: boolean;
}

const ExplanationList = styled.ul<PointExlanationListProps>`
	width: ${({ left }) => left && '100%'};
	max-width: 100%;
	margin-bottom: ${Gutter.SMALL};
	padding-left: 0;
	margin: 0;
	list-style: none;
`;

export const ExplanationItem = styled.p<PointExlanationListProps>`
	color: ${Color.GRAY};
	font-family: Secondary;
	font-size: 14px;
	line-height: 24px;
	justify-content: ${({ center }) => center && 'center'};
`;

const PointExplanationList = (props: PointExlanationListProps) => {
	const { children, center, hasError, ...rest } = props;

	return (
		<ExplanationList {...rest}>
			{React.Children.map(children, (child) => {
				return <li>{child}</li>;
			})}
		</ExplanationList>
	);
};

export default PointExplanationList;
