import * as React from 'react';
import ReactSVG from 'react-svg';
import styled, { css } from 'styled-components/macro';

import { Color } from '../../gfx/constants';
import { Strong } from '../../gfx/globals';
import Notification from '../notification/Notification';

interface Props {
	reasons: string[];
}

export const StyledRejectionReason = styled.div`
	background-color: #fff5f6;
	color: ${Color.GRAY_10};
	padding: 19px 25px;
	margin-bottom: 1rem;
	border: 1px solid ${Color.RED_2};
	border-radius: 4px;
	margin-top: 11px;
	width: 100%;
	ul {
		margin: 7px 0;
		li {
			font-size: 14px;
			line-height: 21px;
		}
	}
`;

const ReasonsHeader = styled.div`
	display: flex;
	color: ${Color.RED_2};
	line-height: 24px;
	font-size: 16px;
	svg {
		margin-right: 8px;
		stroke: ${Color.RED_ERROR};
	}
`;

export default ({ reasons }: Props) => (
	<StyledRejectionReason>
		<ReasonsHeader>
			<ReactSVG src="/files/svg/private/redWarning.svg" />
			<Strong>Reason for rejection</Strong>
		</ReasonsHeader>
		<ul>
			{reasons.map((reason) => {
				return <li>{reason}</li>;
			})}
		</ul>
	</StyledRejectionReason>
);
