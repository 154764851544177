import axios from 'restyped-axios';
import { InvitedUserRole, InvitedUserStatus, InvitedUserType, InviteStatus, UserRole } from '../constants';
import { ApiArrayPayload, ApiGetRequest, ApiResponse } from '../typings';

import { User } from './userApi';
export const api = axios.create<InvitesApi>();

export interface InvitesApi {
	'/invites': {
		GET: {
			query: ApiGetRequest;
			response: ApiResponse<ApiArrayPayload<User>>;
		};

		POST: {
			body: CreateInviteRequest;
			response: ApiResponse<Invite>;
		};
	};

	'/invites/:inviteId': {
		GET: {
			response: ApiResponse<Invite>;
		};
		DELETE: {
			response: ApiResponse<Invite>;
		};
	};

	'/invites/users': {
		GET: {
			query: GetInvitedUsersRequest;
			response: ApiResponse<ApiArrayPayload<ExtendedInvitedUser>>;
		};
	};
	'/invites/usersStatistics': {
		GET: {
			response: ApiResponse<ApiArrayPayload<InvitedUsersCount>>;
		};
	};

	'/invites/user': {
		PUT: {
			body: UpdateInvitedUserRequest;
			response: ApiResponse<Invite>;
		};
	};

	'/invites/user/:userId': {
		GET: {
			response: ApiResponse<InvitedUser>;
		};
	};

	'/invites/user/register': {
		POST: {
			body: RegisterInviteRequest;
			response: ApiResponse<Invite>;
		};
	};

	'/invites/user/disable': {
		PUT: {
			body: DisableInvitedUserRequest;
			response: ApiResponse<User>;
		};
	};

	'/invites/user/enable': {
		PUT: {
			body: EnableInvitedUserRequest;
			response: ApiResponse<User>;
		};
	};

	'/invites/resend': {
		POST: {
			body: ResendEmailInviteRequest;
			response: ApiResponse<Invite>;
		};
	};
}
export interface Invite {
	id: string;
	masterId: string;
	creatorId: string;
	firstName: string;
	lastName: string;
	email: string;
	role: InvitedUserRole;
	status: InviteStatus;
	businessName: string;
	expiryDate: string;
	createdDate: string;
	updatedDate: string;
}

export interface InvitedUser {
	firstName: string;
	lastName: string;
	email: string;
	role: UserRole;
}

export interface ExtendedInvitedUser extends InvitedUser {
	inviteId: string | null;
	userId: string | null;
	type: InvitedUserType;
	status: InvitedUserStatus;
	lastLoginDate: string | null;
	createdDate: string;
}
export interface InvitedUsersCount extends InvitedUser {
	status: InvitedUserStatus;
	count: number;
}

export interface CreateInviteRequest {
	firstName: string;
	lastName: string;
	email: string;
	role: InvitedUserRole | UserRole;
}

export interface GetInviteRequest {
	inviteId: string;
}

export interface GetInvitedUserRequest {
	userId: string;
}

export interface DisableInvitedUserRequest {
	userId: string;
}

export interface EnableInvitedUserRequest {
	userId: string;
}

export interface GetInvitedUsersRequest extends ApiGetRequest {
	status?: InvitedUserStatus;
}

export interface RegisterInviteRequest {
	inviteId: string;
	password: string;
}

export interface UpdateInvitedUserRequest {
	userId: string;
	firstName: string;
	lastName: string;
	role: InvitedUserRole | UserRole;
}

export interface DeleteInviteRequest {
	inviteId: string;
}

export interface ResendEmailInviteRequest {
	inviteId: string;
}

// Invites
export const createInviteApi = async (params: CreateInviteRequest) => {
	const response = await api.post('/invites', {
		...params,
	});

	return response.data;
};

export const registerInviteApi = async (params: RegisterInviteRequest) => {
	const response = await api.post('/invites/user/register', {
		...params,
	});

	return response.data;
};

export const getInviteApi = async (params: GetInviteRequest) => {
	const response = await api.get<'/invites/:inviteId'>(`/invites/${params.inviteId}`);

	return response.data;
};

export const getInvitesApi = async (params: ApiGetRequest) => {
	const response = await api.get('/invites', {
		params: { ...params },
	});

	return response.data;
};

export const updateInviteApi = async (params: UpdateInvitedUserRequest) => {
	const response = await api.put('/invites/user', {
		...params,
	});

	return response.data;
};

export const deleteInviteApi = async (params: DeleteInviteRequest) => {
	const response = await api.delete<'/invites/:inviteId'>(`/invites/${params.inviteId}`);

	return response.data;
};

export const resendEmailInviteApi = async (params: ResendEmailInviteRequest) => {
	const response = await api.post('/invites/resend', {
		...params,
	});

	return response.data;
};

// Invited users
export const getInvitedUserApi = async (params: GetInvitedUserRequest) => {
	const response = await api.get<'/invites/user/:userId'>(`/invites/user/${params.userId}`);

	return response.data;
};

export const getInvitedUsersApi = async (params: GetInvitedUsersRequest) => {
	const response = await api.get('/invites/users', {
		params: { ...params },
	});

	return response.data;
};
export const getInvitedUsersStatisticsApi = async () => {
	const response = await api.get('/invites/usersStatistics');

	return response.data;
};

export const disableInvitedUserApi = async (params: DisableInvitedUserRequest) => {
	const response = await api.put('/invites/user/disable', {
		...params,
	});

	return response.data;
};

export const enableInvitedUserApi = async (params: EnableInvitedUserRequest) => {
	const response = await api.put('/invites/user/enable', {
		...params,
	});

	return response.data;
};
