import React from 'react';
import styled from 'styled-components/macro';
import { Color } from '../../gfx/constants';

interface Step {
	name: string;
}

export interface FormStepperProps {
	currentStep: number;
	steps: Step[];
}

interface LabelProps {
	active: boolean;
	step: number;
}
const StepWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	margin: 4px;
`;

const StepLabel = styled.div<LabelProps>`
	color: ${(props) => (props.active ? Color.GRAY_14 : Color.GRAY_6)};
	position: relative;
	padding-left: 30px;
	line-height: 22px;
	font-style: primary;
	font-weight: 500;
	:before {
		position: absolute;
		left: 0;
		display: block;
		color: white;
		content: '${(props) => props.step}';
		width: 22px;
		height: 22px;
		border-radius: 50%;
		text-align: center;
		margin: auto;
		font-size: 12px;
		line-height: 22px;
		background-color: ${(props) => (props.active ? Color.GREEN_2 : Color.GRAY_5)};
	}
	:after {
		position: absolute;
		top: -20px;
		left: 0;
		right: 0;
		content: '';
		width: 100%;
		height: 10px;
		background-color: ${(props) => (props.active ? Color.GREEN_2 : Color.GRAY_5)};
	}
`;

const Stepper = styled.div`
	display: flex;
	width: 90%;
	margin: 20px auto;
	justify-content: space-between;
	${StepWrapper}:first-child {
		${StepLabel}::after {
			border-radius: 5px 0px 0px 5px;
		}
	}
	${StepWrapper}:last-child {
		${StepLabel}::after {
			border-radius: 0px 5px 5px 0px;
		}
	}
`;

export default function FormStepper(props: FormStepperProps) {
	const { currentStep, steps } = props;

	return (
		<Stepper>
			{steps.map((step, index) => {
				return (
					<StepWrapper key={index}>
						<StepLabel active={currentStep >= index + 1} step={index + 1}>
							{step.name}
						</StepLabel>
					</StepWrapper>
				);
			})}
		</Stepper>
	);
}
