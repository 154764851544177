import dayjs from 'dayjs';
import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import ReactSVG from 'react-svg';
import PanelNew, { PanelSectionType } from '../../../../components/panel-new/PanelNew';
import HookBaseField from '../../../../components/react-hook-form/HookBaseField';
import HookDatePicker from '../../../../components/react-hook-form/HookDatePicker';
import {
	HookFormColumn,
	HookFormSection,
	HookFormSeparator,
} from '../../../../components/react-hook-form/ReactHookFormStyle';
import View from '../../../../components/view/View';
import { Countries, RoutesUrls, VerificationState } from '../../../../constants';
import { H2, NotificationRowWrapper, NotificationText } from '../../../../gfx/globals';
import { useStoreState } from '../../../../services/store';
import { AccountStatus, TitleWrapper, VerificationStateText } from '../SettingsStyle';

interface CompanyDetailsFields {
	businessName: string;
	registrationDate: string;
	phone: string;
	address: string;
	city: string;
	postalCode: string;
	registrationNumber: string;
	email: string;
	vatNumber: string;
	countryCode: string;
	county: string;
	description: string;
}

type CheckIdentity = () => string | VerificationState;

function CompanyDetailsView() {
	const { verifications } = useStoreState((state) => ({
		verifications: state.verification.verifications,
	}));

	const methods = useForm<CompanyDetailsFields>();

	if (!verifications || !verifications.business || !verifications.business.info) {
		return (
			<View paddingTop={27}>
				<TitleWrapper>
					<H2>Company details</H2>
				</TitleWrapper>
				<NotificationRowWrapper>
					<NotificationText>
						Please <Link to={RoutesUrls.VERIFY_BUSINESS}>verify your business</Link>{' '}
					</NotificationText>
				</NotificationRowWrapper>
			</View>
		);
	}

	const { info } = verifications.business;

	const parsedInfo = JSON.parse(info);
	type CountryCode = keyof typeof Countries;

	const checkBusinessState: CheckIdentity = () => {
		if (verifications && verifications.business !== null) {
			return verifications.business.state;
		}

		return 'NOT_STARTED';
	};

	const getVerificationSection = (text: string, iconPath: string) => (
		<AccountStatus>
			<ReactSVG src={iconPath} />
			<VerificationStateText>{text}</VerificationStateText>
		</AccountStatus>
	);

	const getBusinessVerificationState = () => {
		switch (checkBusinessState()) {
			case VerificationState.APPROVED:
				return getVerificationSection('Company verified', '/files/svg/private/Success.svg');
			case VerificationState.PENDING:
				return getVerificationSection('Verification pending', '/files/svg/private/dashboard/icon-pending.svg');
			case VerificationState.REJECTED:
				return getVerificationSection('Verification rejected', '/files/svg/private/dashboard/icon-fail.svg');
			default:
				return '';
		}
	};

	return (
		<>
			<TitleWrapper>
				<H2>Company details</H2>
			</TitleWrapper>
			<NotificationRowWrapper>
				<NotificationText>
					Please contact <a href="mailto:support@dagpay.io">support@dagpay.io</a> if you need to update any disabled
					information.
				</NotificationText>
			</NotificationRowWrapper>
			{parsedInfo && (
				<PanelNew>
					<PanelNew.Section first white panelType={PanelSectionType.FORM} separateWithBorder>
						<FormProvider {...methods}>
							<HookFormSeparator>
								Company details
								{getBusinessVerificationState()}
							</HookFormSeparator>
							<HookFormSection>
								<HookFormColumn>
									<HookBaseField
										name="businessName"
										label="Legal name of business"
										value={parsedInfo.businessName}
										disabled
									/>
									<HookDatePicker
										name="registrationDate"
										label="Business registration date"
										value={dayjs(parsedInfo.registrationDate).toDate()}
										disabled
									/>
									<HookBaseField name="phone" label="Phone number" value={parsedInfo.phone} disabled />
								</HookFormColumn>
								<HookFormColumn>
									<HookBaseField
										name="registrationNumber"
										label="Company registration number"
										value={parsedInfo.registrationNumber}
										disabled
									/>
									<HookBaseField name="email" label="Email" value={parsedInfo.email} disabled />
									<HookBaseField name="vatNumber" label="VAT number" value={parsedInfo.vatNumber} disabled />
								</HookFormColumn>
							</HookFormSection>
							<HookFormSeparator>Business address</HookFormSeparator>
							<HookFormSection>
								<HookFormColumn>
									<HookBaseField name="address" label="Address" value={parsedInfo.address} disabled />
									<HookBaseField name="city" label="City/town" value={parsedInfo.city} disabled />
									<HookBaseField name="postalCode" label="Postal code" value={parsedInfo.postalCode} disabled />
									<HookBaseField
										name="description"
										label="Nature of business"
										value={parsedInfo.description}
										disabled
									/>
								</HookFormColumn>
								<HookFormColumn>
									<HookBaseField
										name="countryCode"
										label="Country"
										value={Countries[parsedInfo.countryCode as CountryCode]}
										disabled
									/>
									<HookBaseField name="county" label="Province, Region or County" value={parsedInfo.county} disabled />
									<HookBaseField name="website" label="Website" value={parsedInfo.website} disabled />
								</HookFormColumn>
							</HookFormSection>
						</FormProvider>
					</PanelNew.Section>
					<PanelNew.Section gray panelType={PanelSectionType.BUTTON} last></PanelNew.Section>
				</PanelNew>
			)}
		</>
	);
}

export default CompanyDetailsView;
