import { Action, action, thunk, Thunk, thunkOn, ThunkOn } from 'easy-peasy';

import { Invoice } from '../api/invoiceApi';
import {
	createPosInvoiceApi,
	CreatePosInvoiceRequest,
	generatePosPairingCodeApi,
	GeneratePosPairingCodeResponse,
	getPosPairingsApi,
	PosPairing,
	revokePosPairingCodeApi,
	RevokePosPairingRequest,
} from '../api/posApi';
import handleFetch from '../services/handle-fetch';
import { ApiArrayPayload, ApiGetRequest, Response, ResponseArray } from '../typings';

import { StoreModel } from '.';

export interface PosModel {
	// state
	posPairings: ApiArrayPayload<PosPairing> | null;
	generatePosPairingResult: GeneratePosPairingCodeResponse | null;

	// actions
	save: Action<PosModel, ApiArrayPayload<PosPairing> | null>;
	saveGeneratePosPairingResult: Action<PosModel, GeneratePosPairingCodeResponse | null>;
	clearPosPairings: Action<PosModel>;
	clearGeneratePosPairingResult: Action<PosModel>;

	// thunks
	createPosInvoice: Thunk<PosModel, CreatePosInvoiceRequest, {}, StoreModel, Response<Invoice>>;
	generatePosPairingCode: Thunk<PosModel, undefined, {}, StoreModel, Response<GeneratePosPairingCodeResponse>>;
	revokePosPairingCode: Thunk<
		PosModel,
		RevokePosPairingRequest,
		{},
		StoreModel,
		Response<GeneratePosPairingCodeResponse>
	>;
	getPosPairings: Thunk<PosModel, ApiGetRequest, {}, StoreModel, ResponseArray<PosPairing>>;

	// listeners
	listeners: ThunkOn<PosModel, {}, StoreModel>;
}

const pos: PosModel = {
	// state
	posPairings: null,
	generatePosPairingResult: null,

	// actions
	save: action((state, payload) => {
		state.posPairings = payload;
	}),
	saveGeneratePosPairingResult: action((state, payload) => {
		state.generatePosPairingResult = payload;
	}),
	clearPosPairings: action((state) => {
		state.posPairings = null;
	}),
	clearGeneratePosPairingResult: action((state) => {
		state.generatePosPairingResult = null;
	}),

	// thunks
	getPosPairings: thunk(async (actions, payload) => {
		actions.clearPosPairings();
		const result = await handleFetch<ApiArrayPayload<PosPairing>>(getPosPairingsApi(payload));

		actions.save(result.payload);

		return result;
	}),
	createPosInvoice: thunk(async (actions, payload) => {
		return await handleFetch<Invoice>(createPosInvoiceApi(payload));
	}),
	generatePosPairingCode: thunk(async (actions, payload) => {
		actions.clearGeneratePosPairingResult();
		const response = await handleFetch<GeneratePosPairingCodeResponse>(generatePosPairingCodeApi());

		actions.saveGeneratePosPairingResult(response.payload);

		return response;
	}),
	revokePosPairingCode: thunk(async (actions, payload) => {
		return await handleFetch<GeneratePosPairingCodeResponse>(revokePosPairingCodeApi(payload));
	}),

	// listeners
	listeners: thunkOn(
		(actions, storeActions) => [actions.createPosInvoice, actions.generatePosPairingCode, actions.revokePosPairingCode],
		(actions, payload) => {
			actions.getPosPairings({});
		},
	),
};

export default pos;
