import React, { useState } from 'react';
import ReactSVG from 'react-svg';
import { Keyframes } from 'styled-components';
import styled, { keyframes } from 'styled-components/macro';

import { Color } from '../../gfx/constants';
import { handleCopyToClipboard } from '../../services/handle-copy-to-clipboard';

interface Props {
	value: string;
	children?: React.ReactNode;
	top?: number;
	right?: number;
}
interface PopupProps {
	animation: Keyframes;
	top?: number;
	right?: number;
}

const fadeAnimation = keyframes`
   0% { opacity: 0; }
   15% { opacity: 1; }
   85% { opacity: 1; }
   100% { opacity: 0; }
`;

const PopUp = styled.div<PopupProps>`
	white-space: nowrap;
	height: 35px;
	top: ${(props) => (props.top ? `${props.top}px` : '-25px')};
	right: ${(props) => (props.right ? `${props.right}px` : '-10px')};
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 2px solid ${Color.GREEN_3};
	border-radius: 5px;
	background-color: white;
	animation: ${({ animation }) => animation};
	animation-duration: 2s;
	animation-iteration-count: 1;
	pointer-events: none;
`;

const PopUpIcon = styled.span`
	margin-right: 10px;
	margin-left: 10px;
	margin-top: 3px;

	svg {
		height: 18px;
		width: 18px;
	}
`;

const PopUpWrapper = styled.div`
	position: relative;
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const PopupMessage = styled.p`
	margin-right: 10px;
`;

export default function CopyButton(props: Props) {
	const [animationIsActive, setAnimationIsActive] = useState(false);

	function handleClick() {
		setAnimationIsActive(true);
		handleCopyToClipboard(props.value);
	}

	return (
		<PopUpWrapper onClick={handleClick}>
			{animationIsActive && (
				<PopUp
					top={props.top}
					right={props.right}
					animation={fadeAnimation}
					onAnimationEnd={() => setAnimationIsActive(false)}
				>
					<PopUpIcon>
						<ReactSVG src="/files/svg/private/Success.svg" />
					</PopUpIcon>
					<PopupMessage>Copied to clipboard</PopupMessage>
				</PopUp>
			)}
			{props.children}
		</PopUpWrapper>
	);
}
