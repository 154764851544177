import { ErrorMessage } from '@hookform/error-message';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import ReactSVG from 'react-svg';
import styled from 'styled-components/macro';

import { getPasswordStrength } from '../../services/get-password-strength';
import PasswordProgress from '../password-progress/PasswordProgress';

import { BaseInput, Border, Error, FieldWrapper, Label } from './HookFieldsStyle';

const VisibilityIcon = styled.span`
	position: relative;
	padding-right: 18px;
	right: 0px;
	cursor: pointer;

	svg {
		width: 22px;
		height: 22px;
	}
`;

// const Progress = styled.div`
// 	display: flex;
// 	justify-content: space-between;

// 	${FieldPasswordProgressBar}{

// 	${FieldPasswordProgress} {
// 		border: 1px solid red;
// 	}
// 	}
// `;

interface Props {
	name: string;
	label?: string;
	showPasswordStrength?: boolean;
}

export default function HookPasswordField(props: Props) {
	const { name, label, showPasswordStrength } = props;
	const [isHidden, setIsHidden] = useState(true);
	const [passwordStrength, setPasswordStrength] = useState({
		hasLowerCase: false,
		hasUpperCase: false,
		hasSpecials: false,
		hasTenChars: false,
	});
	const { register } = useFormContext();

	return (
		<FieldWrapper>
			<Label>{label}</Label>
			<Border>
				<BaseInput
					name={name}
					ref={register}
					type={isHidden ? 'password' : ''}
					spellCheck={false}
					onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
						showPasswordStrength ? setPasswordStrength(getPasswordStrength(event.currentTarget.value)) : null
					}
				/>
				<VisibilityIcon onMouseDown={() => setIsHidden(!isHidden)}>
					{isHidden ? (
						<ReactSVG src="/files/svg/icons/PasswordInvisible.svg" />
					) : (
						<ReactSVG src="/files/svg/icons/PasswordVisible.svg" />
					)}
				</VisibilityIcon>
			</Border>
			<Error>
				<ErrorMessage name={name} />
			</Error>
			{showPasswordStrength && (
				// <Progress>
				<PasswordProgress passwordStrength={passwordStrength} />
				// </Progress>
			)}
		</FieldWrapper>
	);
}
