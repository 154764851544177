import React from 'react';
import styled from 'styled-components/macro';

import { Currency } from '../../constants';
import { Color } from '../../gfx/constants';
import { ColumnedFlex } from '../../gfx/globals';
import round from '../../services/round';

interface Props {
	currencyAmount: number;
	coinAmount: number;
	currency: Currency | null;
}

export const CoinItem = styled.div`
	display: flex;
	font-size: 16px;
	line-height: 19px;
	font-family: Primary;
	color: ${Color.BLUE_7};
`;

export const CurrencyItem = styled.div`
	display: flex;
	font-size: 14px;
	line-height: 16px;
	font-family: Primary;
	color: ${Color.GRAY_8};
`;

export default function InvoicePrice(props: Props) {
	const { coinAmount, currency, currencyAmount } = props;

	if (currency === Currency.DAG) {
		return <CoinItem>{coinAmount} DAG</CoinItem>;
	}

	return (
		<ColumnedFlex>
			<CoinItem>{coinAmount} DAG</CoinItem>
			<CurrencyItem>
				{round(currencyAmount, 2)} {currency}
			</CurrencyItem>
		</ColumnedFlex>
	);
}
