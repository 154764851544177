import * as React from 'react';

import { hasPermission } from '../../services/has-permission';
import { useStoreState } from '../../services/store';
import { privateRoutes } from '../../views/private/routes';

import HeaderTpl from './HeaderTpl';

interface Props {
	white?: boolean;
	isPrivate?: boolean;
}

function Header({ white, isPrivate }: Props) {
	const { activeViewer } = useStoreState(state => ({
		activeViewer: state.viewer.activeViewer,
	}));

	const privateSidebarLinks = isPrivate
		? privateRoutes.filter(privateRoute => privateRoute.navSidebarTitle && hasPermission(privateRoute.permissions))
		: [];

	return (
		<HeaderTpl
			sidebarLinks={privateSidebarLinks}
			white={white}
			isPrivate={isPrivate}
			userName={activeViewer ? `${activeViewer.firstName} ${activeViewer.lastName}` : ''}
			userCompany={activeViewer ? activeViewer.companyName : ''}
		/>
	);
}

export default Header;
