import styled from 'styled-components/macro';
import { Color } from '../../../../gfx/constants';

export const Wrapper = styled.div`
	height: 100%;
	width: 100%;
	padding-top: 39px;
`;

export const LogoWrapper = styled.div`
	position: absolute;
	top: 32px;
	left: 32px;
`;

export const TableWrapper = styled.div`
	display: flex;
	flex-direction: column;
	border: 1px solid ${Color.BLUE_6};
	border-radius: 4px;
	width: 100%;
	margin: 0 auto;

	> :first-child {
		border-bottom: 1px solid ${Color.BLUE_6};
	}
`;

export const TableRow = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;

	> :nth-child(2) {
		width: 56px;
		min-width: 56px;
		border-left: 1px solid ${Color.BLUE_6};
	}
`;

export const TableCell = styled.div<{ allowOverflow?: boolean }>`
	display: flex;
	flex-direction: column;
	justify-content: center;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: ${(props) => (props.allowOverflow ? 'initial' : 'hidden')};
	padding: 12px;
	font-size: 14px;
	line-height: 16px;
	color: ${Color.WHITE};

	div {
		margin-bottom: 3px;
		font-weight: 500;
		color: ${Color.BLUE_1};
	}
`;
