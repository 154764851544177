import { InvoiceTypes } from '../api/invoiceApi';

export default function getInvoiceType(invoiceType: InvoiceTypes, dagloyalty?: boolean) {
	if (invoiceType === InvoiceTypes.POS) {
		if (dagloyalty === true) {
			return 'Dagloyalty';
		}
		return 'POS';
	}

	if (invoiceType === InvoiceTypes.ESCROW) {
		return 'Escrow';
	}

	return invoiceType === InvoiceTypes.MERCHANT ? 'Payment' : 'Email invoice';
}
