import { UserRole } from '../constants';

import store from './store';

export default function isWalletAdded() {
	const viewerState = store.getState().viewer.activeViewer;
	const userVerificationsState = store.getState().verification.verifications;
	const parentVerificationsState = store.getState().verification.parentUserVerifications;

	if (!viewerState) {
		return false;
	}

	const isOwner = viewerState.role === UserRole.OWNER;
	const isAdmin = viewerState.role === UserRole.ADMIN || viewerState.role === UserRole.PENDING_ADMIN;
	const isTeamMember = viewerState.role === UserRole.ADVANCED_TEAM_MEMBER || viewerState.role === UserRole.TEAM_MEMBER;

	let walletAdded: boolean = false;

	if (isOwner && userVerificationsState) {
		walletAdded = userVerificationsState.isWalletAdded;
	}

	if ((isTeamMember || isAdmin) && parentVerificationsState) {
		walletAdded = parentVerificationsState.isWalletAdded;
	}

	return walletAdded;
}
