import dayjs from 'dayjs';
import * as qs from 'query-string';
import React, { useEffect, useState } from 'react';
import CountDown, { CountdownRenderProps } from 'react-countdown-now';
import { withRouter } from 'react-router-dom';
import ReactSVG from 'react-svg';
import { toast } from 'react-toastify';

import { PosPairing } from '../../../../../api/posApi';
import Button from '../../../../../components/button/Button';
import CopyButton from '../../../../../components/copy-button/CopyButton';
import StyledField from '../../../../../components/field/FieldStyle';
import Loader from '../../../../../components/loader/Loader';
import Modal from '../../../../../components/modal/Modal';
import Notification from '../../../../../components/notification/Notification';
import Pager from '../../../../../components/pager/Pager';
import Table from '../../../../../components/table/Table';
import View from '../../../../../components/view/View';
import WithPermission from '../../../../../components/with-permission/WithPermission';
import { GLOBAL_DATE_FORMAT, ITEMS_PER_PAGE, RoutesUrls, Scopes } from '../../../../../constants';
import { ColumnedFlex, H1, Strong } from '../../../../../gfx/globals';
import RpcClient, { SubscribeMethod } from '../../../../../services/invoice-rpc-client';
import { useStoreActions, useStoreState } from '../../../../../services/store';
import { Json } from '../../../../../services/ws-rpc-client';
import { RouteProps } from '../../../../../typings';
import { StyledCountdown } from '../../../../../services/get-payment-countdown';
import { PosPairingColSizes } from '../../../../../gfx/constants';
import {
	EscrowSupportModalWrapper,
	ModalButtonWrapper,
} from '../../../../../components/escrow-state-display/EscrowStateDisplayStyle';

function PosPairingsListView(props: RouteProps) {
	let rpc: RpcClient;

	const [page, setPage] = useState(1);
	const [isSettingsOpened, setIsSettingsOpened] = useState<boolean[]>([]);
	const [isModalOpen, setIsModalOpen] = useState<boolean[]>([]);
	const [activeIndex, setActiveIndex] = useState<number | null>(null);
	const [activePosPairing, setActivePosPairing] = useState<PosPairing | null>(null);
	// const [errorMessage, setErrorMessage] = useState('');
	const [isGenerateCodeModalOpened, setIsGenerateCodeModalOpened] = useState(false);

	const { posPairings, generatePosPairingResult } = useStoreState((state) => ({
		posPairings: state.pos.posPairings,
		generatePosPairingResult: state.pos.generatePosPairingResult,
	}));

	const { generatePosPairingCode, revokePosPairingCode, getPosPairings } = useStoreActions((actions) => ({
		...actions.pos,
	}));

	useEffect(() => {
		const pageFromLink = qs.parse(props.location.search).page;

		if (pageFromLink && typeof pageFromLink === 'string') {
			setPage(parseInt(pageFromLink, 10));
		}
	}, [props.location.search]);

	useEffect(() => {
		const fetchPosPairings = async () => {
			return await getPosPairings({ page: page, itemsPerPage: ITEMS_PER_PAGE });
		};
		fetchPosPairings();
	}, [page, getPosPairings]);

	if (!posPairings) {
		return <Loader />;
	}

	const pairings = posPairings ? posPairings.items : [];
	const itemCount = posPairings ? posPairings.itemCount : 0;
	const pageCount = posPairings ? posPairings.pageCount : 0;

	const setupPosPairingRpcClient = (pairingCode: string) => {
		// avoid trying to setup the rpc client more than once
		if (rpc !== undefined) {
			return;
		}

		const protocol = process.env.REACT_APP_API_SSL === 'true' ? 'wss' : 'ws';
		const url = `${protocol}://${process.env.REACT_APP_API_URL}/ws`;

		rpc = new RpcClient(url, SubscribeMethod.POS_APP_PAIRED, pairingCode);

		rpc.handlePosPaired = async (data: Json) => {
			toast.success(`Device "${data.deviceName}" was successfully paired`);
			setIsGenerateCodeModalOpened(false);
			await getPosPairings({ page, itemsPerPage: ITEMS_PER_PAGE });
		};
	};

	const generatePosPairing = async () => {
		const response = await generatePosPairingCode();

		if (response.success && response.payload) {
			setIsGenerateCodeModalOpened(true);
			setupPosPairingRpcClient(response.payload.pairingCode);
		}
	};

	const renderGeneratePairingCodeModal = () => {
		if (!generatePosPairingResult) {
			return null;
		}

		const renderCountDown = (props: CountdownRenderProps) => (
			<StyledCountdown>{` ${props.formatted.minutes}:${props.formatted.seconds}`}</StyledCountdown>
		);

		return (
			<Modal ariaHideApp={false} isOpen={isGenerateCodeModalOpened} onRequestClose={() => handleGenerateModalClose()}>
				<H1 center>Pairing code</H1>
				<Notification>
					Enter the following code into your Dagpay checkout app to connect with your Dagpay account
				</Notification>
				<p>
					Code expires in:
					<CountDown
						daysInHours
						date={generatePosPairingResult.pairingCodeExpiryDate}
						renderer={renderCountDown}
						onComplete={() => setIsGenerateCodeModalOpened(false)}
					/>
				</p>
				<StyledField>
					<StyledField.InputWrapper>
						<StyledField.Input value={generatePosPairingResult.pairingCode} disabled />
						<StyledField.AddonNode>
							<CopyButton value={generatePosPairingResult.pairingCode}>
								<ReactSVG src="/files/svg/private/Copy.svg" />
							</CopyButton>
						</StyledField.AddonNode>
					</StyledField.InputWrapper>
				</StyledField>
				<Modal.Buttons>
					<Button onClick={handleGenerateModalClose}>Close</Button>
				</Modal.Buttons>
			</Modal>
		);
	};

	const handleGenerateModalClose = async () => {
		setIsGenerateCodeModalOpened(false);
		await getPosPairings({ page, itemsPerPage: ITEMS_PER_PAGE });

		props.history.push(RoutesUrls.POS_PAIRINGS);
	};

	const renderPosPairingTr = (posPairing: PosPairing, index: number) => {
		return (
			<Table.TrPrimary
				key={posPairing.id}
				onMouseLeave={isSettingsOpened[index] ? toggleSettingsVisibility(posPairing, index) : () => null}
			>
				<Table.FixedWidthTd cellWidth={PosPairingColSizes.DATE_ADDED}>
					<Table.DataWrapper data-label="Date added">
						<ColumnedFlex>
							<Strong>{dayjs(posPairing.createdDate).format(GLOBAL_DATE_FORMAT)}</Strong>
							{dayjs(posPairing.createdDate).format('HH:mm')}
						</ColumnedFlex>
					</Table.DataWrapper>
				</Table.FixedWidthTd>
				<Table.FixedWidthTd cellWidth={PosPairingColSizes.DEVICE}>
					<Table.DataWrapper data-label="Device">{posPairing.deviceName}</Table.DataWrapper>
				</Table.FixedWidthTd>

				<WithPermission permissions={[Scopes.POS, Scopes.POS_PAIRING, Scopes.REVOKE_POS_PAIRING]}>
					<Table.FixedWidthTd cellWidth={PosPairingColSizes.ACTIONS}>
						<Table.DataWrapper data-label="Actions">
							<Table.SettingsIcon onClick={toggleSettingsVisibility(posPairing, index)}>
								<ReactSVG src="/files/svg/private/TableSettings.svg" />
							</Table.SettingsIcon>
							{isSettingsOpened[index] && (
								<Table.HiddenSettings>
									<Table.HiddenSettingsItem isDisable key={index} onClick={() => handleOpenModal(index)}>
										<ReactSVG src="/files/svg/private/Delete.svg" />
										<span>Revoke</span>
									</Table.HiddenSettingsItem>
								</Table.HiddenSettings>
							)}
						</Table.DataWrapper>
					</Table.FixedWidthTd>
				</WithPermission>
			</Table.TrPrimary>
		);
	};

	const renderRevokePairingCodeModal = () => {
		if (activeIndex === null || activePosPairing === null) {
			return null;
		}

		return (
			<Modal
				ariaHideApp={false}
				isOpen={isModalOpen[activeIndex]}
				onRequestClose={() => handleCloseModal(activeIndex)}
				small
				hasCloseButton
			>
				<EscrowSupportModalWrapper>
					<h2>Are you sure you want to revoke?</h2>
					<p>
						You are about to revoke <strong>{activePosPairing.deviceName}</strong> device
					</p>
					<ModalButtonWrapper>
						<Button.Secondary white onClick={() => handleCloseModal(activeIndex)}>
							Cancel
						</Button.Secondary>
						<Button.Secondary
							type="button"
							red
							onClick={() => handleRevokePairingCode(activePosPairing.id, activeIndex)}
						>
							Revoke
						</Button.Secondary>
					</ModalButtonWrapper>
				</EscrowSupportModalWrapper>
			</Modal>
		);
	};

	const toggleSettingsVisibility =
		(posPairing: PosPairing, index: number) =>
		(_e: React.MouseEvent<HTMLSpanElement | HTMLDivElement> | KeyboardEvent) => {
			const isSettingsOpenedCopy = isSettingsOpened.slice();

			isSettingsOpenedCopy[index] = !isSettingsOpened[index];
			setIsSettingsOpened(isSettingsOpenedCopy);
			setActivePosPairing(posPairing);
			setActiveIndex(index);
		};

	const handlePageChanged = (newPage: number) => {
		setPage(newPage);
	};

	const handleOpenModal = (index: number) => {
		const isModalOpenCopy = isModalOpen.slice();
		isModalOpenCopy[index] = true;
		setIsModalOpen(isModalOpenCopy);
	};

	const handleCloseModal = (index: number) => {
		const isModalOpenCopy = isModalOpen.slice();
		isModalOpenCopy[index] = false;
		setIsModalOpen(isModalOpenCopy);
	};

	const handleRevokePairingCode = async (pairingId: string, index: number) => {
		const isModalOpenCopy = isModalOpen.slice();

		try {
			const response = await revokePosPairingCode({ pairingId });
			isModalOpenCopy[index] = false;
			setIsModalOpen(isModalOpenCopy);

			if (response.success) {
				setIsModalOpen(isModalOpenCopy);

				await getPosPairings({ page, itemsPerPage: ITEMS_PER_PAGE });

				toast.success('Pairing code successfully revoked');
				props.history.push(RoutesUrls.POS_PAIRINGS);
			}
		} catch (e) {
			// setErrorMessage('Revoking pos pairing failed');
			toast.error('Revoking pos pairing failed');
		}
	};

	return (
		<View paddingBottom={37} paddingTop={37}>
			<H1>Dagpay checkout app</H1>
			<View.ListHeader>
				<Notification>
					Download and install the Dagpay checkout app on your Android or iOS device and start accepting Dagcoin
					payments easily in your brick-and-mortar business
				</Notification>
				<WithPermission permissions={[Scopes.POS, Scopes.POS_PAIRING, Scopes.GENERATE_POS_PAIRING_CODE]}>
					<Button.Secondary green onClick={generatePosPairing}>
						Generate new pairing code
					</Button.Secondary>
				</WithPermission>
			</View.ListHeader>

			{renderGeneratePairingCodeModal()}

			{renderRevokePairingCodeModal()}

			{pairings.length > 0 ? (
				<WithPermission permissions={[Scopes.POS, Scopes.POS_PAIRING, Scopes.GET_POS_PAIRINGS]}>
					<Table>
						<Table.Thead hiddenOnSmallerScreen>
							<tr>
								<th>Date added</th>
								<th>Device</th>
								<WithPermission permissions={[Scopes.POS, Scopes.POS_PAIRING, Scopes.REVOKE_POS_PAIRING]}>
									<th />
								</WithPermission>
							</tr>
						</Table.Thead>
						<tbody>{pairings.map(renderPosPairingTr)}</tbody>
					</Table>
				</WithPermission>
			) : (
				<View.NoItemsFound>
					<ReactSVG src="/files/svg/private/NoTransactions.svg" />
					<p>You haven't connected any devices yet</p>
				</View.NoItemsFound>
			)}
			<Pager totalItems={itemCount} currentPage={page} onChange={handlePageChanged} totalPages={pageCount} />
		</View>
	);
}

export default withRouter(PosPairingsListView);
