import axios from 'restyped-axios';
import { Currency } from '../constants';
import { ApiArrayPayload, ApiGetRequest, ApiResponse } from '../typings';

import { Environment } from './environmentApi';
import { User } from './userApi';
import { Wallet } from './walletApi';
export const api = axios.create<InvoiceApi>();

export interface InvoiceApi {
	'/invoices': {
		GET: {
			query: GetInvoicesRequest;
			response: ApiResponse<ApiArrayPayload<Invoice>>;
		};
	};

	'/invoices/:invoiceId': {
		GET: {
			response: ApiResponse<Invoice>;
		};
	};

	'/invoices/cancel': {
		POST: {
			query: GetInvoiceRequest;
			response: ApiResponse<Invoice>;
		};
	};

	'/email-invoices': {
		GET: {
			query: ApiGetRequest;
			response: ApiResponse<ApiArrayPayload<EmailInvoice>>;
		};

		POST: {
			body: CreateEmailInvoiceRequest;
			response: ApiResponse<EmailInvoice>;
		};
	};

	'/email-invoices/:emailInvoiceId': {
		GET: {
			response: ApiResponse<EmailInvoice>;
		};
	};

	'/email-invoices/cancel': {
		POST: {
			query: CancelEmailInvoiceRequest;
			response: ApiResponse<EmailInvoice>;
		};
	};

	'/email-invoices/:invoiceId': {
		GET: {
			response: ApiResponse<EmailInvoice>;
		};
	};

	'/recurring-invoices': {
		GET: {
			query: ApiGetRequest;
			response: ApiResponse<ApiArrayPayload<RecurringInvoice>>;
		};

		POST: {
			body: CreateRecurringInvoiceRequest;
			response: ApiResponse<RecurringInvoice>;
		};
	};

	'/recurring-invoices/:recurringInvoiceId': {
		GET: {
			response: ApiResponse<EmailInvoice>;
		};
	};

	'/recurring-invoices/cancel': {
		POST: {
			body: CancelRecurringInvoiceRequest;
			response: ApiResponse<RecurringInvoice>;
		};
	};

	'/invoices/send-receipt': {
		POST: {
			query: SendReceiptRequest;
			response: ApiResponse<Invoice>;
		};
	};

	'/invoices/get-receipt/:invoiceId': {
		GET: {
			response: ApiResponse<InvoiceReceipt>;
		};
	};
}

export enum InvoiceState {
	ALL = 'ALL',
	PENDING = 'PENDING',
	PAID = 'PAID',
	PAID_EXPIRED = 'PAID_EXPIRED',
	EXPIRED = 'EXPIRED',
	CANCELLED = 'CANCELLED',
	FAILED = 'FAILED',
	WAITING_FOR_CONFIRMATION = 'WAITING_FOR_CONFIRMATION',
}

export enum RecurringInvoiceState {
	ACTIVE = 'ACTIVE',
	ENDED = 'ENDED',
	CANCELLED = 'CANCELLED',
}

export enum InvoiceTypes {
	ALL = 'ALL',
	MERCHANT = 'MERCHANT',
	EMAIL = 'EMAIL',
	RECURRING = 'RECURRING',
	POS = 'POS',
	ESCROW = 'ESCROW',
}

export enum ExtendedInvoiceTypes {
	DAGLOYALTY = 'DAGLOYALTY',
}

export enum InvoicesOutputFormats {
	CSV = 'CSV',
}

export interface CreateEmailInvoiceRequest {
	walletId: string;
	tax: number;
	to: string;
	cc: string[];
	subject: string;
	description: string;
	validForSeconds: number;
	paymentId: string;
	currencyAmount: number;
	currency: Currency;
}

export interface SendReceiptRequest {
	invoiceId: string;
	to: string;
	cc: string[];
}

export interface GetReceiptRequest {
	invoiceId: string;
}

export interface CancelRecurringInvoiceRequest {
	recurringInvoiceId: string;
}

export interface CancelEmailInvoiceRequest {
	emailInvoiceId: string;
}

export interface CreateRecurringInvoiceRequest {
	walletId: string;
	to: string;
	cc: string[];
	currency: Currency;
	currencyAmount: number;
	tax: number;
	subject: string;
	description: string;
	paymentId: string;
	startDate: string;
	endDate: string;
	sendIntervalSeconds: number;
	validForSeconds: number;
}

export interface GetInvoiceRequest {
	invoiceId: string;
}

export interface GetEmailInvoiceRequest {
	invoiceId: string;
}

export interface GetRecurringInvoiceRequest {
	recurringInvoiceId: string;
}

export interface InvoiceReceipt {
	pdfBase64String: string;
}

export interface Invoice {
	id: string;
	publicId: string;
	userId: string;
	creatorId: string;
	creator: User;
	environmentId: string;
	paymentUnitId: string;
	atmOnly: boolean;
	senderAddresses: string;
	discount: number;
	coinAmount: number;
	currencyAmount: number;
	currency: Currency;
	type: InvoiceTypes;
	no: string;
	description: string;
	emailInvoiceId: string | null;
	data: string;
	paymentId: string;
	merchantName: string;
	merchantEmail: string;
	wallet: Wallet;
	environment: Environment;
	walletAddress: string;
	qrCodeUrl: string;
	paymentUrl: string;
	state: InvoiceState;
	redirectSuccessUrl: string;
	redirectCancelUrl: string;
	redirectFailUrl: string;
	createdDate: string;
	expiryDate: string;
	validForSeconds: number;
	statusDelivered: boolean;
	statusDeliveryAttempts: number;
	statusLastAttemptDate: string | null;
	statusDeliveredDate: string | null;
}

export interface RecurringInvoice {
	id: string;
	userId: string;
	walletId: string;
	wallet: Wallet | null;
	tax: number;
	to: string;
	cc: string[];
	currency: Currency;
	currencyAmount: number;
	subject: string;
	description: string;
	paymentId: string;
	state: RecurringInvoiceState;
	startDate: string;
	endDate: string;
	sendIntervalSeconds: number;
	validForSeconds: number;
	nextSendDate: string;
	createdDate: string;
	updatedDate: string;
}

export interface InvoiceAmountData {
	coin: number;
	currency: number;
}

export interface InvoiceAmount {
	total: InvoiceAmountData;
	base: InvoiceAmountData;
	tax: InvoiceAmountData;
}

export enum EmailInvoiceStateEnum {
	EXPIRED = 'EXPIRED',
	SENT = 'SENT',
	CANCELLED = 'CANCELLED',
}

export interface EmailInvoice {
	id: string;
	userId: string;
	creatorId: string;
	creator: User;
	environmentId: string;
	wallet: Wallet | null;
	invoiceId: string | null;
	recurringInvoiceId: string | null;
	no: string;
	currencyAmount: number;
	currency: Currency;
	tax: number;
	type: InvoiceTypes;
	to: string;
	cc: string[];
	subject: string;
	description: string;
	merchantName: string;
	paymentId: string;
	amount: InvoiceAmount;
	invoice: Invoice;
	recurringInvoice: RecurringInvoice;
	state: EmailInvoiceStateEnum;
	createdDate: string;
	updatedDate: string;
	expiryDate: string;
}

export interface GetInvoicesRequest extends ApiGetRequest {
	startDate?: string;
	endDate?: string;
	type?: string[];
	state?: InvoiceState[];
	users?: string[];
	keyword?: string;
	format?: InvoicesOutputFormats;
}

export const getInvoicesApi = async (params: GetInvoicesRequest) => {
	const response = await api.get('/invoices', {
		params: { ...params },
	});

	return response.data;
};

export const getInvoiceApi = async (params: GetInvoiceRequest) => {
	const response = await api.get<'/invoices/:invoiceId'>(`/invoices/${params.invoiceId}`);

	return response.data;
};

export const cancelInvoiceApi = async (params: GetInvoiceRequest) => {
	const response = await api.post('/invoices/cancel', {
		...params,
	});

	return response.data;
};

export const createEmailInvoiceApi = async (params: CreateEmailInvoiceRequest) => {
	const response = await api.post('/email-invoices', {
		...params,
	});

	return response.data;
};

export const getEmailInvoicesApi = async (params: ApiGetRequest) => {
	const response = await api.get('/email-invoices', {
		params: { ...params },
	});

	return response.data;
};

export const cancelEmailInvoiceApi = async (params: CancelEmailInvoiceRequest) => {
	const response = await api.post('/email-invoices/cancel', {
		...params,
	});

	return response.data;
};

export const getEmailInvoiceApi = async (params: GetEmailInvoiceRequest) => {
	const response = await api.get(`/email-invoices/${params.invoiceId}`);

	return response.data;
};

export const createRecurringInvoiceApi = async (params: CreateRecurringInvoiceRequest) => {
	const response = await api.post('/recurring-invoices', {
		...params,
	});

	return response.data;
};

export const getRecurringInvoicesApi = async (params: ApiGetRequest) => {
	const response = await api.get('/recurring-invoices', {
		params: { ...params },
	});

	return response.data;
};

export const getRecurringInvoiceApi = async (params: GetRecurringInvoiceRequest) => {
	const response = await api.get(`/recurring-invoices/${params.recurringInvoiceId}`);

	return response.data;
};

export const cancelRecurringInvoiceApi = async (params: CancelRecurringInvoiceRequest) => {
	const response = await api.post('/recurring-invoices/cancel', {
		...params,
	});

	return response.data;
};

export const sendInvoiceReceiptApi = async (params: SendReceiptRequest) => {
	const response = await api.post('/invoices/send-receipt', {
		...params,
	});

	return response.data;
};

export const getInvoiceReceiptApi = async (params: GetReceiptRequest) => {
	const response = await api.get<'/invoices/get-receipt/:invoiceId'>(`/invoices/get-receipt/${params.invoiceId}`);

	return response.data;
};
