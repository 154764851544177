import tag from 'clean-tag';
import * as React from 'react';
import ReactSVG from 'react-svg';
import styled, { css } from 'styled-components/macro';

import { Gutter } from '../../gfx/constants';

interface Props {
	isActive?: boolean;
	src: string;
	className?: string;
}

interface IconProps {
	isActive?: boolean;
}

const Icon = styled(tag)<IconProps>`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: ${Gutter.MEDIUM};
	height: 100px;
	pointer-events: none;
	border-radius: 4px;
	background-color: transparent;
	border: ${({ isActive }) => (isActive ? '1px solid #fff' : '1px solid rgba(220, 221, 224, 1)')};
	cursor: pointer;

	svg {
		pointer-events: none;
		max-width: 100%;
		${(props) =>
			!props.isActive &&
			css`
				filter: grayscale(90%);
				opacity: 0.65;
			`};
	}
`;

export default function RadioIcon({ isActive, src, className }: Props) {
	return <Icon blacklist={['isActive']} is={ReactSVG} className={className} isActive={isActive} src={src} />;
}
