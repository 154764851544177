import { Scopes } from '../constants';
import { hasPermission } from './has-permission';

export interface UtmData {
	utmMedium: string;
	utmSource: string;
	utmContent: string;
	utmCampaign: string;
	utmTerm: string;
	clickId: string;
}

export const saveUtmDataToLocalStorage = (utmData: UtmData) => {
	if (Object.keys(utmData).length !== 0) {
		if (hasPermission([Scopes.UTM])) {
			localStorage.removeItem('utmData');
			localStorage.setItem('utmData', JSON.stringify(utmData));
		}
	}
};
