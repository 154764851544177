import axios from 'restyped-axios';
import { DayInfo } from '../components/opening-hours/OpeningHours';
import { ApiArrayPayload, ApiGetRequest, ApiResponse } from '../typings';
export const api = axios.create<AtmApi>();

export interface AtmApi {
	'/atms': {
		GET: {
			query: GetAtmListRequest;
			response: ApiResponse<ApiArrayPayload<Atm>>;
		};

		POST: {
			body: CreateAtmRequest;
			response: ApiResponse<Atm>;
		};
	};

	'/atms/loyalty/:atmId': {
		GET: {
			response: ApiResponse<string>;
		};
	};
	'/atms/:atmId': {
		GET: {
			response: ApiResponse<Atm>;
		};

		// PUT: {
		// 	body: UpdateAtmInfo;
		// 	response: ApiResponse<Atm>;
		// };
	};
}

export enum AtmType {
	VIRTUAL = 'VIRTUAL',
	PHYSICAL = 'PHYSICAL',
}

export interface GetAtmListRequest extends ApiGetRequest {
	typeList: AtmType[];
}

export enum AtmStatus {
	PENDING = 'PENDING',
	ACTIVE = 'ACTIVE',
	REJECTED = 'REJECTED',
	DELETED = 'DELETED',
}

export interface GetAtmRequest {
	atmId: string;
}

export interface CreateAtmRequest {
	userId: string;
	companyName: string;
	address: string | null;
	city: string | null;
	zipCode: string | null;
	state: string | null;
	country: string | null;
	countryCode: string | null;
	formattedAddress: string | null;
	lat: number | null;
	lng: number | null;
	description: string | null;
	openingHours: DayInfo[];
	type: AtmType;
	swipexEmail: string | null;
	serial: string | null;
}

export interface LatLngLiteral {
	lat: number;
	lng: number;
}

export interface Location {
	address: string | null;
	city: string | null;
	zipCode: string | null;
	state: string | null;
	country: string | null;
	countryCode: string | null;
	formattedAddress: string | null;
	position: LatLngLiteral | null;
	description: string | null;
}

export interface Atm {
	id: string;
	userId: string;
	creatorId: string;
	locationId: string;
	location: Location;
	joinApplicationId: string;
	name: string | null;
	openingHours: DayInfo[] | null;
	atmType: AtmType;
	serial: string;
	swipexEmail: string | null;
	status: AtmStatus;
	data: string | null;
	qrCodeUrl: string;
	createdDate: string;
	updatedDate: string;
}

export const getAtmListApi = async (params: GetAtmListRequest) => {
	const response = await api.get('/atms', {
		params: { ...params },
	});

	return response.data;
};

export const createAtmApi = async (params: CreateAtmRequest) => {
	const response = await api.post('/atms', {
		...params,
	});

	return response.data;
};

export const getAtmApi = async (params: GetAtmRequest) => {
	const response = await api.get<'/atms/:atmId'>(`/atms/${params.atmId}`);

	return response.data;
};

export const getAtmLoyaltyPdfBase64 = async (params: GetAtmRequest) => {
	const response = await api.get<'/atms/loyalty/:atmId'>(`/atms/loyalty/${params.atmId}`);

	return response.data;
};
