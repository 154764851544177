import styled from 'styled-components/macro';

import { Color } from '../../../gfx/constants';
import { SectionTitleTextMixin, SubtextDescriptionTextMixin } from '../../../gfx/globals';
import { media } from '../../../services/media';

interface AuthenticatorStepsProps {
	stepNumber: number;
}

export const SettingsSection = styled.div`
	margin-bottom: 60px;
`;

export const QrWrapper = styled.div`
	${SubtextDescriptionTextMixin}
	font-family: primary;
	color: ${Color.GRAY_8};
`;

export const QrBlockTitle = styled.div`
	color: #2d354a;
	font-size: 15px;
	font-family: PrimaryMedium;
	font-weight: 500;
	line-height: 18px;
	text-align: left;
`;

export const QrCantScanBlock = styled.div`
	margin-top: 50px;
	color: #0b132b;
	font-family: Secondary;
	font-size: 15px;
	line-height: 19px;
	text-align: left;
`;

export const QrText = styled.div`
	color: ${Color.GRAY_DARK};

	p {
		font-size: 15px;
		color: ${Color.GRAY_DARK};
	}
`;

export const AuthLinks = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 5px;
	font-size: 14px;
	margin-bottom: 20px;
	font-family: PrimaryMedium;

	span {
		margin: 0;
		text-align: left;
	}

	a {
		color: ${Color.BLUE_1};
	}
`;

export const TitleWrapper = styled.div`
	position: relative;
	width: 100%;
	${SectionTitleTextMixin}
	& h2 {
		margin-bottom: 10px;
	}
`;

export const AccountStatus = styled.div`
	display: flex;
	position: absolute;
	bottom: 0;
	right: 0;

	svg {
		width: 18px;
		height: 18px;
		margin-right: 5px;

		path {
			fill: ${Color.GREEN_3};
		}
	}
`;

export const VerificationStateText = styled.p`
	margin-top: 0;
	margin-bottom: 0;
	font-family: PrimaryMedium;
	font-size: 14px;
	margin-left: 22px;
`;

export const GoogleBox = styled.div`
	display: flex;
	justify-content: space-between;
	margin-right: 70px;

	@media (max-width: 1024px) {
		flex-direction: column;
		margin-right: 0px;
	}
`;

export const LoginSecretBlock = styled.div`
	width: 100%;
	margin-top: 40px;

	input {
		text-align: center;

		${media.maxTablet`
		font-size: 14px;
	`}
		${media.maxMobile`
		font-size: 12px;
		`}
	}
`;

export const VerificationCodeBlock = styled.div``;

export const LoginSecret = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 10px;
	font-size: 14px;
	border: 1px solid rgba(220, 221, 224, 0.5);
	border-radius: 4px;
	text-align: center;
	word-break: break-all;
	min-height: 34px;

	${media.maxTablet`
		margin-bottom: 10px;
	`}

	${media.fromTablet`
		margin-right: 10px;
	`}
`;

export const PasswordSettings = styled.div`
	margin-bottom: 0;
	margin-bottom: 60px;

	form {
		display: flex;
		flex-direction: column;
	}
`;

export const SecurityFormWrapper = styled.div`
	margin-top: 42px;

	& > :first-child {
		margin-bottom: 40px;
	}
`;

export const SecurityFormColumn = styled.div`
	margin-bottom: 40px;
	display: flex;
	flex-direction: column;
	font-family: Secondary;

	${media.maxDesktop`
		margin-bottom: 40px;
		margin-right: 50px;
		width: calc(50% - 50 / 2);
	`}
`;

export const StepsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	background-color: ${Color.GRAY_1};
	border-radius: 4px;
	padding: 18px;
	margin-bottom: 35px;
`;

export const AuthenticatorSteps = styled.p<AuthenticatorStepsProps>`
	position: relative;
	padding-left: 30px;
	margin: 8px 0;

	&:before {
		content: '${({ stepNumber }) => stepNumber && stepNumber}';
		position: absolute;
		width: 20px;
		height: 20px;
		left: 0;
		border-radius: 50%;
		background: ${Color.GRAY_6};
		text-align: center;
		line-height: 20px;
		color: white;
		font-size: 13px;
	}
`;
