import React, { useReducer } from 'react';
import { ResponsiveContainer, AreaChart, XAxis, YAxis, CartesianGrid, Tooltip, Area } from 'recharts';
import numeral from 'numeral';
import { FormatGraphData } from '../../../../../../services/format-graph-data';
import Chart from './AreaChartBlockStyle';
import { Color } from '../../../../../../gfx/constants';

interface Props {
	data?: Array<FormatGraphData>;
	width?: string;
	height?: string;
}

interface ChartToolTipPayload {
	color: string;
	dataKey: string;
	fill: string;
	fillOpacity: number;
	formatter?: string;
	name: string;
	payload: FormatGraphData;
	stroke: string;
	strokeWidth: number;
	unit?: string;
	value: number;
}

interface CustomCursorArgs {
	payload?: ChartToolTipPayload[];
	points?: { x: number; y: number }[];
	dispatch: React.Dispatch<Action>;
	activeX?: number;
}

interface CustomTooltipArgs {
	payload?: ChartToolTipPayload[];
	active?: boolean;
	dispatch: React.Dispatch<Action>;
	activeDate: string | undefined;
}

interface CustomXaxisTickArgs {
	x?: number;
	y?: number;
	payload?: { value: number };
}

type Action = {
	type: 'change' | 'reset';
	payload?: State;
};

interface State {
	activeDate?: string;
	activeTime?: string;
	activeX?: number;
}

const initialState = { activeDate: undefined, activeTime: undefined, activeX: undefined };

const reducer: React.Reducer<State, Action> = (state, { type, payload }) => {
	// need optimization since actions can be called many times in one second
	if (JSON.stringify(state) === JSON.stringify(payload)) {
		return state;
	}

	switch (type) {
		case 'change':
			return { ...state, ...payload };
		case 'reset':
			return initialState;
		default:
			return state;
	}
};

export default function AreaChartBlock(props: Props) {
	const [state, dispatch] = useReducer(reducer, initialState);
	const { data, width = '94%', height = '94%' } = props;
	const { activeDate, activeX } = state;

	return (
		<Chart>
			<ResponsiveContainer width={width} height={height}>
				<AreaChart data={data || []} margin={{ bottom: 5 }}>
					<defs>
						<linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1.2">
							<stop offset="5%" stopColor="#c2c2d6" stopOpacity={0.8} />
							<stop offset="95%" stopColor="#c2c2d6" stopOpacity={0} />
						</linearGradient>
					</defs>
					<XAxis
						tickLine={false}
						interval="preserveStartEnd"
						axisLine={{ stroke: Color.GRAY_5, strokeWidth: 1 }}
						dataKey="xAxis"
						padding={{ left: 0, right: 55 }}
					/>
					<YAxis
						tickLine={false}
						tickFormatter={(payload) => numeral(payload).format('Oa')}
						padding={{ top: 40, bottom: 0 }}
						axisLine={false}
					/>
					<CartesianGrid horizontal={false} />
					<Tooltip
						content={<CustomTooltip dispatch={dispatch} activeDate={activeDate} />}
						cursor={<CustomCursor dispatch={dispatch} activeX={activeX} />}
					/>
					<Area
						animationDuration={750}
						type="linear"
						dataKey="dag"
						stroke={Color.BLUE_1}
						strokeWidth={1}
						fillOpacity={0}
						activeDot={{ stroke: Color.GREEN_2, strokeWidth: 5, r: 5, fill: 'white' }}
					/>
				</AreaChart>
			</ResponsiveContainer>
			{/* <Chart.TransactionInfoBlock x={activeX || 0} active={activeX !== undefined}>
				<Chart.TransactionTime>{activeTime}</Chart.TransactionTime>
				<Chart.TransactionDate>{activeDate}</Chart.TransactionDate>
			</Chart.TransactionInfoBlock> */}
		</Chart>
	);
}

function CustomTooltip(args: CustomTooltipArgs) {
	const { payload, active, dispatch, activeDate } = args;

	if (active) {
		return (
			<Chart.CustomTooltip>
				<Chart.CustomPayload>{payload && payload[0].value}</Chart.CustomPayload>
				<Chart.CustomCurrency>DAG</Chart.CustomCurrency>
				<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M17 7H21M21 7V11M21 7L13 15L9 11L3 17" stroke="#00BB68" strokeWidth="2" />
				</svg>
				<Chart.CustomPayloadDate>{activeDate}</Chart.CustomPayloadDate>
			</Chart.CustomTooltip>
		);
	}

	dispatch({ type: 'reset' });
	return null;
}

function CustomCursor(args: CustomCursorArgs) {
	const { payload, points, dispatch, activeX } = args;

	if (!Array.isArray(payload) || !payload[0] || !points) {
		return null;
	}

	if (activeX === points[0].x) {
		return (
			<line
				x1={points[0].x}
				y1={points[0].y}
				x2={points[1].x}
				y2={points[1].y}
				stroke={Color.GRAY_8}
				strokeDasharray="2 2"
				strokeWidth="2px"
			/>
		);
	}

	dispatch({
		type: 'change',
		payload: {
			activeDate: payload[0].payload.formattedDate,
			activeTime: payload[0].payload.formattedTime,
			activeX: points[0].x,
		},
	});

	return (
		<line x1={points[0].x} y1={points[0].y} x2={points[1].x} y2={points[1].y} stroke={Color.GRAY_5} strokeWidth="2px" />
	);
}
