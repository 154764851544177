import * as React from 'react';
import { NavLink } from 'react-router-dom';

import { RoutesUrls } from '../../constants';
import { PrivateRoute } from '../../views/private/routes';
import BurgerMenu from '../burger-menu/BurgerMenu';
import Button from '../button/Button';
import Logo from '../logo/Logo';

import { HeaderContainer, HeaderWrap, PrivateBurgerMenu, PrivateBurgerMenuLink } from './HeaderStyle';

interface Props {
	white?: boolean;
	isPrivate?: boolean;
	sidebarLinks?: PrivateRoute[];
	userName?: string;
	userCompany?: string;
}

export default function HeaderTpl(props: Props) {
	const { isPrivate = false, sidebarLinks, userCompany, userName, white } = props;

	const getPrivateBurgerMenu = () => {
		return (
			<BurgerMenu right>
				<PrivateBurgerMenu>
					{sidebarLinks &&
						sidebarLinks.map((link) => (
							<NavLink exact key={link.url} to={link.url}>
								<PrivateBurgerMenuLink>{link.navSidebarTitle}</PrivateBurgerMenuLink>
							</NavLink>
						))}
					<a href="https://help.dagpay.io/en/" target="_blank" rel="noopener noreferrer">
						<PrivateBurgerMenuLink>Support</PrivateBurgerMenuLink>
					</a>
					<Button.MobileNavLink to={RoutesUrls.LOGOUT}>Log Out</Button.MobileNavLink>
				</PrivateBurgerMenu>
				<div>
					<div>{userName}</div>
					<div>{userCompany}</div>
				</div>
			</BurgerMenu>
		);
	};

	return (
		<HeaderContainer white={white}>
			<HeaderWrap>
				<Logo to={RoutesUrls.PRIVATE} hasWhiteBackground={white} />
				{isPrivate && getPrivateBurgerMenu()}
			</HeaderWrap>
		</HeaderContainer>
	);
}
