import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { withRouter } from 'react-router-dom';
import ReactSVG from 'react-svg';

import Loader from '../../../../components/loader/Loader';
import PanelNew, { PanelSectionType } from '../../../../components/panel-new/PanelNew';
import HookBaseField from '../../../../components/react-hook-form/HookBaseField';
import {
	HookFormColumn,
	HookFormSection,
	HookFormSectionTitle,
	HookFormSeparator,
} from '../../../../components/react-hook-form/ReactHookFormStyle';
import View from '../../../../components/view/View';
import { Countries, UserRole } from '../../../../constants';
import { H2, NotificationRowWrapper, NotificationText } from '../../../../gfx/globals';
import { useStoreState } from '../../../../services/store';
import { AccountStatus, TitleWrapper, VerificationStateText } from '../SettingsStyle';

interface AccountDetailsFields {
	firstName: string;
	country: string;
	email: string;
	lastName: string;
	phoneNumber: string;
}

const accountStates = {
	NOT_VERIFIED: { text: 'Account not verified', iconPath: '/files/svg/private/dashboard/icon-fail.svg' },
	VERIFIED: { text: 'Account verified', iconPath: '/files/svg/private/Success.svg' },
	PENDING: { text: 'Account verification pending', iconPath: '/files/svg/private/dashboard/icon-pending.svg' },
	INITIATED: { text: 'Account verification started', iconPath: '/files/svg/private/dashboard/icon-pending.svg' },
	REJECTED: { text: 'Account verification rejected', iconPath: '/files/svg/private/dashboard/icon-fail.svg' },
	MANUALLY_REJECTED: { text: 'Account verification rejected', iconPath: '/files/svg/private/dashboard/icon-fail.svg' },
	PROFILE_MISMATCH: {
		text: 'Account verification mismatch',
		iconPath: '/files/svg/private/dashboard/icon-pending.svg',
	},
	FRAUD: { text: 'Account verification issue', iconPath: '/files/svg/private/dashboard/icon-pending.svg' },
	CANT_RETRY: {
		text: 'Account verification terminally rejected',
		iconPath: '/files/svg/private/dashboard/icon-fail.svg',
	},
};

function AccountDetailsView() {
	const { activeViewer, verifications } = useStoreState((state) => ({
		verifications: state.verification.verifications,
		activeViewer: state.viewer.activeViewer,
	}));

	const methods = useForm<AccountDetailsFields>();

	if (!activeViewer || !verifications) {
		return <Loader />;
	}

	const getVerificationSection = ({ text, iconPath }: { text: string; iconPath: string }) => (
		<AccountStatus>
			<ReactSVG src={iconPath} />
			<VerificationStateText>{text}</VerificationStateText>
		</AccountStatus>
	);

	const getIdentityVerificationState = () => {
		const isTeamMember =
			activeViewer.role === UserRole.TEAM_MEMBER || activeViewer.role === UserRole.ADVANCED_TEAM_MEMBER;

		if (!verifications.identity || !verifications.identity.verificationStatus) {
			// team member does not need know here
			if (isTeamMember) {
				return '';
			}

			return getVerificationSection(accountStates['NOT_VERIFIED']);
		}

		return getVerificationSection(accountStates[verifications.identity.verificationStatus]);
	};

	return (
		<>
			<TitleWrapper>
				<H2>Account details</H2>
				<NotificationRowWrapper>
					<NotificationText>
						Please contact <a href="mailto:support@dagpay.io">support@dagpay.io</a> if you need to update any disabled
						information.
					</NotificationText>
				</NotificationRowWrapper>
			</TitleWrapper>
			<View.ListHeader hasNoBorder></View.ListHeader>
			<PanelNew>
				<PanelNew.Section first white panelType={PanelSectionType.FORM} separateWithBorder>
					<FormProvider {...methods}>
						<HookFormSeparator>
							<HookFormSectionTitle>Account details</HookFormSectionTitle>
							{getIdentityVerificationState()}
						</HookFormSeparator>
						<HookFormSection>
							<HookFormColumn>
								<HookBaseField name="firstName" label="First name" value={activeViewer.firstName} disabled />
								<HookBaseField name="country" label="Country" value={Countries[activeViewer.countryCode]} disabled />
							</HookFormColumn>
							<HookFormColumn>
								<HookBaseField name="lastName" label="Last name" value={activeViewer.lastName} disabled />
								<HookBaseField name="email" label="Email" value={activeViewer.email} disabled />
							</HookFormColumn>
						</HookFormSection>
					</FormProvider>
				</PanelNew.Section>
				<PanelNew.Section gray panelType={PanelSectionType.BUTTON} last></PanelNew.Section>
			</PanelNew>
		</>
	);
}

export default withRouter(AccountDetailsView);
