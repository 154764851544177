import React from 'react';
import styled from 'styled-components/macro';
import { EnumType } from 'typescript';
import { Color } from '../../gfx/constants';

const Colored = styled.span<{ color?: string }>`
	color: ${(props) => (props.color ? props.color : Color.GRAY_12)};
	border: 1px solid ${(props) => (props.color ? props.color : Color.GRAY_12)};
	border-radius: 4px;
	padding: 6px 8px;
	white-space: nowrap;
	font-family: PrimaryMedium;
`;

export interface ColoredAtmStatusProps {
	status: string | EnumType;
	color?: string;
}

export const ColoredStatusDisplay = (props: ColoredAtmStatusProps) => {
	const { status, color } = props;

	const capital = () => {
		const lower = status.toString().toLowerCase();
		return lower.replace(lower.charAt(0), lower.charAt(0).toUpperCase());
	};

	return <Colored color={color}>{capital()}</Colored>;
};

export default ColoredStatusDisplay;
