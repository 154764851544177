import { WS_RPC_MAX_PING_FAILURES, WS_RPC_MAX_RECONNECT_FAILURES, WS_RPC_PING_INTERVAL } from '../constants';

export interface ParamsMap {
	[x: string]: string | number | undefined;
}

export type JsonRpc2Version = '2.0';

export type JsonRpc2Id = string;

export enum JsonRpc2Error {
	PARSE_ERROR = -32700,
	INVALID_REQUEST = -32600,
	METHOD_NOT_FOUND = -32601,
	INVALID_PARAMS = -32602,
	INTERNAL_ERROR = -32603,
}

export interface ResolverMap<TResult> {
	[x: string]: {
		resolve(info: TResult): void;
		// tslint:disable-next-line:no-any
		reject(error: any): void;
	};
}

export interface Json {
	[x: string]: string | number | boolean | undefined | null | Json;
}

export interface Request {
	jsonrpc: string;
	id: string;
	method?: string;
	params?: Json;
	result?: Json;
}

// tslint:disable-next-line:no-any
export interface JsonRpc2Response<T = any> {
	jsonrpc: JsonRpc2Version;
	id: JsonRpc2Id | null;
	result?: T;
	error?: JsonRpc2ErrorInfo<T>;
}

export interface JsonRpc2Success<T> extends JsonRpc2Response<T> {
	result: T;
}

export interface JsonRpc2Failure<T> extends JsonRpc2Response<T> {
	id: JsonRpc2Id | null;
	error: JsonRpc2ErrorInfo<T>;
}

export interface JsonRpc2ErrorInfo<T> {
	code: JsonRpc2Error;
	message: string;
	data?: T;
}

export default class WsRpcClient {
	protected ws: WebSocket;
	protected url: string;
	protected isActive = true;
	protected pingFailures: number;
	protected reconnectFailures: number;
	protected reconnectInterval: number;
	protected requestResolverMap: ResolverMap<Json> = {};
	protected pingWsServer?: NodeJS.Timeout;

	public constructor(url: string) {
		this.url = url;
		this.pingWsServer = undefined;
		this.pingFailures = 0;
		this.reconnectFailures = 0;
		this.reconnectInterval = 500;

		try {
			this.ws = new WebSocket(this.url);
		} catch (e) {
			throw new Error(
				`opening websocket rpc client connection to ${this.url} failed: ${e instanceof Error ? e.message : e}`,
			);
		}

		this.setupEventListeners();
	}

	private connect() {
		try {
			this.ws = new WebSocket(this.url);
		} catch (e) {
			throw new Error(
				`opening websocket rpc client connection to ${this.url} failed: ${e instanceof Error ? e.message : e}`,
			);
		}

		this.setupEventListeners();
	}

	private reconnect() {
		console.log({ isAlive: this.isActive, reconattep: this.reconnectFailures, interval: this.reconnectInterval });

		// stop any pinging before reconnecting, otherwise could be double pinging
		if (this.pingWsServer) {
			console.log('stopped pinging');
			clearInterval(this.pingWsServer);

			this.pingWsServer = undefined;
		}

		// attempt to reconnect
		setTimeout(() => {
			this.connect();

			// with backoff: 250, 500, 1_000, 2_000, 4_000, 8_000, 10_000
		}, Math.min(10_000, (this.reconnectInterval += this.reconnectInterval)));
	}

	protected setupEventListeners() {
		this.ws.onopen = () => this.onOpen();
		this.ws.onmessage = (message) => this.onMessage(message);
		this.ws.onclose = (evt) => this.onClose(evt);
		this.ws.onerror = (evt) => this.onerror(evt);
	}

	private onOpen() {
		console.log('onOpen');

		// reset on successful open
		this.isActive = true;
		this.reconnectFailures = 0;
		this.pingFailures = 0;
		this.reconnectInterval = 250;

		// call abstract function implementation
		if (this.ws.readyState === this.ws.OPEN) {
			this.onConnection();
		}

		// ping server to know if connection is still active
		this.pingWsServer = this.startPingInterval();
	}

	private startPingInterval() {
		console.log('started pinging server');
		return setInterval(async () => {
			// not ready to receive
			if (this.ws.readyState !== this.ws.OPEN) {
				return;
			}

			try {
				const pingResponse: Json = await new Promise(async (resolve, reject) => {
					const pongTimeout = setTimeout(() => {
						reject();
						// wait 90% interval, quit before sending another ping
					}, WS_RPC_PING_INTERVAL * 0.9);

					const pong = await this.request('PING', { event: 'PING' });

					clearTimeout(pongTimeout);

					resolve(pong);
				});

				// might contain error and no result
				if (pingResponse.result && typeof pingResponse.result === 'string' && pingResponse.result !== 'PONG') {
					throw new Error(`ping failed with: ${pingResponse.error ? pingResponse.error : 'something went wrong'}`);
				}

				// reset ping failures on pong message
				if (this.pingFailures > 0) {
					this.pingFailures = 0;
				}

				console.log({ pingResponse });
			} catch (error) {
				// increase failed attempts counter
				this.pingFailures += 1;

				console.log(`missing PONG from API: ${this.pingFailures}`, { error });

				// PING has no answer long enough, try to reconnect
				if (this.pingFailures >= WS_RPC_MAX_PING_FAILURES) {
					console.log('trigger reconnect ');
					// reconnecting faster, onClose will not trigger another reconnect because readyState is OPEN instead CLOSED
					this.reconnect();
				}
			}
		}, WS_RPC_PING_INTERVAL);
	}

	// tslint:disable-next-line:no-empty
	protected async onConnection() {}

	// for manual closing, everything ok
	public close() {
		console.log('manual close');
		// stop pinging
		if (this.pingWsServer) {
			console.log('stopped pinging');
			clearInterval(this.pingWsServer);

			this.pingWsServer = undefined;
		}

		// raise normal manual close flag
		this.isActive = false;
		this.ws.close();
	}

	protected onClose(evt: CloseEvent) {
		console.log('onClose', { evt });

		// socket closed manually, ok to close
		if (!this.isActive) {
			return;
		}

		// still not responding, give up
		if (this.reconnectFailures >= WS_RPC_MAX_RECONNECT_FAILURES) {
			return this.close();
		}

		if (this.ws.readyState === this.ws.CLOSED) {
			// increase reconnect attempts
			this.reconnectFailures += 1;

			// attempt to reconnect
			this.reconnect();
		}
	}

	protected async onerror(evt: Event) {
		console.log({ onerror: evt });

		// onClose() called after onerror, reconnect handled there
	}

	protected async onMessage(message: MessageEvent) {
		// only handle string messages
		if (typeof message.data !== 'string') {
			return;
		}

		let request: Request;

		// only handle messages that parse as json
		try {
			request = JSON.parse(message.data);
		} catch (e) {
			console.warn('parsing message as JSON failed', e);

			return;
		}

		if (request.method) {
			await this.handleRequest(request);
		} else {
			this.handleResponse(request);
		}
	}

	public async request(method: string, params: ParamsMap): Promise<Json> {
		// build the rpc request payload
		const id = this.getUuid();
		const payload = {
			jsonrpc: '2.0',
			id,
			method,
			params,
		};

		// create a promise that we can later resolve once we get the response
		const promise = new Promise<Json>((resolve, reject) => {
			this.requestResolverMap[id] = {
				resolve,
				reject,
			};
		});

		// send the rpc request
		this.ws.send(JSON.stringify(payload));

		return promise;
	}

	protected async handleRequest(request: Request) {
		if (!request.method) {
			throw new Error('Request method not available, this should not happen');
		}

		if (!request.params) {
			throw new Error('Got RPC request but missing params');
		}

		// handle server ping
		if (request.method === 'PING') {
			this.handlePing(request.id);

			return;
		}

		let method = request.method;
		let args: string[] = [];

		// check if the method contains additional parameters
		if (method.indexOf(':') !== -1) {
			const tokens = method.split(':');

			method = tokens[0];
			args = tokens.slice(1);
		}

		await this.handleMethod(method, args, request.params);
	}

	private handlePing(requestId: string) {
		// construct pong message
		const pong: JsonRpc2Response = {
			jsonrpc: '2.0',
			id: requestId,
			result: 'PONG',
		};

		// connection not open, do nothing
		if (this.ws.readyState !== this.ws.OPEN) {
			return;
		}

		try {
			// respond with PONG
			this.ws.send(JSON.stringify(pong));
		} catch (error) {
			console.warn(`PONG failed: ${error instanceof Error ? error.message : 'something went wrong'}`);
		}
	}

	// tslint:disable-next-line:no-empty
	protected async handleMethod(_method: string, _args: string[], _params: Json) {}

	protected handleResponse(request: Request) {
		const resolver = this.requestResolverMap[request.id];

		if (!resolver) {
			console.warn(`resolver for rpc request "${request.id}" could not be found`);

			return;
		}

		if (!request.result) {
			throw new Error('Got RPC response but result is missing');
		}

		resolver.resolve(request.result);
	}

	protected getUuid() {
		return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
			// tslint:disable-next-line:no-bitwise
			const r = (Math.random() * 16) | 0;
			// tslint:disable-next-line:no-bitwise
			const v = c === 'x' ? r : (r & 0x3) | 0x8;

			return v.toString(16);
		});
	}
}
