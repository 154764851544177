import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components/macro';
import SupportLink from '../support-link/SupportLink';
import { RoutesUrls } from '../../constants';
import { Color } from '../../gfx/constants';
import { Avatar } from '../../gfx/globals';
import { media } from '../../services/media';
import { useStoreState } from '../../services/store';
import { RouteProps } from '../../typings';

const PrivateHeaderWrap = styled.div`
	position: sticky;
	background: white;
	top: 0;
	width: 100%;
	height: 80px;
	z-index: 100;

	${media.fromDesktop`
		border-bottom: 1px solid #f2f2f2;
	`}

	${media.maxDesktop`
		display: none;
	`}
`;
const PrivateHeaderMenuWrap = styled.div`
	display: flex;
	float: right;
	${media.fromDesktop`
		border-bottom: 1px solid #f2f2f2;
	`}
`;
const PrivateHeaderUserMenu = styled.div`
	display: flex;
	padding: 24px 70px 24px 0;
	position: relative;
	float: right;
	cursor: pointer;
	margin-left: auto;

	p {
		font-size: 14px;
		margin: 0;
		text-overflow: ellipsis;
		overflow: hidden;
		max-width: 400px;
		&:last-of-type {
			color: #babcc3;
		}
	}

	&::after {
		content: '';
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 7px 5px 0 5px;
		border-color: ${Color.GREEN_3} transparent transparent transparent;
		right: 44px;
		position: absolute;
		top: 37px;
	}
`;

const PrivateHeaderUserDropDown = styled.ul`
	position: absolute;
	padding: 0 20px;
	background: #fff;
	box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
	border-radius: 4px;
	top: calc(67% + 10px);
	right: 0px;
	min-width: 215px;

	li {
		list-style: none;
		font-size: 14px;
		padding: 10px;
		font-family: Secondary;
		color: ${Color.ORANGE};
		cursor: pointer;
	}
`;

function PrivateHeader(props: RouteProps) {
	const [isDropDownVisible, setIsDropDownVisible] = useState(false);
	const [wrapperRef] = useState(React.createRef<HTMLDivElement>());

	const { activeViewer } = useStoreState((state) => ({
		activeViewer: state.viewer.activeViewer,
	}));

	const handleClickOutside: EventListenerOrEventListenerObject = (event) => {
		const currentElement = event.target;

		if (
			wrapperRef &&
			wrapperRef.current &&
			currentElement instanceof Node &&
			!wrapperRef.current.contains(currentElement)
		) {
			setIsDropDownVisible(false);
		}
	};

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	});

	const toggleDropDownVisibility = () => {
		setIsDropDownVisible(!isDropDownVisible);
	};

	const handleLogout = () => {
		props.history.push(RoutesUrls.LOGOUT);
	};

	return (
		<PrivateHeaderWrap>
			<PrivateHeaderMenuWrap>
				<SupportLink />
				<PrivateHeaderUserMenu onClick={toggleDropDownVisibility} ref={wrapperRef}>
					{activeViewer && (
						<>
							<Avatar>
								{activeViewer.firstName.charAt(0).toUpperCase()}
								{activeViewer.lastName.charAt(0).toUpperCase()}
							</Avatar>
							<div>
								<p>
									{activeViewer.firstName} {activeViewer.lastName}
								</p>
								<p>{activeViewer.companyName}</p>
							</div>
						</>
					)}
					{isDropDownVisible && (
						<PrivateHeaderUserDropDown>
							<li onClick={handleLogout}>Logout</li>
						</PrivateHeaderUserDropDown>
					)}
				</PrivateHeaderUserMenu>
			</PrivateHeaderMenuWrap>
		</PrivateHeaderWrap>
	);
}

export default withRouter(PrivateHeader);
