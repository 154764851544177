import axios from 'restyped-axios';
import { ApiResponse } from '../typings';
export const api = axios.create<CountryApi>();

export interface CountryApi {
	'/countries': {
		GET: {
			response: ApiResponse<Country[]>;
		};
	};
}

export interface Country {
	code: string;
	name: string;
}

export const getCountriesApi = async () => {
	const getCountriesResponse = await api.get('/countries');

	if (!getCountriesResponse.data.payload) {
		return null;
	}

	// due to the high regulatory environment
	// https://visioncraft.atlassian.net/browse/DGP-129
	const forbiddenCountriesList = ['UM', 'KP'];

	getCountriesResponse.data.payload = getCountriesResponse.data.payload.filter(
		(country) => forbiddenCountriesList.includes(country.code) === false,
	);

	return getCountriesResponse.data;
};
