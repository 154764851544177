import { Country } from '../api/countryApi';
import { FieldOptionProps } from '../components/field/Field';

export function getCountryOptions(countries: Country[]) {
	if (!countries) {
		return [];
	}

	const options: FieldOptionProps[] = countries
		? countries.map((item: Country) => ({
				label: item.name,
				value: item.code,
		  }))
		: [];

	return options;
}
