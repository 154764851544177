import * as React from 'react';
import { withRouter } from 'react-router';
import styled from 'styled-components/macro';

import { EmailInvoiceStateEnum, InvoiceState, RecurringInvoiceState } from '../../api/invoiceApi';
import { Color } from '../../gfx/constants';
import { RouteProps } from '../../typings';

interface Props extends RouteProps {
	state: InvoiceState | RecurringInvoiceState | EmailInvoiceStateEnum;
}

interface StyledInvoiceProps {
	color: Color;
}

const StyledInvoiceState = styled.div<StyledInvoiceProps>`
	display: inline-block;
	color: ${(props) => props.color};
	border: 1px solid ${(props) => props.color};
	border-radius: 4px;
	padding: 6px 8px;
	font-family: PrimaryMedium;
	font-size: 14px;
	line-height: 16px;
`;

function ColoredInvoiceState(props: Props) {
	const { state } = props;
	const isLocationEmailInvoice = /email-invoice/.exec(props.match.path);

	// Green states
	if (state === InvoiceState.PAID) {
		return <StyledInvoiceState color={Color.GREEN_3}>Paid</StyledInvoiceState>;
	}
	if (state === InvoiceState.PAID_EXPIRED) {
		return <StyledInvoiceState color={Color.GREEN_3}>Paid</StyledInvoiceState>;
	}
	if (state === RecurringInvoiceState.ACTIVE) {
		return <StyledInvoiceState color={Color.GREEN_3}>Active</StyledInvoiceState>;
	}
	if (state === EmailInvoiceStateEnum.SENT || (state === InvoiceState.PENDING && isLocationEmailInvoice)) {
		return <StyledInvoiceState color={Color.GREEN_3}>Sent</StyledInvoiceState>;
	}

	// Orange states
	if (state === InvoiceState.WAITING_FOR_CONFIRMATION) {
		return <StyledInvoiceState color={Color.ORANGE}>Pending</StyledInvoiceState>;
	}
	if (state === InvoiceState.PENDING && !isLocationEmailInvoice) {
		return <StyledInvoiceState color={Color.ORANGE}>Pending</StyledInvoiceState>;
	}
	if (state === InvoiceState.EXPIRED || state === EmailInvoiceStateEnum.EXPIRED) {
		return <StyledInvoiceState color={Color.ORANGE}>Expired</StyledInvoiceState>;
	}
	if (state === RecurringInvoiceState.ENDED) {
		return <StyledInvoiceState color={Color.ORANGE}>Ended</StyledInvoiceState>;
	}

	// Red states
	if (
		state === InvoiceState.CANCELLED ||
		state === RecurringInvoiceState.CANCELLED ||
		state === EmailInvoiceStateEnum.CANCELLED
	) {
		return <StyledInvoiceState color={Color.RED_2}>Cancelled</StyledInvoiceState>;
	}

	return <StyledInvoiceState color={Color.RED_2}>Failed</StyledInvoiceState>;
}

export default withRouter(ColoredInvoiceState);
