import React from 'react';
import styled, { css } from 'styled-components/macro';
import { Color } from '../../gfx/constants';
import { ColumnedFlex, Strong } from '../../gfx/globals';
import dayjs from 'dayjs';
import { GLOBAL_DATE_FORMAT } from '../../constants';

interface Props {
	dateString: string;
}

export const StyledWalletUsageDate = styled.div<{ isDate: boolean }>`
	${(props) =>
		!props.isDate &&
		css`
			line-height: 18px;
			font-family: Primary;
			color: ${Color.ORANGE};
		`}
`;

export default function ColoredWalletUsageDate(props: Props) {
	const { dateString } = props;

	if (dayjs(dateString).isValid()) {
		return (
			<StyledWalletUsageDate isDate={true}>
				<ColumnedFlex>
					<Strong>{dayjs(dateString).format(GLOBAL_DATE_FORMAT)}</Strong>
					{dayjs(dateString).format('HH:mm')}
				</ColumnedFlex>
			</StyledWalletUsageDate>
		);
	}
	return <StyledWalletUsageDate isDate={false}>Unused</StyledWalletUsageDate>;
}
