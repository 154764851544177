import { Scopes } from '../../constants';
import { hasPermission } from '../../services/has-permission';

interface Props {
	children?: any;
	permissions: Scopes[];
}

function WithPermission(props: Props) {
	const { permissions, children } = props;

	if (hasPermission(permissions)) {
		return children;
	}

	return null;
}

export default WithPermission;
