import { FlattenSimpleInterpolation } from 'styled-components';
import { css } from 'styled-components/macro';

interface Sizes {
	fromDesktop: number;
	maxDesktop: number;
	fromTablet: number;
	maxTablet: number;
	fromMobile: number;
	maxMobile: number;
	fromTable: number;
	maxTable: number;
	fromNewInvoiceTable: number;
	maxNewInvoiceTable: number;
	fromTransactionTable: number;
	maxTransactionTable: number;
	fromSize1400: number;
	maxSize1400: number;
	fromSize481: number;
	maxSize481: number;
	fromSize800: number;
	maxSize800: number;
	fromSize1000: number;
	maxSize1000: number;
}

// MEDIA QUERIES
export const mobile = 376;
export const size481 = 481;
export const table = 681;
export const tablet = 720;
export const size800 = 800;
export const size1000 = 1000;
export const desktop = 1024;
export const newInvoiceTable = 1200;
export const transactionTable = 1260;
export const size1400 = 1400;

const sizes: Sizes = {
	fromDesktop: desktop,
	maxDesktop: desktop - 1,
	fromTablet: tablet,
	maxTablet: tablet - 1,
	fromMobile: mobile,
	maxMobile: mobile - 1,
	fromTable: table,
	maxTable: table - 1,
	fromNewInvoiceTable: newInvoiceTable,
	maxNewInvoiceTable: newInvoiceTable - 1,
	fromTransactionTable: transactionTable,
	maxTransactionTable: transactionTable - 1,
	fromSize1400: size1400,
	maxSize1400: size1400 - 1,
	fromSize481: size481,
	maxSize481: size481 - 1,
	fromSize800: size800,
	maxSize800: size800 - 1,
	fromSize1000: size1000,
	maxSize1000: size1000 - 1,
};

// How to use:
// ${media.maxDesktop`
// 	padding: 0;
// `}

const mediaKeys = Object.keys(sizes) as (keyof typeof sizes)[];

type AccumulatorResult = Record<
	keyof typeof sizes,
	// tslint:disable-next-line:no-any
	(l: TemplateStringsArray, ...p: any[]) => string | null | FlattenSimpleInterpolation
>;

// Iterate through the sizes and create a media template
export const media = mediaKeys.reduce(
	(acc, label) => {
		// tslint:disable-next-line:no-any
		acc[label] = (literals: TemplateStringsArray, ...placeholders: any[]) => {
			if (label.startsWith('max')) {
				return css`
					@media (max-width: ${sizes[label]}px) {
						${css(literals, ...placeholders)};
					}
				`;
			}

			if (label.startsWith('from')) {
				return css`
					@media (min-width: ${sizes[label]}px) {
						${css(literals, ...placeholders)};
					}
				`;
			}

			return null;
		};

		return acc;
	},
	// tslint:disable-next-line:no-object-literal-type-assertion
	{} as AccumulatorResult,
);
