import React, { useEffect } from 'react';
import { Redirect } from 'react-router';
import { Route, Switch } from 'react-router-dom';

import PrivateRoute from '../components/private-route/PrivateRoute';
import ViewerProvider from '../components/viewer-provider/ViewerProvider';
import { RoutesUrls } from '../constants';
import { useStoreActions, useStoreState } from '../services/store';
import { RouteProps } from '../typings';

import InvoiceView from './invoices/InvoiceView';
import PublicView from './public/PublicView';
import { CombinedVerificationsInfo } from './../api/verificationApi';
import * as qs from 'query-string';

export interface AppRouteProps extends RouteProps {
	referralCode: string;
}

export default function App() {
	const { isAuthorized, activeViewer, verifications } = useStoreState((state) => ({
		isAuthorized: state.viewer.isAuthorized,
		activeViewer: state.viewer.activeViewer,
		verifications: state.verification.verifications as CombinedVerificationsInfo | null,
	}));

	const { getVerifications } = useStoreActions((actions) => ({
		...actions.verification,
	}));

	const isLoggedIn = isAuthorized && !!activeViewer;

	useEffect(() => {
		const fetchVerifications = async () => {
			await getVerifications({});
		};
		fetchVerifications();
	}, [getVerifications]);

	// Ensure backwards compatibility with old referral links
	const renderReferralCodeRedirect = (props: RouteProps<AppRouteProps>) => (
		<Redirect
			from="/public/sign-up/:referralCode?"
			to={{
				...props.location,
				pathname: `${RoutesUrls.SIGN_UP}/${props.match.params.referralCode ? props.match.params.referralCode : ''}`,
			}}
		/>
	);

	const renderRedirectToPrivate = (props: RouteProps) => {
		const redirectToVatm =
			props.location &&
			props.location.pathname === '/login' &&
			props.location.search === '?referer=VATM' &&
			!props.location.state;
		const redirectUrl = qs.parse(props.location.search).redirectUrl as string;

		// automatically redirect !verified! and !logged! in user coming from https://vatm.dagcoin.org/
		if (isLoggedIn && redirectToVatm) {
			// no wallet check in dashboard
			const verificationsOk =
				verifications &&
				verifications.isBusinessVerified &&
				verifications.isEmailVerified &&
				verifications.isIdentityVerified;

			if (verificationsOk) {
				return (
					<Redirect
						from="/login?referer=VATM"
						to={{
							pathname: RoutesUrls.VATMS,
						}}
					/>
				);
			}
		}

		if (isLoggedIn && redirectUrl) {
			const routes = Object.values(RoutesUrls) as string[];
			if (routes.includes(redirectUrl)) {
				return (
					<Redirect
						from="/"
						to={{
							pathname: redirectUrl,
						}}
					/>
				);
			}
		}
		// automatically redirect logged in user to dashboard
		if (
			isLoggedIn &&
			(props.location.pathname === '/' || props.location.pathname === '/login') &&
			!props.location.state
		) {
			return (
				<Redirect
					from="/"
					to={{
						...props.location,
						pathname: RoutesUrls.PRIVATE,
					}}
				/>
			);
		}

		return <PublicView {...props} />;
	};

	return (
		<main x-ms-format-detection="none">
			<ViewerProvider>
				<Switch>
					<Route path="/public/sign-up/:referralCode?" render={renderReferralCodeRedirect} />
					<PrivateRoute path={RoutesUrls.PRIVATE} unauthorizedPath="/" />
					<Route path="/invoices/pay/:invoiceId?" component={InvoiceView} />
					<Route path="/" render={renderRedirectToPrivate} />
				</Switch>
			</ViewerProvider>
		</main>
	);
}
