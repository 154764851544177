import { FormikFieldState } from 'formik-fields';
import * as React from 'react';

import { FieldProps } from '../field/Field';
import StyledField from '../field/FieldStyle';

interface BaseInputProps extends FieldProps {
	formikField: FormikFieldState<string | boolean | number | null | File[]>;
}

export default function BaseInput(props: BaseInputProps) {
	// tslint:disable-next-line:no-any
	const { formikField, form, isPassword = false, ...rest }: any = props;

	return (
		<StyledField.BaseInputWrapper>
			<StyledField.Input
				name={formikField.name}
				value={formikField.value || ''}
				onChange={(element: React.ChangeEvent<HTMLInputElement>) => {
					formikField.setValue(element.target.value);
				}}
				onBlur={() => formikField.handleBlur()}
				isPasswordField={isPassword}
				{...rest}
			/>
		</StyledField.BaseInputWrapper>
	);
}
