import * as React from 'react';
import { withRouter } from 'react-router';
import styled from 'styled-components/macro';

import { Color } from '../../gfx/constants';
import { RouteProps } from '../../typings';

import { InvitedUserStatus } from '../../constants';

interface Props extends RouteProps {
	state: InvitedUserStatus;
}

interface StyledInvoiceProps {
	color: Color;
}

const StyledInvitedUserStatus = styled.div<StyledInvoiceProps>`
	display: inline-block;
	color: ${(props) => props.color};
	border: 1px solid ${(props) => props.color};
	border-radius: 4px;
	padding: 6px 8px;
	font-family: PrimaryMedium;
	font-size: 14px;
	line-height: 16px;
`;

function ColoredInvitedUserState(props: Props) {
	const { state } = props;

	// Green states
	if (state === InvitedUserStatus.ACTIVE) {
		return <StyledInvitedUserStatus color={Color.GREEN_3}>Active</StyledInvitedUserStatus>;
	}

	// Orange states

	if (state === InvitedUserStatus.SENT) {
		return <StyledInvitedUserStatus color={Color.BLUE_1}>Sent</StyledInvitedUserStatus>;
	}
	if (state === InvitedUserStatus.EXPIRED) {
		return <StyledInvitedUserStatus color={Color.ORANGE}>Expired</StyledInvitedUserStatus>;
	}

	// Red states
	if (state === InvitedUserStatus.FAILED) {
		return <StyledInvitedUserStatus color={Color.RED_2}>Failed</StyledInvitedUserStatus>;
	}

	// gray states
	if (state === InvitedUserStatus.INACTIVE) {
		return <StyledInvitedUserStatus color={Color.GRAY_9}>Inactive</StyledInvitedUserStatus>;
	}

	return <StyledInvitedUserStatus color={Color.RED_2}>Failed</StyledInvitedUserStatus>;
}

export default withRouter(ColoredInvitedUserState);
