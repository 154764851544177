import React, { useEffect } from 'react';

import { useStoreActions, useStoreState } from '../../services/store';
import Loader from '../loader/Loader';

export default function ViewerProvider({ children }: { children: JSX.Element }) {
	const { isViewerLoading } = useStoreState((state) => ({
		isViewerLoading: state.viewer.isViewerLoading,
	}));

	const { getViewer } = useStoreActions((actions) => ({
		...actions.viewer,
	}));

	useEffect(() => {
		const fetchViewer = async () => {
			await getViewer();
		};
		fetchViewer();
	}, [getViewer]);

	if (isViewerLoading) {
		return <Loader />;
	}

	return children;
}
