import tag from 'clean-tag';
import { FastField as FieldFormik } from 'formik';
import * as React from 'react';
import ReactSVG from 'react-svg';
import styled from 'styled-components/macro';

import { FieldTypes } from '../../constants';
import { Color, Gutter } from '../../gfx/constants';

interface Props {
	name?: string;
	label: string;
	value: string;
	disabled?: boolean;
	selected?: boolean;
	isActive?: boolean;
	isCentered?: boolean;
	icon?: JSX.Element;
	radioWithLabel?: JSX.Element;
	checkIcon?: boolean;
	className?: string;
	left?: boolean;
	environmentViewLabel?: boolean;
	onClick?(e: React.MouseEvent<any>): void;
}

interface CheckMarkProps {
	isActive?: boolean;
	checkIcon?: boolean;
}

interface CheckIconProps {
	src: string;
	isActive?: boolean;
}

interface RadioLabelProps {
	environmentViewLabel?: boolean;
}

interface LabelProps {
	environmentViewLabel?: boolean;
}

export const Label = styled.label<LabelProps>`
	user-select: none;
	cursor: pointer;

	@media only screen and (max-width: 650px) {
		margin-bottom: ${({ environmentViewLabel }) => environmentViewLabel && `${Gutter.LARGE}`};
	}

	@media only screen and (max-width: 440px) {
		flex-direction: ${({ environmentViewLabel }) => environmentViewLabel && 'column'};
	}

	input {
		position: absolute;
		margin: 0;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		opacity: 0;
		cursor: pointer;
	}
`;

export const LabelText = styled.div`
	margin: 0;
	font-size: 18px;
`;

const RadioLabel = styled.div<RadioLabelProps>`
	display: flex;
	align-items: center;
	justify-content: center;

	@media only screen and (max-width: 650px) {
		padding-left: ${({ environmentViewLabel }) => environmentViewLabel && '20px'};
		justify-content: ${({ environmentViewLabel }) => {
			if (environmentViewLabel) {
				return 'left';
			}

			return 'center';
		}};
	}

	@media only screen and (max-width: 440px) {
		width: ${({ environmentViewLabel }) => environmentViewLabel && '100% !important'};
		padding-left: ${({ environmentViewLabel }) => environmentViewLabel && 0};
		justify-content: ${({ environmentViewLabel }) => environmentViewLabel && 'center'};
	}
`;

const CheckMark = styled.div<CheckMarkProps>`
	position: relative;
	height: 24px;
	width: 24px;
	min-width: 24px;
	background-color: transparent;
	border-radius: 50%;
	border: ${({ isActive, checkIcon }) => {
		if (isActive && !checkIcon) {
			return `8px solid ${Color.GREEN_3}`;
		}
		if (checkIcon && isActive) {
			return 'none';
		}

		return '2px solid #969aa4';
	}};
	margin-right: 12px;
`;

const CheckIcon = styled(tag)<CheckIconProps>`
	display: ${({ isActive }) => !isActive && 'none'};
	position: absolute;
	top: 0;
	left: 0;
	width: 24px;
	height: 24px;
`;

export default function RadioSelector(props: Props) {
	const {
		name,
		label,
		value,
		selected,
		onClick,
		disabled,
		isActive,
		isCentered,
		icon,
		checkIcon,
		className,
		left,
		environmentViewLabel,
		...rest
	} = props;

	return (
		<Label environmentViewLabel={environmentViewLabel}>
			{icon && icon}
			<RadioLabel className={className} environmentViewLabel={environmentViewLabel}>
				<CheckMark isActive={isActive} checkIcon={checkIcon}>
					{checkIcon && (
						<CheckIcon
							blacklist={['isActive']}
							is={ReactSVG}
							isActive={isActive}
							src="/files/svg/private/Success.svg"
						/>
					)}
					<FieldFormik
						type={FieldTypes.RADIO}
						name={name}
						title={label}
						value={value}
						disabled={disabled}
						defaultChecked={selected}
						onClick={onClick}
						{...rest}
					/>
				</CheckMark>
				<LabelText>
					<strong>{label}</strong>
				</LabelText>
			</RadioLabel>
		</Label>
	);
}
