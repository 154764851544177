import { thunk, Thunk } from 'easy-peasy';

import {
	changePasswordApi,
	ChangePasswordRequest,
	signUpApi,
	SignUpRequest,
	validateUniqueEmailApi,
	ValidateUniqueEmailRequest,
	ValidateUniqueEmailResponse,
	User,
	getUserByEmailApi,
	GetUserByEmailResponse,
	GetUserByEmailRequest,
} from '../api/userApi';
import handleFetch from '../services/handle-fetch';
import { Response } from '../typings';

import { StoreModel } from '.';

export interface UserModel {
	// thunks
	signUp: Thunk<UserModel, SignUpRequest, {}, StoreModel, Response<User>>;
	changePassword: Thunk<UserModel, ChangePasswordRequest, {}, StoreModel, Response<User>>;
	getUserByEmail: Thunk<UserModel, GetUserByEmailRequest, {}, StoreModel, Response<GetUserByEmailResponse>>;
	validateUniqueEmail: Thunk<
		UserModel,
		ValidateUniqueEmailRequest,
		{},
		StoreModel,
		Response<ValidateUniqueEmailResponse>
	>;
}

const user: UserModel = {
	// thunks
	getUserByEmail: thunk(async (actions, payload) => {
		return await handleFetch<GetUserByEmailResponse>(getUserByEmailApi(payload));
	}),

	signUp: thunk(async (actions, payload) => {
		return await handleFetch<User>(signUpApi(payload));
	}),
	changePassword: thunk(async (actions, payload) => {
		return await handleFetch<User>(changePasswordApi(payload));
	}),
	validateUniqueEmail: thunk(async (actions, payload) => {
		return await handleFetch<ValidateUniqueEmailResponse>(validateUniqueEmailApi(payload));
	}),
};

export default user;
