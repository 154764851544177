import styled from 'styled-components/macro';

import { Color } from '../../gfx/constants';

export const SelectWrapper = styled.div`
	position: relative;
`;

export const LoaderWrapper = styled.div`
	margin-right: 20px;
`;

// export const StyledGeocoder = styled(AsyncSelect)`
// 	font-weight: normal;
// 	width: 100%;

// 	.Geocode__control {
// 		border: none;
// 		appearance: none;
// 		color: #bfbfc6;
// 		background: transparent;
// 		border-radius: 4px;
// 		font-family: Primary;
// 		font-size: 16px;
// 		line-height: 29px;
// 		height: 48px;
// 		cursor: text;
// 		width: 100%;
// 		box-shadow: none;

// 		/* &::after {
// 			content: '';
// 			width: 0;
// 			right: 15px;
// 			height: 0;
// 			border-style: solid;
// 			border-width: 7px 5px 0 5px;
// 			border-color: ${Color.GREEN_3} transparent transparent transparent;
// 			position: relative;
// 			margin-left: 20px;
// 		} */
// 	}

// 	.Geocode__menu {
// 		border-radius: 4px;
// 		background-color: #f8f8f8;
// 		margin-top: 4px;
// 	}

// 	.Geocode__option--is-focused {
// 		background-color: #f8f8f8;
// 	}

// 	.Geocode__option--is-selected {
// 		background-color: ${Color.GREEN_3};

// 		div span {
// 			color: ${Color.GREEN_3};
// 			background: white;
// 		}
// 	}

// 	.Geocode__option {
// 		&:hover {
// 			color: white;
// 			cursor: pointer;
// 			background-color: ${Color.GREEN_3};

// 			div span {
// 				color: ${Color.GREEN_3};
// 				background: white;
// 			}
// 		}
// 	}

// 	.Geocode__value-container {
// 		max-height: 100%;
// 		max-width: 360px;
// 	}

// 	.Geocode__indicators,
// 	.Geocode__indicator-separator {
// 		display: none;
// 	}
// 	.Geocode__placeholder {
// 		color: #adadad;
// 		font-family: Primary;
// 		font-size: 16px;
// 		line-height: 29px;
// 	}
// `;

export const customReactSelectStyles = {
	container: (_provided: any, state: any) => {
		const display = 'flex';
		const backgroundColor = state.isDisabled ? '#fafafa' : 'transparent';
		const border = 'none';
		const borderRadius = '3px';
		const height = '100%';
		const width = '100%';
		const alignItems = 'center';
		const fontFamily = 'Primary';
		const fontSize = '16px';
		const lineHeight = '29px';

		return {
			display,
			alignItems,
			border,
			borderRadius,
			backgroundColor,
			height,
			width,
			fontFamily,
			fontSize,
			lineHeight,
		};
	},
	placeholder: (provided: any, state: any) => {
		const color = '#adadad';
		const display = 'flex';
		const flexGrow = 1;

		return { ...provided, display, color, flexGrow };
	},
	indicatorSeparator: (provided: any, state: any) => {
		const display = 'none';
		return { ...provided, display };
	},
	dropdownIndicator: (provided: any, state: any) => {
		const display = 'none';
		return { ...provided, display };
	},
	loadingIndicator: (provided: any, state: any) => {
		const display = 'none';
		return { ...provided, display };
	},
	control: (_: any, state: any) => {
		const padding = '14px 18px';
		const flexGrow = 1;
		const display = 'flex';

		return { padding, flexGrow, display };
	},
	option: (provided: any, state: any) => {
		const color = state.isFocused ? 'white' : 'initial';
		const cursor = 'pointer';
		const backgroundColor = state.isFocused ? Color.GREEN_3 : state.isOptionSelected ? '#43ff92' : 'initial';
		return { ...provided, cursor, backgroundColor, color };
	},
	valueContainer: (provided: any, state: any) => {
		return {};
	},
	singleValue: (provided: any, state: any) => {
		const paddingRight = '18px';
		return { ...provided, paddingRight };
	},
};
