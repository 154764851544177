import * as React from 'react';
import ReactSVG from 'react-svg';

import Panel from '../../../../components/panel/Panel';
import View from '../../../../components/view/View';
import WithPermission from '../../../../components/with-permission/WithPermission';
import { RoutesUrls, Scopes } from '../../../../constants';
import { H1, H2 } from '../../../../gfx/globals';

export default () => (
	<View noUnderlineForLinks>
		<H1 center>Merchant tools</H1>
		<View.Description>
			Integrations and tools for integrating Dagpay to your website and merchant discovery
		</View.Description>

		<Panel.AnchorWrapper
			href={process.env.REACT_APP_MERCHANT_PUBLIC_URL ? process.env.REACT_APP_MERCHANT_PUBLIC_URL : '/'}
			target="_blank"
		>
			<Panel infoPanel>
				<Panel.Icon>
					<ReactSVG src="/files/svg/private/merchant-tools/icon-merchant-finder-glass.svg" />
				</Panel.Icon>
				<Panel.SectionWrapper>
					<H2>Merchant finder</H2>
					<Panel.Text>
						Sign up with your Dagpay account and add your e-commerce or retail business listing to Dag Merchant Finder
						to let your customers discover you’re accepting dagcoin payments.
					</Panel.Text>
				</Panel.SectionWrapper>
			</Panel>
		</Panel.AnchorWrapper>

		<WithPermission permissions={[Scopes.ENVIRONMENTS, Scopes.CREATE_ENVIRONMENT, Scopes.GET_ENVIRONMENTS]}>
			<Panel.LinkWrapper to={RoutesUrls.ENVIRONMENTS}>
				<Panel infoPanel>
					<Panel.Icon>
						<ReactSVG src="/files/svg/private/merchant-tools/icon-integrations.svg" />
					</Panel.Icon>

					<Panel.SectionWrapper>
						<H2>Integrations </H2>
						<Panel.Text>
							Add, revoke, or modify Dagpay environments for custom API or e-commerce plugin integrations.
						</Panel.Text>
					</Panel.SectionWrapper>
				</Panel>
			</Panel.LinkWrapper>
		</WithPermission>

		<WithPermission
			permissions={[Scopes.POS, Scopes.POS_PAIRING, Scopes.GET_POS_PAIRINGS, Scopes.GENERATE_POS_PAIRING_CODE]}
		>
			<Panel.LinkWrapper to={RoutesUrls.POS_PAIRINGS}>
				<Panel infoPanel>
					<Panel.Icon>
						<ReactSVG src="/files/svg/private/merchant-tools/Dagpay-checkout-app.svg" />
					</Panel.Icon>

					<Panel.SectionWrapper>
						<H2>Dagpay checkout app </H2>
						<Panel.Text>
							Download and install the Dagpay checkout app on your Android or iOS device and start accepting Dagcoin
							payments easily in your brick-and-mortar business
						</Panel.Text>
					</Panel.SectionWrapper>
				</Panel>
			</Panel.LinkWrapper>
		</WithPermission>

		<WithPermission
			permissions={[
				Scopes.ATMS,
				Scopes.GET_ATMS,
				Scopes.CREATE_ATM,
				Scopes.GET_ATM,
				Scopes.UPDATE_ATM,
				Scopes.DELETE_ATM,
			]}
		>
			<Panel.LinkWrapper to={RoutesUrls.ATMS}>
				<Panel infoPanel>
					<Panel.Icon>
						<ReactSVG src="/files/svg/private/merchant-tools/icon-dagcoin-atm.svg" />
					</Panel.Icon>

					<Panel.SectionWrapper>
						<H2>Dagloyalty ATM </H2>
						<Panel.Text>
							Accept dags purchased from Dagcoin ATMs and exchange received dags to FIAT money via ATM operators
						</Panel.Text>
					</Panel.SectionWrapper>
				</Panel>
			</Panel.LinkWrapper>
		</WithPermission>

		<WithPermission
			permissions={[
				Scopes.VATMS,
				Scopes.GET_VATMS,
				Scopes.CREATE_VATM,
				Scopes.GET_VATM,
				Scopes.UPDATE_VATM,
				Scopes.DELETE_VATM,
			]}
		>
			<Panel.LinkWrapper to={RoutesUrls.VATMS}>
				<Panel infoPanel>
					<Panel.Icon>
						<ReactSVG src="/files/svg/private/merchant-tools/icon-dagcoin-virtual-atm.svg" />
					</Panel.Icon>

					<Panel.SectionWrapper>
						<H2>Dagloyalty V-ATM </H2>
						<Panel.Text>
							Offer customers a quick way to buy dags via V-ATM and exchange received dags to FIAT money in SwipeX
							Exchange
						</Panel.Text>
					</Panel.SectionWrapper>
				</Panel>
			</Panel.LinkWrapper>
		</WithPermission>
	</View>
);
