import * as React from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import styled from 'styled-components/macro';

import { Color } from '../../gfx/constants';

interface WrapProps {
	size?: number;
	width?: number;
}
const Wrap = styled.div<WrapProps>`
	display: flex;
	position: relative;
	min-width: ${(props) => (props.width ? `${props.width - 32}px` : '80px')};
	width: 100%;
	min-height: ${(props) => (props.size ? `${props.size}px` : '80px')};
	height: 100%;
	align-items: center;
	justify-content: center;
	margin: auto;
	z-index: 100;
	overflow: hidden;
	background: transparent;
	pointer-events: none;
`;

export default ({
	isVisible = true,
	size = 80,
	color = Color.GREEN_3,
	width,
}: {
	isVisible?: boolean;
	size?: number;
	color?: string;
	width?: number;
}) => {
	if (isVisible === false) {
		return null;
	}

	return (
		<Wrap size={size} width={width}>
			<ClipLoader sizeUnit={'px'} size={size} color={color} />
		</Wrap>
	);
};
