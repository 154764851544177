import * as React from 'react';
import { StyledComponentProps } from 'styled-components';
import styled, { css } from 'styled-components/macro';

import { Color, Gutter } from '../../../../../gfx/constants';
import { media } from '../../../../../services/media';

const StyledStatisticsBlock = styled.div`
	display: flex;
	width: 100%;
	height: 390px;
	margin-bottom: 30px;

	${media.maxTablet`
		flex-direction: column;
		height: 780px;
	`}

	/* needed when without All time value */
	${media.maxMobile`
		margin-bottom: 0;
		height: 600px;	
	`}
`;

const Statistics = (props: StyledComponentProps<'div', {}, {}, never>) => (
	<StyledStatisticsBlock>{props.children}</StyledStatisticsBlock>
);

Statistics.ChartWrapper = styled.div`
	position: relative;
	display: flex;
	width: 100%;
	margin-left: 30px;
	height: 100%;
	font-size: 14px;
	${media.maxTablet`
	height: 350px;
	margin: 20px 0 0;
`}

	${media.maxMobile`
	height: 300px;
`}
`;

Statistics.FilterList = styled.div`
	display: flex;

	a:first-child {
		margin-left: 0;
	}

	${media.maxTablet`
	justify-content: space-between;
`}

	${media.maxMobile`
	flex-wrap: wrap;
`}
`;

Statistics.Filter = styled.a<{ isActive?: boolean }>`
	display: inline-block;
	height: 34px;
	margin-left: 10px;
	border-radius: 3px;
	border: 1px solid #dcdde0;
	background-color: transparent;
	font-size: 12px;
	text-align: center;
	line-height: 34px;
	text-transform: capitalize;
	color: #515667;
	cursor: pointer;

	${media.maxMobile`
	margin: 0 0 10px;
	width: calc(33.333% - 10px);
`}

	${media.fromMobile`
	width: 84px;
`}

${(props) =>
		props.isActive &&
		css`
			border: 1px solid ${Color.GREEN_2};
			background-color: ${Color.GREEN_2};
			color: #fff;
		`};

	&:hover {
		border: 1px solid ${Color.GREEN_2};
		background-color: ${Color.GREEN_2};
		color: white;
	}
`;

Statistics.UnitsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	height: 100%;

	& > div :nth-child(2) {
		margin: 10px 0 10px 0;
	}

	/* not needed when without All time value */
	/* & > div :nth-child(3) {
		margin-bottom: 10px;
	} */

	${media.maxTablet`
	width: 100%;
	height: 390px;
`}
`;

Statistics.AllTimeValue = styled.div`
	display: flex;
`;

Statistics.AllTimeValueExtendedMessage = styled.div`
	line-height: 0;
	opacity: 0;
	transition: opacity 0.2s ease;
`;

Statistics.UnitBlock = styled.div<{ isCurrentValueBlock?: boolean }>`
	position: relative;
	width: 280px;
	/* height 100% needed when without All time value */
	height: 100%;
	/* height: 89px; */
	border: 1px solid ${Color.GRAY_5};
	border-radius: 4px;
	background-color: #fff;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: ${Gutter.SMALL};
	${(props) =>
		props.isCurrentValueBlock &&
		css`
			padding-bottom: 80px;
		`};

	/* ${media.fromMobile`
		padding: ${Gutter.MEDIUM};
	`}

	${media.fromTablet`
		padding: ${Gutter.SMALL};
	`} */

	${media.maxTablet`
		width: 100%;
		flex-direction: row;
		justify-content: space-between;
	`}

	${media.maxMobile`
		width: 100%;
		flex-direction: column;
		/* height needed when without All time value */
		height: 89px;
	`}

	& + & {
		${media.fromDesktop`
			margin-left: 0;
		`}
	}
`;

Statistics.UnitTitle = styled.p`
	opacity: 0.65;
	color: #2d354a;
	height: 24px;
	line-height: 24px;
	font-family: Primary;
	font-size: 14px;
	margin: 0;
`;

Statistics.UnitAmount = styled.p`
	margin: 0;
	font-size: 16px;
	line-height: 24px;
	height: 24px;
	font-family: PrimaryMedium;
	font-weight: 500;
	display: flex;
	& svg {
		margin-left: 5px;
	}
`;

Statistics.UnitCurrency = styled.span<{ isDag?: boolean }>`
	color: ${Color.GRAY_14};
	font-family: PrimaryMedium;
	margin: 0 2px;
`;

Statistics.AllTimeValueIcon = styled.div`
	margin: 2px 0 0 70px;
	fill: ${Color.GRAY_MENULINK};

	&:hover {
		fill: ${Color.GREEN_2};
		cursor: pointer;
	}

	${media.maxTablet`
	margin: 2px 0 0 15px;
`}
`;

Statistics.AllTimeValueWrapper = styled.div<{ isClicked: boolean }>`
	min-height: 90px;
	position: absolute;
	bottom: 0;
	left: 0;
	transition: min-height 0.3s ease;
	background-color: white;
	border-radius: 5px;

	${(props) =>
		props.isClicked &&
		css`
			min-height: 390px;
			background-color: white;
			border-radius: 5px;
			box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.12);

			${Statistics.UnitBlock} {
				box-shadow: none;
				border-bottom: none;
			}

			${Statistics.AllTimeValueIcon} {
				circle {
					fill: ${Color.GREEN_2};
				}
				&:hover circle {
					fill: ${Color.GREEN_3};
				}
				svg {
					width: 18px;
					height: 18px;
				}
			}

			${Statistics.AllTimeValueExtendedMessage} {
				line-height: 18px;
				font-size: 14px;
				color: #2d354a;
				padding: 0 20px 0;
				opacity: 0.65;

				${media.fromMobile`
				padding: 0 ${Gutter.MEDIUM} 0;
			`}

				${media.fromTablet`
				padding: 0 ${Gutter.SMALL} 0;
			`}
			}
		`}

	${media.maxTablet`
	width: 100%;
`}
`;

Statistics.Header = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-between;

	${media.maxTablet`
	flex-direction: column;
	margin-bottom: 20px;
`}
`;

export default Statistics;
