import styled from 'styled-components/macro';
import { AtmPaymentsDefault } from '../../../../constants';
import { Color } from '../../../../gfx/constants';

interface NotificationColor {
	color?: AtmPaymentsDefault;
}

export const PosSeparator = styled.div`
	display: flex;
	align-items: center;
	color: #0b132b;
	font-size: 16px;
	border-bottom: 1px solid rgba(220, 221, 224, 0.5);
	font-weight: 500;
	line-height: 22px;
	padding-bottom: 18px;
	margin-bottom: 38px;
	margin-top: 38px;

	svg {
		margin-right: 12px;
	}
`;

export const PosTotalAmount = styled.div`
	display: flex;
	flex-direction: column;
	color: #2d354a;

	font-size: 15px;
	font-weight: 500;
	line-height: 18px;
	margin-right: 100px;
	width: 200px;
	margin-left: auto;
	text-align: left;
	margin-bottom: 84px;

	span {
		margin-bottom: 11px;
	}
`;

export const PosButtonColumn = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin: 0 20px;
	button {
		margin: 7px auto;
		width: 100%;
	}
	@media (max-width: 360px) {
		margin: 0;
	}
`;

export const PosModalTitleWrapper = styled.div`
	text-align: center;
	font-size: 14px;
	font-family: Secondary;
	margin-bottom: 20px;
`;

export const PosModalWrapper = styled.div`
	width: 100%;
	height: 100%;
	position: relative;
	box-sizing: border-box;
	@media (max-width: 1024px) {
		padding: 0 30px;
	}
`;

export const AtmPaymentsNotification = styled.p<NotificationColor>`
	font-weight: 500;
	font-size: 15px;
	font-family: primaryMedium;
	span {
		color: ${(props) =>
			(props.color === AtmPaymentsDefault.ENABLED && Color.GREEN_3) ||
			(props.color === AtmPaymentsDefault.ASK && Color.ORANGE) ||
			(props.color === AtmPaymentsDefault.OFF && Color.RED_2)};
	}
`;

export const LimitNotificationText = styled.span<{ error?: boolean }>`
	color: ${(props) => props.error && Color.RED_2};
`;
