import axios from 'restyped-axios';
import { ApiResponse } from '../typings';
export const api = axios.create<SwipexApi>();

export interface SwipexApi {
	'/swipex': {
		POST: {
			body: LinkSwipexAccountRequest;
			response: ApiResponse<SwipexAccountLink>;
		};
	};

	// '/swipex/remote-status': {
	// 	POST: {
	// 		body: LinkSwipexAccountRequest;
	// 		response: ApiResponse<SwipexAccountLink>;
	// 	};
	// };

	'/swipex/info': {
		GET: {
			query: GetLinkRequest;
			response: ApiResponse<SwipexAccountLink>;
		};
	};
}

export interface LinkSwipexAccountRequest {
	swipexEmail: string;
}

export interface GetLinkRequest {
	userId: string;
}

export interface SwipexAccountLink {
	id: string;
	userId: string;
	email: string;
	swipexEmail: string;
	linkStatus: SwipexLinkStatus;
	createdDate: string;
	updatedDate: string;
}

export enum SwipexLinkStatus {
	REQUESTED = 'REQUESTED',
	LINKED = 'LINKED',
	NOT_LINKED = 'NOT_LINKED',
}

export const linkSwipexAccountApi = async (params: LinkSwipexAccountRequest) => {
	const response = await api.post('/swipex', {
		...params,
	});

	return response.data;
};

export const getAccountLinkInfoApi = async (params: GetLinkRequest) => {
	const response = await api.get('/swipex/info', {
		params: params,
	});

	return response.data;
};
