import { User } from '../api/userApi';
import { Scopes } from '../constants';

import store from './store';

export const hasPermission = (permissions: Scopes[]) => {
	const viewerState = store.getState().viewer.activeViewer as User | null;
	const isAuthorized = store.getState().viewer.isAuthorized;
	const isLoading = store.getState().viewer.isViewerLoading;

	const limitedPermissions = [
		Scopes.CUSTOMER_LIMITS,
		Scopes.CREATE_CUSTOMER_LIMIT,
		Scopes.GET_CUSTOMER_LIMIT,
		Scopes.GET_CUSTOMER_LIMITS,
		Scopes.UPDATE_CUSTOMER_LIMIT,
		Scopes.DELETE_CUSTOMER_LIMIT,
	];

	if (permissions.length === 0) {
		return true;
	}

	if (!isAuthorized && isLoading) {
		console.log('Viewer is not loaded, this should not happen');
		store.dispatch.viewer.getViewer();
	}

	if (isLoading || !viewerState) {
		return false;
	}

	let scopes = viewerState ? viewerState.scopes : [];

	// filter out GET_LIMITS permissions if customer limit is not active
	// for showing hidden functionality only enabled merchants
	if (!viewerState.customerLimitActive) {
		scopes = scopes.filter((scope: any) => !limitedPermissions.includes(scope));
	}

	const userHasPermission = scopes.some((scope) => permissions.indexOf(scope) >= 0);

	return userHasPermission;
};
