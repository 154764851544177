import * as React from 'react';
import ReactSVG from 'react-svg';

import dayjs from 'dayjs';
import {
	NodePoint,
	StyledPanel,
	TimeAddonNode,
	TimeLine,
	TimeLineLabel,
	TimeLineLabelHeader,
} from './TimeLineProgressBarStyle';

interface Node {
	label: string;
	status: TimelineNodeStatus;
	icon: string;
	time?: Date;
}

export enum TimelineNodeStatus {
	ACTIVE = 'ACTIVE',
	FAIL = 'FAIL',
	NEXT = 'NEXT',
	INACTIVE = 'INACTIVE',
}
export interface PanelProps {
	nodes: Node[];
	disputed?: boolean;
	forceComplete?: boolean;
}

const TimeLineProgressBar = (props: PanelProps) => {
	const { nodes, disputed, forceComplete } = props;

	return (
		<StyledPanel>
			<TimeLine>
				{nodes.map((node, index, array) => {
					return (
						<NodePoint
							width={100 / nodes.length}
							status={
								forceComplete
									? TimelineNodeStatus.ACTIVE
									: node.status === TimelineNodeStatus.INACTIVE && array[index - 1].status === TimelineNodeStatus.ACTIVE
									? disputed
										? TimelineNodeStatus.FAIL
										: TimelineNodeStatus.NEXT
									: node.status
							}
							key={index}
						>
							<TimeLineLabel>
								<TimeLineLabelHeader>
									<ReactSVG src={node.icon} />
									<span>{node.label}</span>
								</TimeLineLabelHeader>
								{node.time &&
								node.status === TimelineNodeStatus.INACTIVE &&
								array[index - 1].status === TimelineNodeStatus.ACTIVE ? (
									<TimeAddonNode>{disputed ? <p>Disputed...</p> : <p>Waiting for confirmation...</p>}</TimeAddonNode>
								) : (
									<TimeAddonNode status={node.status}>
										<span>{dayjs(node.time).format('DD.MM.YYYY')}</span>
									</TimeAddonNode>
								)}
							</TimeLineLabel>
						</NodePoint>
					);
				})}
			</TimeLine>
		</StyledPanel>
	);
};

export default TimeLineProgressBar;
