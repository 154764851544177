import React, { useState } from 'react';
import ReactSVG from 'react-svg';

import { handleCopyToClipboard } from '../../services/handle-copy-to-clipboard';
import { CopiedMessage, CopyQrInfo, PopUpWrapper } from './CopyQrInfoButtonStyle';

export interface CopyQrInfoButtonProps {
	qrInfo: string;
	label: string;
	successMessage: string;
}

export default function CopyQrInfoButton(props: CopyQrInfoButtonProps) {
	const { label, qrInfo, successMessage } = props;
	const [animationIsActive, setAnimationIsActive] = useState(false);

	const handleCopyQrInfo = () => {
		setAnimationIsActive(true);
		handleCopyToClipboard(qrInfo);
	};

	return (
		<CopyQrInfo onClick={handleCopyQrInfo}>
			{label}
			<ReactSVG src="/files/svg/invoice/CopyLink.svg" />
			{animationIsActive && (
				<PopUpWrapper onAnimationEnd={() => setAnimationIsActive(false)}>
					<ReactSVG src="/files/svg/invoice/Paper.svg" />
					<CopiedMessage>{successMessage}</CopiedMessage>
				</PopUpWrapper>
			)}
		</CopyQrInfo>
	);
}
