import * as React from 'react';
import ReactSVG from 'react-svg';
import styled, { css } from 'styled-components/macro';

import { Color, Gutter } from '../../gfx/constants';

interface StyledNotificationProps {
	children: React.ReactNode;
	left?: boolean;
	center?: boolean;
	gray?: boolean;
	hasError?: boolean;
	box?: boolean;
	noIcon?: boolean;
}

const StyledNotification = styled.div<StyledNotificationProps>`
	width: ${({ left }) => left && '100%'};
	max-width: 100%;
	margin-bottom: ${Gutter.SMALL};
	border-radius: 4px;
	${(props) =>
		props.box &&
		css`
			background: ${props.gray ? Color.GRAY_2 : 'rgba(255, 145, 9, 0.11849)'};
			border: 1px solid ${props.gray ? Color.GRAY_5 : Color.ORANGE_0};
			padding: 13px;
		`}
`;

export const StyledInfoBlock = styled.div<StyledNotificationProps>`
	display: flex;
	max-width: 100%;
	color: ${Color.GREY_INFO};
	font-family: Secondary;
	font-size: 14px;
	line-height: 24px;
	justify-content: ${({ center }) => center && 'center'};

	> div div {
		margin-top: 3px;
	}

	a,
	a:visited {
		color: ${({ gray }) => (gray ? Color.BLUE_1 : Color.ORANGE)};
	}

	div {
		h1 {
			color: ${Color.GRAY_12};
			font-style: primaryMedium;
			font-size: 16px;
			line-height: 21px;
		}
		margin: 0;
		p:first-child {
			margin: 0;
			margin-bottom: 27px;
		}
	}

	svg {
		margin-right: 8px;
		fill: ${Color.ORANGE_INFO};
		${(props) =>
			props.hasError &&
			css`
				fill: ${Color.RED_ERROR};
			`}
		${(props) =>
			props.gray &&
			!props.hasError &&
			css`
				fill: ${Color.GRAY_6};
			`}
	}
`;

const Notification = (props: StyledNotificationProps) => {
	const { children, center, hasError, noIcon, ...rest } = props;

	return (
		<StyledNotification {...rest}>
			<StyledInfoBlock {...props}>
				{!noIcon && <ReactSVG src="/files/svg/private/Info.svg" />}
				<div>{children}</div>
			</StyledInfoBlock>
		</StyledNotification>
	);
};

export default Notification;
