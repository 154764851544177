import * as React from 'react';
import ReactDatePicker from 'react-datepicker';
import styled from 'styled-components/macro';
import { Controller, useFormContext } from 'react-hook-form';
import { Border, Error, FieldWrapper, Label } from './HookFieldsStyle';
import { FieldIconTypes, iconPaths } from '../../constants';
import { StyledInputMixin } from '../field/FieldStyle';
import { Color } from '../../gfx/constants';
import ReactSVG from 'react-svg';
import { ErrorMessage } from '@hookform/error-message';
import InputMask from 'react-input-mask';
import dayjs from 'dayjs';
import { LinkButton } from '../../gfx/globals';
import {
	NextButtonLabel,
	AddonIcon,
	DateLinkNavigationWrapper,
	DateNavigationHeader,
	DateNavigationWrapper,
	PreviousButtonLabel,
} from './ReactHookFormStyle';

const StyledDatePicker = styled(ReactDatePicker)`
	${StyledInputMixin};
	/* background: ${Color.GRAY_2}; */
	:disabled {
		background: ${Color.GRAY_2};
	}
`;

interface Props {
	name: string;
	label?: string;
	isMulti?: boolean;
	tabIndex?: number;
	isSearchable?: boolean;
	defaultValue?: Date | null;
	noErrorElement?: boolean;
	dateFormat?: string;
	disabled?: boolean;
	value?: Date;
	maxDate?: Date;
	minDate?: Date;
	optional?: boolean;
	optionalBubble?: boolean;
}

export default function HookDateField(props: Props) {
	const {
		name,
		label,
		dateFormat = 'yyyy-MM-dd',
		value,
		maxDate,
		minDate = new Date('1925/01/01'),
		optional,
		optionalBubble,
		disabled,
		...rest
	} = props;
	const { control } = useFormContext();

	const parseDate = (date: string) => {
		if (dayjs(date).isValid()) {
			return dayjs(date).toDate();
		}
	};

	return (
		<FieldWrapper>
			<Label optional={optional} optionalBubble={optionalBubble}>
				{label}
			</Label>
			<Border>
				<AddonIcon disabled={props.disabled}>
					<ReactSVG src={iconPaths[FieldIconTypes.CALENDAR]} />
				</AddonIcon>
				<Controller
					control={control}
					name={name}
					valueName="selected" // DateSelect value's name is selected
					defaultValue={value}
					render={({ onChange, value, ref }) => (
						<StyledDatePicker
							dateFormat={dateFormat}
							formatWeekDay={(nameOfDay: string) => nameOfDay.substr(0, 1)}
							showMonthDropdown
							showYearDropdown
							scrollableYearDropdown
							selected={value ? dayjs(value).toDate() : null}
							onChange={(date: string) => onChange(parseDate(date))}
							placeholderText="yyyy-mm-dd"
							disabled={disabled}
							customInput={<InputMask mask="9999-99-99" maskChar={null} placeholder="yyyy-mm-dd" inputRef={ref} />}
							maxDate={maxDate}
							minDate={minDate}
							popperModifiers={{
								offset: {
									enabled: true,
									offset: '-50px, 0px',
								},
								preventOverflow: {
									enabled: true,
									escapeWithReference: false,
									boundariesElement: 'scrollParent',
								},
							}}
							renderCustomHeader={({
								date,
								decreaseMonth,
								increaseMonth,
								prevMonthButtonDisabled,
								nextMonthButtonDisabled,
							}: {
								date: Date;
								changeYear(year: number): void;
								changeMonth(month: number): void;
								decreaseMonth(): void;
								increaseMonth(): void;
								prevMonthButtonDisabled: boolean;
								nextMonthButtonDisabled: boolean;
							}) => (
								<>
									<DateNavigationHeader>{dayjs(date).format('ddd, MMM D')}</DateNavigationHeader>
									<DateNavigationWrapper>
										<div>{dayjs(date).format('MMMM YYYY')}</div>
										<DateLinkNavigationWrapper>
											<PreviousButtonLabel>
												<LinkButton
													onClick={decreaseMonth}
													type="button"
													disabled={prevMonthButtonDisabled}
												></LinkButton>
											</PreviousButtonLabel>
											<NextButtonLabel>
												<LinkButton
													onClick={increaseMonth}
													type="button"
													disabled={nextMonthButtonDisabled}
												></LinkButton>
											</NextButtonLabel>
										</DateLinkNavigationWrapper>
									</DateNavigationWrapper>
								</>
							)}
							{...rest}
						/>
					)}
				/>
			</Border>
			<Error>
				<ErrorMessage name={name} />
			</Error>
		</FieldWrapper>
	);
}
