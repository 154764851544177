import { FormikFieldState } from 'formik-fields';
import * as React from 'react';
import Dropzone, { DropEvent, DropzoneProps, FileRejection } from 'react-dropzone';
import ReactSVG from 'react-svg';
import { toast } from 'react-toastify';

import { ACCEPTED_FILE_FORMATS, MAX_FILE_SIZE } from '../../constants';
import { ColumnedFlex, LinkButton, NoOverflowDiv } from '../../gfx/globals';

import {
	DocumentIcon,
	DropzoneDropNotification,
	DropzoneFooterData,
	DropzoneLimitsDetails,
	DropzoneWrapper,
	StyledDropzone,
} from './DropzoneStyle';

interface Props extends DropzoneProps {
	formikField: FormikFieldState<File[]>;
	multiple?: boolean;
	maxFiles?: number;
}

type DropFilesHandler = (
	accepted: File[],
	rejected: FileRejection[],
	event: DropEvent,
	formikField: FormikFieldState<File[]>,
) => void;

export default function DropZoneComponent(props: Props) {
	const { formikField, multiple = false, maxFiles } = props;

	// const getFileName = (index: number) => {
	// 	if (formikField.value.length === 1) {
	// 		return formikField.value[index].name;
	// 	}

	// 	return null;
	// };

	const onRemoveFileClick = (formikField: FormikFieldState<File[]>, fileName: string) => (
		e: React.MouseEvent<HTMLAnchorElement>,
	) => {
		e.stopPropagation();
		formikField.setValue(
			formikField.value.filter((file, index, arr) => {
				return file.name !== fileName;
			}),
		);
	};

	// const getDropzoneContent = (index: number) => {
	// 	if (formikField.error) {
	// 		return (
	// 			<ColumnedFlex>
	// 				<NoOverflowDiv key={index}>{getFileName(index)}</NoOverflowDiv>
	// 				<div>{formikField.error}</div>
	// 			</ColumnedFlex>
	// 		);
	// 	}

	// 	return <NoOverflowDiv key={index}>{getFileName(index)}</NoOverflowDiv>;

	// 	return null;
	// };

	const handleDrop: DropFilesHandler = (
		acceptedFiles,
		rejectedFiles,
		_event,
		formikField: FormikFieldState<File[]>,
	) => {
		if (acceptedFiles.some((file) => file.name.length > 100)) {
			toast.error('The file name(s) are too long. Please shorten the file name and try again.');
			return;
		}
		//check if current file stack can fit the number of added files
		if (maxFiles) {
			if (formikField.value.length + acceptedFiles.length > maxFiles) {
				toast.error('File count limit exceeded for field');
				formikField.setIsTouched(false);
				return;
			}
		}
		formikField.setValue(acceptedFiles.concat(formikField.value));
		formikField.setIsTouched(true);
	};

	return (
		<ColumnedFlex fullWidth>
			<DropzoneWrapper hasError={formikField.error !== undefined}>
				<StyledDropzone>
					<Dropzone
						multiple={multiple}
						maxSize={MAX_FILE_SIZE}
						accept={ACCEPTED_FILE_FORMATS.join(',')}
						onDrop={(accepted, rejected, event) => handleDrop(accepted, rejected, event, formikField)}
					>
						{({ getRootProps, getInputProps }) => (
							<ColumnedFlex fullWidth fullHeight isCentered {...getRootProps()}>
								<input name={formikField.name} {...getInputProps()} />
								<ReactSVG src="/files/svg/private/CloudUpload.svg" />
								<DropzoneDropNotification>
									Drag & drop or <LinkButton type="button">browse</LinkButton>{' '}
								</DropzoneDropNotification>
								<DropzoneLimitsDetails>
									We support JPG, PNG and PDF files. <div>Make sure that your file size is under 5 MB.</div>
								</DropzoneLimitsDetails>
							</ColumnedFlex>
						)}
					</Dropzone>
				</StyledDropzone>
			</DropzoneWrapper>
			{formikField.value.length >= 1 &&
				formikField.value.map((file, index) => {
					return (
						<DropzoneFooterData key={index} hasError={formikField.error !== undefined}>
							<DocumentIcon>
								<ReactSVG src="/files/svg/private/verification/Document.svg" />
							</DocumentIcon>

							<NoOverflowDiv key={index}>{file.name}</NoOverflowDiv>
							<LinkButton type="button" onClick={onRemoveFileClick(formikField, file.name)}>
								<ReactSVG src="/files/svg/private/Delete.svg" />
							</LinkButton>
						</DropzoneFooterData>
					);
				})}
		</ColumnedFlex>
	);
}
