import { thunk, Thunk } from 'easy-peasy';

import handleFetch from '../services/handle-fetch';
import { Response } from '../typings';

import { StoreModel } from '.';
import {
	ConfirmPaymentRequest,
	ConfirmPaymentResponse,
	dagCardInitPaymentApi,
	DagcardInitPaymentRequest,
	DagcardInitPaymentResponse,
	dagCardPinConfirmPaymentApi,
} from '../api/dagCardApi';

export interface DagCardModel {
	// state

	// actions

	// thunks
	dagCardInitPayment: Thunk<
		DagCardModel,
		DagcardInitPaymentRequest,
		{},
		StoreModel,
		Response<DagcardInitPaymentResponse>
	>;

	dagCardPinConfirmPayment: Thunk<
		DagCardModel,
		ConfirmPaymentRequest,
		{},
		StoreModel,
		Response<ConfirmPaymentResponse>
	>;

	// listeners
}

const dagCard: DagCardModel = {
	// state

	// actions

	// thunks
	dagCardInitPayment: thunk(async (actions, payload) => {
		return await handleFetch<DagcardInitPaymentResponse>(dagCardInitPaymentApi(payload));
	}),

	dagCardPinConfirmPayment: thunk(async (actions, payload) => {
		return await handleFetch<ConfirmPaymentResponse>(dagCardPinConfirmPaymentApi(payload));
	}),

	// listeners
};

export default dagCard;
