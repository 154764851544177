import React from 'react';
import { Switch } from 'react-router';
import { Route, withRouter } from 'react-router-dom';
import NavigationBar from '../../../components/navigation-bar/NavigationBar';
import View from '../../../components/view/View';
import { ParentViews, RoutesUrls } from '../../../constants';
import { H1 } from '../../../gfx/globals';
import AccountDetailsView from './account-details/AccountDetailsView';
import CompanyDetailsView from './company-details/CompanyDetailsView';
import CustomerLimitsListView from './customer-limits/CustomerLimitsListView';
import MerchantProfileView from './merchant-profile/MerchantProfileView';
import SecurityView from './security/SecurityView';
import UsersListView from './users/UsersListView';
import WalletListView from './wallet/WalletListView';

function SettingsView() {
	return (
		<View paddingTop={27}>
			<H1>Settings</H1>
			<NavigationBar parentView={ParentViews.SETTINGS} />
			<Switch>
				<Route exact path={RoutesUrls.SETTINGS} component={MerchantProfileView} />
				<Route path={RoutesUrls.WALLETS} component={WalletListView} />
				<Route path={RoutesUrls.USERS} component={UsersListView} />
				<Route path={RoutesUrls.ACCOUNT_DETAILS} component={AccountDetailsView} />
				<Route path={RoutesUrls.COMPANY_DETAILS} component={CompanyDetailsView} />
				<Route path={RoutesUrls.SECURITY} component={SecurityView} />
				<Route path={RoutesUrls.LIMITS} component={CustomerLimitsListView} />
			</Switch>
		</View>
	);
}

export default withRouter(SettingsView);
