import * as React from 'react';
import styled from 'styled-components/macro';

import RadioButton from '../radio/Radio';

const RadioGroupWrap = styled.div`
	label {
		font-family: PrimaryMedium;
	}
`;

interface Props {
	name?: string;
	disabled?: boolean;
	isColumned?: boolean;
	isLeftAligned?: boolean;
	children?: React.ReactNode;
	defaultSelected?: string | null;
	className?: string;
	onClick?(e: React.MouseEvent<any>): void;
}

export default function RadioButtonGroup(props: Props) {
	const { name, children, className, defaultSelected, disabled = false, isLeftAligned, onClick } = props;

	return (
		<RadioGroupWrap className={className} role="group">
			{React.Children.map(children, (child) => {
				const { value, ...rest } = (child as JSX.Element).props;

				return (
					<RadioButton
						isLeftAligned={isLeftAligned}
						name={name}
						value={value}
						disabled={disabled}
						selected={value === defaultSelected}
						onClick={onClick}
						{...rest}
					/>
				);
			})}
		</RadioGroupWrap>
	);
}
