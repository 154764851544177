import { RSA_X931_PADDING } from 'constants';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { withRouter } from 'react-router-dom';

import { Invoice, InvoiceState, InvoiceTypes } from '../../api/invoiceApi';
import { getServerValidationErrors } from '../../services/input-error-utils';
import RpcClient, { SubscribeMethod } from '../../services/invoice-rpc-client';
import { useStoreActions, useStoreState } from '../../services/store';
import { Json } from '../../services/ws-rpc-client';
import { RouteProps } from '../../typings';

import { EmailReceiptRequestFields } from './components/InvoiceContentBlock';
import InvoiceViewTpl from './InvoiceViewTpl';

interface RouteParams {
	invoiceId?: string;
}

function InvoiceView(props: RouteProps<RouteParams>) {
	const [invoiceState, setInvoiceState] = useState(InvoiceState.PENDING);
	const [errorMessage, setErrorMessage] = useState('');
	const [isMobileDevice, setIsMobileDevice] = useState<boolean | null>(null);
	const [isReceiptSent, setIsReceiptSent] = useState(false);
	const [isRepeatableSentActive, setIsRepeatableSentActive] = useState(false);
	const [emailReceiptInputValue, setEmailReceiptInputValue] = useState('');

	const rpcRef = useRef<RpcClient>();

	const { invoice } = useStoreState((state) => ({
		invoice: state.invoice.invoice,
	}));

	const { getInvoice, cancelInvoice, sendInvoiceReceipt } = useStoreActions((actions) => ({
		...actions.invoice,
	}));

	const fetchInvoice = useCallback(async () => {
		if (props.match.params.invoiceId) {
			const response = await getInvoice({ invoiceId: props.match.params.invoiceId });

			if (response.success && response.payload && response.payload.state !== InvoiceState.PENDING) {
				setInvoiceState(response.payload.state);
				setIsRepeatableSentActive(false);
			}
		}
	}, [getInvoice, props.match.params.invoiceId]);

	const setupInvoiceRpcClient = (invoiceId: string) => {
		console.log('setup rpc');
		// avoid trying to setup the rpc client more than once
		if (rpcRef.current) {
			return;
		}

		const protocol = process.env.REACT_APP_API_SSL === 'true' ? 'wss' : 'ws';
		const url = `${protocol}://${process.env.REACT_APP_API_URL}/ws`;

		rpcRef.current = new RpcClient(url, SubscribeMethod.INVOICE_STATE_CHANGED, invoiceId);

		rpcRef.current.handleInvoiceStateChanged = (invoice: Json) => {
			if (typeof invoice.state !== undefined && typeof invoice.state === 'string') {
				setInvoiceState(InvoiceState[invoice.state as InvoiceState]);
			}
		};
	};

	const checkIsMobileDevice = () => {
		if (isMobile || window.innerWidth < 800) {
			return setIsMobileDevice(true);
		}

		return setIsMobileDevice(false);
	};
	/* eslint-disable */
	useEffect(() => {
		if (props.match.params.invoiceId) {
			fetchInvoice();

			window.addEventListener('resize', checkIsMobileDevice);

			checkIsMobileDevice();

			setupInvoiceRpcClient(props.match.params.invoiceId);

			return () => {
				window.removeEventListener('resize', checkIsMobileDevice);

				// politely close ws connection
				if (rpcRef.current) {
					rpcRef.current.close();
				}
			};
		}
	}, [fetchInvoice, props.match.params.invoiceId]);
	/* eslint-enable */
	const onEmailReceiptFieldChange = (email: string) => {
		if (invoiceState === InvoiceState.WAITING_FOR_CONFIRMATION) {
			setEmailReceiptInputValue(email);
		}
	};

	const handleReceiptRepeatableSent = () => {
		if (isReceiptSent) {
			setIsRepeatableSentActive(!isRepeatableSentActive);
		}
	};

	const getEscrowProductInfo = (invoice: Invoice) => {
		if (invoice.type !== InvoiceTypes.ESCROW) {
			return undefined;
		}

		const parsed = JSON.parse(invoice.data);

		if (typeof parsed === 'object' && parsed.description) {
			return parsed.description as string;
		}

		return undefined;
	};

	const handleCancelPayment = async () => {
		try {
			if (props.match.params.invoiceId) {
				const response = await cancelInvoice({ invoiceId: props.match.params.invoiceId });

				if (response.success) {
					setInvoiceState(InvoiceState.CANCELLED);
				}

				if (response.error) {
					setErrorMessage(response.error);

					return;
				}
			}
		} catch (e) {
			setErrorMessage('Cancelling invoice failed');
		}
	};

	const handleSendReceipt = async (input: EmailReceiptRequestFields) => {
		try {
			if (invoice && invoice.id !== null) {
				const response = await sendInvoiceReceipt({
					...input,
					invoiceId: invoice.id,
					cc: [],
				});

				if (response.success) {
					setIsReceiptSent(true);
				}

				if (response.error) {
					const serverValidationErrors = getServerValidationErrors<Invoice>(response);

					setErrorMessage(serverValidationErrors.errorMessage);

					return;
				}
			}
		} catch (e) {
			setErrorMessage('Sending receipt failed');
		}
	};

	const { invoiceId } = props.match.params;

	if (!invoiceId || isMobileDevice === null) {
		return null;
	}

	if (!invoice) {
		return null;
	}
	const canCancel = () => {
		return (invoice.type === InvoiceTypes.MERCHANT ||
			invoice.type === InvoiceTypes.POS ||
			invoice.type === InvoiceTypes.ESCROW) &&
			invoiceState === InvoiceState.PENDING
			? true
			: false;
	};

	return (
		<InvoiceViewTpl
			{...props}
			isReceiptSent={isReceiptSent}
			isRepeatableSentActive={isRepeatableSentActive}
			invoiceError={errorMessage}
			sendReceiptError={errorMessage}
			invoice={invoice}
			handleCancelPayment={handleCancelPayment}
			invoiceState={invoiceState}
			handleSendReceipt={handleSendReceipt}
			isMobile={isMobileDevice}
			handleReceiptRepeatableSent={handleReceiptRepeatableSent}
			onEmailReceiptFieldChange={onEmailReceiptFieldChange}
			emailReceiptInputValue={emailReceiptInputValue}
			canCancel={canCancel()}
			escrowProductInfo={getEscrowProductInfo(invoice)}
		/>
	);
}

export default withRouter(InvoiceView);
