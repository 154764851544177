import React from 'react';
import styled from 'styled-components';
import Button, { ButtonTypes } from '../../../../../components/button/Button';
import { EscrowSupportModalWrapper } from '../../../../../components/escrow-state-display/EscrowStateDisplayStyle';
import Modal from '../../../../../components/modal/Modal';
interface Props {
	onClickCallback: () => void;
	handleCloseModal: () => void;
}

export const ModalButtonWrapper = styled.div`
	display: flex;
	justify-content: space-between;
`;

export default function EscrowMarkAsShippedModal(props: Props) {
	const { onClickCallback, handleCloseModal } = props;

	return (
		<Modal isOpen={true} ariaHideApp={false} onRequestClose={handleCloseModal} small hasCloseButton>
			<EscrowSupportModalWrapper>
				<h2>Product sent?</h2>
				<p>Did you send the product and it’s on the way to the buyer?</p>
				<ModalButtonWrapper>
					<Button.Secondary white onClick={handleCloseModal}>
						Cancel
					</Button.Secondary>
					<Button.Secondary type={ButtonTypes.BUTTON} green onClick={onClickCallback}>
						Yes, I sent the product
					</Button.Secondary>
				</ModalButtonWrapper>
			</EscrowSupportModalWrapper>
		</Modal>
	);
}
