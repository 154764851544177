import * as React from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components/macro';

import { ParentViews } from '../../constants';
import { Color } from '../../gfx/constants';
import getMergedRoutes from '../../services/get-merged-routes';
import { media } from '../../services/media';
import { RouteProps } from '../../typings';
import { privateRoutes } from '../../views/private/routes';

interface NavLinkProps {
	active: boolean;
}

export const NavLink = styled.a<NavLinkProps>`
	border-bottom: ${({ active }) => active && `3px solid ${Color.GREEN_3}`};
	font-family: ${({ active }) => active && 'PrimaryMedium'};
	padding-bottom: 7px;
	text-align: center;
	color: ${({ active }) => (active ? `${Color.BLUE_9}` : `${Color.GRAY_MENULINK}`)};

	&:hover {
		cursor: pointer;
		border-bottom: 3px solid #05c355;
		text-decoration: none;
	}

	${media.maxDesktop`
		margin-bottom: 10px;
	`}
`;

export const StyledNavigationBar = styled.nav`
	display: flex;
	position: relative;
	margin-bottom: 38px;
	width: 100%;
	font-family: Secondary;
	font-size: 15px;

	&:after {
		content: '';
		position: absolute;
		top: calc(100% - 10px);
		left: 0;
		right: 0;
		height: 1px;
		background-color: ${Color.GRAY_5};

		${media.fromDesktop`
			top: 100%;
		`}
	}

	a {
		:not(:last-child) {
			margin-right: 5%;
		}
	}

	@media (max-width: 1024px) {
		flex-wrap: wrap;
	}
`;

interface Props extends RouteProps {
	parentView: ParentViews;
}

function NavigationBar(props: Props) {
	const { history, parentView } = props;

	const links = getMergedRoutes(privateRoutes.filter((route) => route.parentView === parentView));
	const navigationLinks = links.filter((link) => link.navLinkTitle && link.url);

	const onLinkClick = (link: string) => (_e: React.MouseEvent<HTMLElement>) => props.history.push(link);

	return (
		<StyledNavigationBar>
			{navigationLinks.map((unit, index) => (
				<NavLink key={index} active={history.location.pathname === unit.url} onClick={onLinkClick(unit.url)}>
					{unit.navLinkTitle}
				</NavLink>
			))}
		</StyledNavigationBar>
	);
}

export default withRouter(NavigationBar);
