import { Discount, DiscountType } from "../api/discountApi";

export default function getDiscount(list: Discount[] | null, type: DiscountType) {
	if (!list || list.length === 0) {
		return null;
	}

	const filterResult = list.filter((item) => item.type === type);

	return filterResult.length === 0 ? null : filterResult[0];
}