import * as Yup from 'yup';

import { InitialFields } from '../typings';

export function getValidationSchema<T extends object>(initialFields: InitialFields<T>) {
	const schemaObject = Object.entries(initialFields).reduce(
		(acc, key) => {
			// tslint:disable-next-line:no-any
			const value: any = key[1] || { initialValue: '' };
			acc[key[0]] = value.validator;

			return acc;
		},
		// tslint:disable-next-line:no-any
		{} as any,
	);

	return Yup.object<T>().shape({
		...schemaObject,
	});
}
