import React from 'react';
import styled from 'styled-components';

import { AtmStatus } from '../../api/atmApi';
import { Color } from '../../gfx/constants';

const Colored = styled.div<{ color: string }>`
	color: ${(props) => props.color};
	border: 1px solid ${(props) => props.color};
	border-radius: 4px;
	padding: 6px 8px;
	font-family: PrimaryMedium;
	font-size: 14px;
	line-height: 16px;
	margin: 0 auto 0 0;
`;

export interface ColoredAtmStatusProps {
	status: AtmStatus;
}

export const ColoredAtmStatus = (props: ColoredAtmStatusProps) => {
	const { status } = props;

	let color = Color.RED_2;

	switch (status) {
		case AtmStatus.ACTIVE:
			color = Color.GREEN_3;
			break;
		case AtmStatus.PENDING:
			color = Color.ORANGE;
			break;

		default:
			color = Color.RED_2;
			break;
	}

	const capital = () => {
		const lower = status.toLowerCase();
		return lower.replace(lower.charAt(0), lower.charAt(0).toUpperCase());
	};

	return <Colored color={color}>{capital()}</Colored>;
};
