import * as React from 'react';
import { Escrow, EscrowStatus } from '../../api/escrowApi';
import Button from '../button/Button';
import ReactSVG from 'react-svg';
import { useStoreActions } from '../../services/store';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import Modal from '../modal/Modal';
import ReactHookForm from '../react-hook-form/ReactHookFormStyle';
import HookTextAreaField from '../react-hook-form/HookTextAreaField';
import { FormProvider, useForm } from 'react-hook-form';
import {
	EscrowStatusButtons,
	EscrowStatusHeader,
	EscrowStatusText,
	EscrowStatusWrapper,
	EscrowSupportModalWrapper,
	ModalButtonWrapper,
} from './EscrowStateDisplayStyle';
import Loader from '../loader/Loader';
import { yupResolver } from '@hookform/resolvers/yup';

export enum StatusHeaders {
	ESCROW_CREATED = 'Escrow deal created',
	ESCROW_DECLINED = 'Escrow deal declined',
	ESCROW_CANCELLED = 'Escrow deal cancelled',
	ESCROW_ACCEPTED = '',
	INVOICE_CREATED = 'Awaiting payment...',
	INVOICE_PAID = 'Payment in escrow',
	MARKED_AS_SHIPPED = 'Product shipped',
	MARKED_AS_RECEIVED = 'Product received',
	FUNDS_RELEASED = 'Funds released',
	ESCROW_COMPLETED = 'Escrow deal completed!',
	DISPUTE_OPENED = 'Escrow in dispute',
	DISPUTE_RESOLVED = 'Escrow dispute resolved',
	ESCROW_EXPIRED = 'Escrow deal has expired',
}
export enum StatusMessages {
	ESCROW_CREATED = '', //deprecated due to <strong> text
	ESCROW_DECLINED = '',
	ESCROW_ACCEPTED = '',
	INVOICE_CREATED = 'Awaiting payment',
	INVOICE_PAID = 'Payment has been received and is secured in escrow. You will receive a notification by email once the seller has shipped the product',
	MARKED_AS_SHIPPED = '',
	MARKED_AS_RECEIVED = 'The payment will be released to the seller shortly',
	FUNDS_RELEASED = 'Payment has been rleased by the escrow',
	ESCROW_COMPLETED = 'Payment has been sent and received by the seller',
	DISPUTE_OPENED = 'Escrow has been disputed',
	DISPUTE_RESOLVED = 'Escrow dispute has been resolved',
}

export enum StatusIconPaths {
	CREATED = '/files/svg/escrows/createdActive.svg',
	CREATED_ACTIVE = '/files/svg/escrows/createdActive.svg',
	PAYMENT = '/files/svg/escrows/payment.svg',
	PAYMENT_ACTIVE = '/files/svg/escrows/paymentActive.svg',
	SHIPPED = '/files/svg/escrows/shipped.svg',
	SHIPPED_ACTIVE = '/files/svg/escrows/shippedActive.svg',
	RECEIVED = '/files/svg/escrows/received.svg',
	RECEIVED_ACTIVE = '/files/svg/escrows/receivedActive.svg',
	RELEASED = '/files/svg/escrows/released.svg',
	RELEASED_ACTIVE = '/files/svg/escrows/releasedActive.svg',
	CREATED_CANCELLED = '/files/svg/escrows/createdCancelled.svg',
}

export interface EscrowStatusDisplayProps {
	escrow: Escrow;
	buyerToken?: string;
	sellerToken?: string;
}

interface StatusOutput {
	header: string;
	message: string | React.ReactNode;
	iconPath: string;
	buttons: React.ReactNode;
}

interface OpenDisputeEscrowFields {
	description: string;
}
const schema = Yup.object().shape({
	description: Yup.string()
		.required('Problem description required')
		.trim('Description cannot be left empty of filled with only spaces'),
});
const defaultValues = {
	description: '',
};

export default function EscrowStateDisplay(props: EscrowStatusDisplayProps) {
	const [isDisputeModalOpen, setIsDisputeModalOpen] = React.useState<boolean>(false);
	const [isDeclineModalOpen, setIsDeclineModalOpen] = React.useState<boolean>(false);
	const [isMarkAsReceivedModalOpen, setIsMarkAsReceivedModalOpen] = React.useState<boolean>(false);
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const appElement = document.getElementById('root')!;
	const { declineEscrow, acceptEscrow, markEscrowAsReceived, openDisputeEscrow } = useStoreActions((actions) => ({
		...actions.escrow,
	}));
	const toggleDisputeModal = () => {
		setIsDisputeModalOpen(!isDisputeModalOpen);
	};
	const toggleDeclineModal = () => {
		setIsDeclineModalOpen(!isDeclineModalOpen);
	};

	const toggleMarkAsReceivedModal = () => {
		setIsMarkAsReceivedModalOpen(!isMarkAsReceivedModalOpen);
	};

	const disputeFormMethods = useForm<OpenDisputeEscrowFields>({
		resolver: yupResolver(schema),
		defaultValues: defaultValues,
		mode: 'onChange',
	});
	const handleEscrowOpenDispute = async (input: OpenDisputeEscrowFields) => {
		if (input.description && input.description.length === 0) {
			return;
		}

		setIsLoading(true);
		const response = await openDisputeEscrow({
			escrowId: props.escrow.id,
			token: props.buyerToken || '',
			reason: input.description,
		});
		setIsLoading(false);

		if (response.success) {
			setIsDisputeModalOpen(!isDisputeModalOpen);
			window.location.reload();
			toast.success('Dispute has been opened by the seller');
			return;
		}

		setErrorMessage(
			'Something went wrong, please try again. If problem persists, try adding contacting dagpay support directly',
		);
	};

	const handleDeclineEscrow = async () => {
		try {
			const response = await declineEscrow({
				escrowId: props.escrow.id,
				buyerToken: props.buyerToken || '',
			});

			if (response.success) {
				setIsDeclineModalOpen(!isDeclineModalOpen);
				window.location.reload();
				toast.success('Escrow declined');
			}
			if (response.error) {
				toast.error('Declining escrow failed');
			}
		} catch (e) {
			toast.error('Declining escrow failed');
		}
	};

	const handleAcceptEscrow = async () => {
		setIsLoading(true);
		try {
			const response = await acceptEscrow({
				escrowId: props.escrow.id,
				buyerToken: props.buyerToken || '',
			});
			const invoiceUrl = response.payload && response.payload.escrowInvoiceUrl ? response.payload.escrowInvoiceUrl : '';
			if (response.success && invoiceUrl) {
				window.location.href = invoiceUrl;
				setIsLoading(false);
				toast.success('Escrow accepted. You can proceed with payment');
			}
			if (response.error) {
				toast.error('Accepting escrow failed');
			}
			setIsLoading(false);
		} catch (e) {
			toast.error('Accepting escrow failed');
			setIsLoading(false);
		}
	};

	const handleMarkEscrowAsReceived = async () => {
		try {
			const response = await markEscrowAsReceived({
				escrowId: props.escrow.id,
				buyerToken: props.buyerToken || '',
			});
			if (response.success) {
				toast.success('Escrow Marked as received');
			}
			if (response.error) {
				toast.error('Unable to mark product as received');
			}
		} catch (e) {
			toast.error('Unable to mark product as received');
		}
	};

	let statusOuput: StatusOutput = {
		header: '',
		message: '',
		iconPath: '',
		buttons: <></>,
	};

	React.useEffect(() => {}, [props.escrow]);

	switch (props.escrow.status) {
		case EscrowStatus.ESCROW_CREATED:
			statusOuput.header = StatusHeaders.ESCROW_CREATED;
			statusOuput.message = (
				<>
					Seller has created a new escrow deal. Confirm the details below and <strong>Accept deal</strong> to proceed
					with payment.
				</>
			);
			statusOuput.iconPath = StatusIconPaths.CREATED_ACTIVE;
			statusOuput.buttons = props.buyerToken && (
				<>
					<Button.Secondary white onClick={() => toggleDeclineModal()}>
						Decline
					</Button.Secondary>
					<Button.Secondary green onClick={handleAcceptEscrow}>
						Accept deal
					</Button.Secondary>
				</>
			);
			break;

		case EscrowStatus.ESCROW_DECLINED:
			statusOuput.header = StatusHeaders.ESCROW_DECLINED;
			statusOuput.message = (
				<>
					Escrow deal <strong>has been declined</strong> and the seller will need to create a new escrow deal to
					continue.
				</>
			);
			statusOuput.iconPath = StatusIconPaths.CREATED_CANCELLED;
			statusOuput.buttons = <></>;
			break;

		case EscrowStatus.ESCROW_CANCELLED:
			statusOuput.header = StatusHeaders.ESCROW_CANCELLED;
			statusOuput.message = (
				<>
					Escrow deal <strong>has been cancelled</strong> and the seller will need to create a new escrow deal to
					continue.
				</>
			);
			statusOuput.iconPath = StatusIconPaths.CREATED_CANCELLED;
			statusOuput.buttons = <></>;
			break;

		case EscrowStatus.ESCROW_ACCEPTED:
			statusOuput.header = StatusHeaders.ESCROW_CREATED;
			statusOuput.message = (
				<>
					Seller has created a new escrow deal. Confirm the details below and <strong>Accept deal</strong> to proceed
					with payment.
				</>
			);
			statusOuput.iconPath = StatusIconPaths.CREATED_ACTIVE;
			statusOuput.buttons = props.buyerToken && (
				<>
					<Button.Secondary white onClick={() => toggleDeclineModal()}>
						Decline
					</Button.Secondary>
					<Button.Secondary green onClick={handleAcceptEscrow}>
						Accept deal
					</Button.Secondary>
				</>
			);
			break;

		case EscrowStatus.INVOICE_CREATED:
			statusOuput.header = StatusHeaders.ESCROW_CREATED;
			statusOuput.message = (
				<>
					Seller has created a new escrow deal. Confirm the details below and <strong>Accept deal</strong> to proceed
					with payment.
				</>
			);
			statusOuput.iconPath = StatusIconPaths.CREATED_ACTIVE;
			statusOuput.buttons = props.buyerToken && (
				<>
					<Button.Secondary white onClick={() => toggleDeclineModal()}>
						Decline
					</Button.Secondary>
					<Button.Secondary green onClick={handleAcceptEscrow}>
						Accept deal
					</Button.Secondary>
				</>
			);
			break;

		case EscrowStatus.INVOICE_PAID:
			statusOuput.header = StatusHeaders.INVOICE_PAID;
			statusOuput.message = StatusMessages.INVOICE_PAID;
			statusOuput.iconPath = StatusIconPaths.PAYMENT_ACTIVE;
			statusOuput.buttons = (
				<>
					<Button.Secondary onClick={() => toggleDisputeModal()} white>
						Open dispute
					</Button.Secondary>
				</>
			);
			break;

		case EscrowStatus.MARKED_AS_SHIPPED:
			statusOuput.header = StatusHeaders.MARKED_AS_SHIPPED;
			statusOuput.message = (
				<>
					The seller has shipped the product. Please wait until you have received the product, then confirm by selecting{' '}
					<strong>Product received</strong> to complete the deal.
				</>
			);
			statusOuput.iconPath = StatusIconPaths.SHIPPED_ACTIVE;
			statusOuput.buttons = (
				<>
					<Button.Secondary onClick={() => toggleDisputeModal()} white>
						Open dispute
					</Button.Secondary>
					<Button.Secondary onClick={() => toggleMarkAsReceivedModal()} green>
						Mark as received
					</Button.Secondary>
				</>
			);
			break;

		case EscrowStatus.MARKED_AS_RECEIVED:
			statusOuput.header = StatusHeaders.MARKED_AS_RECEIVED;
			statusOuput.message = StatusMessages.MARKED_AS_RECEIVED;
			statusOuput.iconPath = StatusIconPaths.RECEIVED_ACTIVE;
			statusOuput.buttons = (
				<>
					<Button.Secondary onClick={() => toggleDisputeModal()} white>
						Open dispute
					</Button.Secondary>
				</>
			);
			break;

		case EscrowStatus.FUNDS_RELEASED:
			statusOuput.header = StatusHeaders.MARKED_AS_RECEIVED;
			statusOuput.message = StatusMessages.MARKED_AS_RECEIVED;
			statusOuput.iconPath = StatusIconPaths.RELEASED_ACTIVE;
			//no action
			break;

		case EscrowStatus.ESCROW_COMPLETED:
			statusOuput.header = StatusHeaders.ESCROW_COMPLETED;
			statusOuput.message = StatusMessages.ESCROW_COMPLETED;
			statusOuput.iconPath = StatusIconPaths.RELEASED_ACTIVE;
			break;

		case EscrowStatus.DISPUTE_OPENED:
			statusOuput.header = StatusHeaders.DISPUTE_OPENED;
			statusOuput.message = StatusMessages.DISPUTE_OPENED;
			statusOuput.iconPath = StatusIconPaths.CREATED_CANCELLED;
			break;

		case EscrowStatus.DISPUTE_RESOLVED:
			statusOuput.header = StatusHeaders.DISPUTE_RESOLVED;
			statusOuput.message = StatusMessages.DISPUTE_RESOLVED.concat(
				` in favour of the ${props.escrow.dispute.releaseToParty.role.toLowerCase()}`,
			);
			statusOuput.iconPath = StatusIconPaths.RELEASED_ACTIVE;
			break;

		case EscrowStatus.ESCROW_EXPIRED:
			statusOuput.header = StatusHeaders.ESCROW_EXPIRED;
			statusOuput.message = (
				<>
					The escrow deal <strong>has expired</strong> and the seller will need to create a new escrow deal to continue.
				</>
			);
			statusOuput.iconPath = StatusIconPaths.CREATED_CANCELLED;
			break;

		default:
			break;
	}

	if (isLoading) {
		return (
			<EscrowStatusWrapper>
				<Loader />
			</EscrowStatusWrapper>
		);
	}
	return (
		<>
			<EscrowStatusWrapper
				problem={
					props.escrow.status === EscrowStatus.ESCROW_DECLINED ||
					props.escrow.status === EscrowStatus.ESCROW_CANCELLED ||
					props.escrow.status === EscrowStatus.ESCROW_EXPIRED ||
					props.escrow.status === EscrowStatus.DISPUTE_OPENED
				}
			>
				<EscrowStatusHeader
					problem={
						props.escrow.status === EscrowStatus.ESCROW_DECLINED ||
						props.escrow.status === EscrowStatus.ESCROW_CANCELLED ||
						props.escrow.status === EscrowStatus.ESCROW_EXPIRED ||
						props.escrow.status === EscrowStatus.DISPUTE_OPENED
					}
				>
					<h2>
						<ReactSVG src={statusOuput.iconPath} />
						{statusOuput.header}
					</h2>
				</EscrowStatusHeader>
				<EscrowStatusText>
					<p>{statusOuput.message}</p>
					<EscrowStatusButtons>{statusOuput.buttons}</EscrowStatusButtons>
				</EscrowStatusText>
			</EscrowStatusWrapper>
			{/* Escrow decline confirm modal */}
			<Modal
				appElement={appElement}
				isOpen={isDeclineModalOpen}
				ariaHideApp={false}
				onRequestClose={() => toggleDeclineModal()}
				small
				hasCloseButton
			>
				<EscrowSupportModalWrapper>
					<h2>Decline escrow deal?</h2>
					<p>
						This will cancel the escrow deal request. The seller will need to create a new escrow deal if you wish to
						continue with it later.
					</p>
					<ModalButtonWrapper>
						<Button.Secondary white onClick={() => toggleDeclineModal()}>
							Cancel
						</Button.Secondary>
						<Button.Secondary type="button" red onClick={handleDeclineEscrow}>
							Decline escrow deal
						</Button.Secondary>
					</ModalButtonWrapper>
				</EscrowSupportModalWrapper>
			</Modal>
			{/* Escrow mark as received confirm modal */}
			<Modal
				appElement={appElement}
				isOpen={isMarkAsReceivedModalOpen}
				ariaHideApp={false}
				onRequestClose={() => toggleMarkAsReceivedModal()}
				small
				hasCloseButton
			>
				<EscrowSupportModalWrapper>
					<h2>Happy with the product?</h2>
					<p>Is everything as expected and are you ready to release the coins to the seller?</p>
					<ModalButtonWrapper>
						<Button.Secondary white onClick={() => toggleMarkAsReceivedModal()}>
							Cancel
						</Button.Secondary>
						<Button.Secondary type="button" green onClick={handleMarkEscrowAsReceived}>
							Yes, release coins to seller
						</Button.Secondary>
					</ModalButtonWrapper>
				</EscrowSupportModalWrapper>
			</Modal>
			{/* Escrow dispute modal */}
			<Modal
				appElement={appElement}
				isOpen={isDisputeModalOpen}
				ariaHideApp={false}
				onRequestClose={() => toggleDisputeModal()}
				small
				hasCloseButton
			>
				<EscrowSupportModalWrapper>
					<FormProvider {...disputeFormMethods}>
						<ReactHookForm onSubmit={disputeFormMethods.handleSubmit(handleEscrowOpenDispute)} id="escrowSupportModal">
							<h2>Open dispute</h2>
							<p>
								If you haven’t received the product and the estimated time has passed, please contact the merchant for
								more info. Otherwise you can open a dispute.
							</p>
							<HookTextAreaField label="Description" name="description" rows={3} />
							<span>The response will be sent to your email</span>
							<ModalButtonWrapper>
								<Button.Secondary white onClick={() => toggleDisputeModal()}>
									Cancel
								</Button.Secondary>
								<Button.Secondary type="submit" form="escrowSupportModal" green>
									Send
								</Button.Secondary>
							</ModalButtonWrapper>
						</ReactHookForm>
					</FormProvider>
				</EscrowSupportModalWrapper>
			</Modal>
		</>
	);
}
function setErrorMessage(arg0: string) {
	throw new Error('Function not implemented.');
}
