import React, { useEffect, useRef, useState } from 'react';
import Fade from 'react-reveal';
import { withRouter } from 'react-router';
import ClipLoader from 'react-spinners/ClipLoader';
import ReactSVG from 'react-svg';

import { Invoice, InvoiceState, InvoiceTypes } from '../../api/invoiceApi';
import ErrorView from '../../components/error/ErrorView';
import { Currency } from '../../constants';
import { Color } from '../../gfx/constants';
import { CenteredFlex } from '../../gfx/globals';
import getPaymentCountdown from '../../services/get-payment-countdown';
import { RouteProps } from '../../typings';

import CopyQrInfoButton from '../../components/copy-qr-info/CopyQrInfoButton';
import { DagcoinCardContent } from './components/DagcoinCard/DagcoinCardContent';
import InvoiceContentBlock, { EmailReceiptRequestFields } from './components/InvoiceContentBlock';
import MobileInvoiceDetail from './components/mobile-device-view/MobileInvoiceDetail';
import {
	CardSlideWrapper,
	HelpIcon,
	HelpLink,
	PayWithCardButton,
} from './components/mobile-device-view/MobileInvoiceDetailStyle';
import {
	AwaitingPaymentText,
	CancelPaymentWrapper,
	ContentHeader,
	ContentHeaderIconWrapper,
	InvoiceDiscount,
	PayContainer,
	PaymentAmountBlock,
	PaymentAmountInfoBlock,
	PaymentCancelLink,
	PaymentCurrencyAmount,
	PaymentDagAmount,
	PaymentDagPayLogo,
	PaymentExpirationBlock,
	PaymentHourGlassLoader,
	PaymentInfoBlock,
	PaymentInfoText,
	PaymentInfoLabelText,
	PaySidebar,
	PayWrapper,
	QrCode,
	QrWrapperMaxTablet,
} from './InvoiceViewStyle';

export interface InvoiceViewTplProps extends RouteProps {
	invoice: Invoice;
	invoiceError: string | null;
	sendReceiptError: string | null;
	isReceiptSent: boolean;
	invoiceState: InvoiceState;
	isMobile: boolean;
	isRepeatableSentActive: boolean;
	emailReceiptInputValue: string;
	canCancel: boolean;
	escrowProductInfo?: string;
	handleSendReceipt(input: EmailReceiptRequestFields): void;
	handleCancelPayment(): void;
	onEmailReceiptFieldChange(email: string): void;
	handleReceiptRepeatableSent(): void;
}

function InvoiceViewTpl(props: InvoiceViewTplProps) {
	const [timer, setTimer] = useState<JSX.Element | string>('');
	const payWrapperRef = useRef<HTMLDivElement | null>(null);

	const { invoiceState, invoiceError, isMobile, canCancel, escrowProductInfo } = props;

	useEffect(() => {
		setTimer(getPaymentCountdown({ invoice: props.invoice, onComplete: () => window.location.reload() }));
	}, [setTimer, props.invoice]);

	if (!Object.keys(InvoiceState).includes(invoiceState)) {
		// TODO: maybe return error view instead
		throw new Error(
			`Unexpected invoice state "${invoiceState}", expected one of ${Object.keys(InvoiceState).join(', ')}`,
		);
	}

	if (invoiceError && invoiceState !== InvoiceState.FAILED) {
		return <ErrorView />;
	}

	const getQrCode = () => {
		const { invoiceState, invoice } = props;

		if (invoiceState === InvoiceState.PENDING) {
			// send google tag manager data
			window.dataLayer.push({
				event: 'transaction_initiated',
				category: 'transaction',
				transactionTotal: invoice.coinAmount,
			});

			return (
				<QrWrapperMaxTablet>
					<Fade>
						<QrCode isPosInvoice={invoice.type === InvoiceTypes.POS}>
							<img alt="QR code" src={invoice ? invoice.qrCodeUrl : ''} />
						</QrCode>
					</Fade>
					{!isMobile && invoice.type !== InvoiceTypes.POS && (
						<CopyQrInfoButton
							qrInfo={invoice.id}
							label="Copy Payment ID"
							successMessage="Payment ID copied to clipboard"
						></CopyQrInfoButton>
					)}
				</QrWrapperMaxTablet>
			);
		}

		return undefined;
	};

	const getReceivedBy = () => (
		<PaymentInfoBlock>
			<CenteredFlex>
				<ReactSVG src="/files/svg/invoice/ReceiverIcon.svg" />
				<PaymentInfoLabelText>Receiver</PaymentInfoLabelText>
			</CenteredFlex>
			<PaymentInfoText>{props.invoice ? props.invoice.merchantName : ''}</PaymentInfoText>
		</PaymentInfoBlock>
	);

	const getProductDescription = (description: string) => (
		<PaymentInfoBlock>
			<CenteredFlex>
				<ReactSVG src="/files/svg/invoice/ReceiverIcon.svg" />
				<PaymentInfoLabelText>Description</PaymentInfoLabelText>
			</CenteredFlex>
			<PaymentInfoText>{description}</PaymentInfoText>
		</PaymentInfoBlock>
	);

	const getAmountInfo = () => {
		const { invoice } = props;
		return (
			<PaymentAmountInfoBlock>
				<CenteredFlex>
					<ReactSVG src="/files/svg/invoice/WalletGreenIcon.svg" />
					<PaymentInfoLabelText>Amount</PaymentInfoLabelText>
				</CenteredFlex>
				<PaymentAmountBlock isDiscountEnabled={invoice.atmOnly && invoice.discount > 0}>
					<PaymentDagAmount>
						{invoice.atmOnly && invoice.discount > 0 && (
							<InvoiceDiscount>{`-${invoice.discount}% Dagloyalty discount`}</InvoiceDiscount>
						)}
						<ReactSVG src="/files/svg/invoice/DagcoinCurrencyIcon.svg" />
						{`${invoice.coinAmount} DAG`}
					</PaymentDagAmount>
					{invoice.currency !== Currency.DAG && (
						<PaymentCurrencyAmount>{`${invoice.currencyAmount} ${invoice.currency}`}</PaymentCurrencyAmount>
					)}
				</PaymentAmountBlock>
			</PaymentAmountInfoBlock>
		);
	};

	const getExpirationBlock = () => {
		const { invoiceState } = props;

		if (invoiceState === InvoiceState.PENDING) {
			return (
				<PaymentExpirationBlock>
					<CenteredFlex>Payment expires in {timer}</CenteredFlex>
					<AwaitingPaymentText>
						Awaiting payment
						<PaymentHourGlassLoader>
							<ClipLoader color={Color.GREEN_3} sizeUnit={'px'} size={20} />
						</PaymentHourGlassLoader>
					</AwaitingPaymentText>
				</PaymentExpirationBlock>
			);
		}

		return '';
	};

	const getPaymentSidebar = () => {
		const { handleCancelPayment, invoice } = props;

		if (!invoice || !payWrapperRef) {
			// TODO: maybe return error view instead
			throw new Error(`Invoice not found or not loaded properly`);
		}

		const isPosInvoice = invoice.type === InvoiceTypes.POS;
		const isCardPayment = !!/card-payment/.exec(props.location.pathname);

		return (
			<>
				{!invoice.atmOnly && isMobile && isPosInvoice && payWrapperRef !== null && (
					<CardSlideWrapper slide={isCardPayment}>
						<DagcoinCardContent {...props} parentContainerRef={payWrapperRef} />
					</CardSlideWrapper>
				)}
				<ContentHeader>
					<ContentHeaderIconWrapper>
						<ReactSVG src="/files/svg/logos/DagcoinWhiteLogoSquared.svg" />
					</ContentHeaderIconWrapper>
					<HelpLink target="_blank" href="https://help.dagpay.io/en/articles/4248432-how-do-i-pay-a-dagpay-invoice">
						<span>Help</span>
						<HelpIcon>
							<ReactSVG src="/files/svg/icons/Help.svg" />
						</HelpIcon>
					</HelpLink>
				</ContentHeader>
				{getAmountInfo()}
				{getReceivedBy()}
				{escrowProductInfo && getProductDescription(escrowProductInfo)}
				{getExpirationBlock()}
				{isMobile && InvoiceState.PENDING ? getQrCode() : ''}
				{!invoice.atmOnly && isPosInvoice && isMobile && payWrapperRef !== null && (
					<PayWithCardButton
						disabled={invoice.atmOnly}
						type={'button'}
						onClick={() => props.history.push(`${props.location.pathname}/card-payment`)}
						color="GREEN"
					>
						Pay with Dagcoin card
					</PayWithCardButton>
				)}
				{canCancel && (
					<CancelPaymentWrapper>
						<PaymentCancelLink onClick={handleCancelPayment}>Cancel payment</PaymentCancelLink>
					</CancelPaymentWrapper>
				)}
			</>
		);
	};

	const getCurrentDeviceView = () => {
		const { invoice } = props;

		if (invoiceState === InvoiceState.PENDING) {
			if (invoice.type === InvoiceTypes.POS) {
				return (
					<>
						<PaySidebar>{getPaymentSidebar()}</PaySidebar>
						{!isMobile && <InvoiceContentBlock {...props} />}
					</>
				);
			}

			return isMobile ? (
				<MobileInvoiceDetail {...props} getQrCode={getQrCode} payWrapperRef={payWrapperRef} timer={timer} />
			) : (
				<>
					<PaySidebar>{getPaymentSidebar()}</PaySidebar>
					<InvoiceContentBlock {...props} />
				</>
			);
		}

		return (
			<>
				{!isMobile && <PaySidebar>{getPaymentSidebar()}</PaySidebar>}
				<InvoiceContentBlock {...props} />
			</>
		);
	};

	return (
		<PayContainer>
			<PaymentDagPayLogo>
				Powered by
				<ReactSVG src="/files/svg/logos/DagpayLogoGray.svg" />
			</PaymentDagPayLogo>
			<PayWrapper ref={payWrapperRef}>{getCurrentDeviceView()}</PayWrapper>
		</PayContainer>
	);
}

export default withRouter(InvoiceViewTpl);
