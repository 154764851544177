import { ApiResponse, ValidationFailedResponse } from '../typings';

export default async function handleFetch<TResult>(apiRequest: Promise<any>): Promise<ApiResponse<TResult>> {
	try {
		const result = await apiRequest;

		if (typeof result === 'string') {
			return {
				payload: result as any,
				status: 200,
				error: '',
				success: true,
				validationErrors: [],
			};
		}

		return { ...result, status: 200 };
	} catch (e) {
		const error: ValidationFailedResponse = e;
		return {
			payload: null,
			status: error.response ? error.response.status : 500,
			error:
				error.response && (error.response.data as unknown as string) === 'AUTHENTICATOR_LOGIN_CODE_REQUIRED'
					? 'AUTHENTICATOR_LOGIN_CODE_REQUIRED'
					: error.message,
			success: false,
			validationErrors:
				error.response &&
				error.response.data &&
				typeof error.response.data === 'object' &&
				error.response.data.validationErrors &&
				error.response.data.validationErrors.length > 0
					? error.response.data.validationErrors
					: [],
		};
	}
}
