import { ErrorMessage } from '@hookform/error-message';
import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { FieldOptionProps } from '../field/Field';
import { Error, FieldWrapper, Label } from '../react-hook-form/HookFieldsStyle';
import {
	HiddenControl,
	RadioButton,
	RadioButtonsContainer,
	RadioFieldWrapper,
	RadioLabel,
} from './HookVerticalRadioGroupStyle';

export interface Props {
	selectOptions: FieldOptionProps[];
	name: string;
	errorMessage?: string;
	className?: string;
	disabled?: boolean;
	label?: string;
	labelSize?: number;
}

export interface RadioProp {
	selected: boolean;
	disabled?: boolean;
	labelSize?: number;
}

export default function HookRadioButtonGroup(props: Props) {
	const { name, selectOptions, className, label, disabled, labelSize } = props;

	const { watch, setValue, register } = useFormContext();

	const watchValue = watch(name);
	return (
		<FieldWrapper>
			{label && <Label>{label}</Label>}
			<RadioFieldWrapper>
				<RadioButtonsContainer className={className} role="group">
					{selectOptions.map((child, index) => {
						return (
							<RadioLabel selected={watchValue === child.value} key={index} disabled={disabled} labelSize={labelSize}>
								<HiddenControl
									type="radio"
									ref={register}
									name={name}
									checked={watchValue === child.value}
									onChange={(e: React.ChangeEvent<HTMLInputElement>) => setValue(name, child.value)}
									value={child.value}
									disabled={disabled}
								/>
								<RadioButton>{child.label}</RadioButton>
							</RadioLabel>
						);
					})}
				</RadioButtonsContainer>
			</RadioFieldWrapper>
			<Error>
				<ErrorMessage name={name} />
			</Error>
		</FieldWrapper>
	);
}
