import { NavLink } from 'react-router-dom';
import styled from 'styled-components/macro';

import { Color } from '../../gfx/constants';
import { media } from '../../services/media';

const activeClassName = 'nav-item-active';

export const StyledPrivateSidebar = styled.div`
	position: relative;
	min-width: 260px;
	background: ${Color.BLUE_9};
	color: #fefefe;

	${media.maxDesktop`
		display: none;
	`}
`;

export const StickyWrapper = styled.div`
	position: sticky;
	top: 0;
	padding-bottom: 40px;
`;

export const PrivateSidebarLogo = styled.div`
	display: flex;
	align-items: center;
	padding: 25px;
	justify-content: center;
	text-align: left;

	${media.maxDesktop`
		display: none;
	`}
`;

export const PrivateSidebarNavigation = styled.nav`
	padding-top: 65px;

	a {
		&:first-of-type {
			margin-top: 0;
		}
	}
`;

export const PrivateSidebarNavLink = styled(NavLink).attrs({ activeClassName })`
	align-items: center;
	font-weight: 400;
	margin: 0px 18px;
	height: 48px;
	color: white;
	margin-top: 12px;
	display: flex;
	font-size: 15px;
	border-radius: 6px;
	justify-content: left;
	padding-left: 32px;
	padding-right: 32px;
	line-height: 18px;
	text-decoration: none;

	&:hover {
		background-color: rgba(186, 188, 195, 0.1);
	}

	&.${activeClassName} {
		background-color: ${Color.GREEN_3};
	}
`;

export const PrivateSidebarVersion = styled.div`
	position: absolute;
	left: 50%;
	bottom: 10px;
	transform: translateX(-50%);
	opacity: 0.6;
`;

export const PrivateSidebarIcon = styled.div`
	margin-right: 20px;
	min-width: 18px;
`;
