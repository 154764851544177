import { FormikFieldState } from 'formik-fields';
import * as React from 'react';

import { FieldProps } from '../field/Field';
import StyledField from '../field/FieldStyle';

interface DecimalInputProps extends FieldProps {
	formikField: FormikFieldState<string | boolean | number | null | File[]>;
	decimalPlaces?: number;
	fieldLength?: number;
}

export default function DecimalInput(props: DecimalInputProps) {
	// tslint:disable-next-line:no-any
	const { formikField, form, decimalPlaces, fieldLength, ...rest }: any = props;

	const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (!fieldLength || e.target.value.toString().length <= fieldLength) {
			formikField.setValue(
				e.target.value
					.toString()
					.split('.')
					.map((el, i) => (i ? el.split('').slice(0, decimalPlaces).join('') : el))
					.join('.'),
			);
		}
	};

	return (
		<StyledField.BaseInputWrapper>
			<StyledField.Input
				name={formikField.name}
				value={formikField.value || ''}
				onChange={handleOnChange}
				onBlur={() => {
					formikField.handleBlur();
				}}
				{...rest}
			/>
		</StyledField.BaseInputWrapper>
	);
}
