import ReactPager from 'react-pager';
import styled from 'styled-components/macro';
import { Color } from '../../gfx/constants';

export const StyledPager = styled(ReactPager)`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	list-style: none;
	user-select: none;
	padding-left: 0;
	margin: 20px 0;
	width: 100%;

	.active {
		color: ${Color.GRAY_12};
		cursor: initial !important;
		text-decoration: underline;
	}

	li {
		height: 22px;
		width: 24px;
		margin: 0 1px;
		text-align: center;
		font-family: Primary;
		font-size: 14px;
		line-height: 21px;
		color: ${Color.GRAY_7};

		a {
			min-width: 100%;
		}

		&:hover:not(.disabled) {
			cursor: pointer;
			color: ${Color.GRAY_12};
		}
	}

	.btn-first-page,
	.btn-last-page {
		width: 29px;
	}

	.btn-prev-page {
		a {
			display: block;
			position: relative;
			height: 22px;
			width: 24px;
			color: transparent;

			::after {
				position: absolute;
				top: 4px;
				left: 8px;
				height: 12px;
				width: 9px;
				content: '';
				mask: url('/files/svg/private/Arrow.svg');
				mask-size: contain;
				mask-repeat: no-repeat;
				background-color: ${Color.GRAY_7};
			}
		}

		:hover:not(.disabled) {
			a {
				::after {
					background-color: ${Color.GRAY_12};
				}
			}
		}
	}

	.btn-next-page {
		transform: rotate(180deg);

		a {
			display: block;
			position: relative;
			height: 22px;
			width: 24px;
			color: transparent;

			::after {
				position: absolute;
				top: 6px;
				left: 8px;
				height: 12px;
				width: 9px;
				content: '';
				mask: url('/files/svg/private/Arrow.svg');
				mask-size: contain;
				mask-repeat: no-repeat;
				background-color: ${Color.GRAY_7};
			}
		}

		:hover:not(.disabled) {
			a {
				::after {
					background-color: ${Color.GRAY_12};
				}
			}
		}
	}
`;
