import { Action, action, thunk, Thunk, thunkOn, ThunkOn } from 'easy-peasy';

import {
	Atm,
	AtmType,
	createAtmApi,
	CreateAtmRequest,
	getAtmApi,
	getAtmListApi,
	GetAtmListRequest,
	getAtmLoyaltyPdfBase64,
	GetAtmRequest,
} from '../api/atmApi';
import handleFetch from '../services/handle-fetch';
import { ApiArrayPayload, Response, ResponseArray } from '../typings';

import { StoreModel } from '.';

export interface AtmModel {
	// state
	mixedTypeList: ApiArrayPayload<Atm> | null;
	atmList: ApiArrayPayload<Atm> | null;
	vAtmList: ApiArrayPayload<Atm> | null;
	atm: Atm | null;
	vAtm: Atm | null;

	// actions
	saveMixedTypeList: Action<AtmModel, ApiArrayPayload<Atm> | null>;
	saveAtmList: Action<AtmModel, ApiArrayPayload<Atm> | null>;
	saveVatmList: Action<AtmModel, ApiArrayPayload<Atm> | null>;
	saveAtm: Action<AtmModel, Atm | null>;
	saveVatm: Action<AtmModel, Atm | null>;
	clearMixedTypeList: Action<AtmModel>;
	clearAtmList: Action<AtmModel>;
	clearVatmList: Action<AtmModel>;
	clearAtm: Action<AtmModel>;
	clearVatm: Action<AtmModel>;

	// thunks
	getAtmLoyaltyPdfBase64: Thunk<AtmModel, GetAtmRequest, {}, StoreModel, Response<string>>;
	getMixedTypeList: Thunk<AtmModel, GetAtmListRequest, {}, StoreModel, ResponseArray<Atm>>;
	getAtmList: Thunk<AtmModel, GetAtmListRequest, {}, StoreModel, ResponseArray<Atm>>;
	getVatmList: Thunk<AtmModel, GetAtmListRequest, {}, StoreModel, ResponseArray<Atm>>;
	getAtm: Thunk<AtmModel, GetAtmRequest, {}, StoreModel, Response<Atm>>;
	getVatm: Thunk<AtmModel, GetAtmRequest, {}, StoreModel, Response<Atm>>;
	createAtm: Thunk<AtmModel, CreateAtmRequest, {}, StoreModel, Response<Atm>>;
	createVatm: Thunk<AtmModel, CreateAtmRequest, {}, StoreModel, Response<Atm>>;

	// listeners
	//listeners: Listen<AtmModel>;
	onCreateVatm: ThunkOn<AtmModel, {}, StoreModel>;
}

const atm: AtmModel = {
	// state
	mixedTypeList: null,
	atmList: null,
	vAtmList: null,
	vAtm: null,
	atm: null,

	// actions
	saveMixedTypeList: action((state, payload) => {
		state.mixedTypeList = payload;
	}),
	saveAtmList: action((state, payload) => {
		state.atmList = payload;
	}),
	saveVatmList: action((state, payload) => {
		state.vAtmList = payload;
	}),
	saveAtm: action((state, payload) => {
		state.atm = payload;
	}),
	saveVatm: action((state, payload) => {
		state.vAtm = payload;
	}),
	clearMixedTypeList: action((state) => {
		state.mixedTypeList = null;
	}),
	clearAtmList: action((state) => {
		state.atmList = null;
	}),
	clearVatmList: action((state) => {
		state.vAtmList = null;
	}),
	clearAtm: action((state) => {
		state.atm = null;
	}),
	clearVatm: action((state) => {
		state.vAtm = null;
	}),

	// thunks
	getAtmLoyaltyPdfBase64: thunk(async (actions, payload) => {
		return await handleFetch<string>(getAtmLoyaltyPdfBase64(payload));
	}),
	getMixedTypeList: thunk(async (actions, payload) => {
		actions.clearMixedTypeList();

		const result = await handleFetch<ApiArrayPayload<Atm>>(getAtmListApi(payload));

		actions.saveMixedTypeList(result.payload);

		return result;
	}),
	getAtmList: thunk(async (actions, payload) => {
		actions.clearAtmList();

		const result = await handleFetch<ApiArrayPayload<Atm>>(getAtmListApi(payload));

		actions.saveAtmList(result.payload);

		return result;
	}),
	getVatmList: thunk(async (actions, payload) => {
		actions.clearVatmList();

		const result = await handleFetch<ApiArrayPayload<Atm>>(getAtmListApi(payload));

		actions.saveVatmList(result.payload);

		return result;
	}),
	getAtm: thunk(async (actions, payload) => {
		actions.clearAtm();

		const result = await handleFetch<Atm>(getAtmApi(payload));

		actions.saveAtm(result.payload);

		return result;
	}),
	getVatm: thunk(async (actions, payload) => {
		actions.clearVatm();

		const result = await handleFetch<Atm>(getAtmApi(payload));

		actions.saveVatm(result.payload);

		return result;
	}),
	createAtm: thunk(async (actions, payload) => {
		return await handleFetch<Atm>(createAtmApi(payload));
	}),
	createVatm: thunk(async (actions, payload) => {
		return await handleFetch<Atm>(createAtmApi(payload));
	}),

	// listeners
	onCreateVatm: thunkOn(
		(actions, storeActions) => actions.createVatm,
		(actions, payload) => {
			if (payload.type === AtmType.PHYSICAL) {
				actions.getAtmList({ typeList: [payload.type as AtmType] });
			}
			if (payload.type === AtmType.VIRTUAL) {
				actions.getVatmList({ typeList: [payload.type as AtmType] });
			}
		},
	),
};

export default atm;
