import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

import { Color } from '../../gfx/constants';
import { H1 } from '../../gfx/globals';

export const StyledForbiddenView = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	margin: auto;
	padding-top: 3%;
	width: 100%;

	a {
		color: ${Color.GREEN_3};
	}
`;

const ForbiddenView = () => (
	<StyledForbiddenView>
		<H1 center>
			You don't have permission to view this page, please return back to <Link to="/">main page</Link>.
		</H1>
	</StyledForbiddenView>
);

export default ForbiddenView;
