import * as qs from 'query-string';
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';

import Loader from '../../../../components/loader/Loader';
import { ITEMS_PER_PAGE, UserRole } from '../../../../constants';
import isParentVerified from '../../../../services/is-parent-verified';
import isUserVerified from '../../../../services/is-user-verified';
import isWalletAdded from '../../../../services/is-wallet-added';
import { useStoreActions, useStoreState } from '../../../../services/store';
import { RouteProps } from '../../../../typings';

import OneTimeInvoicesViewTpl from './OneTimeInvoicesListViewTpl';

export interface OpenedInformation {
	invoiceId: string;
	isOpened: boolean;
}

function OneTimeInvoicesListView(props: RouteProps) {
	const [page, setPage] = useState(1);
	const [openedInformationRows, setOpenedInformationRows] = useState<OpenedInformation[]>([]);

	const { emailInvoices, activeViewer } = useStoreState((state) => ({
		emailInvoices: state.invoice.emailInvoices,
		activeViewer: state.viewer.activeViewer,
	}));

	const { getEmailInvoices } = useStoreActions((actions) => ({
		...actions.invoice,
	}));

	useEffect(() => {
		const fetchEmailInvoices = async (page: number) => {
			await getEmailInvoices({ page: page, itemsPerPage: ITEMS_PER_PAGE });
		};
		const newPage = qs.parse(props.location.search).page;

		if (newPage && typeof newPage === 'string') {
			setPage(parseInt(newPage, 10));
		}

		fetchEmailInvoices(page);
	}, [page, props.location.search, getEmailInvoices]);

	const handleToggleInformation = (invoiceId: string) => {
		const index = openedInformationRows.findIndex((obj) => obj.invoiceId === invoiceId);
		const openedInformationRowsCopy = openedInformationRows.slice();

		if (index === -1) {
			openedInformationRowsCopy.push({ invoiceId, isOpened: true });
		}

		if (index >= 0) {
			openedInformationRowsCopy[index].isOpened = !openedInformationRows[index].isOpened;
		}

		setOpenedInformationRows(openedInformationRowsCopy);
	};

	const handlePageChanged = (newPage: number) => {
		setPage(newPage);
	};

	if (!emailInvoices || !activeViewer) {
		return <Loader />;
	}

	const { page: pageFromLink } = qs.parse(window.location.search);

	if (emailInvoices && pageFromLink && typeof pageFromLink === 'string') {
		if (parseInt(pageFromLink, 10) < 1 || parseInt(pageFromLink, 10) > emailInvoices.pageCount) {
			return <div />;
		}
	}

	return (
		<OneTimeInvoicesViewTpl
			page={page}
			handlePageChanged={handlePageChanged}
			emailInvoices={emailInvoices}
			openedInformationRows={openedInformationRows}
			handleToggleInformation={handleToggleInformation}
			isWalletAdded={isWalletAdded()}
			isVerified={isUserVerified()}
			isParentVerified={isParentVerified()}
			isTeamMember={activeViewer.role === UserRole.TEAM_MEMBER || activeViewer.role === UserRole.ADVANCED_TEAM_MEMBER}
			isOwner={activeViewer.role === UserRole.OWNER}
		/>
	);
}

export default withRouter(OneTimeInvoicesListView);
