import React from 'react';
import { withRouter } from 'react-router';

import Loader from '../../../components/loader/Loader';
import View from '../../../components/view/View';
import { UserRole } from '../../../constants';
import { H1 } from '../../../gfx/globals';
import { useStoreState } from '../../../services/store';

import VerifyBusinessTiersTpl from './VerifyBusinessTiersTpl';

function Dashboard() {
	const { activeViewer, verifications } = useStoreState((state) => ({
		activeViewer: state.viewer.activeViewer,
		verifications: state.verification.verifications,
	}));

	if (!activeViewer || !verifications) {
		return <Loader />;
	}

	// Notify admin / team member when organistation owner is rejected / has uncomplete profile setup
	if (activeViewer.role !== UserRole.OWNER) {
		return (
			<View noUnderlineForLinks>
				<H1 center>Organisation owner account setup not completed</H1>
				<View.Description>
					Your organisation account owner must first complete their account setup in order to continue using Dagpay
				</View.Description>
			</View>
		);
	}

	if (!verifications) {
		return <Loader />;
	}

	return (
		<VerifyBusinessTiersTpl
			isBusinessVerified={verifications.isBusinessVerified}
			tier2VerificationStatus={verifications.tiers?.TIER2?.state}
			tier3VerificationStatus={verifications.tiers?.TIER3?.state}
		/>
	);
}

export default withRouter(Dashboard);
