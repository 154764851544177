import axios from 'restyped-axios';
import { ApiResponse } from '../typings';
export const api = axios.create<DiscountApi>();

export interface DiscountApi {
	'/': {
		GET: {
			response: ApiResponse<Discount[]>;
		};
		POST: {
			body: CreateDiscountRequest;
			response: ApiResponse<Discount>;
		};
		PUT: {
			body: UpdateDiscountRequest;
			response: ApiResponse<Discount>;
		};
	};
}

export enum DiscountType {
	VATM = 'VATM',
	ATM = 'ATM',
}

export interface Discount {
	id: string;
	userId: string;
	type: DiscountType;
	amount: number;
	createdDate: string;
	updatedDate: string;
}

export interface CreateDiscountRequest {
	userId: string;
	type: DiscountType;
	amount: number;
}

export interface UpdateDiscountRequest {
	discountId: string;
	amount: number;
}

export const getDiscountListApi = async () => {
	const response = await api.get('/discount');

	return response.data;
};

export const createDiscountApi = async (params: CreateDiscountRequest) => {
	const response = await api.post('/discount', {
		...params,
	});

	return response.data;
};

export const updateDiscountApi = async (params: UpdateDiscountRequest) => {
	const response = await api.put('/discount', {
		...params,
	});

	return response.data;
};
