import * as qs from 'query-string';
import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { UserRole } from '../../constants';
import isUsingIEBrowser from '../../services/is-using-IE-browser';
import { saveUtmDataToLocalStorage, UtmData } from '../../services/save-utm-data-to-local-storage';
import { useStoreState } from '../../services/store';
import { windowIfDefined } from '../../services/window-is-defined';
import PrivateView from '../../views/private/PrivateView';
import NotSupportedView from '../../views/public/not-supported/NotSupportedView';
import ViewerDataProvider from '../viewer-data-provider/ViewerDataProvider';

interface Props extends RouteProps {
	unauthorizedPath?: string;
}

function PrivateRoute({ unauthorizedPath = '/', location, ...rest }: Props) {
	// IE is not supported

	// Save UTM marketing data to local storage
	if (location) {
		saveUtmDataToLocalStorage(qs.parse(location.search) as unknown as UtmData);
	}

	const { isAuthorized, activeViewer } = useStoreState((state) => ({
		isAuthorized: state.viewer.isAuthorized,
		activeViewer: state.viewer.activeViewer,
	}));

	// Add viewer id to trackJS
	if (isUsingIEBrowser(navigator.userAgent)) {
		return <NotSupportedView />;
	}
	if (isAuthorized && windowIfDefined.trackJs) {
		windowIfDefined.trackJs.configure({
			userId: activeViewer && activeViewer.id ? activeViewer.id : '',
		});
	}

	const isPos = activeViewer ? activeViewer.role === UserRole.POS : false;

	const renderPrivateRoute = (props: RouteProps) => {
		if (props.location) {
			return isAuthorized && activeViewer && activeViewer.id && !isPos ? (
				<ViewerDataProvider>
					<PrivateView />
				</ViewerDataProvider>
			) : (
				<Redirect
					to={{
						pathname: unauthorizedPath,
						search: props.location.search,
						state: {
							from: props.location,
						},
					}}
				/>
			);
		}

		return null;
	};

	return <Route {...rest} render={renderPrivateRoute} />;
}

export default PrivateRoute;
