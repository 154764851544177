import * as React from 'react';
import ReactSVG from 'react-svg';
import { useEffect } from 'react';
import * as qs from 'query-string';
import { withRouter } from 'react-router-dom';
import { RouteProps } from '../../../typings';
import Loader from '../../../components/loader/Loader';
import { useStoreActions, useStoreState } from '../../../services/store';
import {
	EscrowViewWrapper,
	HeaderWrapper,
	EscrowBuyerWrapper,
	TimeLineWrapper,
	TimelineHeader,
	AmountField,
} from './escrowBuyerStyle';

import CircularProgressBar, {
	CircularProgressbarState,
} from '../../../components/circular-progress-bar/CircularProgressBar';
import EscrowStateDisplay from '../../../components/escrow-state-display/EscrowStateDisplay';
import FlexGrid, { Cell, CellContent } from '../../../components/flex-grid/FlexGrid';
import TimeLineProgressBar, {
	TimelineNodeStatus,
} from '../../../components/time-line-progress-bar/TimeLineProgressBar';
import dayjs from 'dayjs';
import { EscrowStatus } from '../../../api/escrowApi';
import HookTimer from '../../../components/hook-timer/HookTimer';
import { Currency } from '../../../constants';

interface RouteParams {
	escrowId: string;
}

const EscrowBuyerView = (props: RouteProps<RouteParams>) => {
	const { token } = qs.parse(props.location.search);
	const { escrow } = useStoreState((state) => ({
		escrow: state.escrow.escrow,
	}));

	const { getEscrow } = useStoreActions((actions) => ({
		...actions.escrow,
	}));

	useEffect(() => {
		const escrowId = props.match.params.escrowId;
		const fetchEscrow = async (escrowId: string) => {
			await getEscrow({ escrowId: escrowId });
		};
		fetchEscrow(escrowId);
	}, [getEscrow, props.match.params.escrowId]);

	if (!escrow) {
		return <Loader />;
	}

	return (
		<EscrowViewWrapper>
			<EscrowBuyerWrapper>
				<HeaderWrapper>
					<ReactSVG src="/files/svg/logos/DagcoinBlueLogo.svg" />
					<span>
						<a
							href="https://help.dagpay.io/en/collections/2832109-dagpay-escrow-deals"
							target="_blank"
							rel="noopener noreferrer"
						>
							Help & Support{' '}
						</a>
					</span>
				</HeaderWrapper>
				<TimeLineWrapper>
					<TimelineHeader>Estimated timeline</TimelineHeader>
					<TimeLineProgressBar
						disputed={escrow.status === EscrowStatus.DISPUTE_OPENED || escrow.status === EscrowStatus.DISPUTE_RESOLVED}
						nodes={[
							{
								label: 'Escrow deal created',
								time: dayjs(escrow.dealEstimationStatuses.escrowCreated.date).toDate(),
								icon: escrow.dealEstimationStatuses.escrowCreated.estimated
									? '/files/svg/escrows/createdActive.svg'
									: '/files/svg/escrows/createdActive.svg',
								status: !escrow.dealEstimationStatuses.escrowCreated.estimated
									? TimelineNodeStatus.ACTIVE
									: TimelineNodeStatus.INACTIVE,
							},
							{
								label: 'Payment in escrow',
								time: dayjs(escrow.dealEstimationStatuses.invoicePaid.date).toDate(),
								icon:
									escrow.status === EscrowStatus.ESCROW_DECLINED ||
									escrow.status === EscrowStatus.ESCROW_CANCELLED ||
									escrow.status === EscrowStatus.ESCROW_EXPIRED
										? '/files/svg/escrows/paymentCancelled.svg'
										: escrow.dealEstimationStatuses.escrowCreated.estimated
										? '/files/svg/escrows/payment.svg'
										: '/files/svg/escrows/paymentActive.svg',
								status:
									escrow.status === EscrowStatus.ESCROW_DECLINED ||
									escrow.status === EscrowStatus.ESCROW_CANCELLED ||
									escrow.status === EscrowStatus.ESCROW_EXPIRED
										? TimelineNodeStatus.FAIL
										: !escrow.dealEstimationStatuses.invoicePaid.estimated
										? TimelineNodeStatus.ACTIVE
										: TimelineNodeStatus.INACTIVE,
							},
							{
								label: 'Product shipped',
								time: dayjs(escrow.dealEstimationStatuses.markedAsShipped.date).toDate(),
								icon: escrow.dealEstimationStatuses.invoicePaid.estimated
									? '/files/svg/escrows/shipped.svg'
									: '/files/svg/escrows/shippedActive.svg',
								status: !escrow.dealEstimationStatuses.markedAsShipped.estimated
									? TimelineNodeStatus.ACTIVE
									: TimelineNodeStatus.INACTIVE,
							},
							{
								label: 'Product received',
								time: dayjs(escrow.dealEstimationStatuses.markedAsReceived.date).toDate(),
								icon: escrow.dealEstimationStatuses.markedAsShipped.estimated
									? '/files/svg/escrows/received.svg'
									: '/files/svg/escrows/receivedActive.svg',
								status: !escrow.dealEstimationStatuses.markedAsReceived.estimated
									? TimelineNodeStatus.ACTIVE
									: TimelineNodeStatus.INACTIVE,
							},
							{
								label: 'Coins released',
								time: dayjs(escrow.dealEstimationStatuses.escrowCompleted.date).toDate(),
								icon: escrow.dealEstimationStatuses.markedAsReceived.estimated
									? '/files/svg/escrows/released.svg'
									: '/files/svg/escrows/releasedActive.svg',
								status: !escrow.dealEstimationStatuses.escrowCompleted.estimated
									? TimelineNodeStatus.ACTIVE
									: TimelineNodeStatus.INACTIVE,
							},
						]}
					/>
				</TimeLineWrapper>
				<EscrowStateDisplay escrow={escrow} buyerToken={token as string} />
				<FlexGrid>
					<Cell column size={3}>
						<Cell cells={2} bb>
							<Cell br mobileRow>
								<CellContent>
									<span>Seller name</span>
									<p>{escrow.sellerParty.fullName}</p>
								</CellContent>
							</Cell>
							<Cell>
								<CellContent>
									<span>Product</span>
									<p>{escrow.product}</p>
								</CellContent>
							</Cell>
						</Cell>
						<Cell mobileRow>
							<CellContent>
								<span>Product notes</span>
								<p>{escrow.productNotes ? escrow.productNotes : '-'}</p>
							</CellContent>
						</Cell>
					</Cell>
					<Cell column bl br>
						<Cell>
							<CellContent>
								<span>Shipping info</span>
								<p>{escrow.shippingInfo ? escrow.shippingInfo : '-'}</p>
							</CellContent>
						</Cell>
						<Cell mobileRow>
							<CellContent>
								<span>Estimated deal duration</span>
								<p>{escrow.estimatedDealDurationDays} days</p>
							</CellContent>
						</Cell>
						<Cell>
							<CellContent>
								<AmountField>
									<span>Amount</span>
									{escrow.currency === Currency.DAG ? (
										<p>{escrow.coinAmount} DAG</p>
									) : (
										<>
											<p>
												{escrow.price} {escrow.currency}
											</p>
										</>
									)}
								</AmountField>
							</CellContent>
						</Cell>
					</Cell>
					<Cell>
						{escrow.status !== EscrowStatus.MARKED_AS_RECEIVED && (
							<CircularProgressBar
								state={
									escrow.status === EscrowStatus.ESCROW_DECLINED ||
									escrow.status === EscrowStatus.ESCROW_CANCELLED ||
									escrow.status === EscrowStatus.ESCROW_EXPIRED ||
									escrow.status === EscrowStatus.DISPUTE_OPENED
										? CircularProgressbarState.ERROR
										: escrow.dealEstimationStatuses.invoicePaid.estimated
										? CircularProgressbarState.LOADER
										: escrow.status === EscrowStatus.INVOICE_PAID
										? CircularProgressbarState.LOCKED
										: !escrow.dealEstimationStatuses.escrowCompleted.estimated ||
										  escrow.status === EscrowStatus.ESCROW_COMPLETED ||
										  escrow.status === EscrowStatus.DISPUTE_RESOLVED
										? CircularProgressbarState.DONE
										: CircularProgressbarState.DISPLAY
								}
								size={100}
								lineWidth={7}
								maxValue={escrow.estimatedDealDurationDays}
								currentValue={escrow.estimatedDealDurationDays - escrow.daysLeft}
								label={
									escrow.status === EscrowStatus.DISPUTE_OPENED || escrow.status === EscrowStatus.INVOICE_PAID
										? 'Coins locked'
										: escrow.status === EscrowStatus.ESCROW_DECLINED
										? 'Deal declined'
										: escrow.status === EscrowStatus.ESCROW_CANCELLED
										? 'Deal Cancelled'
										: escrow.status === EscrowStatus.ESCROW_EXPIRED
										? 'Deal expired'
										: escrow.dealEstimationStatuses.invoicePaid.estimated
										? 'Waiting for payment'
										: escrow.dealEstimationStatuses.escrowCompleted.estimated &&
										  escrow.status !== EscrowStatus.DISPUTE_RESOLVED
										? 'Coins released in'
										: 'Coins released'
								}
								blue
							/>
						)}
						{escrow.status === EscrowStatus.MARKED_AS_RECEIVED && (
							<HookTimer
								endDate={dayjs(escrow.dealEstimationStatuses.markedAsReceived.date)
									.add(escrow.releaseDuration, 'hour')
									.toDate()}
								label="Coins released in"
								size={100}
								lineWidth={7}
								blue
							/>
						)}
					</Cell>
				</FlexGrid>
			</EscrowBuyerWrapper>
		</EscrowViewWrapper>
	);
};

export default withRouter(EscrowBuyerView);
