import styled from 'styled-components/macro';

import { Color } from '../../gfx/constants';

interface StyledDropzoneProps {
	hasError?: boolean;
}

interface DropzoneFooterDataProps {
	hasError?: boolean;
}

export const DropzoneDropNotification = styled.div`
	color: ${Color.GRAY_DARK};
	font-size: 16px;
	font-weight: 500;
	line-height: 27px;
	padding-bottom: 17px;

	button {
		color: ${Color.ORANGE};
		text-decoration: underline;
	}
`;

export const DropzoneLimitsDetails = styled.div`
	color: #515667;

	font-size: 13px;
	line-height: 20px;
	text-align: center;
	font-weight: 400;
`;

export const StyledDropzone = styled.div`
	cursor: pointer;
	align-items: center;
	border-radius: 4px;
	border-style: dashed;
	border-width: 1px;
	display: flex;
	font-family: PrimaryMedium;
	min-width: 100%;
	height: 219px;
	justify-content: center;
	flex-direction: column;
	width: 100%;
`;

export const DropzoneWrapper = styled.div<StyledDropzoneProps>`
	div:first-of-type {
		border-color: ${({ hasError }) => (hasError ? `${Color.RED_2}` : '#BABCC3')};
		border-width: ${({ hasError }) => hasError && `2px`};
	}
`;

export const DropzoneText = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow: hidden;
`;

export const RemoveFileIcon = styled.a`
	margin-left: auto;
	cursor: pointer;
	padding: 12px;
	margin-right: -10px;
`;

export const DropzoneNotification = styled.div`
	font-size: 12px;
	color: #727272;
	padding-bottom: 10px;
`;

export const DropzoneFooterData = styled.div<DropzoneFooterDataProps>`
	background-color: ${({ hasError }) => (hasError ? `${Color.RED_2}` : `${Color.GREEN_3}`)};
	display: flex;
	align-items: center;
	padding: 10px;
	font-weight: 400;
	height: 48px;
	min-width: 100%;
	color: #ffffff;
	font-size: 13px;
	margin-top: 24px;
	border: 1px solid #dcdde0;
	border-radius: 4px;

	svg {
		g,
		path {
			fill: white;
		}
	}
`;

export const DocumentIcon = styled.div`
	svg {
		margin-right: 10px;
	}
`;
