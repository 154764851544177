import styled from 'styled-components/macro';

import { HookFormColumn } from '../../../../../../components/react-hook-form/ReactHookFormStyle';
import { media } from '../../../../../../services/media';

export const InformationSection = styled.div`
	display: flex;
	justify-content: space-between;

	${HookFormColumn} {
		margin-right: 0;
	}

	${media.fromDesktop`
		${HookFormColumn}{
			width: calc(50% - 50px / 2);
		}
	`}

	${media.maxNewInvoiceTable`
		flex-direction: column;
		align-items: center;

		${HookFormColumn}{
			width: 100%;
		}
	`}
`;
