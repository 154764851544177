import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import ReactSVG from 'react-svg';
import styled from 'styled-components/macro';
import { BusinessType, Currency, RoutesUrls, BusinessTier } from '../../constants';
import { Color } from '../../gfx/constants';
import { SubtextDescriptionTextMixin } from '../../gfx/globals';
import { media } from '../../services/media';
import round from '../../services/round';
import { useStoreActions, useStoreState } from '../../services/store';
import Button from '../button/Button';
import { LabelTooltipIcon } from '../react-hook-form/HookFieldsStyle';
import Tooltip, { TooltipPlace } from '../tooltip/Tooltip';

const PrgogressbarBackground = styled.div`
	height: 18px;
	border: 1px solid ${Color.GRAY_5};
	border-radius: 100px;
	width: 100%;
	display: flex;
	box-sizing: border-box;
`;
const ProgressbarFill = styled.div<{ progress: number; gray?: boolean }>`
	height: 16px;
	background-color: ${(props) => (props.gray ? Color.GRAY_3 : Color.GREEN_2)};
	border-radius: ${(props) =>
		!props.gray ? (props.progress > 98 ? '100px' : '100px 0 0 100px') : props.progress > 98 ? '0 100px 100px 0' : '0'};
	width: ${(props) => `${props.progress}%`};
	box-sizing: border-box;
`;
const ProgressbarRemainingFill = styled.div`
	height: 16px;
	border-radius: ${(props) =>
		!props.gray ? (props.progress > 98 ? '100px' : '100px 0 0 100px') : props.progress > 98 ? '0 100px 100px 0' : '0'};
	flex-grow: 1;
	box-sizing: border-box;
`;

const VolumeLimitDisplayWrapper = styled.div`
	width: 100%;
	margin-bottom: 28px;
`;
const VolumeLimitText = styled.div`
	${SubtextDescriptionTextMixin}
	color: ${Color.GRAY_14};
	margin-bottom: 12px;
	display: flex;
	justify-content: space-between;
`;
const VolumeLimitCurrency = styled.span`
	font-weight: 700;
	font-size: 14px;
	margin: 0 4px;
`;

const LimitLink = styled(Link)`
	font-family: primary;
	font-size: 14px;
	line-height: 16px;
	text-decoration: underline !important;
	color: ${Color.BLUE_1};
`;

export const LimitWarningWrapper = styled.div<{ problem: boolean }>`
	background: ${(props) => (props.problem ? Color.RED_1 : Color.GRAY_3)};
	border: 1px solid ${(props) => (props.problem ? Color.RED_2 : Color.GRAY_5)};
	padding: 18px 18px 18px 64px;
	border-radius: 4px;
	font-family: secondary;
	font-size: 14px;
	margin-bottom: 24px;

	span {
		font-size: 16px;
		font-family: primaryMedium;
	}
	${media.maxSize800`
	  flex-direction: column;
	  padding: 22px 26px;
  `}
`;

const LimitStatusButton = styled.div`
	display: flex;
	margin-left: 20px;
	button {
		margin: 5px;
	}
	${media.maxSize800`
	  justify-content: flex-end;
  `}
`;

const LimitStatusText = styled.div`
	display: flex;
	overflow: none;
	${SubtextDescriptionTextMixin}
	p {
		height: 100%;
		margin: auto 0;
		padding-right: 14px;
		flex-shrink: 1;
		max-width: 40%;
	}
	${LimitStatusButton} {
		flex: 1;
	}
	${media.maxSize800`
	  width: 100%;
	  padding-left: 34px;
	  flex-direction: column;
	  p {
		flex: 1;
		max-width: 100%;
		margin-top: 0;
		margin-bottom: 40px;
	}
  `}
`;

export const LimitStatusHeader = styled.div`
	width: 100%;
	overflow: none;
	position: relative;
	padding-right: 30px;
	h2 {
		font-size: 16px;
		font-family: primaryMedium;
		line-height: 24px;
		margin-bottom: 6px;
		margin-top: 0;
	}
	svg {
		position: absolute;
		left: -34px;
		height: 24px;
		width: 24px;
	}
	${media.maxSize800`
	  width: 100%;
	  padding-left: 34px;
	  svg {
		  left: 0;
	  }
  `}
`;

export const LimitWrapper = styled.div`
	display: flex;
`;

const VolumeLimitDisplay = () => {
	const { viewerLimits } = useStoreState((state) => ({
		viewerLimits: state.viewer.viewerBusinessTierLimits,
	}));
	// import from '../../../services/store'
	const { getViewerBusinessLimits } = useStoreActions((actions) => ({
		...actions.viewer,
	}));

	useEffect(() => {
		getViewerBusinessLimits();
	}, [getViewerBusinessLimits]);

	if (!viewerLimits) {
		return <></>;
	}
	const progress =
		!!viewerLimits.volumeLimit.eur && !!viewerLimits.monthlyGrossVolume
			? round((viewerLimits.monthlyGrossVolume.eur * 100) / viewerLimits.volumeLimit.eur)
			: 0;
	const reserved =
		!!viewerLimits.volumeLimit.eur && !!viewerLimits.monthlyTotalVolume.eur
			? round(
					((viewerLimits.monthlyTotalVolume.eur - viewerLimits.monthlyGrossVolume.eur) * 100) /
						viewerLimits.volumeLimit.eur,
			  )
			: 0;
	return (
		<>
			{reserved + progress >= 90 && reserved + progress < 99.8 && (
				<LimitWarningWrapper>
					<LimitStatusHeader>
						<h2>
							<ReactSVG src="/files/svg/private/greenWarning.svg" />
							Monthly limit is about to be reached
						</h2>
					</LimitStatusHeader>
					{viewerLimits.businessType === BusinessType.BUSINESS ? (
						<LimitStatusText>
							You are about to reach your monthly limit for accepting payments in the current calendar month. Please
							upgrade your verification tier to raise your limits. Otherwise you will need to wait until the next
							calendar month to reset your current limit
							{viewerLimits.tier !== BusinessTier.TIER_3 && (
								<LimitStatusButton>
									<Button.Secondary
										type="button"
										white
										onClick={() => (window.location.href = RoutesUrls.VERIFY_BUSINESS_TIERS)}
									>
										Raise limit
									</Button.Secondary>
								</LimitStatusButton>
							)}
						</LimitStatusText>
					) : (
						<LimitStatusText>
							You are about to reach your monthly limit for accepting payments in the current calendar month. When your
							limit is reached, you will need to wait until the next calendar month to reset your current limit
						</LimitStatusText>
					)}
				</LimitWarningWrapper>
			)}
			{reserved + progress >= 99.8 && (
				<LimitWarningWrapper>
					<LimitStatusHeader>
						<h2>
							<ReactSVG src="/files/svg/private/greenWarning.svg" />
							Monthly limit reached
						</h2>
					</LimitStatusHeader>
					{viewerLimits.businessType === BusinessType.BUSINESS ? (
						<LimitStatusText>
							You have reached your monthly limit for accepting payments. Please upgrade your verification tier to raise
							your limits. Otherwise you will need to wait until the next calendar month to reset your current limit
							{viewerLimits.tier !== BusinessTier.TIER_3 && (
								<LimitStatusButton>
									<Button.Secondary
										type="button"
										white
										onClick={() => (window.location.href = RoutesUrls.VERIFY_BUSINESS_TIERS)}
									>
										Raise limit
									</Button.Secondary>
								</LimitStatusButton>
							)}
						</LimitStatusText>
					) : (
						<LimitStatusText>
							You have reached your monthly limit for accepting payments. You will need to wait until the next calendar
							month to reset your current limit.
						</LimitStatusText>
					)}
				</LimitWarningWrapper>
			)}
			{viewerLimits && !!viewerLimits.volumeLimit.base && viewerLimits.tier !== BusinessTier.TIER_3 && (
				<VolumeLimitDisplayWrapper>
					<VolumeLimitText>
						<LimitWrapper>
							Limit &#126;{' '}
							{viewerLimits.baseCurrency !== Currency.DAG
								? round(viewerLimits.monthlyTotalVolume.base, 2)
								: round(viewerLimits.monthlyTotalVolume.base, 6)}{' '}
							/{' '}
							{viewerLimits.baseCurrency !== Currency.DAG
								? round(viewerLimits.volumeLimit.base, 2)
								: round(viewerLimits.volumeLimit.base, 6) || String.fromCharCode(8734)}
							<VolumeLimitCurrency>{viewerLimits.baseCurrency}</VolumeLimitCurrency>
							<LabelTooltipIcon /* data-event="click" */ data-tip data-for="tierLimit">
								<ReactSVG src="/files/svg/private/Info.svg" />
							</LabelTooltipIcon>
							<Tooltip
								tipId="tierLimit"
								delayHide={1200}
								content={
									<div>
										<span>Your monthly limit for processing payments</span>
										<br />
										<span>
											{`Limit refresh date: ${dayjs(new Date()).add(1, 'month').format('1. MMM YYYY')}.`}{' '}
											<a
												target="_blank"
												href="http://help.dagpay.io/en/articles/5591746-account-types-and-approved-monthly-processing-volume-tier-limits"
												rel="noopener noreferrer"
											>
												Learn more
											</a>
										</span>
									</div>
								}
							/>
						</LimitWrapper>
						{viewerLimits.businessType === BusinessType.BUSINESS && (
							<LimitLink to={RoutesUrls.VERIFY_BUSINESS_TIERS}>Raise Limit</LimitLink>
						)}
					</VolumeLimitText>
					<PrgogressbarBackground>
						<Tooltip
							content={
								<span>{`processed ${round(viewerLimits.monthlyGrossVolume.base, 2)} ${
									viewerLimits.baseCurrency
								}`}</span>
							}
							tipId="volumeBalance"
							delayHide={0}
						/>
						<ProgressbarFill
							progress={progress > 1 ? progress : 0}
							id="volumeBalance"
							data-tip
							data-for="volumeBalance"
						/>
						{reserved > 1 && (
							<Tooltip
								content={
									<span>{`reserved ${round(
										viewerLimits.monthlyTotalVolume.base - viewerLimits.monthlyGrossVolume.base,
										2,
									)} ${viewerLimits.baseCurrency}`}</span>
								}
								tipId="totalBalance"
								place={TooltipPlace.BOTTOM}
								delayHide={0}
							/>
						)}
						<ProgressbarFill
							progress={reserved > 1 ? reserved : 0}
							gray
							id="totalBalance"
							data-tip
							data-for="totalBalance"
						></ProgressbarFill>
						<ProgressbarRemainingFill data-tip data-for="remainingBalance" />
						<Tooltip
							content={
								<span>{`remaining ${round(viewerLimits.volumeLimit.base - viewerLimits.monthlyTotalVolume.base, 2)} ${
									viewerLimits.baseCurrency
								}`}</span>
							}
							tipId="remainingBalance"
							delayHide={0}
						/>
					</PrgogressbarBackground>
				</VolumeLimitDisplayWrapper>
			)}
		</>
	);
};

export default VolumeLimitDisplay;
