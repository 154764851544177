import { Action, action, thunk, Thunk } from 'easy-peasy';

import { Country, getCountriesApi } from '../api/countryApi';
import handleFetch from '../services/handle-fetch';
import { Response } from '../typings';

import { StoreModel } from '.';

export interface CountryModel {
	// state
	countries: Country[] | null;

	// actions
	saveCountries: Action<CountryModel, Country[] | null>;
	clearCountries: Action<CountryModel>;

	// thunks
	getCountries: Thunk<CountryModel, undefined, {}, StoreModel, Response<Country[]>>;
}

const country: CountryModel = {
	// state
	countries: null,

	// actions
	saveCountries: action((state, payload) => {
		state.countries = payload;
	}),
	clearCountries: action(state => {
		state.countries = null;
	}),

	// thunks
	getCountries: thunk(async actions => {
		actions.clearCountries();
		const result = await handleFetch<Country[]>(getCountriesApi());
		actions.saveCountries(result.payload);

		return result;
	}),
};

export default country;
