import * as React from 'react';
import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components/macro';

import Header from '../../../components/header/Header';
import Loader from '../../../components/loader/Loader';
import { useStoreActions, useStoreState } from '../../../services/store';
import { RouteProps } from '../../../typings';

const EmailInvoiceExpiredWrap = styled.div`
	padding-top: 20vh;
	text-align: center;
`;

interface RouteParams {
	emailInvoiceId: string;
}

const EmailInvoiceExpired = (props: RouteProps<RouteParams>) => {
	// import from '../../../services/store'
	const { emailInvoice } = useStoreState((state) => ({
		emailInvoice: state.invoice.emailInvoice,
	}));

	const { getEmailInvoice } = useStoreActions((actions) => ({
		...actions.invoice,
	}));
	const emailInvoiceId = props.match.params.emailInvoiceId;

	useEffect(() => {
		const fetchEmailInvoiceCreator = async () => {
			await getEmailInvoice({ invoiceId: emailInvoiceId });
		};
		fetchEmailInvoiceCreator();
	}, [getEmailInvoice, emailInvoiceId]);

	if (!emailInvoice) {
		return <Loader />;
	}
	return (
		<>
			<Header white />
			<EmailInvoiceExpiredWrap>
				<h1>
					It seems that the invoice sent by <strong>{emailInvoice.merchantName}</strong> has expired
				</h1>
				<p>
					Please <a href={`mailto:${emailInvoice.creator.supportEmail}`}>contact the merchant</a> to request a new
					invoice.
				</p>
			</EmailInvoiceExpiredWrap>
		</>
	);
};

export default withRouter(EmailInvoiceExpired);
