import React from 'react';
import { withRouter } from 'react-router';
import styled from 'styled-components/macro';

import { RouteProps } from '../../typings';
import Button from '../button/Button';
import Notification from '../notification/Notification';

const Wrap = styled.div`
	display: flex;
	flex-direction: column;
	background-color: #ff91091e;
	border: 1px solid #ff91097b;
	border-radius: 4px;
	padding: 17px;
	margin-top: 21px;
	margin-bottom: 15px;

	${Button.Secondary} {
		align-self: flex-start;
	}
`;

export interface SwipexLinkNotificationProps extends RouteProps {
	prop?: string;
}

const SwipexLinkNotification = (props: SwipexLinkNotificationProps) => {
	const handleRedirectToSwipex = () => {
		window.location.assign(`${process.env.REACT_APP_SWIPEX_BASE_URL}/login`);
	};

	return (
		<Wrap>
			<Notification>
				In order to approve your Virtual ATM submission and to be able to sell dagcoins via Virtual ATM and accept ATM
				backed payments,{' '}
				<strong>you will need an active and verified SwipeX account and connect it with your Dagpay account.</strong>{' '}
				Please navigate to SwipeX in order to confirm the linking with your SwipeX account.
			</Notification>
			<Button.Secondary orange onClick={handleRedirectToSwipex}>
				<span>Connect SwipeX account</span>
			</Button.Secondary>
		</Wrap>
	);
};

export default withRouter(SwipexLinkNotification);
