import React, { useCallback, useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import Button from '../../../../components/button/Button';
import View from '../../../../components/view/View';
import WithPermission from '../../../../components/with-permission/WithPermission';
import { Avatar, ColumnedFlex, H1, JustifiedLeftFlex } from '../../../../gfx/globals';
import { RouteProps } from '../../../../typings';
import { ParentViews, GLOBAL_DATE_FORMAT, RoutesUrls, Scopes, ITEMS_PER_PAGE, UserRole } from '../../../../constants';

import ColoredStatusDisplay from '../../../../components/colored-status-display/ColoredStatusDisplay';
import EscrowOpenDisputeModal from './modals/EscrowOpenDisputeModal';
import EscrowMarkAsShippedModal from './modals/EscrowMarkAsShippedModal';
import EscrowDeclineModal from './modals/EscrowDeclineModal';
import Table from '../../../../components/table/Table';
import ReactSVG from 'react-svg';
import dayjs from 'dayjs';
import InvoicePrice from '../../../../components/invoice-price/InvoicePrice';
import GridTable, { GridTableButton, GridTableItem, GridTableType } from '../../../../components/grid-table/GridTable';
import CircularProgressBar, {
	CircularProgressbarState,
} from './../../../../components/circular-progress-bar/CircularProgressBar';
import HookTimer from './../../../../components/hook-timer/HookTimer';
import { Color, EscrowColSizes } from '../../../../gfx/constants';
import { Escrow, EscrowPartyRole, EscrowStatus, EscrowStatusEstimation } from '../../../../api/escrowApi';
import { useStoreActions, useStoreState } from '../../../../services/store';
import Loader from '../../../../components/loader/Loader';
import NavigationBar from '../../../../components/navigation-bar/NavigationBar';
import {
	ListTimelineWrapper,
	NotificationRowWrapper,
	NotificationText,
	TitleWrapper,
	InvoiceWrapper,
} from './EscrowListViewStyle';
import { toast } from 'react-toastify';
import Pager from '../../../../components/pager/Pager';
import TimeLineProgressBar, {
	TimelineNodeStatus,
} from '../../../../components/time-line-progress-bar/TimeLineProgressBar';
import isWalletAdded from '../../../../services/is-wallet-added';
import Notification from '../../../../components/notification/Notification';
import isUserVerified from '../../../../services/is-user-verified';
import isParentVerified from '../../../../services/is-parent-verified';

export interface EscrowListViewProps {
	prop?: string;
}

export interface OpenedInformation {
	escrowId: string;
	isOpened: boolean;
}

export enum EscrowListViewModalType {
	CANCEL_ESCROW = 'CANCEL_ESCROW',
	OPEN_DISPUTE = 'OPEN_DISPUTE',
	MARK_AS_SHIPPED = 'MARK_AS_SHIPPED',
}

const EscrowListView = (props: EscrowListViewProps & RouteProps) => {
	const [openedInformationRows, setOpenedInformationRows] = useState<OpenedInformation[]>([]);
	const [activeIndex, setActiveIndex] = useState<number | null>(null);
	const [activeEscrow, setActiveEscrow] = useState<Escrow | null>(null);
	const [modalType, setModalType] = useState<EscrowListViewModalType | null>(null);
	const [page, setPage] = useState(1);

	const { escrows, activeViewer } = useStoreState((state) => ({
		escrows: state.escrow.escrows,
		activeViewer: state.viewer.activeViewer,
	}));

	const { getEscrows, markEscrowAsShipped } = useStoreActions((actions) => ({
		...actions.escrow,
	}));

	const isBuyerTab = !!RegExp(RoutesUrls.ESCROW_BUYER_DEALS).exec(props.location.pathname);
	const walletAdded = isWalletAdded();
	const isVerified = isUserVerified();
	const parentVerified = isParentVerified();
	const isTeamMember =
		(!!activeViewer && activeViewer.role === UserRole.TEAM_MEMBER) ||
		(!!activeViewer && activeViewer.role === UserRole.ADVANCED_TEAM_MEMBER);
	const isOwner = !!activeViewer && activeViewer.role === UserRole.OWNER;
	const accountProcessIssue = !walletAdded || !isVerified || !parentVerified;
	const displayHeaderButton =
		!accountProcessIssue && !!activeViewer && !isBuyerTab && !!escrows && escrows.itemCount > 0;
	const displayCenterButton = !accountProcessIssue && !isBuyerTab && !!escrows && escrows.itemCount === 0;

	useEffect(() => {
		const fetchEscrows = async (id: string) => {
			await getEscrows({
				userId: id,
				role: isBuyerTab ? EscrowPartyRole.BUYER : EscrowPartyRole.SELLER,
				page,
				itemsPerPage: ITEMS_PER_PAGE,
			});
		};
		if (activeViewer && !accountProcessIssue) {
			fetchEscrows(activeViewer.id);
		}
	}, [activeViewer, isBuyerTab, getEscrows, page, accountProcessIssue]);

	const handleCloseModalClick = useCallback(() => {
		setModalType(null);
		setActiveIndex(null);
		setActiveEscrow(null);
	}, []);

	const handleToggleInformation = (escrowId: string) => {
		const index = openedInformationRows.findIndex((obj) => obj.escrowId === escrowId);
		const openedInformationRowsCopy = [...openedInformationRows];

		if (index === -1) {
			openedInformationRowsCopy.push({ escrowId, isOpened: true });
			setOpenedInformationRows(openedInformationRowsCopy);
		}

		if (index >= 0) {
			openedInformationRowsCopy[index].isOpened = !openedInformationRows[index].isOpened;
			setOpenedInformationRows(openedInformationRowsCopy);
		}
	};
	const handleMarkAsShippedEscrow = async (escrow: Escrow, activeViewerId: string) => {
		try {
			const response = await markEscrowAsShipped({
				escrowId: escrow.id,
				sellerToken: escrow.sellerParty.token,
			});
			if (response.success) {
				await getEscrows({ userId: activeViewerId, role: EscrowPartyRole.SELLER });
				handleCloseModalClick();
				toast.success('Escrow marked as shipped');
			}
			if (response.error) {
				handleCloseModalClick();
				toast.error('Failed to mark escrow as shipped');
			}
		} catch (e) {
			handleCloseModalClick();
			toast.error('Failed to mark escrow as shipped');
		}
	};

	const handleOpenModal = (escrow: Escrow, index: number, modalType: EscrowListViewModalType) => {
		setModalType(modalType);
		setActiveIndex(index);
		setActiveEscrow(escrow);
	};

	const isOpened = (escrowId: string) =>
		openedInformationRows.filter((obj) => obj.escrowId === escrowId && obj.isOpened).length > 0;

	const getMoreEscrowInformation = (escrow: Escrow, index: number) => {
		const productInfo: GridTableItem[] = [
			{
				value: (
					<ListTimelineWrapper>
						<TimeLineProgressBar
							disputed={
								escrow.status === EscrowStatus.DISPUTE_OPENED || escrow.status === EscrowStatus.DISPUTE_RESOLVED
							}
							forceComplete={
								escrow.dispute &&
								escrow.dispute.releaseToParty &&
								escrow.dispute.releaseToParty.userId === activeViewer?.id
							}
							nodes={[
								{
									label: 'Escrow deal created',
									time: dayjs(escrow.dealEstimationStatuses.escrowCreated.date).toDate(),
									icon: escrow.dealEstimationStatuses.escrowCreated.estimated
										? '/files/svg/escrows/createdActive.svg'
										: '/files/svg/escrows/createdActive.svg',
									status: !escrow.dealEstimationStatuses.escrowCreated.estimated
										? TimelineNodeStatus.ACTIVE
										: TimelineNodeStatus.INACTIVE,
								},
								{
									label: 'Payment in escrow',
									time: dayjs(escrow.dealEstimationStatuses.invoicePaid.date).toDate(),
									icon:
										escrow.status === EscrowStatus.ESCROW_DECLINED ||
										escrow.status === EscrowStatus.ESCROW_CANCELLED ||
										escrow.status === EscrowStatus.ESCROW_EXPIRED
											? '/files/svg/escrows/paymentCancelled.svg'
											: escrow.dealEstimationStatuses.escrowCreated.estimated
											? '/files/svg/escrows/payment.svg'
											: '/files/svg/escrows/paymentActive.svg',
									status:
										escrow.status === EscrowStatus.ESCROW_DECLINED ||
										escrow.status === EscrowStatus.ESCROW_CANCELLED ||
										escrow.status === EscrowStatus.ESCROW_EXPIRED
											? TimelineNodeStatus.FAIL
											: !escrow.dealEstimationStatuses.invoicePaid.estimated
											? TimelineNodeStatus.ACTIVE
											: TimelineNodeStatus.INACTIVE,
								},
								{
									label: 'Product shipped',
									time: dayjs(escrow.dealEstimationStatuses.markedAsShipped.date).toDate(),
									icon: escrow.dealEstimationStatuses.invoicePaid.estimated
										? '/files/svg/escrows/shipped.svg'
										: '/files/svg/escrows/shippedActive.svg',
									status: !escrow.dealEstimationStatuses.markedAsShipped.estimated
										? TimelineNodeStatus.ACTIVE
										: TimelineNodeStatus.INACTIVE,
								},
								{
									label: 'Product received',
									time: dayjs(escrow.dealEstimationStatuses.markedAsReceived.date).toDate(),
									icon: escrow.dealEstimationStatuses.markedAsShipped.estimated
										? '/files/svg/escrows/received.svg'
										: '/files/svg/escrows/receivedActive.svg',
									status: !escrow.dealEstimationStatuses.markedAsReceived.estimated
										? TimelineNodeStatus.ACTIVE
										: TimelineNodeStatus.INACTIVE,
								},
								{
									label: 'Coins released',
									time: dayjs(escrow.dealEstimationStatuses.escrowCompleted.date).toDate(),
									icon: escrow.dealEstimationStatuses.markedAsReceived.estimated
										? '/files/svg/escrows/released.svg'
										: '/files/svg/escrows/releasedActive.svg',
									status: !escrow.dealEstimationStatuses.escrowCompleted.estimated
										? TimelineNodeStatus.ACTIVE
										: TimelineNodeStatus.INACTIVE,
								},
							]}
						/>
					</ListTimelineWrapper>
				),
				position: { startingRow: 1, endingRow: 1, startingColumn: 1, endingColumn: 5 },
			},
			{
				position: { startingRow: 2, endingRow: 2, startingColumn: 1, endingColumn: 5 },
				isSeparatorLine: true,
			},
			{
				label: 'Product',
				value: escrow.product,
				position: { startingColumn: 1, endingColumn: 1, startingRow: 3, endingRow: 3 },
				preWrap: true,
			},
			{
				label: 'Escrow ID',
				value: escrow.id,
				position: { startingColumn: 2, endingColumn: 2, startingRow: 3, endingRow: 3 },
			},
			{
				position: { startingRow: 4, endingRow: 4, startingColumn: 1, endingColumn: 2 },
				isSeparatorLine: true,
			},
			{
				label: 'Product notes',
				value: escrow.productNotes ? escrow.productNotes : '-',
				position: { startingColumn: 1, endingColumn: 2, startingRow: 5, endingRow: 5 },
				preWrap: true,
			},
			{
				position: { startingRow: 3, endingRow: 5, startingColumn: 3, endingColumn: 3 },
				isSeparatorLine: true,
			},
			{
				label: 'Shipping info',
				value: escrow.shippingInfo,
				position: { startingColumn: 4, endingColumn: 4, startingRow: 3, endingRow: 3 },
			},
			{
				label: 'Estimated deal duration',
				value: `${escrow.estimatedDealDurationDays} days`,
				position: { startingColumn: 4, endingColumn: 4, startingRow: 5, endingRow: 5 },
			},
			{
				value: (
					<div>
						{escrow.status !== EscrowStatus.MARKED_AS_RECEIVED && (
							<CircularProgressBar
								state={
									escrow.status === EscrowStatus.ESCROW_DECLINED ||
									escrow.status === EscrowStatus.ESCROW_CANCELLED ||
									escrow.status === EscrowStatus.ESCROW_EXPIRED ||
									escrow.status === EscrowStatus.DISPUTE_OPENED
										? CircularProgressbarState.ERROR
										: escrow.dealEstimationStatuses.invoicePaid.estimated
										? CircularProgressbarState.LOADER
										: escrow.status === EscrowStatus.INVOICE_PAID
										? CircularProgressbarState.LOCKED
										: !escrow.dealEstimationStatuses.escrowCompleted.estimated ||
										  escrow.status === EscrowStatus.ESCROW_COMPLETED ||
										  escrow.status === EscrowStatus.DISPUTE_RESOLVED
										? CircularProgressbarState.DONE
										: CircularProgressbarState.DISPLAY
								}
								size={100}
								lineWidth={7}
								maxValue={escrow.estimatedDealDurationDays}
								currentValue={escrow.estimatedDealDurationDays - escrow.daysLeft}
								label={
									escrow.status === EscrowStatus.DISPUTE_OPENED || escrow.status === EscrowStatus.INVOICE_PAID
										? 'Coins locked'
										: escrow.status === EscrowStatus.ESCROW_DECLINED
										? 'Deal declined'
										: escrow.status === EscrowStatus.ESCROW_CANCELLED
										? 'Deal Cancelled'
										: escrow.status === EscrowStatus.ESCROW_EXPIRED
										? 'Deal expired'
										: escrow.dealEstimationStatuses.invoicePaid.estimated
										? 'Waiting for payment'
										: escrow.dealEstimationStatuses.escrowCompleted.estimated &&
										  escrow.status !== EscrowStatus.DISPUTE_RESOLVED
										? 'Coins released in'
										: 'Coins released'
								}
								blue
							/>
						)}
						{escrow.status === EscrowStatus.MARKED_AS_RECEIVED && (
							<HookTimer
								endDate={dayjs(escrow.dealEstimationStatuses.markedAsReceived.date)
									.add(escrow.releaseDuration, 'hour')
									.add(5, 'minute') //add scheduler delay
									.toDate()}
								label="Coins released in"
								size={100}
								lineWidth={7}
								blue
							/>
						)}
					</div>
				),
				position: { startingRow: 3, endingRow: 5, startingColumn: 5, endingColumn: 5 },
			},
			{
				position: { startingColumn: 1, endingColumn: 5, startingRow: 6, endingRow: 6 },
				isSeparatorLine: true,
				hidden: escrow.status !== EscrowStatus.DISPUTE_RESOLVED,
			},
			{
				label: escrow.status === EscrowStatus.DISPUTE_RESOLVED ? 'Dispute resolution' : undefined,
				value:
					escrow.status === EscrowStatus.DISPUTE_RESOLVED
						? escrow.dispute.releaseToParty.userId === activeViewer?.id
							? `Funds released to you`
							: `Funds returned to the buyer`
						: undefined,
				position: { startingColumn: 1, endingColumn: 5, startingRow: 7, endingRow: 7 },
				hidden: escrow.status !== EscrowStatus.DISPUTE_RESOLVED,
			},
		];

		const buttonList: GridTableButton[] = [
			{
				label: 'Open dispute',
				iconPath: '',
				white: true,
				isVisible:
					!escrow.dealEstimationStatuses.invoicePaid.estimated &&
					escrow.status !== EscrowStatus.ESCROW_COMPLETED &&
					escrow.status !== EscrowStatus.ESCROW_EXPIRED &&
					escrow.status !== EscrowStatus.DISPUTE_OPENED &&
					escrow.status !== EscrowStatus.DISPUTE_RESOLVED &&
					!isBuyerTab,
				func: () => handleOpenModal(escrow, index, EscrowListViewModalType.OPEN_DISPUTE),
			},
			{
				label: 'Mark as shipped',
				iconPath: '',
				green: true,
				isVisible: escrow.status === EscrowStatus.INVOICE_PAID && !isBuyerTab,
				func: () => handleOpenModal(escrow, index, EscrowListViewModalType.MARK_AS_SHIPPED),
			},
			{
				label: 'Cancel deal',
				iconPath: '',
				red: true,
				isVisible:
					(escrow.status === EscrowStatus.ESCROW_CREATED || escrow.status === EscrowStatus.INVOICE_CREATED) &&
					!isBuyerTab,
				func: () => {
					handleOpenModal(escrow, index, EscrowListViewModalType.CANCEL_ESCROW);
				},
			},
			{
				label: 'Open deal',
				iconPath: '',
				white: true,
				isVisible: isBuyerTab,
				func: () => {
					window.open(escrow.escrowDetailsUrl, '_blank');
				},
			},
		];
		return (
			<GridTable
				opened={isOpened(escrow.id)}
				tableType={GridTableType.ESCROW_DEAL}
				tableItems={productInfo}
				tableButtons={buttonList}
			/>
		);
	};

	const renderModal = (activeViewerId: string) => {
		if (activeIndex === null || activeEscrow === null) {
			return null;
		}

		switch (modalType) {
			case EscrowListViewModalType.CANCEL_ESCROW:
				return <EscrowDeclineModal escrow={activeEscrow} handleCloseModal={handleCloseModalClick} />;
			case EscrowListViewModalType.OPEN_DISPUTE:
				return <EscrowOpenDisputeModal escrow={activeEscrow} handleCloseModal={handleCloseModalClick} />;
			case EscrowListViewModalType.MARK_AS_SHIPPED:
				return (
					<EscrowMarkAsShippedModal
						onClickCallback={() => handleMarkAsShippedEscrow(activeEscrow, activeViewerId)}
						handleCloseModal={handleCloseModalClick}
					/>
				);
			default:
				return null;
		}
	};

	const getEscrowStatusColor = (status: EscrowStatus) => {
		switch (status) {
			case EscrowStatus.ESCROW_DECLINED:
				return Color.RED_2;
			case EscrowStatus.ESCROW_CANCELLED:
				return Color.RED_2;
			case EscrowStatus.ESCROW_EXPIRED:
				return Color.RED_2;
			case EscrowStatus.DISPUTE_OPENED:
				return Color.RED_2;
			case EscrowStatus.ESCROW_COMPLETED:
				return Color.GREEN_3;
			case EscrowStatus.DISPUTE_RESOLVED:
				return Color.GREEN_3;
			default:
				return Color.ORANGE;
		}
	};

	enum EscrowStatusLabels {
		ESCROW_CREATED = 'Deal created',
		ESCROW_DECLINED = 'Deal declined',
		ESCROW_CANCELLED = 'Deal cancelled',
		ESCROW_ACCEPTED = 'Deal accepted',
		INVOICE_CREATED = 'Waiting for payment',
		INVOICE_PAID = 'Payment in escrow',
		MARKED_AS_SHIPPED = 'Product shipped',
		MARKED_AS_RECEIVED = 'Product received',
		FUNDS_RELEASED = 'Payment released',
		ESCROW_COMPLETED = 'Deal completed',
		DISPUTE_OPENED = 'Dispute opened',
		DISPUTE_RESOLVED = 'Dispute resolved',
		ESCROW_EXPIRED = 'Deal expired',
	}

	const getEscrowStatusRealValue = (estimation: EscrowStatusEstimation) => {
		return estimation.estimated ? '-' : dayjs(estimation.date).format(GLOBAL_DATE_FORMAT);
	};

	const renderEscrowRows = (escrow: Escrow, index: number) => {
		return (
			<React.Fragment key={escrow.id}>
				<Table.TrPrimary>
					<Table.UserNameTd data-label={isBuyerTab ? 'Seller' : 'Buyer'}>
						<JustifiedLeftFlex>
							<Avatar>
								{isBuyerTab
									? escrow.sellerParty.fullName.charAt(0).toUpperCase()
									: escrow.buyerParty.fullName.charAt(0).toUpperCase()}
							</Avatar>
							<ColumnedFlex>
								<Table.UserName>{isBuyerTab ? escrow.sellerParty.fullName : escrow.buyerParty.fullName}</Table.UserName>
								<Table.Email>{isBuyerTab ? escrow.sellerParty.email : escrow.buyerParty.email}</Table.Email>
							</ColumnedFlex>
						</JustifiedLeftFlex>
					</Table.UserNameTd>
					<Table.FixedWidthTd cellWidth={EscrowColSizes.DEAL_CREATED}>
						<Table.DataWrapper data-label="Deal created">
							{dayjs(escrow.dealEstimationStatuses.escrowCreated.date).format(GLOBAL_DATE_FORMAT)}
						</Table.DataWrapper>
					</Table.FixedWidthTd>
					<Table.FixedWidthTd cellWidth={EscrowColSizes.DEAL_COMPLETED}>
						<Table.DataWrapper data-label="Deal completed">
							{getEscrowStatusRealValue(escrow.dealEstimationStatuses.escrowCompleted)}
						</Table.DataWrapper>
					</Table.FixedWidthTd>
					<Table.FixedWidthTd cellWidth={EscrowColSizes.STATUS}>
						<Table.DataWrapper data-label="Status">
							<ColoredStatusDisplay
								status={EscrowStatusLabels[escrow.status]}
								color={getEscrowStatusColor(escrow.status)}
							/>
						</Table.DataWrapper>
					</Table.FixedWidthTd>
					<Table.FixedWidthTd noWhiteSpaceWrap cellWidth={EscrowColSizes.AMOUNT}>
						<Table.DataWrapper data-label="Amount">
							<InvoicePrice currencyAmount={escrow.price} coinAmount={escrow.coinAmount} currency={escrow.currency} />
						</Table.DataWrapper>
					</Table.FixedWidthTd>
					<Table.SeeMoreTd className={isOpened(escrow.id) ? 'opened' : undefined} cellWidth={EscrowColSizes.ACTIONS}>
						<Table.Arrow opened={isOpened(escrow.id)} onClick={onTableArrowClick(escrow.id)}>
							{isOpened(escrow.id) ? 'See less' : 'See more'}
							<ReactSVG src="/files/svg/private/Arrow.svg" />
						</Table.Arrow>
					</Table.SeeMoreTd>
				</Table.TrPrimary>
				{isOpened(escrow.id) && getMoreEscrowInformation(escrow, index)}
			</React.Fragment>
		);
	};

	const onTableArrowClick = (escrowId: string) => (_e: React.MouseEvent<HTMLElement>) =>
		handleToggleInformation(escrowId);

	const handleEscrowNewDeal = () => {
		props.history.push(RoutesUrls.ESCROW_NEW_DEAL);
	};

	return (
		<>
			{escrows && activeViewer && renderModal(activeViewer.id)}
			<View>
				<TitleWrapper>
					<H1>Escrow</H1>
				</TitleWrapper>
				<NotificationRowWrapper>
					<NotificationText>
						Create and manage escrow deals allowing for secure transactions and confidence for your customers.{' '}
						<a
							target="_blank"
							href="https://help.dagpay.io/en/collections/2832109-dagpay-escrow-deals"
							rel="noopener noreferrer"
						>
							Learn more
						</a>
						.
					</NotificationText>

					{displayHeaderButton && (
						<WithPermission permissions={[]}>
							<Button.Secondary green onClick={handleEscrowNewDeal}>
								New escrow deal
							</Button.Secondary>
						</WithPermission>
					)}
				</NotificationRowWrapper>

				<InvoiceWrapper>
					{!accountProcessIssue && <NavigationBar parentView={ParentViews.ESCROW_DEALS} />}
					{accountProcessIssue && (
						<>
							{!walletAdded && (
								<Notification left>
									Please <Link to={RoutesUrls.WALLETS_DETAIL}> add the wallet</Link> to create new escrow deal
								</Notification>
							)}
							{!isTeamMember && !isVerified && (
								<Notification left>
									Your identity or business is not verified, you can create new escrow deal after process is completed
								</Notification>
							)}
							{!isOwner && !parentVerified && <Notification left>Dagpay account access limited</Notification>}
						</>
					)}

					{!accountProcessIssue && (!escrows || !activeViewer) && <Loader />}

					{!accountProcessIssue && !!escrows && escrows.itemCount > 0 && (
						<>
							<WithPermission
								permissions={[
									Scopes.ESCROW,
									Scopes.GET_ESCROW,
									Scopes.GET_ESCROWS,
									Scopes.CREATE_ESCROW,
									Scopes.UPDATE_ESCROW,
								]}
							>
								<Table>
									<Table.Thead hiddenOnSmallerScreen>
										<tr>
											<th>{isBuyerTab ? 'Seller' : 'Buyer'}</th>
											<th>Deal created</th>
											<th>Deal completed</th>
											<th>Status</th>
											<th>Amount</th>
											<th />
										</tr>
									</Table.Thead>
									<tbody>{escrows.items.map((escrow, index) => renderEscrowRows(escrow, index))}</tbody>
								</Table>
							</WithPermission>
							<Pager
								totalItems={escrows.itemCount}
								currentPage={page}
								onChange={(newPage: number) => setPage(newPage)}
								totalPages={escrows.pageCount}
							/>
						</>
					)}

					{!accountProcessIssue && !!escrows && escrows.itemCount === 0 && (
						<View.NoItemsFound>
							<ReactSVG src="/files/svg/private/NoEmailInvoice.svg" />
							<p style={{ marginBottom: '23px' }}>
								{isBuyerTab
									? 'You haven’t been offered any escrow deals yet'
									: 'You haven’t created any escrow deals yet'}
							</p>
							{displayCenterButton && (
								<WithPermission permissions={[]}>
									<Button.Secondary align="center" white onClick={handleEscrowNewDeal}>
										Create new escrow deal
									</Button.Secondary>
								</WithPermission>
							)}
						</View.NoItemsFound>
					)}
				</InvoiceWrapper>
			</View>
		</>
	);
};

export default withRouter(EscrowListView);
