import { Action, action, Computed, computed, thunk, Thunk, ThunkOn, thunkOn } from 'easy-peasy';

import {
	AuthenticatorResponse,
	BusinessTierLimitsResponse,
	enableAuthenticatorApi,
	handleResetPasswordApi,
	HandleResetPasswordRequest,
	isResetPasswordTokenValidApi,
	IsResetPasswordTokenValidRequest,
	IsResetPasswordTokenValidResponse,
	loginApi,
	LoginRequest,
	logoutApi,
	resendEmailNotificationApi,
	resendLoginCodeApi,
	resetPasswordApi,
	ResetPasswordRequest,
	ResetPasswordResponse,
	StatisticsResponse,
	viewerApi,
	viewerLimitsApi,
	viewerStatisticsApi,
	ViewerStatisticsRequest,
} from '../api/authApi';
import {
	updateSupportEmailApi,
	UpdateSupportEmailRequest,
	UpdateSupportEmailResponse,
	updateUserApi,
	UpdateUserRequest,
	User,
} from '../api/userApi';
import handleFetch from '../services/handle-fetch';
import { Response } from '../typings';

import { StoreModel } from '.';

export interface ViewerModel {
	// state
	activeViewer: User | null;
	viewerStatistics: StatisticsResponse | null;
	viewerBusinessTierLimits: BusinessTierLimitsResponse | null;
	isViewerLoading: boolean;
	isAuthorized: Computed<ViewerModel, boolean>;

	// actions
	saveActiveViewer: Action<ViewerModel, User | null>;
	saveViewerStatistics: Action<ViewerModel, StatisticsResponse | null>;
	saveViewerBusinessTierLimits: Action<ViewerModel, BusinessTierLimitsResponse | null>;
	clearStatistics: Action<ViewerModel>;

	// thunks
	getViewer: Thunk<ViewerModel, undefined, {}, StoreModel, Response<User>>;
	getViewerStatistics: Thunk<ViewerModel, ViewerStatisticsRequest, {}, StoreModel, Response<StatisticsResponse>>;
	getViewerBusinessLimits: Thunk<ViewerModel, undefined, {}, StoreModel, Response<BusinessTierLimitsResponse>>;
	login: Thunk<ViewerModel, LoginRequest, {}, StoreModel, Response<User>>;
	logout: Thunk<ViewerModel, undefined, {}, StoreModel, Response<User>>;
	enableAuthenticator: Thunk<ViewerModel, undefined, {}, StoreModel, Response<AuthenticatorResponse>>;
	handleResetPassword: Thunk<ViewerModel, HandleResetPasswordRequest, {}, StoreModel, Response<User>>;
	isResetPasswordTokenValid: Thunk<
		ViewerModel,
		IsResetPasswordTokenValidRequest,
		{},
		StoreModel,
		Response<IsResetPasswordTokenValidResponse>
	>;
	resendEmailNotification: Thunk<ViewerModel, undefined, {}, StoreModel, Response<User>>;
	resendLoginCode: Thunk<ViewerModel, ResetPasswordRequest, {}, StoreModel, Response<ResetPasswordResponse>>;
	resetPassword: Thunk<ViewerModel, ResetPasswordRequest, {}, StoreModel, Response<ResetPasswordResponse>>;
	updateUser: Thunk<ViewerModel, UpdateUserRequest, {}, StoreModel, Response<User>>;
	updateSupportEmail: Thunk<
		ViewerModel,
		UpdateSupportEmailRequest,
		{},
		StoreModel,
		Response<UpdateSupportEmailResponse>
	>;

	// listeners
	listeners: ThunkOn<ViewerModel, {}, StoreModel>;
}

const viewer: ViewerModel = {
	// state
	activeViewer: null,
	viewerStatistics: null,
	viewerBusinessTierLimits: null,
	isViewerLoading: true,
	isAuthorized: computed((state) => state.activeViewer !== null),

	// actions
	saveActiveViewer: action((state, payload) => {
		state.activeViewer = payload;
		state.isViewerLoading = false;
	}),
	saveViewerStatistics: action((state, payload) => {
		state.viewerStatistics = payload;
	}),
	saveViewerBusinessTierLimits: action((state, payload) => {
		state.viewerBusinessTierLimits = payload;
	}),
	clearStatistics: action((state) => {
		state.viewerStatistics = null;
	}),

	// thunks
	getViewer: thunk(async (actions, _payload, { getState }) => {
		try {
			const result = await handleFetch<User>(viewerApi());

			actions.saveActiveViewer(result.payload);

			return result;
		} catch (e) {
			actions.saveActiveViewer(null);
			throw e;
		}

		// return result;
	}),
	login: thunk(async (actions, payload, { dispatch }) => {
		const result = await handleFetch<User>(loginApi(payload));

		await dispatch.viewer.getViewer();

		return result;
	}),
	getViewerStatistics: thunk(async (actions, payload) => {
		actions.clearStatistics();
		const result = await handleFetch<StatisticsResponse>(viewerStatisticsApi(payload));
		actions.saveViewerStatistics(result.payload);

		return result;
	}),
	getViewerBusinessLimits: thunk(async (actions, payload) => {
		actions.clearStatistics();
		const result = await handleFetch<BusinessTierLimitsResponse>(viewerLimitsApi());
		actions.saveViewerBusinessTierLimits(result.payload);

		return result;
	}),
	logout: thunk(async (actions, payload, { dispatch }) => {
		const response = await handleFetch<User>(logoutApi());

		dispatch.reset(payload);

		return response;
	}),
	enableAuthenticator: thunk(async (actions) => {
		return await handleFetch<AuthenticatorResponse>(enableAuthenticatorApi());
	}),
	handleResetPassword: thunk(async (actions, payload) => {
		return await handleFetch<User>(handleResetPasswordApi(payload));
	}),
	isResetPasswordTokenValid: thunk(async (actions, payload) => {
		return await handleFetch<IsResetPasswordTokenValidResponse>(isResetPasswordTokenValidApi(payload));
	}),
	resendEmailNotification: thunk(async (actions) => {
		return await handleFetch<User>(resendEmailNotificationApi());
	}),
	resendLoginCode: thunk(async (actions, payload) => {
		return await handleFetch<ResetPasswordResponse>(resendLoginCodeApi(payload));
	}),
	resetPassword: thunk(async (actions, payload) => {
		return await handleFetch<ResetPasswordResponse>(resetPasswordApi(payload));
	}),
	updateUser: thunk(async (actions, payload) => {
		return await handleFetch<User>(updateUserApi(payload));
	}),
	updateSupportEmail: thunk(async (actions, payload) => {
		return await handleFetch<User>(updateSupportEmailApi(payload));
	}),

	// listeners
	listeners: thunkOn(
		(actions, storeActions) => [actions.updateUser, actions.updateSupportEmail],
		(actions, payload) => {
			actions.getViewer();
		},
	),
};

export default viewer;
