import axios from 'restyped-axios';
import { RouteSchema } from '../typings';
export const api = axios.create<SchemaApi>();

export interface SchemaApi {
	'/schema': {
		GET: {
			response: Schema;
		};
	};
}

export interface Metadata {
	title: string;
	description: string;
	version: string;
}

export interface Schema {
	metadata: Metadata;
	routes: RouteSchema[];
}

export const getSchemaApi = async () => {
	const schemaResponse = await api.get('/schema');

	return schemaResponse.data;
};
