import React from 'react';
import CountDown, { CountdownRenderProps } from 'react-countdown-now';
import styled from 'styled-components';

import { Invoice } from '../api/invoiceApi';
import { Color } from '../gfx/constants';

export const StyledCountdown = styled.span`
	color: ${Color.GREEN_3};
	white-space: pre-wrap;
	font-family: PrimaryMedium;
`;

interface Props {
	invoice: Invoice;
	onComplete: () => void;
}

export default function getPaymentCountdown({ invoice, onComplete }: Props) {
	const renderCountDown = (props: CountdownRenderProps) => (
		<StyledCountdown>{` ${props.formatted.hours}:${props.formatted.minutes}:${props.formatted.seconds}`}</StyledCountdown>
	);

	return invoice ? (
		<CountDown
			daysInHours
			date={Date.now() + invoice.validForSeconds * 1000}
			renderer={renderCountDown}
			onComplete={onComplete}
			// onComplete={() => window.location.reload()}
		/>
	) : (
		''
	);
}
