import { FormikActions } from 'formik';
import { CFormikProps, FormikFields as FormikForm, FormikFieldsState } from 'formik-fields';
import React, { useEffect, useState } from 'react';
import Fade from 'react-reveal';
import { withRouter } from 'react-router';
import { toast } from 'react-toastify';

import { CreateWalletRequest, Wallet } from '../../../../api/walletApi';
import Button, { ButtonTypes } from '../../../../components/button/Button';
import { CheckboxText } from '../../../../components/checkbox/CheckboxStyle';
import Field from '../../../../components/field/Field';
import Form from '../../../../components/form/Form';
import Loader from '../../../../components/loader/Loader';
import Modal from '../../../../components/modal/Modal';
import Notification from '../../../../components/notification/Notification';
import View from '../../../../components/view/View';
import { FieldTypes, ITEMS_PER_PAGE, Validations } from '../../../../constants';
import { getValidationSchema } from '../../../../services/get-validation-schema';
import { getServerValidationErrors } from '../../../../services/input-error-utils';
import { useStoreActions, useStoreState } from '../../../../services/store';
import { InitialFields, RouteProps } from '../../../../typings';

interface RouteParams {
	id?: string;
}

interface WalletFields {
	name: string;
	address: string;
	default: boolean;
}

function WalletsDetailView(props: RouteProps<RouteParams>) {
	const [errorMessage, setErrorMessage] = useState('');
	const [isModalOpened, setIsModalOpened] = useState(true);
	const [wallet, setWallet] = useState<Wallet | null>(null);
	const [isFirst, setIsFirst] = useState(false);

	const isCreatable = !props.match.params.id;
	const isDefaultVisible = isCreatable || (wallet && !wallet.isDefault);
	const isDefaultWalletVisible = isDefaultVisible && !isFirst;

	const { wallets, environments } = useStoreState((state) => ({
		wallets: state.wallet.wallets,
		environments: state.environment.environments,
	}));

	const { getWallet, createWallet, updateWallet, getWallets, getEnvironments } = useStoreActions((actions) => ({
		...actions.wallet,
		getEnvironments: actions.environment.getEnvironments,
	}));

	useEffect(() => {
		const fetchWallet = async (walletId: string) => {
			const response = await getWallet({ walletId });

			if (response && response.success) {
				if (!wallet) {
					setWallet(response.payload);
				}
			}
		};

		const fetchEnvironments = async () => await getEnvironments({});
		fetchEnvironments();

		if (props.match.params.id) {
			fetchWallet(props.match.params.id);
		}
		if (wallets) {
			setIsFirst(wallets.itemCount === 0);
		}
	}, [getWallet, wallet, wallets, getEnvironments, props.match.params.id]);

	if (!isCreatable && !wallet) {
		return <Loader />;
	}

	const initialLoginFields: InitialFields<WalletFields> = {
		name: { initialValue: wallet ? wallet.name : '', validator: Validations.WALLET_NAME_IS_REQUIRED },
		address: { initialValue: wallet ? wallet.address : '', validator: Validations.INVALID_WALLET_ADDRESS },
		default: { initialValue: wallet ? wallet.isDefault : false, validator: Validations.DEFAULT_IS_OPTIONAL },
	};

	const toggleModal = () => {
		props.history.goBack();
		setIsModalOpened(!isModalOpened);
	};

	const handleSaveWallet = async (input: CreateWalletRequest, formActions: FormikActions<CreateWalletRequest>) => {
		const response = props.match.params.id
			? await updateWallet({ ...input, id: props.match.params.id })
			: await createWallet({ ...input });

		if (response.success) {
			await getWallets({ itemsPerPage: ITEMS_PER_PAGE });
			props.history.goBack();
			toast.success(`Wallet successfully ${props.match.params.id ? 'updated' : 'added'}.`);

			return;
		}

		const serverValidationErrors = getServerValidationErrors<Wallet>(response);

		if (serverValidationErrors.errors && serverValidationErrors.errors.address) {
			formActions.setFieldError('address', 'Address already in use');

			return;
		}

		return setErrorMessage('Something went wrong, please try again');
	};

	return (
		<Modal hasCloseButton ariaHideApp={false} isOpen={isModalOpened} onRequestClose={toggleModal}>
			<FormikForm<CreateWalletRequest>
				fields={initialLoginFields}
				validationSchema={getValidationSchema(initialLoginFields)}
				onSubmit={handleSaveWallet}
				render={(fields: FormikFieldsState<CreateWalletRequest>, fieldActions: CFormikProps<CreateWalletRequest>) => {
					return (
						<Form.Modal autoComplete="off">
							<Field
								formikField={fields.name}
								noAddonIcon
								placeholder="e.g. Personal finances"
								title="Wallet name"
								maxLength={32}
							/>
							<Field
								formikField={fields.address}
								noAddonIcon
								placeholder="Your DagWallet address"
								title="Wallet address"
							/>
							{isDefaultWalletVisible && (
								<>
									<Field formikField={fields.default} type={FieldTypes.CHECKBOX} isCentered>
										<CheckboxText isBlack>Set as default wallet</CheckboxText>
									</Field>
									<Notification>
										If enabled, all payments will default to this wallet address as the receiving wallet for invoices,
										but you may choose another wallet when creating or configuring your invoices.
										<br />
									</Notification>

									{environments && environments.itemCount > 0 && (
										<Fade collapse when={fields.default.value}>
											<Notification>
												<strong>
													NB. Wallet(s) configured for your integrations won't be affected by this change, for that, you
													will need to change it manually on the integration page.
												</strong>
											</Notification>
										</Fade>
									)}
								</>
							)}
							<Modal.Buttons>
								<Button.Secondary white type={ButtonTypes.BUTTON} onClick={toggleModal}>
									Cancel
								</Button.Secondary>
								<Button.Secondary
									green
									isDisabled={isCreatable ? !fieldActions.isValid : !fieldActions.isValid && !fieldActions.dirty}
									disabled={isCreatable ? !fieldActions.isValid : !fieldActions.isValid && !fieldActions.dirty}
									type={ButtonTypes.SUBMIT}
								>{`${isCreatable ? 'Add Wallet' : 'Update'}`}</Button.Secondary>
							</Modal.Buttons>
							<View.Error>{errorMessage}</View.Error>
						</Form.Modal>
					);
				}}
			/>
		</Modal>
	);
}

export default withRouter(WalletsDetailView);
