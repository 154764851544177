import axios from 'restyped-axios';
import { ApiArrayPayload, ApiGetRequest, ApiResponse } from '../typings';

export const api = axios.create<WalletApi>();

export interface WalletApi {
	'/wallets': {
		GET: {
			query: ApiGetRequest;
			response: ApiResponse<ApiArrayPayload<WalletWithUsageDate>>;
		};

		POST: {
			body: CreateWalletRequest;
			response: ApiResponse<Wallet>;
		};

		PUT: {
			body: UpdateWalletRequest;
			response: ApiResponse<Wallet>;
		};
	};

	'/wallets/:walletId': {
		GET: {
			response: ApiResponse<Wallet>;
		};

		DELETE: {
			body: DeleteWalletRequest;
			response: ApiResponse<Wallet>;
		};
	};
}

export interface CreateWalletRequest {
	address: string;
	name: string;
	default: boolean;
}

export interface GetWalletRequest {
	walletId: string;
}

export interface UpdateWalletRequest extends CreateWalletRequest {
	id: string;
}

export interface DeleteWalletRequest {
	walletId: string;
}

export interface Wallet {
	id: string;
	userId: string;
	creatorId: string;
	address: string;
	name: string;
	isDefault: boolean;
}

export interface WalletWithUsageDate extends Wallet {
	usageDate: string;
}

export const createWalletApi = async (params: CreateWalletRequest) => {
	const response = await api.post('/wallets', {
		...params,
	});

	return response.data;
};

export const updateWalletApi = async (params: UpdateWalletRequest) => {
	const response = await api.put('/wallets', {
		...params,
	});

	return response.data;
};

export const deleteWalletApi = async (params: DeleteWalletRequest) => {
	const response = await api.delete<'/wallets/:walletId'>(`/wallets/${params.walletId}`);

	return response.data;
};

export const getWalletsApi = async (params: ApiGetRequest) => {
	const response = await api.get('/wallets', {
		params: { ...params },
	});

	return response.data;
};

export const getWalletApi = async (params: GetWalletRequest) => {
	const response = await api.get<'/wallets/:walletId'>(`/wallets/${params.walletId}`);

	return response.data;
};
