import * as React from 'react';
import { DropEvent, DropzoneProps, FileRejection, useDropzone } from 'react-dropzone';
import ReactSVG from 'react-svg';
import { toast } from 'react-toastify';
import { ACCEPTED_FILE_FORMATS, MAX_FILE_SIZE } from '../../constants';
import { ColumnedFlex, LinkButton, NoOverflowDiv } from '../../gfx/globals';
import styled from 'styled-components/macro';
import { Color } from '../../gfx/constants';
import { useFormContext } from 'react-hook-form';
import StyledField from '../field/FieldStyle';
import { ErrorMessage } from '@hookform/error-message';
import { Error } from './HookFieldsStyle';

interface Props extends DropzoneProps {
	name: string;
	label?: string;
	maxFiles?: number;
	optional?: boolean;
	hasError?: boolean;
}

type DropFilesHandler = (accepted: File[], rejected: FileRejection[], event: DropEvent) => void;

interface StyledDropzoneProps {
	hasError?: boolean;
}

interface DropzoneFooterDataProps {
	hasError?: boolean;
}

const DropzoneDropNotification = styled.div`
	color: ${Color.GRAY_DARK};
	font-size: 16px;
	font-weight: 500;
	line-height: 27px;
	padding-bottom: 17px;

	button {
		color: ${Color.ORANGE};
		text-decoration: underline;
	}
`;

const DropzoneLimitsDetails = styled.div`
	color: #515667;

	font-size: 13px;
	line-height: 20px;
	text-align: center;
	font-weight: 400;
`;

const StyledDropzone = styled.div`
	cursor: pointer;
	align-items: center;
	border-radius: 4px;
	border-style: dashed;
	border-width: 1px;
	display: flex;
	font-family: PrimaryMedium;
	min-width: 100%;
	height: 219px;
	justify-content: center;
	flex-direction: column;
	width: 100%;
`;

const DropzoneWrapper = styled.div<StyledDropzoneProps>`
	div:first-of-type {
		border-color: ${({ hasError }) => (hasError ? `${Color.RED_2}` : '#BABCC3')};
		border-width: ${({ hasError }) => hasError && `2px`};
	}
`;

const DropzoneFooterData = styled.div<DropzoneFooterDataProps>`
	background-color: ${({ hasError }) => (hasError ? `${Color.RED_2}` : `${Color.GREEN_3}`)};
	display: flex;
	align-items: center;
	padding: 10px;
	font-weight: 400;
	height: 48px;
	min-width: 100%;
	color: #ffffff;
	font-size: 13px;
	margin-top: 24px;
	border: 1px solid #dcdde0;
	border-radius: 4px;

	svg {
		g,
		path {
			fill: white;
		}
	}
`;

const DocumentIcon = styled.div`
	svg {
		margin-right: 10px;
	}
`;

export default function HookDropZoneField(props: Props) {
	const { maxFiles, name, label, optional } = props;
	const { setValue, register, watch, errors } = useFormContext();

	const files: File[] = watch(name, []);

	const onRemoveFileClick = (fileName: string) => (e: React.MouseEvent<HTMLAnchorElement>) => {
		e.stopPropagation();
		setValue(
			props.name as never,
			files.filter((file, index) => {
				return file.name !== fileName;
			}),
		);
	};

	const handleDrop: DropFilesHandler = (acceptedFiles, rejectedFiles, _event) => {
		if (acceptedFiles.some((file) => file.name.length > 100)) {
			toast.error('The file name(s) are too long. Please shorten the file name and try again.');
			return;
		}
		//check if current file stack can fit the number of added files
		if (maxFiles) {
			if (files.length + acceptedFiles.length > maxFiles) {
				toast.error('File count limit exceeded for field');
				return;
			}
		}
		setValue(name as never, acceptedFiles.concat(files));
	};

	const { getRootProps, getInputProps } = useDropzone({
		onDrop: handleDrop,
		multiple: !!maxFiles && maxFiles > 1,
		maxFiles: maxFiles,
		maxSize: MAX_FILE_SIZE,
		accept: ACCEPTED_FILE_FORMATS,
	});

	return (
		<ColumnedFlex fullWidth>
			{label && (
				<StyledField.Title htmlFor={name} isCentered={true}>
					{label} {optional && <StyledField.Optional isAbsolute>Optional</StyledField.Optional>}
				</StyledField.Title>
			)}
			<DropzoneWrapper hasError={errors.name && errors.type === 'required'} {...getRootProps()}>
				<StyledDropzone>
					<ColumnedFlex fullWidth fullHeight isCentered>
						<input
							{...register(name, {
								required: !optional,
							})}
							{...getInputProps()}
						/>
						<ReactSVG src="/files/svg/private/CloudUpload.svg" />
						<DropzoneDropNotification>
							Drag & drop or <LinkButton type="button">browse</LinkButton>{' '}
						</DropzoneDropNotification>
						<DropzoneLimitsDetails>
							We support JPG, PNG and PDF files. <div>Make sure that your file size is under 5 MB.</div>
						</DropzoneLimitsDetails>
					</ColumnedFlex>
				</StyledDropzone>
				<Error>
					<ErrorMessage name={name} />
				</Error>
			</DropzoneWrapper>
			{files.length >= 1 &&
				files.map((file, index) => {
					return (
						<DropzoneFooterData key={index} hasError={!!errors[name]}>
							<DocumentIcon>
								<ReactSVG src="/files/svg/private/verification/Document.svg" />
							</DocumentIcon>

							<NoOverflowDiv key={index}>{file.name}</NoOverflowDiv>
							<LinkButton type="button" onClick={onRemoveFileClick(file.name)}>
								<ReactSVG src="/files/svg/private/Delete.svg" />
							</LinkButton>
						</DropzoneFooterData>
					);
				})}
		</ColumnedFlex>
	);
}
