import { Action, action, thunk, Thunk, thunkOn, ThunkOn } from 'easy-peasy';

import {
	createInviteApi,
	CreateInviteRequest,
	deleteInviteApi,
	DeleteInviteRequest,
	disableInvitedUserApi,
	DisableInvitedUserRequest,
	enableInvitedUserApi,
	EnableInvitedUserRequest,
	ExtendedInvitedUser,
	getInviteApi,
	getInvitedUserApi,
	GetInvitedUserRequest,
	getInvitedUsersApi,
	GetInvitedUsersRequest,
	getInvitedUsersStatisticsApi,
	GetInviteRequest,
	Invite,
	InvitedUser,
	InvitedUsersCount,
	registerInviteApi,
	RegisterInviteRequest,
	resendEmailInviteApi,
	ResendEmailInviteRequest,
	updateInviteApi,
	UpdateInvitedUserRequest,
} from '../api/inviteApi';
import { User } from '../api/userApi';
import handleFetch from '../services/handle-fetch';
import { ApiArrayPayload, Response, ResponseArray } from '../typings';

import { StoreModel } from '.';

export interface InviteModel {
	// state
	invitedUsersStatistics: ApiArrayPayload<InvitedUsersCount> | null;
	invitedUsers: ApiArrayPayload<ExtendedInvitedUser> | null;
	invitedUser: InvitedUser | null;
	invite: Invite | null;

	// actions
	saveInvitedUsers: Action<InviteModel, ApiArrayPayload<ExtendedInvitedUser> | null>;
	saveInvitedUsersStatistics: Action<InviteModel, ApiArrayPayload<InvitedUsersCount> | null>;
	saveInvitedUser: Action<InviteModel, InvitedUser | null>;
	saveInvite: Action<InviteModel, Invite | null>;
	clearInvitedUsers: Action<InviteModel>;
	clearInvitedUsersStatistics: Action<InviteModel>;
	clearInvitedUser: Action<InviteModel>;
	clearInvite: Action<InviteModel>;

	// thunks
	getInvitedUsers: Thunk<InviteModel, GetInvitedUsersRequest, {}, StoreModel, ResponseArray<InvitedUser>>;
	getInvitedUsersStatistics: Thunk<InviteModel, {}, {}, StoreModel, ResponseArray<InvitedUsersCount>>;
	getInvitedUser: Thunk<InviteModel, GetInvitedUserRequest, {}, StoreModel, Response<InvitedUser>>;
	getInvite: Thunk<InviteModel, GetInviteRequest, {}, StoreModel, Response<Invite>>;
	registerInvite: Thunk<InviteModel, RegisterInviteRequest, {}, StoreModel, Response<Invite>>;
	resendEmailInvite: Thunk<InviteModel, ResendEmailInviteRequest, {}, StoreModel, Response<Invite>>;
	deleteInvite: Thunk<InviteModel, DeleteInviteRequest, {}, StoreModel, Response<Invite>>;
	disableInvitedUser: Thunk<InviteModel, DisableInvitedUserRequest, {}, StoreModel, Response<User>>;
	enableInvitedUser: Thunk<InviteModel, EnableInvitedUserRequest, {}, StoreModel, Response<User>>;
	updateInvite: Thunk<InviteModel, UpdateInvitedUserRequest, {}, StoreModel, Response<Invite>>;
	createInvite: Thunk<InviteModel, CreateInviteRequest, {}, StoreModel, Response<Invite>>;

	// listeners
	listeners: ThunkOn<InviteModel, {}, StoreModel>;
}

const invite: InviteModel = {
	// state
	invitedUsers: null,
	invitedUsersStatistics: null,
	invitedUser: null,
	invite: null,

	// actions
	saveInvitedUsers: action((state, payload) => {
		state.invitedUsers = payload;
	}),
	saveInvitedUsersStatistics: action((state, payload) => {
		state.invitedUsersStatistics = payload;
	}),
	saveInvitedUser: action((state, payload) => {
		state.invitedUser = payload;
	}),

	saveInvite: action((state, payload) => {
		state.invite = payload;
	}),
	clearInvitedUsers: action((state) => {
		state.invitedUsers = null;
	}),
	clearInvitedUsersStatistics: action((state) => {
		state.invitedUsersStatistics = null;
	}),
	clearInvitedUser: action((state) => {
		state.invitedUser = null;
	}),
	clearInvite: action((state) => {
		state.invite = null;
	}),

	// thunks
	getInvitedUsers: thunk(async (actions, payload) => {
		actions.clearInvitedUsers();
		const result = await handleFetch<ApiArrayPayload<ExtendedInvitedUser>>(getInvitedUsersApi(payload));

		if (result.success === false && result.status === 403) {
			actions.saveInvitedUsers({
				itemCount: 0,
				items: [],
				itemsPerPage: 10,
				page: 1,
				pageCount: 1,
			});
		} else {
			actions.saveInvitedUsers(result.payload);
		}

		return result;
	}),
	getInvitedUsersStatistics: thunk(async (actions) => {
		actions.clearInvitedUsers();
		const result = await handleFetch<ApiArrayPayload<InvitedUsersCount>>(getInvitedUsersStatisticsApi());

		if (result.success === false && result.status === 403) {
			actions.saveInvitedUsers({
				itemCount: 0,
				items: [],
				itemsPerPage: 10,
				page: 1,
				pageCount: 1,
			});
		} else {
			actions.saveInvitedUsersStatistics(result.payload);
		}

		return result;
	}),
	getInvitedUser: thunk(async (actions, payload) => {
		actions.clearInvitedUser();
		const result = await handleFetch<ExtendedInvitedUser>(getInvitedUserApi(payload));

		actions.saveInvitedUser(result.payload);

		return result;
	}),
	getInvite: thunk(async (actions, payload) => {
		actions.clearInvite();
		const result = await handleFetch<Invite>(getInviteApi(payload));

		actions.saveInvite(result.payload);

		return result;
	}),
	registerInvite: thunk(async (actions, payload) => {
		const result = await handleFetch<Invite>(registerInviteApi(payload));

		return result;
	}),
	resendEmailInvite: thunk(async (actions, payload) => {
		return await handleFetch<Invite>(resendEmailInviteApi(payload));
	}),
	deleteInvite: thunk(async (actions, payload) => {
		return await handleFetch<Invite>(deleteInviteApi(payload));
	}),
	disableInvitedUser: thunk(async (actions, payload) => {
		return await handleFetch<User>(disableInvitedUserApi(payload));
	}),
	enableInvitedUser: thunk(async (actions, payload) => {
		return await handleFetch<User>(enableInvitedUserApi(payload));
	}),
	updateInvite: thunk(async (actions, payload) => {
		return await handleFetch<Invite>(updateInviteApi(payload));
	}),
	createInvite: thunk(async (actions, payload) => {
		return await handleFetch<Invite>(createInviteApi(payload));
	}),

	// getInvitedUsers must be handled conditionally which cant be done here

	// listeners
	listeners: thunkOn(
		(actions, storeActions) => [actions.createInvite],
		(actions, payload) => {
			actions.getInvitedUsers({});
		},
	),
};

export default invite;
