import { ApiResponse } from '../typings';

export interface Error {
	fieldName: string;
	errorMessage?: string;
}

export interface InputErrors {
	[name: string]: string;
}

export interface ValidateFormValuesResult {
	errors: InputErrors;
	isValid: boolean;
	errorMessage: string;
}

export const isObjEmpty = (obj: object) => Object.keys(obj).length === 0;

export function getServerValidationErrors<TPayload>(apiResponse: ApiResponse<TPayload>): ValidateFormValuesResult {
	const { validationErrors } = apiResponse;
	const generalError = apiResponse.error ? apiResponse.error : '';
	const removePassword = (error: string) => error.replace(/password: .*/g, `password: *******`);

	// tslint:disable-next-line:no-any
	const errors = validationErrors.reduce((obj: any, item, index) => {
		const pathRegex = new RegExp('#/(.*)');
		const field = validationErrors[index].path.match(pathRegex);

		if (field && field.length > 1 && field[1]) {
			obj[field[1]] = removePassword(item.message);
		}

		return obj;
	}, {});

	return {
		isValid: isObjEmpty(errors),
		errors,
		errorMessage: removePassword(generalError),
	};
}
