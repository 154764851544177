import React from 'react';
import { toast } from 'react-toastify';
import { Escrow } from '../../../../../api/escrowApi';
import Button from '../../../../../components/button/Button';
import {
	EscrowSupportModalWrapper,
	ModalButtonWrapper,
} from '../../../../../components/escrow-state-display/EscrowStateDisplayStyle';
import Modal from '../../../../../components/modal/Modal';
import { useStoreActions } from '../../../../../services/store';

interface Props {
	escrow: Escrow;
	handleCloseModal: () => void;
}

export default function EscrowCancelModal(props: Props) {
	const { escrow, handleCloseModal } = props;

	const { cancelEscrow } = useStoreActions((actions) => ({
		...actions.escrow,
	}));

	const handleCancelEscrow = async () => {
		try {
			const response = await cancelEscrow({
				escrowId: escrow.id,
				sellerToken: escrow.sellerParty.token,
			});
			if (response.success) {
				handleCloseModal();
				toast.success('Escrow declined');
				window.location.reload();
			}
			if (response.error) {
				toast.error('Declining escrow failed');
			}
		} catch (e) {
			toast.error('Declining escrow failed');
		}
	};

	return (
		<Modal isOpen={true} ariaHideApp={false} onRequestClose={handleCloseModal} small hasCloseButton>
			<EscrowSupportModalWrapper>
				<h2>Cancel escrow deal?</h2>
				<p>
					This will cancel the escrow deal request. You will need to create a new escrow deal if you wish to continue
					with it later.
				</p>
				<ModalButtonWrapper>
					<Button.Secondary white onClick={handleCloseModal}>
						Back
					</Button.Secondary>
					<Button.Secondary type="button" red onClick={handleCancelEscrow}>
						Cancel escrow deal
					</Button.Secondary>
				</ModalButtonWrapper>
			</EscrowSupportModalWrapper>
		</Modal>
	);
}
