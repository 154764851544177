import * as React from 'react';
import styled from 'styled-components/macro';

const PrivateHeaderSupportLink = styled.a`
	text-decoration: none;
	padding: 30px 20px 30px 0;
	color: #777;
	float: right;
	font-size: 13px;
	line-height: 17px;
	font-family: Secondary;
	position: relative;
	:hover {
		color: #191a2a;
	}
`;

export default function SupportLink() {
	return (
		<PrivateHeaderSupportLink target="_blank" href="https://help.dagpay.io/en/">
			Support
		</PrivateHeaderSupportLink>
	);
}
