import { Action, action, thunk, Thunk, thunkOn, ThunkOn } from 'easy-peasy';

import {
	cancelInvoiceApi,
	cancelRecurringInvoiceApi,
	CancelRecurringInvoiceRequest,
	createEmailInvoiceApi,
	CreateEmailInvoiceRequest,
	createRecurringInvoiceApi,
	CreateRecurringInvoiceRequest,
	EmailInvoice,
	getEmailInvoicesApi,
	getEmailInvoiceApi,
	getInvoiceApi,
	getInvoiceReceiptApi,
	GetInvoiceRequest,
	getInvoicesApi,
	GetInvoicesRequest,
	GetReceiptRequest,
	getRecurringInvoicesApi,
	Invoice,
	InvoiceReceipt,
	InvoicesOutputFormats,
	RecurringInvoice,
	sendInvoiceReceiptApi,
	SendReceiptRequest,
	GetEmailInvoiceRequest,
} from '../api/invoiceApi';
import { createPosInvoiceApi, CreatePosInvoiceRequest } from '../api/posApi';
import handleFetch from '../services/handle-fetch';
import { ApiArrayPayload, ApiGetRequest, Response, ResponseArray } from '../typings';

import { StoreModel } from '.';

export interface InvoiceModel {
	// state
	invoices: ApiArrayPayload<Invoice> | null;
	emailInvoices: ApiArrayPayload<EmailInvoice> | null;
	recurringInvoices: ApiArrayPayload<RecurringInvoice> | null;
	invoice: Invoice | null;
	emailInvoice: EmailInvoice | null;

	// actions
	saveInvoices: Action<InvoiceModel, ApiArrayPayload<Invoice> | null>;
	saveInvoice: Action<InvoiceModel, Invoice | null>;
	saveEmailInvoices: Action<InvoiceModel, ApiArrayPayload<EmailInvoice> | null>;
	saveEmailInvoice: Action<InvoiceModel, EmailInvoice | null>;
	saveRecurringInvoices: Action<InvoiceModel, ApiArrayPayload<RecurringInvoice> | null>;
	clearInvoices: Action<InvoiceModel>;
	clearInvoice: Action<InvoiceModel>;
	clearEmailInvoices: Action<InvoiceModel>;
	clearRecurringInvoices: Action<InvoiceModel>;

	// thunks
	getInvoices: Thunk<InvoiceModel, GetInvoicesRequest, {}, StoreModel, ResponseArray<Invoice>>;
	getInvoice: Thunk<InvoiceModel, GetInvoiceRequest, {}, StoreModel, Response<Invoice>>;
	getEmailInvoice: Thunk<InvoiceModel, GetEmailInvoiceRequest, {}, StoreModel, Response<EmailInvoice>>;
	getEmailInvoices: Thunk<InvoiceModel, ApiGetRequest, {}, StoreModel, ResponseArray<EmailInvoice>>;
	getRecurringInvoices: Thunk<InvoiceModel, ApiGetRequest, {}, StoreModel, ResponseArray<RecurringInvoice>>;
	getInvoiceReceipt: Thunk<InvoiceModel, GetReceiptRequest, {}, StoreModel, Response<InvoiceReceipt>>;
	createPosInvoice: Thunk<InvoiceModel, CreatePosInvoiceRequest, {}, StoreModel, Response<Invoice>>;
	createEmailInvoice: Thunk<InvoiceModel, CreateEmailInvoiceRequest, {}, StoreModel, Response<EmailInvoice>>;
	createRecurringInvoice: Thunk<
		InvoiceModel,
		CreateRecurringInvoiceRequest,
		{},
		StoreModel,
		Response<RecurringInvoice>
	>;
	cancelInvoice: Thunk<InvoiceModel, GetInvoiceRequest, {}, StoreModel, Response<Invoice>>;
	cancelRecurringInvoice: Thunk<
		InvoiceModel,
		CancelRecurringInvoiceRequest,
		{},
		StoreModel,
		Response<RecurringInvoice>
	>;
	sendInvoiceReceipt: Thunk<InvoiceModel, SendReceiptRequest, {}, StoreModel, Response<Invoice>>;

	// listeners
	onCreatePosInvoice: ThunkOn<InvoiceModel, {}, StoreModel>;
	onCreateEmailInvoice: ThunkOn<InvoiceModel, {}, StoreModel>;
	onCancelRecurringInvoice: ThunkOn<InvoiceModel, {}, StoreModel>;
}

const invoice: InvoiceModel = {
	// state
	invoices: null,
	emailInvoices: null,
	recurringInvoices: null,
	invoice: null,
	emailInvoice: null,

	// actions
	saveInvoices: action((state, payload) => {
		state.invoices = payload;
	}),
	saveEmailInvoices: action((state, payload) => {
		state.emailInvoices = payload;
	}),
	saveRecurringInvoices: action((state, payload) => {
		state.recurringInvoices = payload;
	}),
	saveInvoice: action((state, payload) => {
		state.invoice = payload;
	}),
	saveEmailInvoice: action((state, payload) => {
		state.emailInvoice = payload;
	}),
	clearInvoices: action((state) => {
		state.invoices = null;
	}),
	clearInvoice: action((state) => {
		state.invoice = null;
	}),
	clearEmailInvoices: action((state) => {
		state.invoices = null;
	}),
	clearRecurringInvoices: action((state) => {
		state.invoices = null;
	}),

	// thunks
	getInvoices: thunk(async (actions, payload) => {
		if (payload.format !== InvoicesOutputFormats.CSV) {
			actions.clearInvoices();
		}

		const result = await handleFetch<ApiArrayPayload<Invoice>>(getInvoicesApi(payload));

		if (payload.format !== InvoicesOutputFormats.CSV) {
			actions.saveInvoices(result.payload);
		}

		return result;
	}),
	getInvoice: thunk(async (actions, payload) => {
		actions.clearInvoice();
		const result = await handleFetch<Invoice>(getInvoiceApi(payload));

		actions.saveInvoice(result.payload);

		return result;
	}),
	getEmailInvoice: thunk(async (actions, payload) => {
		actions.clearEmailInvoices();
		const result = await handleFetch<EmailInvoice>(getEmailInvoiceApi(payload));

		actions.saveEmailInvoice(result.payload);

		return result;
	}),
	getEmailInvoices: thunk(async (actions, payload) => {
		actions.clearEmailInvoices();
		const result = await handleFetch<ApiArrayPayload<EmailInvoice>>(getEmailInvoicesApi(payload));

		actions.saveEmailInvoices(result.payload);

		return result;
	}),
	getRecurringInvoices: thunk(async (actions, payload) => {
		actions.clearRecurringInvoices();
		const result = await handleFetch<ApiArrayPayload<RecurringInvoice>>(getRecurringInvoicesApi(payload));

		actions.saveRecurringInvoices(result.payload);

		return result;
	}),
	getInvoiceReceipt: thunk(async (actions, payload) => {
		return await handleFetch<InvoiceReceipt>(getInvoiceReceiptApi(payload));
	}),
	createPosInvoice: thunk(async (actions, payload) => {
		return await handleFetch<Invoice>(createPosInvoiceApi(payload));
	}),
	createEmailInvoice: thunk(async (actions, payload) => {
		return await handleFetch<EmailInvoice>(createEmailInvoiceApi(payload));
	}),
	createRecurringInvoice: thunk(async (actions, payload) => {
		return await handleFetch<RecurringInvoice>(createRecurringInvoiceApi(payload));
	}),
	cancelInvoice: thunk(async (actions, payload) => {
		return await handleFetch<Invoice>(cancelInvoiceApi(payload));
	}),
	cancelRecurringInvoice: thunk(async (actions, payload) => {
		return await handleFetch<RecurringInvoice>(cancelRecurringInvoiceApi(payload));
	}),
	sendInvoiceReceipt: thunk(async (actions, payload) => {
		return await handleFetch<Invoice>(sendInvoiceReceiptApi(payload));
	}),

	// listeners
	onCreatePosInvoice: thunkOn(
		(actions, storeActions) => actions.createPosInvoice,
		(actions, payload) => {
			actions.getInvoices({});
		},
	),
	onCreateEmailInvoice: thunkOn(
		(actions, storeActions) => actions.createEmailInvoice,
		(actions, payload) => {
			actions.getEmailInvoices({});
		},
	),
	onCancelRecurringInvoice: thunkOn(
		(actions, storeActions) => actions.cancelRecurringInvoice,
		(actions, payload) => {
			actions.getRecurringInvoices({});
		},
	),
};

export default invoice;
