import { css, keyframes } from 'styled-components';

// Keyframes

const progressLineKeyframes = keyframes`
   4% { stroke-dasharray: 0 100; }
   56% { stroke-dasharray: 61 100; }
   68% { stroke-dasharray: 61 100; }
   96% { stroke-dasharray: 0 100; }
`;

export const progressLineAnimation = css`
	${progressLineKeyframes}
	animation-duration: 5s;
	animation-timing-function: ease-in-out;
	animation-iteration-count: infinite;
`;

const successProgressLineKeyframes = keyframes`
   4% { stroke-dasharray: 0 100; }
   100% { stroke-dasharray: 61 100; }
`;

export const successProgressLineAnimation = css`
	${successProgressLineKeyframes}
	animation-fill-mode: forwards;
	animation-iteration-count: 1;
	animation-delay: 0s;
	animation-duration: 2s;
`;

const pendingCircleKeyframes = keyframes`
   4% {stroke: #DFE0E3; fill: white; }
   46% { stroke: #DFE0E3; fill: white; }
   72.9% { stroke: #FF9109; fill: #FF9109; }
   73% { stroke: #DFE0E3; fill: white; }
   96% { stroke: #DFE0E3; fill: white; }
`;

export const pendingCircleAnimation = css`
	${pendingCircleKeyframes};
	animation-iteration-count: infinite;
	animation-duration: 5s;
`;

const successCircleKeyframes = keyframes`
  0% { opacity: 0; }
  20% { opacity: 1; }
  80% { opacity: 1; }
`;

export const successCircleAnimation = css`
	${successCircleKeyframes}
	animation-fill-mode: forwards;
	animation-iteration-count: 1;
	animation-delay: 0s;
	animation-duration: 2.9s;
`;

const successTickKeyframes = keyframes`
  0% { opacity: 0; }
  60% { opacity: 0; }
  70% { opacity: 1; }
`;

export const successTickAnimation = css`
	${successTickKeyframes}
	animation-fill-mode: forwards;
	animation-iteration-count: 1;
	animation-delay: 0s;
	animation-duration: 2.9s;
`;

const fadeInKeyframes = keyframes`
  from { opacity: 0; }
  to   { opacity: 1; }
`;

export const fadeInAnimation = css`
	${fadeInKeyframes}
	animation-duration: 0.5s;
`;

const moveKeyframes = keyframes`
   0%, 100% { transform: translateY(-10px); }
   75% { transform: translateY(190px); }
`;

export const moveAnimation = css`
	${moveKeyframes}
	animation-duration: 3s;
	animation-timing-function: cubic-bezier(0.15, 0.44, 0.76, 0.64);
`;

const jumpUpKeyframes = keyframes`
   0% {
      opacity: 1;
   }
   50% {
      opacity: 1;
   }
`;

export const jumpUpAnimation = css`
	${jumpUpKeyframes}
	animation-duration: 1s;
`;

const showCompleteIconKeyframes = keyframes`
   0% { opacity: 0; }
   90% { opacity: 1; }
   91% { opacity: 0; }
   100% { opacity: 0; }
`;

export const showCompleteIconAnimation = css`
	${showCompleteIconKeyframes}
	animation-duration: 2s;
`;

const jumpDownKeyframes = keyframes`
   0% {
      margin-bottom: 0;
      transform: translate(0, 0);
      opacity: 0;
   }

   50% {
      transform: translate(0, -10px);
      opacity: 0;

   }
`;

export const jumpDownAnimation = css`
	${jumpDownKeyframes}
	animation-duration: 1s;
`;
