import styled from 'styled-components/macro';
import { Label } from '../../../../components/react-hook-form/HookFieldsStyle';
import { Color } from '../../../../gfx/constants';
import { media } from '../../../../services/media';

export const NumberFieldWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;

	& > :first-child {
		width: 58%;

		${media.maxTable`
			max-width: 100%;
			width: 100%;
		`}
	}

	& > :last-child {
		width: 34%;

		${Label} {
			white-space: nowrap;
			margin-right: 0 !important;
		}

		${media.maxTable`
			max-width: 100%;
			width: 100%;
		`}
	}

	${media.maxTable`
		flex-direction: column-reverse;
	`}
`;

export const TotalItem = styled.div`
	display: flex;
	justify-content: space-between;
`;

export const DealTotalText = styled.div`
	font-size: 14px;
	line-height: 16px;
	font-family: PrimaryMedium;
	color: ${Color.GRAY_8};
`;

export const CoinItem = styled.div`
	display: flex;
	font-size: 16px;
	line-height: 19px;
	font-family: Primary;
	color: ${Color.BLUE_9};
`;

export const CurrencyItem = styled.div`
	display: flex;
	font-size: 14px;
	line-height: 16px;
	font-family: Primary;
	color: ${Color.GRAY_8};
`;

export const CurrencyPairWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-end;
`;

export const EscrowTotalWrapper = styled.div`
	padding: 42px 36px;
	margin-left: auto;
	width: 404px;
	font-size: 18px;
	line-height: 22px;
	font-family: PrimaryMedium;
	color: ${Color.GRAY_12};

	> :first-child {
		margin-top: 20px;
		padding-top: 20px;
		margin-bottom: 20px;
		border-top: 1px solid ${Color.GRAY_5};
	}

	${CurrencyPairWrapper} {
		margin-left: 106px;
	}

	& > ${TotalItem} > ${CoinItem} {
		margin-left: 38px;
	}

	${media.maxSize481`
	width: 100%;
	`}
`;
