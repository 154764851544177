import * as React from 'react';
import styled, { css } from 'styled-components/macro';
import { StyledComponentProps } from 'styled-components';
import { Color } from '../../../../../../gfx/constants';

const StyledChart = styled.div`
	width: 100%;
	height: 100%;
	background-color: #fff;
	border-radius: 4px;
	border: 1px solid ${Color.GRAY_5};
	.recharts-responsive-container {
		margin-left: calc(2% + 1px);
		margin-top: calc(2% + 1px);
	}
`;

const Chart = (props: StyledComponentProps<'div', {}, {}, never>) => <StyledChart>{props.children}</StyledChart>;

Chart.CustomTooltip = styled.div`
	display: flex;
	background-color: ${Color.WHITE};
	padding: 6px 10px 6px 10px;
	border-radius: 3px;
	color: ${Color.GRAY_14};
	border: 1px solid ${Color.GRAY_5};
`;

Chart.CustomPayload = styled.span`
	color: ${Color.GRAY_14};
	line-height: 24px;
	margin-left: 4px;
`;

Chart.CustomPayloadDate = styled.span`
	color: ${Color.GRAY_14};
	line-height: 24px;
	margin: 0 4px;
`;

Chart.CustomCurrency = styled.span`
	font-weight: bold;
	margin: 0 4px;
	line-height: 24px;
`;

Chart.TransactionInfoBlock = styled.div<{ x: number; active: boolean }>`
	height: 40px;
	width: 120px;
	position: absolute;
	border-radius: 50px;
	padding-top: 5px;
	background-color: black;
	text-align: center;
	justify-content: center;

	${(props) =>
		!props.active &&
		css`
			display: none;
		`}
	${(props) =>
		props.x &&
		css`
			margin-left: ${props.x - 57}px;
		`}
`;

Chart.TransactionTime = styled.div`
	color: white;
	font-size: 12px;
`;

Chart.TransactionDate = styled.div`
	color: ${Color.ORANGE};
	font-size: 12px;
`;

export default Chart;
