import 'github-markdown-css';
import * as H from 'history';
import { JSONSchema4 } from 'json-schema';
import React, { useEffect } from 'react';
import JSONTree from 'react-json-tree';
import BurgerMenu from '../../../components/burger-menu/BurgerMenu';
import Header from '../../../components/header/Header';
import { PrivateBurgerMenu } from '../../../components/header/HeaderStyle';
import Logo from '../../../components/logo/Logo';
import { RoutesUrls } from '../../../constants';
import { Strong } from '../../../gfx/globals';
import { RouteSchema } from '../../../typings';
import { GroupedRoutes } from './DocumentationView';
import {
	DocumentationBody,
	DocumentationContent,
	DocumentationEndpoint,
	DocumentationEndpointDescription,
	DocumentationEndpointRqRs,
	DocumentationEndpointSection,
	DocumentationEndpointTitle,
	DocumentationHeader,
	DocumentationHeaderLogo,
	DocumentationMiddleColumn,
	DocumentationNavLink,
	DocumentationSchemaUrl,
	DocumentationSectionGroup,
	DocumentationSectionGroupTitle,
	DocumentationSidebar,
	DocumentationTable,
	DocumentationWrap,
	LogoWrapper,
	StickyBackground,
	StickyWrap,
} from './DocumentationViewStyle';
import Sticky from 'react-stickynode';

interface Props {
	routes: GroupedRoutes;
	documentation?: string;
	isPrivate?: boolean;
	history: H.History;
	location: H.Location;
}

export default function DocumentationViewTpl(props: Props) {
	const scrollToAnchor = (anchor: string) => {
		const element = document.getElementById(`${anchor}`) as HTMLElement;

		element.scrollIntoView(true);
		const scrolledY = window.scrollY;

		if (scrolledY) {
			window.scroll(0, scrolledY - 125);
		}
	};

	useEffect(() => {
		const anchor = props.location.hash.substr(1);
		if (anchor) {
			scrollToAnchor(anchor);
		}
	}, [props.location]);

	const { isPrivate = false } = props;

	if (!props.documentation) {
		throw new Error('Documentation markdown file not found');
	}

	const getGroupTitles = () => {
		const { routes, location } = props;

		const currentHash = location.hash.substr(1);

		const groupTitles = Object.keys(routes).map((group) => (
			<DocumentationNavLink
				active={currentHash === group ? 1 : 0}
				to={`${RoutesUrls.DOCUMENTATION}#${group}`}
				key={group}
			>
				{group}
			</DocumentationNavLink>
		));

		groupTitles.unshift(
			<DocumentationNavLink
				active={currentHash === 'home' ? 1 : 0}
				to={`${RoutesUrls.DOCUMENTATION}#home`}
				key={'home'}
			>
				Home
			</DocumentationNavLink>,
		);

		return groupTitles;
	};

	const getGroups = () => {
		const { routes } = props;

		return Object.entries(routes).map(([key], index) => {
			const groupTitle = routes[key][0].group;

			return (
				<DocumentationSectionGroup key={index}>
					<DocumentationSectionGroupTitle id={key}>{groupTitle}</DocumentationSectionGroupTitle>
					{getGroupEndpoints(routes[key])}
				</DocumentationSectionGroup>
			);
		});
	};

	const getGroupEndpoints = (group: RouteSchema[]) =>
		group.map((route, index) => (
			<DocumentationEndpointSection key={index}>
				<DocumentationEndpointTitle>{route.metadata.title}</DocumentationEndpointTitle>
				<DocumentationEndpointDescription>{route.metadata.description}</DocumentationEndpointDescription>
				<DocumentationEndpoint>{`${route.method.toUpperCase()} ${route.endpointUrl}`}</DocumentationEndpoint>
				{route.requestSchema.properties && (
					<>
						<DocumentationEndpointRqRs>Request</DocumentationEndpointRqRs>
						{getParameters(route.requestSchema.properties, route.requestSchema.required)}
					</>
				)}
				{route.responseSchema.properties &&
					route.responseSchema.properties.payload.oneOf &&
					route.responseSchema.properties.payload.oneOf[1].properties && (
						<>
							<DocumentationEndpointRqRs>Response</DocumentationEndpointRqRs>
							{getParameters(route.responseSchema.properties.payload.oneOf[1].properties)}
						</>
					)}
				{route.responseSchema.properties && (
					<>
						<DocumentationEndpointRqRs>Response schema</DocumentationEndpointRqRs>
						<JSONTree data={route.responseSchema.properties} hideRoot theme={'google'} />
					</>
				)}
				<DocumentationSchemaUrl
					href={`http://${process.env.REACT_APP_API_URL}${route.schemaUrl.replace('/api', '')}`}
					target="_blank"
				>
					Schema url
				</DocumentationSchemaUrl>
			</DocumentationEndpointSection>
		));

	const getParameters = (properties: JSONSchema4['properties'], required?: JSONSchema4['required']) => {
		if (properties) {
			return (
				<DocumentationTable>
					<thead>
						<tr>
							<th>Name (type)</th>
							<th>Description </th>
							<th>Constraints </th>
							{required && <th>Required </th>}
						</tr>
					</thead>
					<tbody>
						{Object.entries(properties).map(([key], index) => (
							<tr key={index}>
								<td data-label="Name (type)">
									<Strong>{`${key}`}</Strong> {properties[key].type ? <i>({properties[key].type})</i> : ''}
								</td>
								<td data-label="Description">{properties[key].description}</td>
								<td data-label="Constraints">{getConstraints(properties[key])}</td>
								{required && <td data-label="Required">{required.includes(key) ? <Strong>✔</Strong> : ''}</td>}
							</tr>
						))}
					</tbody>
				</DocumentationTable>
			);
		}

		return '';
	};

	const getConstraints = (property: JSONSchema4) => {
		const { type, minLength, maxLength, minimum, maximum, oneOf } = property;

		if (type === 'string') {
			return (
				<>
					{minLength !== undefined && minLength === maxLength ? <div>Length: {minLength}</div> : ''}
					{minLength !== undefined && minLength !== maxLength ? <div>Minimum length: {minLength}</div> : ''}
					{maxLength !== undefined && minLength !== maxLength ? <div> Maximum length: {maxLength}</div> : ''}
					{getOneOfProperty(oneOf)}
					{property.enum && ` Possible values: ${property.enum.join(', ')} `}
					{property.default && <div>{`Default: ${property.default} `}</div>}
				</>
			);
		}

		if (type === 'number') {
			return (
				<>
					{minimum !== undefined ? <div> Minimum: {minimum}</div> : ''}
					{maximum !== undefined ? <div> Maximum: {maximum} </div> : ''}
				</>
			);
		}

		return getOneOfProperty(oneOf);
	};

	const getOneOfProperty = (oneOf: JSONSchema4[] | undefined) =>
		oneOf !== undefined
			? oneOf.map((constraint, index) => (
					<div key={index}>
						{constraint.type !== undefined ? <div> Type: {constraint.type}</div> : ''}
						{constraint.minLength !== undefined ? <div> Minimum length: {constraint.minLength}</div> : ''}
						{constraint.maxLength !== undefined ? <div> Maximum length: {constraint.maxLength}</div> : ''}
						{oneOf.length > 1 && index !== oneOf.length - 1 ? <Strong>OR</Strong> : ''}
					</div>
			  ))
			: '';

	const getPrivateBurgerMenu = () => (
		<BurgerMenu right whiteBackground>
			<PrivateBurgerMenu>{getGroupTitles()}</PrivateBurgerMenu>
		</BurgerMenu>
	);

	const titles = getGroupTitles();

	return (
		<>
			{!isPrivate && <Header white />}
			<DocumentationWrap>
				<StickyBackground />
				<LogoWrapper>
					<Logo hasWhiteBackground />
				</LogoWrapper>
				<StickyWrap>
					<Sticky enableTransforms={false}>
						<DocumentationSidebar>{titles}</DocumentationSidebar>
					</Sticky>
				</StickyWrap>
				<DocumentationMiddleColumn>
					<DocumentationHeader>
						<DocumentationHeaderLogo>
							<Logo hasWhiteBackground />
						</DocumentationHeaderLogo>
						<a href={`${process.env.REACT_APP_WP_PUBLIC_URL}/support`}>Support</a>
						{getPrivateBurgerMenu()}
					</DocumentationHeader>
					<DocumentationContent>
						<DocumentationBody id="home" dangerouslySetInnerHTML={{ __html: props.documentation }} />
						{getGroups()}
					</DocumentationContent>
				</DocumentationMiddleColumn>
			</DocumentationWrap>
		</>
	);
}
