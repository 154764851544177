/* 
	Code originates from:
	https://stackoverflow.com/questions/1685680/how-to-avoid-scientific-notation-for-large-numbers-in-javascript/1685917
*/

const toFixed = (value: number) => {
	let result;
	if (Math.abs(value) < 1.0) {
		const e = parseInt(value.toString().split('e-')[1]);
		if (e) {
			value *= Math.pow(10, e - 1);
			result = '0.' + new Array(e).join('0') + value.toString().substring(2);
		}
	} else {
		let e = parseInt(value.toString().split('+')[1]);
		if (e > 20) {
			e -= 20;
			result = (value / Math.pow(10, e)).toString();
			result += new Array(e + 1).join('0');
		}
	}
	return result || value.toString();
};

export default toFixed;
