export const handleCopyToClipboard = (text: string) => {
	const textField = document.createElement('textarea');
	textField.innerText = text;
	document.body.appendChild(textField);

	// https://stackoverflow.com/questions/34045777/copy-to-clipboard-using-javascript-in-ios
	// iOS Safari copy to clipboard workaround
	if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
		const oldContentEditable = textField.contentEditable,
			oldReadOnly = textField.readOnly;
		const range = document.createRange();

		textField.contentEditable = 'true';
		textField.readOnly = true;
		range.selectNodeContents(textField);

		const s = window.getSelection();
		if (s) {
			s.removeAllRanges();
			s.addRange(range);
		}
		textField.setSelectionRange(0, 999999);
		textField.contentEditable = oldContentEditable;
		textField.readOnly = oldReadOnly;
	} else {
		textField.select();
	}

	document.execCommand('copy');
	textField.remove();
};
