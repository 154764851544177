import React from 'react';
import styled, { StyledComponentProps } from 'styled-components/macro';
import { Color } from '../../../gfx/constants';

const StyledSignUp = styled.div`
	display: flex;
	position: relative;
	justify-content: center;
	min-height: 100vh;
	padding: 20px;

	form {
		margin: auto;
		max-width: 477px;

		padding-top: 40px;
		padding-bottom: 40px;
	}
`;

export const Agreement = styled.div`
	margin: 0;
	font-family: Secondary;
	font-size: 14px;
	letter-spacing: 0px;
	line-height: 23px;
	word-break: keep-all;
	white-space: nowrap;
	a {
		margin: 0;
		font-family: Secondary;
		font-size: 14px;
		letter-spacing: 0px;
		line-height: 23px;

		:visited,
		:hover,
		:link {
			color: ${Color.LIGHT_BLUE};
			outline: none;
		}
		:active,
		:focus {
			color: #${Color.LIGHT_BLUE};
			outline: 1px solid ${Color.LIGHT_BLUE};
		}
	}
`;

export const AccountTypeHeading = styled.div`
	margin-top: 74px;
	margin-bottom: 47px;
	font-family: PrimaryMedium;
	font-size: 26px;
	letter-spacing: 0px;
	line-height: 30px;
`;

export const AccountTypeDescription = styled.div`
	font-family: Primary;
	font-size: 14px;
	line-height: 21px;
`;

export const PreviousWrapper = styled.div`
	a div div svg path {
		fill: #1592e6;
	}

	& a :hover {
		color: #1592e6;
	}
`;

const SignUp = (props: StyledComponentProps<'div', {}, {}, never>) => <StyledSignUp {...props} />;

export default SignUp;
