import * as qs from 'query-string';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import ReactSVG from 'react-svg';
import styled from 'styled-components/macro';

import { Atm, AtmStatus, AtmType } from '../../../../../../api/atmApi';
import Button from '../../../../../../components/button/Button';
import { ColoredAtmStatus } from '../../../../../../components/colored-atm-status/ColoredAtmStatus';
import Loader from '../../../../../../components/loader/Loader';
import Notification from '../../../../../../components/notification/Notification';
import Table from '../../../../../../components/table/Table';
import View from '../../../../../../components/view/View';
import WithPermission from '../../../../../../components/with-permission/WithPermission';
import { GLOBAL_DATE_FORMAT, ITEMS_PER_PAGE, RoutesUrls, Scopes } from '../../../../../../constants';
import { AtmColSizes } from '../../../../../../gfx/constants';
import { ColumnedFlex, H1 } from '../../../../../../gfx/globals';
import { useStoreActions, useStoreState } from '../../../../../../services/store';
import { RouteProps } from '../../../../../../typings';

import { NotificationWrapper } from './AtmListViewStyle';
import Pager from '../../../../../../components/pager/Pager';

const EnvironmentNameWrapper = styled.div`
	display: flex;
	font-family: PrimaryMedium;
`;

function AtmListView(props: RouteProps) {
	const [isSettingsOpened, setIsSettingsOpened] = useState<boolean[]>([]);
	const [page, setPage] = useState(1);

	const { atmList, verifications, activeViewer } = useStoreState((state) => ({
		atmList: state.atm.atmList,
		verifications: state.verification.verifications,
		activeViewer: state.viewer.activeViewer,
	}));

	const { getAtmList } = useStoreActions((actions) => ({
		...actions.atm,
	}));

	// TODO: will it work with page change?
	useEffect(() => {
		const newPage = qs.parse(props.location.search).page;

		if (newPage && typeof newPage === 'string') {
			setPage(parseInt(newPage as string, 10));
		}
		const fetchAtmList = async () => {
			await getAtmList({ typeList: [AtmType.PHYSICAL], page: page, itemsPerPage: ITEMS_PER_PAGE });
		};
		fetchAtmList();
	}, [page, props.location, getAtmList, setPage]);

	if (!atmList || !activeViewer) {
		return <Loader />;
	}

	const verificationsCompleted = verifications ? verifications.isComplete : false;

	const renderAtmTable = (atm: Atm, index: number) => (
		<Table key={index}>
			<Table.Thead>
				<tr>
					<th colSpan={4}>
						<EnvironmentNameWrapper>{atm.name}</EnvironmentNameWrapper>
					</th>
				</tr>
			</Table.Thead>

			<tbody>
				<Table.TrPrimary
					key={atm.id}
					onMouseLeave={isSettingsOpened[index] ? toggleSettingsVisibility(atm, index) : () => null}
				>
					<Table.FixedWidthTd cellWidth={AtmColSizes.DATE_ADDED}>
						<Table.NoLabelDataWrapper justifyOption="flex-start" data-label="Date added">
							<ColumnedFlex>
								<Table.TdInsideTitle>Date added</Table.TdInsideTitle>
								{dayjs(atm.createdDate).format(GLOBAL_DATE_FORMAT)}
							</ColumnedFlex>
						</Table.NoLabelDataWrapper>
					</Table.FixedWidthTd>
					<Table.FixedWidthTd cellWidth={AtmColSizes.SERIAL}>
						<Table.NoLabelDataWrapper justifyOption="flex-start" data-label="Serial">
							<ColumnedFlex>
								<Table.TdInsideTitle>ATM serial number</Table.TdInsideTitle>
								{atm.serial}
							</ColumnedFlex>
						</Table.NoLabelDataWrapper>
					</Table.FixedWidthTd>
					<Table.FixedWidthTd cellWidth={AtmColSizes.STATUS} noBeforeElement={atm.status === AtmStatus.PENDING}>
						<Table.NoLabelDataWrapper justifyOption="flex-start" data-label="Status">
							<ColumnedFlex>
								<Table.TdInsideTitle>Status</Table.TdInsideTitle>
								<ColoredAtmStatus status={atm.status} />
							</ColumnedFlex>
						</Table.NoLabelDataWrapper>
					</Table.FixedWidthTd>
					<Table.FixedWidthTd cellWidth={AtmColSizes.ACTIONS}>
						{atm.status !== AtmStatus.PENDING && (
							<Table.DataWrapper data-label="Actions">
								<Table.SettingsIcon onClick={toggleSettingsVisibility(atm, index)}>
									<ReactSVG src="/files/svg/private/TableSettings.svg" />
								</Table.SettingsIcon>
								{isSettingsOpened[index] && (
									<Table.HiddenSettings>
										<Link to={`${RoutesUrls.ATMS_DETAIL}/${atm.id}`}>
											<Table.HiddenSettingsItem>
												<ReactSVG src="/files/svg/private/Edit.svg" />
												<span>Details</span>
											</Table.HiddenSettingsItem>
										</Link>
									</Table.HiddenSettings>
								)}
							</Table.DataWrapper>
						)}
					</Table.FixedWidthTd>
				</Table.TrPrimary>
			</tbody>
		</Table>
	);

	const toggleSettingsVisibility = (atm: Atm, index: number) => (
		_e: React.MouseEvent<HTMLSpanElement | HTMLDivElement> | KeyboardEvent,
	) => {
		const isSettingsOpenedCopy = isSettingsOpened.slice();

		isSettingsOpenedCopy[index] = !isSettingsOpened[index];

		setIsSettingsOpened(isSettingsOpenedCopy);
	};

	const handleRedirectToAddBusiness = () => {
		props.history.push(RoutesUrls.ATMS_DETAIL);
	};

	return (
		<View paddingBottom={37} paddingTop={37}>
			<H1>Dagloyalty ATM</H1>
			<View.ListHeader>
				<NotificationWrapper>
					<Notification>Connect your business or store with a Dagcoin ATM and accept Dagloyalty payments </Notification>
					{!verificationsCompleted && <Notification>Complete verifications to join ATM services</Notification>}
				</NotificationWrapper>
				{verificationsCompleted && (
					<WithPermission permissions={[Scopes.ENVIRONMENTS, Scopes.CREATE_ENVIRONMENT]}>
						<Button.Secondary green onClick={handleRedirectToAddBusiness}>
							<span>Add business</span>
						</Button.Secondary>
					</WithPermission>
				)}
			</View.ListHeader>
			{atmList && atmList.items.length > 0 ? (
				<WithPermission permissions={[Scopes.ATMS, Scopes.GET_ATMS]}>
					{atmList.items.map(renderAtmTable)}
				</WithPermission>
			) : (
				<View.NoItemsFound>
					<ReactSVG src="/files/svg/private/merchant-tools/icon-disconnected.svg" />
					<p>You haven’t connected any business(es) with a ATM yet</p>
				</View.NoItemsFound>
			)}
			<Pager
				totalItems={atmList.itemCount}
				currentPage={page}
				onChange={(newPage: number) => setPage(newPage)}
				totalPages={atmList.pageCount}
			/>
		</View>
	);
}

export default withRouter(AtmListView);
