import { DurationInputArg2, unitOfTime } from 'moment';

import { GraphData } from '../api/authApi';
import { DashboardFilters } from '../constants';

import { addMissingGraphTicks } from './add-missing-graph-ticks';

export interface FormatGraphData {
	xAxis: string;
	dag: number;
	formattedTime: string;
	formattedDate: string;
}

interface FormatGraphDataArgs {
	dataIn: Array<GraphData> | null;
	filter: DashboardFilters;
}

type RangeByUnits = { [key in unitOfTime.Diff]: unitOfTime.Diff };
type SubstractUnits = { [key in DurationInputArg2]: DurationInputArg2 };

const rangeByUnits: Pick<RangeByUnits, 'year' | 'month' | 'week' | 'day' | 'hour'> = {
	year: 'year',
	month: 'month',
	week: 'week',
	day: 'day',
	hour: 'hour',
};

export const substractUnits: Pick<SubstractUnits, 'year' | 'month' | 'week' | 'day' | 'hour'> = {
	year: 'year',
	month: 'month',
	week: 'week',
	day: 'day',
	hour: 'hour',
};

const graphFormattingOptions = {
	ALL: {
		formatString: 'YYYY',
		rangeByUnit: rangeByUnits.year,
		subtractAmount: 5,
		subtractUnit: substractUnits.year,
	},
	YEAR: {
		formatString: 'MMM',
		rangeByUnit: rangeByUnits.month,
		subtractAmount: 1,
		subtractUnit: substractUnits.year,
	},
	QUARTER: {
		formatString: 'MMM',
		rangeByUnit: rangeByUnits.month,
		subtractAmount: 4,
		subtractUnit: substractUnits.month,
	},
	MONTH: {
		formatString: 'DD',
		rangeByUnit: rangeByUnits.day,
		subtractAmount: 1,
		subtractUnit: substractUnits.month,
	},
	WEEK: {
		formatString: 'D/MM',
		rangeByUnit: rangeByUnits.day,
		subtractAmount: 1,
		subtractUnit: substractUnits.week,
	},
	DAY: {
		formatString: 'H:mm',
		rangeByUnit: rangeByUnits.hour,
		subtractAmount: 1,
		subtractUnit: substractUnits.day,
	},
};

export function formatGraphData(args: FormatGraphDataArgs) {
	const { dataIn, filter } = args;

	if (!dataIn) {
		return [];
	}

	return addMissingGraphTicks({
		...graphFormattingOptions[filter],
		dataIn,
	});
}
