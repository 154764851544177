import axios from 'restyped-axios';
import { ApiResponse } from '../typings';
export const api = axios.create<GeoApi>();

export interface GeoApi {
	'/geo/:address': {
		GET: {
			response: ApiResponse<GeocodeResult[]>;
		};
	};
}

export interface GetGeocodeResultRequest {
	address: string;
}

export interface GeocodeResult {
	id: string;
	formattedAddress: string;
	position: LatLngLiteral;
	country?: string;
	countryCode?: string;
	state?: string;
	city?: string;
	address?: string;
	zipCode?: string;
	geometry: Geometry;
	types: string[];
}

export interface Geometry {
	viewport: {
		ne: LatLngLiteral;
		sw: LatLngLiteral;
	};
	location: LatLngLiteral;
	locationType: string;
}
export interface LatLngLiteral {
	lat: number;
	lng: number;
}

export const getGeocodeResultApi = async (params: GetGeocodeResultRequest) => {
	const response = await api.get(`/geo/${params.address}`);

	return response.data;
};
