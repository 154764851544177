import zipcelx, { ZipCelXConfig, ZipCelXDataSet } from 'zipcelx';

export default function downloadXlsxFile(fileString: string, fileName: string) {
	const toNoNull = /,(?=,)/;
	const toRows = /"\r\n(?=")|"\n(?=")|"\r(?=")/;
	const toCells = /,(?=")|,(?=\d+\.?\d*?,)/;
	const numberTypeColumnIndexList = [9, 10];

	// Replace null values with ""
	const normalized = fileString.split(toNoNull).join(',""');

	// Separate rows from newline
	const rowList = normalized.split(toRows);

	// Format each cell to object with correct type
	const dataArray = rowList.map((row, index) => {
		const firstRow = index === 0;

		return row.split(toCells).map((cell, index) => {
			const numberTypeColumn = numberTypeColumnIndexList.includes(index);

			return {
				value: cell.split('"').join(''),
				type: firstRow || !numberTypeColumn ? 'string' : 'number',
			};
		});
	});

	const xlsxConfiq: ZipCelXConfig = {
		filename: fileName,
		sheet: {
			data: dataArray as ZipCelXDataSet,
		},
	};

	zipcelx(xlsxConfiq);

	return;
}
