import styled from 'styled-components/macro';

import RadioButtonGroup from '../../../../components/radio-group/RadioGroup';
import { Color, Gutter } from '../../../../gfx/constants';
import { SubtextDescriptionTextMixin } from '../../../../gfx/globals';
import { media } from '../../../../services/media';

interface UsersFilterLinkProps {
	active: boolean;
}

export const UserName = styled.p`
	font-family: PrimaryMedium;
	margin: 0;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	max-width: 550px;
	margin: auto;
	@media (max-width: 1300px) {
		max-width: 280px;
	}
	@media (max-width: 900px) {
		max-width: 180px;
	}
`;

export const UserEmail = styled.p`
	margin: 0;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	max-width: 550px;
	margin: auto;
	@media (max-width: 1300px) {
		max-width: 280px;
	}
	@media (max-width: 900px) {
		max-width: 180px;
	}
`;

export const UsersFilterLink = styled.a<UsersFilterLinkProps>`
	border-bottom: ${({ active }) => active && `2px solid ${Color.GREEN_3}`};
	font-family: ${({ active }) => active && 'PrimaryMedium'};
	padding-bottom: 10px;
	text-align: center;

	&:hover {
		cursor: pointer;
		border-bottom: 2px solid #05c355;
		text-decoration: none;
	}
`;

export const UsersFilters = styled.nav`
	display: flex;
	margin-bottom: 25px;
	font-family: Secondary;
	font-size: 15px;
	border-bottom: 1px solid rgba(128, 128, 128, 0.3);
	width: 100%;

	a {
		:not(:first-child) {
			margin-left: 20px;
		}
	}

	@media (max-width: 1024px) {
		flex-wrap: wrap;
		justify-content: center;
	}
`;

export const AddUserRadioGroup = styled(RadioButtonGroup)`
	margin-bottom: ${Gutter.LARGE};
`;

export const InviteUserConfirmationWrapper = styled.div`
	display: flex;
	justify-content: center;
`;

export const InviteUserConfirmationInnerWrapper = styled.div`
	display: inline-block;

	${media.maxTablet`
		max-width: calc(100% - 1px); /* IE Fix for width */
	`}
`;

export const UserStatusBulletList = styled.div`
	display: flex;
	justify-content: center;
	${SubtextDescriptionTextMixin}
	line-height: 24px;
`;

export const BulletMarker = styled.div<{ color: Color }>`
	border-radius: 5px;
	background-color: ${(props) => props.color};
	color: white;
	height: 24px;
	min-width: 40px;
	line-height: 24px;
	text-align: center;
	margin: 0 10px;
	float: left;
	user-select: none;
`;
