import React, { Fragment, useEffect } from 'react';
import { RouteProps, withRouter } from 'react-router-dom';

const ScrollToTop = (props: RouteProps) => {
	const { children, location } = props;

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [location]);

	return <Fragment>{children}</Fragment>;
};

export default withRouter(ScrollToTop);
