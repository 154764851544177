import React from 'react';
import { PublicBackgroundImage, H2 } from '../../../gfx/globals';
import NotSupported from './NotSupportedViewStyle';
import Logo from '../../../components/logo/Logo';

export default function NotSupportedView() {
	return (
		<NotSupported>
			<NotSupported.SidePanel>
				<NotSupported.Wrapper>
					<NotSupported.Header>
						<Logo />
					</NotSupported.Header>
					<NotSupported.Notification>
						<H2>Your browser is not supported</H2>
						<p>
							The Internet Explorer browser is no longer supported. Dagpay works best on{' '}
							<a href="https://www.google.com/chrome/">Chrome</a>,{' '}
							<a href="https://www.mozilla.org/en-US/firefox/new/">Firefox</a>,{' '}
							<a href="https://www.microsoft.com/en-us/windows/microsoft-edge">Edge</a> and{' '}
							<a href="https://www.apple.com/safari/">Safari</a>.
						</p>
					</NotSupported.Notification>
				</NotSupported.Wrapper>
			</NotSupported.SidePanel>
			<PublicBackgroundImage />
		</NotSupported>
	);
}
