import styled, { css } from 'styled-components/macro';
import { Color } from '../../../../gfx/constants';
import { media } from '../../../../services/media';

export const SelectCurrencyWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: center;
	justify-content: center;
`;

export const Container = styled.div`
	width: 100%;
	margin-top: 62px;

	${media.maxSize1000`
		margin-top: 50px; 
		padding: 0 80px;
	`}

	${media.maxSize800`
		margin-top: 50px; 
		padding: 0 50px;
	`}

	${media.maxSize481`
		margin-top: 30px; 
		padding: 0;
	`}
`;

export const Box = styled.div<{ selected: boolean; disabled: boolean; initiallySelected: boolean }>`
	display: flex;
	align-items: center;
	height: 48px;
	width: 100%;
	padding: 8px 16px;
	margin-bottom: 4px;
	border: 1px solid ${Color.GRAY_5};
	border-radius: 4px;
	cursor: pointer;

	/* hovering effect */

	${(props) =>
		!props.selected &&
		!props.disabled &&
		css`
			:hover {
				background-color: ${Color.GRAY_0};
			}
		`}

	/* 
	one was selected, but not this one 
	*/
	${(props) =>
		!props.selected &&
		props.disabled &&
		css`
			filter: grayscale(90%);
			opacity: 0.65;
			cursor: inherit;
		`}

	/* selected element */
	${(props) =>
		props.selected &&
		props.disabled &&
		css`
			cursor: inherit;
		`}
`;

export const Icon = styled.div`
	margin-right: 8px;

	/* adjust svg to center */
	div,
	div div {
		display: flex;
		align-items: center;
		justify-content: center;
	}
`;

export const Label = styled.div`
	font-family: PrimaryMedium;
	font-size: 16px;
	line-height: 21px;
	margin-right: 10px;
`;
