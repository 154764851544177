import * as React from 'react';
import { Link } from 'react-router-dom';
import ReactSVG from 'react-svg';
import styled from 'styled-components/macro';
import Button from '../button/Button';

interface Props {
	title: string;
	to: string;
	tabIndex?: number;
}

export const StyledPreviousLink = styled(Link)`
	text-decoration: none;
`;

export default function PreviousLink({ title, to, tabIndex }: Props) {
	return (
		<StyledPreviousLink tabIndex={tabIndex} to={to}>
			<Button.Secondary white>
				<ReactSVG src="/files/svg/private/Arrow.svg" />
				{title}
			</Button.Secondary>
		</StyledPreviousLink>
	);
}
