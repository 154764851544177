import React from 'react';
import ReactSVG from 'react-svg';
import styled from 'styled-components/macro';
import { Color } from '../../gfx/constants';
import { HelpIcon, HelpLink } from '../../views/invoices/components/mobile-device-view/MobileInvoiceDetailStyle';
import Logo from '../logo/Logo';

export const SidebarHeader = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 10px;
`;

interface Props {
	helpLink?: string;
	logoClickLink?: string;
	hasWhiteBackground?: boolean;
	disableLogoClick?: boolean;
}

const ExternalInvoiceHeader = (props: Props) => {
	const { helpLink, logoClickLink, hasWhiteBackground: hasWhiteBackgorund, disableLogoClick } = props;
	return (
		<SidebarHeader>
			<Logo to={logoClickLink} hasWhiteBackground={hasWhiteBackgorund} notClickable={disableLogoClick} />
			{!!helpLink && (
				<HelpLink target="_blank" href={helpLink}>
					<span style={hasWhiteBackgorund ? { color: Color.BLUE_9 } : undefined}>Help</span>
					<HelpIcon hasWhiteBackground={hasWhiteBackgorund}>
						<ReactSVG src="/files/svg/icons/Help.svg" />
					</HelpIcon>
				</HelpLink>
			)}
		</SidebarHeader>
	);
};

export default ExternalInvoiceHeader;
