import * as qs from 'query-string';
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';

import { RecurringInvoice } from '../../../../api/invoiceApi';
import Button from '../../../../components/button/Button';
import {
	EscrowSupportModalWrapper,
	ModalButtonWrapper,
} from '../../../../components/escrow-state-display/EscrowStateDisplayStyle';
import Loader from '../../../../components/loader/Loader';
import Modal from '../../../../components/modal/Modal';
import { ITEMS_PER_PAGE, RoutesUrls } from '../../../../constants';
import { Strong } from '../../../../gfx/globals';
import isUserVerified from '../../../../services/is-user-verified';
import isWalletAdded from '../../../../services/is-wallet-added';
import { useStoreActions, useStoreState } from '../../../../services/store';
import { RouteProps } from '../../../../typings';

import RecurringInvoicesViewTpl from './RecurringInvoicesListViewTpl';

export interface OpenedInformation {
	invoiceId: string;
	isOpened: boolean;
}

function RecurringInvoicesListView(props: RouteProps) {
	const [page, setPage] = useState(1);
	const [openedInformationRows, setOpenedInformationRows] = useState<OpenedInformation[]>([]);
	const [activeInvoice, setActiveInvoice] = useState<RecurringInvoice | null>(null);
	const [activeIndex, setActiveIndex] = useState<number | null>(null);
	const [isModalOpen, setIsModalOpen] = useState<boolean[]>([]);

	const { recurringInvoices, rates } = useStoreState((state) => ({
		recurringInvoices: state.invoice.recurringInvoices,
		rates: state.rates.rates,
	}));

	const { getRecurringInvoices, getRates, cancelRecurringInvoice } = useStoreActions((actions) => ({
		...actions.invoice,
		...actions.rates,
	}));

	useEffect(() => {
		const fetchRecurringInvoices = async (page: number) => {
			await getRecurringInvoices({ page: page, itemsPerPage: ITEMS_PER_PAGE });
		};

		const fetchRates = async () => {
			await getRates();
		};
		const newPage = qs.parse(props.location.search).page;

		if (newPage && typeof newPage === 'string') {
			setPage(parseInt(newPage, 10));
		}

		fetchRecurringInvoices(page);
		fetchRates();
	}, [page, props.location.search, getRates, getRecurringInvoices]);

	if (!rates || !recurringInvoices) {
		return <Loader />;
	}

	const { page: pageFromLink } = qs.parse(window.location.search);

	if (recurringInvoices && pageFromLink && typeof pageFromLink === 'string') {
		if (parseInt(pageFromLink, 10) < 1 || parseInt(pageFromLink, 10) > recurringInvoices.pageCount) {
			return <div />;
		}
	}

	const handleCancelInvoice = async (recurringInvoiceId: string, index: number) => {
		const isModalOpenCopy = isModalOpen.slice();

		try {
			const response = await cancelRecurringInvoice({ recurringInvoiceId });

			isModalOpenCopy[index] = false;
			setIsModalOpen(isModalOpenCopy);

			if (response.success) {
				props.history.push(RoutesUrls.RECURRING_INVOICES);

				setIsModalOpen(isModalOpenCopy);

				toast.success(`Recurring invoice is cancelled successfully`);
			}
		} catch (e) {
			toast.error(`There was an error when cancelling recurring invoice.`);
		}
	};

	const handleToggleInformation = (invoiceId: string) => {
		const index = openedInformationRows.findIndex((obj) => obj.invoiceId === invoiceId);
		const openedInformationRowsCopy = openedInformationRows.slice();

		if (index === -1) {
			openedInformationRowsCopy.push({ invoiceId, isOpened: true });
		}

		if (index >= 0) {
			openedInformationRowsCopy[index].isOpened = !openedInformationRows[index].isOpened;
		}

		setOpenedInformationRows(openedInformationRowsCopy);
	};

	const handlePageChanged = (newPage: number) => {
		setPage(newPage);
	};

	const handleOpenModal = (invoice: RecurringInvoice, index: number) => {
		const isModalOpenCopy = isModalOpen.slice();
		isModalOpenCopy[index] = true;

		setIsModalOpen(isModalOpenCopy);
		setActiveIndex(index);
		setActiveInvoice(invoice);
	};

	const handleCloseModal = (index: number) => {
		const isModalOpenCopy = isModalOpen.slice();
		isModalOpenCopy[index] = false;
		setIsModalOpen(isModalOpenCopy);
	};

	const renderActionModal = () => {
		if (activeIndex === null || activeInvoice === null) {
			return null;
		}

		return (
			<Modal
				small
				hasCloseButton
				ariaHideApp={false}
				isOpen={isModalOpen[activeIndex]}
				onRequestClose={() => handleCloseModal(activeIndex)}
			>
				<EscrowSupportModalWrapper>
					<h2>Cancel recurring invoice?</h2>
					<p>
						You are about to cancel recurring invoice sent to <Strong>{`${activeInvoice.to}`}</Strong>
					</p>
					<ModalButtonWrapper>
						<Button.Secondary white onClick={() => handleCloseModal(activeIndex)}>
							Cancel
						</Button.Secondary>
						<Button.Secondary type="button" red onClick={() => handleCancelInvoice(activeInvoice.id, activeIndex)}>
							Cancel invoice
						</Button.Secondary>
					</ModalButtonWrapper>
				</EscrowSupportModalWrapper>
			</Modal>
		);
	};

	return (
		<>
			{renderActionModal()}
			<RecurringInvoicesViewTpl
				page={page}
				handlePageChanged={handlePageChanged}
				history={props.history}
				rates={rates}
				invoices={recurringInvoices}
				isWalletAdded={isWalletAdded()}
				isVerified={isUserVerified()}
				openedInformationRows={openedInformationRows}
				handleToggleInformation={handleToggleInformation}
				handleOpenModal={handleOpenModal}
			/>
		</>
	);
}

export default withRouter(RecurringInvoicesListView);
