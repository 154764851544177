import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import ClipLoader from 'react-spinners/ClipLoader';
import ReactSVG from 'react-svg';
import * as qs from 'query-string';

import { AwaitingPaymentText, PaymentDagPayLogo, PaymentHourGlassLoader } from '../../invoices/InvoiceViewStyle';

import { isMobile, isTablet } from 'react-device-detect';
import { FaqLinks } from '../../../constants';
import { RouteProps } from '../../../typings';
import { CenteredFlex } from '../../../gfx/globals';
import { Color } from '../../../gfx/constants';
import ExternalInvoiceRpcClient, {
	ExternalInvoiceSubscribeMethod,
} from '../../../services/external-invoice-rpc-client';
import { Json } from '../../../services/ws-rpc-client';
import getPaymentCountdown from '../../../services/get-payment-countdown';
import { Invoice } from '../../../api/invoiceApi';
import {
	MaximizedContent,
	CopyBlockWrapper,
	CopyBlockRow,
	CopyBlockRowLabel,
	CopyBlockRowValue,
	CopyBlockRowButton,
	ContentTitle,
	MaximizedText,
	Container,
	Sidebar,
	SidebarBlock,
	SidebarBlockCell,
	CurrencyPair,
	BaseAmount,
	BoldCellItem,
	ExpirationBlock,
	ValueItem,
	CancelModalButtonWrap,
	ExternalInvoiceWrapper,
	UnorderedList,
	ContentWrapper,
} from './ExternalInvoiceViewStyle';
import getExternalInvoiceGroupedStatus, {
	ExternalInvoiceGroupedStatus,
} from '../../../services/get-external-invoice-grouped-status';
import Button from '../../../components/button/Button';
import { toast } from 'react-toastify';
import CopyButton from '../../../components/copy-button/CopyButton';
import trimStringCenter from '../../../services/trim-string-center';
import { ExternalInvoice, ExternalInvoiceCurrency, ExternalInvoiceStatus } from '../../../api/externalInvoiceApi';
import { useStoreActions, useStoreState } from '../../../services/store';
import ExternalInvoiceDetailsView from './details/ExternalInvoiceDetailsView';
import ExternalInvoiceSelectCurrencyView from './select-currency/ExternalInvoiceSelectCurrencyView';
import Modal from '../../../components/modal/Modal';
import { size1000, size481 } from '../../../services/media';
import ExternalInvoiceHeader from '../../../components/ext-invoice-header/ExternalInvoiceHeader';
import ExternalInvoiceStatusView from './status/ExternalInvoiceStatusView';
import ExternalInvoiceQrModalView from './qr-modal/ExternalInvoiceQrModalView';
import ExternalInvoiceQr from '../../../components/external-invoice-qr/ExternalInvoiceQr';
import Loader from '../../../components/loader/Loader';
import toFixed from '../../../services/toFixed';

export enum UpdateContentName {
	CURRENCY = 'currency',
	DETAILS = 'details',
}

enum ScreenSizeName {
	SMALL = 'SMALL',
	MEDIUM = 'MEDIUM',
	LARGE = 'LARGE',
}

interface RouteParams {
	externalInvoiceId?: string;
	redirectUrl?: string;
}

/* 
	ScreenSizeName.SMALL is related to size481 and isMobile
	ScreenSizeName.MEDIUM is between  size481 and size1000 and related to isTablet
	ScreenSizeName.LARGE is > size1000

	media breaks size481, size800 and size1000

*/
function ExternalInvoiceView(props: RouteProps<RouteParams>) {
	const [timer, setTimer] = useState<JSX.Element | string>('');
	const [isCancelModalOpen, setIsCancelModalOpen] = useState<boolean>(false);
	const [isQrModalOpen, setIsQrModalOpen] = useState<boolean>(false);
	const [screenSizeName, setScreenSizeName] = useState<ScreenSizeName>(ScreenSizeName.LARGE);

	const { externalInvoice, externalInvoiceStatus, isExtInvoiceLoading } = useStoreState((state) => ({
		externalInvoice: state.externalInvoice.externalInvoice,
		externalInvoiceStatus: state.externalInvoice.status,
		isExtInvoiceLoading: state.externalInvoice.isLoading,
	}));

	const { getExternalInvoice, cancelExternalInvoice, expireExternalInvoice, setExternalInvoiceGroupedStatus } =
		useStoreActions((actions) => ({
			...actions.externalInvoice,
		}));

	let rpc: ExternalInvoiceRpcClient | undefined;

	const externalInvoiceId: string | undefined =
		props.match && props.match.params && props.match.params.externalInvoiceId;

	const redirectUrl = qs.parse(props.location.search).redirectUrl as string | undefined;
	const updateContentName = qs.parse(props.location.search).update as string | undefined;

	const checkScreenSize = () => {
		if (window.innerWidth < size481) {
			return setScreenSizeName(ScreenSizeName.SMALL);
		}

		if (window.innerWidth >= size1000) {
			return setScreenSizeName(ScreenSizeName.LARGE);
		}

		return setScreenSizeName(ScreenSizeName.MEDIUM);
	};

	const setupRpcClient = (externalInvoiceId: string) => {
		// avoid trying to setup the rpc client more than once
		if (rpc !== undefined) {
			return;
		}

		const protocol = process.env.REACT_APP_API_SSL === 'true' ? 'wss' : 'ws';
		const url = `${protocol}://${process.env.REACT_APP_API_URL}/ws`;

		rpc = new ExternalInvoiceRpcClient(url, ExternalInvoiceSubscribeMethod.COINBASE_PAYMENT, externalInvoiceId);

		rpc.handleNewPayment = (rpcNotification: Json) => {
			if (typeof rpcNotification.newStatus !== undefined && typeof rpcNotification.newStatus === 'string') {
				const newStatus = getExternalInvoiceGroupedStatus(rpcNotification.newStatus as ExternalInvoiceStatus);

				// no data in rpc call or status is already changed
				if (!newStatus) {
					return;
				}

				setExternalInvoiceGroupedStatus(newStatus);

				return;
			}

			// console.log('notification has no content', { rpcNotification });
		};
	};

	useEffect(() => {
		const fetchExternalInvoice = async (id: string) => {
			await getExternalInvoice({ externalInvoiceId: id });
		};

		if (externalInvoiceId) {
			fetchExternalInvoice(externalInvoiceId);

			setupRpcClient(externalInvoiceId);

			window.addEventListener('resize', checkScreenSize);

			checkScreenSize();
		}

		return () => {
			window.removeEventListener('resize', checkScreenSize);
		};
	}, []);

	useEffect(() => {
		if (externalInvoice) {
			setTimer(
				getPaymentCountdown({
					invoice: externalInvoice as unknown as Invoice,
					onComplete: () => handleExpiration(externalInvoice.id, isCancelModalOpen, isQrModalOpen),
				}),
			);
		}
	}, [setTimer, externalInvoice]);

	const handleExpiration = async (id: string, isCancelModalOpen: boolean, isQrModalOpen: boolean) => {
		// close cancel modal if still opened
		handleCloseCancelModal();

		const isExpired = await expireExternalInvoice({ externalInvoiceId: id });

		if (!isExpired) {
			toast.error('Failed to expire invoice');

			return;
		}
	};

	const getReceivedBy = () => (
		<SidebarBlock marginTop={15}>
			<SidebarBlockCell columnStart={1} columnEnd={1} rowStart={1} rowEnd={1}>
				<ReactSVG src="/files/svg/invoice/ReceiverIcon.svg" />
			</SidebarBlockCell>
			<SidebarBlockCell columnStart={2} columnEnd={2} rowStart={1} rowEnd={1}>
				Receiver
			</SidebarBlockCell>
			<SidebarBlockCell paddingTop={15} columnStart={2} columnEnd={2} rowStart={2} rowEnd={2}>
				<BoldCellItem>{!externalInvoice || !externalInvoice.receiver ? '...' : externalInvoice.receiver}</BoldCellItem>
			</SidebarBlockCell>
		</SidebarBlock>
	);

	const getProductDescription = () => (
		<SidebarBlock marginTop={15}>
			<SidebarBlockCell columnStart={1} columnEnd={1} rowStart={1} rowEnd={1}>
				<ReactSVG src="/files/svg/invoice/ReceiverIcon.svg" />
			</SidebarBlockCell>
			<SidebarBlockCell columnStart={2} columnEnd={2} rowStart={1} rowEnd={1}>
				Product
			</SidebarBlockCell>
			<SidebarBlockCell paddingTop={15} columnStart={2} columnEnd={2} rowStart={2} rowEnd={2}>
				<BoldCellItem>
					{externalInvoice && externalInvoice.product ? trimStringCenter(externalInvoice.product, 30) : '...'}
				</BoldCellItem>
			</SidebarBlockCell>
		</SidebarBlock>
	);

	const getAmountInfo = () => {
		/* 
			Payment currency can be optional:
				- display dash if payment currency missing
				- show quote currency in gray
		*/
		return (
			<SidebarBlock marginTop={46}>
				<SidebarBlockCell columnStart={1} columnEnd={1} rowStart={1} rowEnd={1}>
					<ReactSVG src="/files/svg/invoice/WalletGreenIcon.svg" />
				</SidebarBlockCell>
				<SidebarBlockCell columnStart={2} columnEnd={2} rowStart={1} rowEnd={1}>
					Amount
				</SidebarBlockCell>
				<SidebarBlockCell paddingTop={15} columnStart={2} columnEnd={2} rowStart={2} rowEnd={2}>
					<CurrencyPair>
						<BoldCellItem>
							{!externalInvoice
								? '...'
								: !!externalInvoice && !externalInvoice.paymentCurrency
								? '-'
								: `${toFixed(Number.parseFloat(externalInvoice.paymentAmount))} ${externalInvoice.paymentCurrency}`}
						</BoldCellItem>
						<BaseAmount>
							{!externalInvoice ? '...' : `${externalInvoice.baseAmount} ${externalInvoice.baseCurrency}`}
						</BaseAmount>
					</CurrencyPair>
				</SidebarBlockCell>
			</SidebarBlock>
		);
	};

	const getExpirationBlock = () => {
		// use estate only while pending
		if (externalInvoiceStatus === ExternalInvoiceGroupedStatus.PENDING) {
			return (
				<ExpirationBlock>
					<CenteredFlex>Payment expires in {timer}</CenteredFlex>
					<AwaitingPaymentText>
						Awaiting payment
						<PaymentHourGlassLoader>
							<ClipLoader color={Color.GREEN_3} sizeUnit={'px'} size={20} />
						</PaymentHourGlassLoader>
					</AwaitingPaymentText>
				</ExpirationBlock>
			);
		}

		return '';
	};

	const handleCloseCancelModal = () => {
		setIsCancelModalOpen(false);
	};

	const renderConfirmCancelModal = () => {
		return (
			<Modal
				medium
				hasCloseButton
				ariaHideApp={false}
				isOpen={isCancelModalOpen && !!externalInvoice}
				onRequestClose={handleCloseCancelModal}
			>
				<ContentWrapper>
					<h2>Cancel payment</h2>
					<p>Are you sure you want to cancel the payment?</p>
					<CancelModalButtonWrap>
						<Button.Secondary white onClick={handleCloseCancelModal}>
							No, don’t cancel
						</Button.Secondary>
						<Button.Secondary red onClick={handleCancel}>
							Yes, cancel payment
						</Button.Secondary>
					</CancelModalButtonWrap>
				</ContentWrapper>
			</Modal>
		);
	};

	const handleCancel = async () => {
		if (!externalInvoice) {
			setIsCancelModalOpen(false);

			return;
		}

		const cancelledExtInvoice = await cancelExternalInvoice({ externalInvoiceId: externalInvoice.id });

		setIsCancelModalOpen(false);

		if (!cancelledExtInvoice) {
			toast.error('Failed to cancel invoice');

			return;
		}
	};

	const getCancelBlock = () => {
		if (!externalInvoice) {
			return '';
		}

		if (externalInvoiceStatus !== ExternalInvoiceGroupedStatus.PENDING) {
			return '';
		}

		// different style when only sidebar is displayed
		if (screenSizeName === ScreenSizeName.SMALL || screenSizeName === ScreenSizeName.MEDIUM || isMobile || isTablet) {
			return (
				<Button onClick={() => setIsCancelModalOpen(true)} centered transparent color={Color.WHITE} marginBottom={20}>
					Cancel payment
				</Button>
			);
		}

		return (
			<Button onClick={() => setIsCancelModalOpen(true)} centered transparent noBorder underLined color={Color.WHITE}>
				Cancel payment
			</Button>
		);
	};

	const getInfoBlock = () => {
		return (
			<UnorderedList>
				{getAmountInfo()}
				{getReceivedBy()}
				{getProductDescription()}
			</UnorderedList>
		);
	};

	const getSidebarBackButton = () => {
		// wrong view to display button
		if (screenSizeName === ScreenSizeName.LARGE) {
			return null;
		}

		return (
			<Button
				onClick={() =>
					props.history.push(
						`${props.location.pathname}${props.location.search}&${qs.stringify({
							update: UpdateContentName.CURRENCY,
						})}`, // TODO: what if search has no content: ;
					)
				}
				centered
				transparent
				noBorder
				underLined
				color={Color.WHITE}
				marginBottom="auto"
			>
				Back
			</Button>
		);
	};

	const getSidebarPayInvoiceButton = () => {
		// device in wrong size for display
		if (screenSizeName !== ScreenSizeName.SMALL || isTablet) {
			return null;
		}

		return (
			<Button onClick={() => setIsQrModalOpen(true)} centered marginTop={20} marginBottom={14}>
				Continue to payment
			</Button>
		);
	};

	const getPaymentSidebar = () => {
		const isSmallDevice =
			screenSizeName === ScreenSizeName.MEDIUM || screenSizeName === ScreenSizeName.SMALL || isMobile || isTablet;
		const updateDetails = !!updateContentName && updateContentName === UpdateContentName.DETAILS;
		const updateCurrency = !!updateContentName && updateContentName === UpdateContentName.CURRENCY;
		const missingDetails = !!externalInvoice && (!externalInvoice.customerName || !externalInvoice.customerEmail);
		const missingCurrency = !!externalInvoice && !externalInvoice.paymentCurrency;

		// missing details in small screen view or update request
		if (isSmallDevice && (updateDetails || missingDetails)) {
			return (
				<ExternalInvoiceDetailsView
					extInvoiceCustomerEmail={externalInvoice && externalInvoice.customerEmail}
					extInvoiceCustomerName={externalInvoice && externalInvoice.customerName}
					extInvoiceId={externalInvoice && externalInvoice.id}
					extInvoicePaymentCurrency={externalInvoice && externalInvoice.paymentCurrency}
					showHeader
				/>
			);
		}

		// missing currency or change attempt
		if (isSmallDevice && (updateCurrency || missingCurrency)) {
			return (
				<ExternalInvoiceSelectCurrencyView
					extInvoiceId={externalInvoice && externalInvoice.id}
					extInvoiceCustomerName={externalInvoice && externalInvoice.customerName}
					extInvoiceCustomerEmail={externalInvoice && externalInvoice.customerEmail}
					extInvoicePaymentCurrency={externalInvoice && externalInvoice.paymentCurrency}
					showHeader
				/>
			);
		}

		return (
			<Sidebar>
				<ExternalInvoiceHeader disableLogoClick helpLink={FaqLinks.externalInvoiceHelp} />
				{getInfoBlock()}
				{(screenSizeName === ScreenSizeName.MEDIUM || isTablet) &&
					externalInvoiceStatus === ExternalInvoiceGroupedStatus.PENDING && (
						<ExternalInvoiceQr
							isDarkBackground={true}
							paymentCurrency={externalInvoice ? externalInvoice.paymentCurrency : undefined}
							qrCodeUrl={externalInvoice ? externalInvoice.qrCodeUrl : undefined}
						/>
					)}
				{getExpirationBlock()}
				{getSidebarPayInvoiceButton()}
				{getCancelBlock()}
				{getSidebarBackButton()}
				<ExternalInvoiceQrModalView
					handleOnClose={() => setIsQrModalOpen(false)}
					isOpen={!!externalInvoice && isQrModalOpen && screenSizeName === ScreenSizeName.SMALL}
					qrCodeUrl={externalInvoice ? externalInvoice.qrCodeUrl : undefined}
					paymentCurrency={externalInvoice ? externalInvoice.paymentCurrency : undefined}
					paymentAmount={externalInvoice ? externalInvoice.paymentAmount : undefined}
					receivingWallet={externalInvoice ? externalInvoice.receivingWallet : undefined}
				/>
			</Sidebar>
		);
	};

	const getCopyInfoBlock = (extInvoice: ExternalInvoice) => {
		return (
			<CopyBlockWrapper>
				<CopyBlockRow>
					<CopyBlockRowLabel>Amount</CopyBlockRowLabel>
					<CopyBlockRowValue>
						<ValueItem>{toFixed(Number.parseFloat(extInvoice.paymentAmount))}</ValueItem>
						<ValueItem>{extInvoice.paymentCurrency}</ValueItem>
						<CopyButton value={extInvoice.paymentAmount} top={-38} right={-30}>
							<CopyBlockRowButton>COPY</CopyBlockRowButton>
						</CopyButton>
					</CopyBlockRowValue>
				</CopyBlockRow>
				<CopyBlockRow>
					<CopyBlockRowLabel>
						{extInvoice.paymentCurrency}
						{` Address`}
					</CopyBlockRowLabel>
					<CopyBlockRowValue>
						<ValueItem>{trimStringCenter(extInvoice.receivingWallet, 15)}</ValueItem>
						<CopyButton value={extInvoice.receivingWallet} top={-38} right={-30}>
							<CopyBlockRowButton>COPY</CopyBlockRowButton>
						</CopyButton>
					</CopyBlockRowValue>
				</CopyBlockRow>
			</CopyBlockWrapper>
		);
	};

	const getLoadingContent = () => {
		return (
			<MaximizedContent>
				<Loader />
			</MaximizedContent>
		);
	};

	const getMaximizedPendingContent = () => {
		// not loaded yet
		if (!externalInvoice || !externalInvoiceStatus) {
			return getLoadingContent();
		}

		// missing contact details
		if (
			!externalInvoice.customerEmail ||
			!externalInvoice.customerName ||
			(!!updateContentName && updateContentName === UpdateContentName.DETAILS)
		) {
			return (
				<ExternalInvoiceDetailsView
					extInvoiceId={externalInvoice.id}
					extInvoiceCustomerName={externalInvoice.customerName}
					extInvoiceCustomerEmail={externalInvoice.customerEmail}
					extInvoicePaymentCurrency={externalInvoice.paymentCurrency}
				/>
			);
		}

		// missing payment currency OR viewer wants to update
		if (!externalInvoice.paymentCurrency || (!!updateContentName && updateContentName === UpdateContentName.CURRENCY)) {
			return (
				<ExternalInvoiceSelectCurrencyView
					extInvoiceId={externalInvoice.id}
					extInvoiceCustomerName={externalInvoice.customerName}
					extInvoiceCustomerEmail={externalInvoice.customerEmail}
					extInvoicePaymentCurrency={externalInvoice.paymentCurrency}
				/>
			);
		}

		return (
			<MaximizedContent>
				<ContentTitle>Pay invoice</ContentTitle>
				<MaximizedText marginTop={10} marginBottom={20}>
					Scan QR code with your
					<strong>
						{` ${
							externalInvoice.paymentCurrency === ExternalInvoiceCurrency.USDC
								? 'USD Coin wallet (ERC-20)'
								: externalInvoice.paymentCurrency === ExternalInvoiceCurrency.USDT
								? 'Tether wallet (ERC-20)'
								: `${externalInvoice.paymentCurrency} wallet`
						} `}
					</strong>
					<br />
					to proceed with payment
				</MaximizedText>
				<ExternalInvoiceQr
					isDarkBackground={false}
					paymentCurrency={externalInvoice.paymentCurrency}
					qrCodeUrl={externalInvoice.qrCodeUrl}
				/>
				<MaximizedText strong marginTop={17} marginBottom={10}>
					Or
				</MaximizedText>
				<MaximizedText
					marginBottom={24}
				>{`Send ${externalInvoice.paymentCurrency} to the address below:`}</MaximizedText>

				{getCopyInfoBlock(externalInvoice)}

				<MaximizedText
					onClick={() =>
						props.history.push(
							`${props.location.pathname}${props.location.search}&${qs.stringify({
								update: UpdateContentName.CURRENCY,
							})}`, // TODO: what if search has no content: ;
						)
					}
					hasAction
					marginTop={27}
					marginBottom={12}
				>
					Back
				</MaximizedText>

				<MaximizedText marginTop={13}>Payments processed by Dagpay</MaximizedText>
			</MaximizedContent>
		);
	};

	const getContent = () => {
		// process not completed
		if (externalInvoiceStatus === ExternalInvoiceGroupedStatus.PENDING) {
			return (
				<>
					{getPaymentSidebar()}
					{!isMobile && !isTablet && screenSizeName === ScreenSizeName.LARGE && getMaximizedPendingContent()}
				</>
			);
		}

		// process completed
		return (
			<>
				{!isMobile && !isTablet && screenSizeName === ScreenSizeName.LARGE && getPaymentSidebar()}
				<ExternalInvoiceStatusView
					isExtInvoiceLoading={isExtInvoiceLoading}
					externalInvoiceStatus={externalInvoiceStatus}
					redirectUrl={redirectUrl}
					showHeader={isMobile || isTablet || screenSizeName !== ScreenSizeName.LARGE}
				/>
			</>
		);
	};

	return (
		<ExternalInvoiceWrapper>
			{screenSizeName === ScreenSizeName.LARGE && (
				<PaymentDagPayLogo>
					Powered by
					<ReactSVG src="/files/svg/logos/DagpayLogoGray.svg" />
				</PaymentDagPayLogo>
			)}
			<Container>
				{getContent()}
				{renderConfirmCancelModal()}
			</Container>
		</ExternalInvoiceWrapper>
	);
}

export default withRouter(ExternalInvoiceView);
