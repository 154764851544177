import React, { useState } from 'react';
import { UseFormMethods } from 'react-hook-form/dist/types';

import { DAGCOIN_CARD_PIN_CODE_LENGTH } from '../../constants';
import { DagcoinCardFields } from '../../views/invoices/components/DagcoinCard/DagcoinCardContent';

import { Cell, CellWrapper, ErrorWrapper, FieldTitle, InputWrapper, ParentWrapper } from './PinCodeFieldStyle';

interface Props {
	name: string;
	methods: UseFormMethods<DagcoinCardFields>;
	setMoveFocus: (value: boolean) => void;
	id?: string;
	title?: string;
}

export default function PinCodeField({ name, id = 'pinCode', setMoveFocus, title, methods }: Props) {
	const [currentValue, setCurrentValue] = useState('');
	const [fieldHasFocus, setFieldHasFocus] = useState(false);

	const { register, errors, formState, trigger } = methods;

	const handleCellBlink = (cellPosition: number) => {
		return currentValue.length === cellPosition;
	};

	const handleCellFill = (cellPosition: number) => {
		if (currentValue.length - 1 >= cellPosition) {
			return '•';
		}

		return '';
	};

	const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setCurrentValue(event.target.value);

		if (event.target.value.length === DAGCOIN_CARD_PIN_CODE_LENGTH) {
			setMoveFocus(true);
		}
	};

	const handleOnKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		// prevent non digit value, allow deletion
		if (!/\d/.exec(event.key) && event.key !== 'Backspace') {
			event.preventDefault();

			return;
		}
	};

	const handleOnFocus = () => {
		setFieldHasFocus(true);
	};

	const handleOnBlur = () => {
		setFieldHasFocus(false);
		if (formState.isDirty) {
			trigger('pinCode');
		}
	};

	return (
		<>
			<ParentWrapper>
				<FieldTitle>{title}</FieldTitle>
				<InputWrapper>
					<input
						id={id}
						name={name}
						type="tel"
						spellCheck={false}
						ref={register}
						maxLength={DAGCOIN_CARD_PIN_CODE_LENGTH}
						onChange={handleOnChange}
						onKeyDown={handleOnKeyDown}
						onFocus={handleOnFocus}
						onBlur={handleOnBlur}
					/>
					<CellWrapper>
						<Cell id="cell-0" blink={handleCellBlink(0)} fillValue={handleCellFill(0)} hasFocus={fieldHasFocus} />
						<Cell id="cell-1" blink={handleCellBlink(1)} fillValue={handleCellFill(1)} hasFocus={fieldHasFocus} />
						<Cell id="cell-2" blink={handleCellBlink(2)} fillValue={handleCellFill(2)} hasFocus={fieldHasFocus} />
						<Cell
							id="cell-3"
							blink={handleCellBlink(3)}
							fillValue={handleCellFill(3)}
							hasFocus={fieldHasFocus}
							maxLengthReached={currentValue.length === DAGCOIN_CARD_PIN_CODE_LENGTH}
						/>
					</CellWrapper>
				</InputWrapper>
				<ErrorWrapper>{errors.pinCode && errors.pinCode.message}</ErrorWrapper>
			</ParentWrapper>
		</>
	);
}
