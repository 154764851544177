import styled from 'styled-components/macro';
import { media } from '../../../../services/media';

export const DetailsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;
`;

export const FormWrapper = styled.div`
	width: 100%;
	padding: 0 44px;

	${media.maxSize800`
		padding: 0 70px;
	`}

	${media.maxSize481`
		padding: 0;
	`}
`;

export const ButtonWrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 20px;
`;
