import { UserRole } from '../constants';

import isVerificationCompleted from './is-verification-completed';
import store from './store';

export default function isUserVerified() {
	const viewerState = store.getState().viewer.activeViewer;
	const userVerificationsState = store.getState().verification.verifications;
	const parentVerificationsState = store.getState().verification.parentUserVerifications;

	if (!viewerState) {
		return false;
	}

	const isOwner = viewerState.role === UserRole.OWNER;
	const isAdmin = viewerState.role === UserRole.ADMIN || viewerState.role === UserRole.PENDING_ADMIN;
	const isTeamMember = viewerState.role === UserRole.ADVANCED_TEAM_MEMBER || viewerState.role === UserRole.TEAM_MEMBER;

	let isUserVerified: boolean = false;

	if (isOwner && userVerificationsState) {
		isUserVerified = isVerificationCompleted(userVerificationsState);
	}

	if (isAdmin && userVerificationsState) {
		isUserVerified = userVerificationsState.isEmailVerified && userVerificationsState.isIdentityVerified;
	}

	if (isTeamMember && parentVerificationsState) {
		isUserVerified = isVerificationCompleted(parentVerificationsState);
	}

	return isUserVerified;
}
