import React from 'react';
import { ExternalInvoiceCurrency } from '../../../../api/externalInvoiceApi';
import Button from '../../../../components/button/Button';
import { CircleCopy } from '../../../../components/circle-copy/CircleCopy';
import ExternalInvoiceQr from '../../../../components/external-invoice-qr/ExternalInvoiceQr';
import Logo from '../../../../components/logo/Logo';
import Modal from '../../../../components/modal/Modal';
import { Color } from '../../../../gfx/constants';
import toFixed from '../../../../services/toFixed';
import trimStringCenter from '../../../../services/trim-string-center';
import { ContentTitle, MaximizedText } from '../ExternalInvoiceViewStyle';
import { LogoWrapper, TableCell, TableRow, TableWrapper, Wrapper } from './ExternalInvoiceQrModalViewStyle';

interface QrModalProps {
	handleOnClose: () => void;
	isOpen?: boolean;
	paymentCurrency?: ExternalInvoiceCurrency;
	paymentAmount?: string;
	receivingWallet?: string;
	qrCodeUrl?: string;
}

const ExternalInvoiceQrModalView = (props: QrModalProps) => {
	const { handleOnClose, isOpen, paymentAmount, paymentCurrency, receivingWallet, qrCodeUrl } = props;

	return (
		<Modal
			backgroundColor={Color.BLUE_7}
			borderColor={Color.BLUE_7}
			hasCloseButton
			ariaHideApp={false}
			isOpen={!!isOpen}
			onRequestClose={handleOnClose}
			closeButtonColor={Color.WHITE}
		>
			<Wrapper>
				<LogoWrapper>
					<Logo notClickable />
				</LogoWrapper>
				<ContentTitle color={Color.WHITE} weight={500} size={22} height={26}>
					Pay invoice
				</ContentTitle>
				<MaximizedText marginTop={12} marginBottom={26} height={24} color={Color.WHITE}>
					{`Scan QR code with your ${paymentCurrency || 'currency'} wallet to proceed with payment`}
				</MaximizedText>
				<ExternalInvoiceQr isDarkBackground={true} paymentCurrency={paymentCurrency} qrCodeUrl={qrCodeUrl} />
				<MaximizedText marginTop={39} marginBottom={14} height={24} color={Color.WHITE}>
					{`Send ${paymentCurrency || 'currency'} to the address below:`}
				</MaximizedText>
				<TableWrapper>
					<TableRow>
						<TableCell>
							<div>AMOUNT</div>
							{`${paymentAmount ? `${toFixed(Number.parseFloat(paymentAmount))}` : ''} ${paymentCurrency || ''}`}
						</TableCell>
						<TableCell allowOverflow>
							<CircleCopy value={paymentAmount} />
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>
							<div>{paymentCurrency || 'CURRENCY'} ADDRESS</div>
							{receivingWallet ? trimStringCenter(receivingWallet, 20) : 'receiver ...'}
						</TableCell>
						<TableCell allowOverflow>
							<CircleCopy value={receivingWallet} />
						</TableCell>
					</TableRow>
				</TableWrapper>

				<Button
					onClick={handleOnClose}
					centered
					transparent
					noBorder
					underLined
					color={Color.WHITE}
					marginTop={24}
					fontSize={14}
					lineHeight={19}
					fontWeight="normal"
				>
					See details
				</Button>
			</Wrapper>
		</Modal>
	);
};

export default ExternalInvoiceQrModalView;
