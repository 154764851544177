import * as React from 'react';
import styled, { css } from 'styled-components/macro';
import { Color } from '../../gfx/constants';
import { media } from '../../services/media';

interface CellProps {
	cells?: number;
	column?: boolean;
	mobileRow?: boolean;
	size?: number;
	br?: boolean;
	bl?: boolean;
	bt?: boolean;
	bb?: boolean;
}

export const CellContent = styled.div<{ padding?: number }>`
	padding: 32px;
	span {
		font-size: 14px;
		font-family: primaryMedium;
		margin-bottom: 4px;
		color: ${Color.GRAY_11};
	}
	p {
		font-size: 14px;
		font-family: secondary;
		margin: 0;
		color: ${Color.GRAY_9};
	}

	${media.maxSize800`
		padding: 15px;
	`}
`;

export const Cell = styled.div<CellProps>`
	display: flex;
	box-sizing: border-box;
	flex: 1;
	${(props) =>
		props.column &&
		css`
			flex-direction: column;
			> :not(:first-child):not(:last-child) ${CellContent} {
				padding-bottom: 0;
				padding-top: 0;
			}
		`};
	${(props) =>
		props.size &&
		css`
			flex: ${props.size};
		`};
	${(props) =>
		props.br &&
		css`
			border-right: 1px solid ${Color.GRAY_5};
		`};
	${(props) =>
		props.bl &&
		css`
			border-left: 1px solid ${Color.GRAY_5};
		`};
	${(props) =>
		props.bt &&
		css`
			border-top: 1px solid ${Color.GRAY_5};
		`};
	${(props) =>
		props.bb &&
		css`
			border-bottom: 1px solid ${Color.GRAY_5};
		`};
	@media (max-width: 800px) {
		flex-direction: column;
		border: none;
		display: flex;
		box-sizing: border-box;
		${(props) =>
			props.mobileRow &&
			css`
				background: ${Color.GRAY_1};
			`};
		> :not(:first-child):not(:last-child) ${CellContent} {
			padding: 15px;
		}
	}
`;

export const Grid = styled.div`
	background: ${Color.WHITE};
	display: flex;
	flex: 1;
	border: 1px solid ${Color.GRAY_5};
	border-radius: 4px;
	${media.maxSize800`
		padding: 20px;
		flex-direction: column;
	`}
`;

interface FlexProps {
	children: React.ReactNode;
}

const FlexGrid = (props: FlexProps) => {
	return <Grid>{props.children}</Grid>;
};

export default FlexGrid;
