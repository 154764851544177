import React, { useState } from 'react';
import Form from '../../../components/form/Form';
import ReactSVG from 'react-svg';
import { BusinessVerificationFormDescription, DropzoneSection } from './VerifyStyle';
import { ColumnedLeftCenterAlignedFlex, GoBackLink, H1 } from '../../../gfx/globals';
import Notification from '../../../components/notification/Notification';
import HookDropZoneField from '../../../components/react-hook-form/HookDropzoneField';
import { RouteProps } from '../../../typings';
import ReactHookForm, {
	RowPlacement,
	SectionTitle,
	WhiteHookForm,
} from '../../../components/react-hook-form/ReactHookFormStyle';
import { Redirect, withRouter } from 'react-router-dom';
import { PreviousWrapper } from '../../public/sign-up/SignUpViewStyle';
import { GoBack, GoBackText } from '../../public/sign-up/FillDetailsStep';
import Button, { ButtonTypes } from '../../../components/button/Button';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useStoreActions, useStoreState } from '../../../services/store';
import * as Yup from 'yup';
import { validateFileType } from '../../../services/validate-file-type';
import { validateMaxFileSize } from '../../../services/validate-max-file-size';
import { toast } from 'react-toastify';
import View from '../../../components/view/View';
import Loader from 'react-spinners/ClipLoader';
import { BusinessType, RoutesUrls, VerificationState } from '../../../constants';
import RejectionReason from '../../../components/rejection-reason/RejectionReason';
import { getRejectReasonStringArray } from './VerifyBusinessView';

const schema = Yup.object().shape({
	ultimateBeneficialOwnerImage: Yup.array()
		.min(1, 'Business address proof is required')
		.test('does-not-exceed-size', `File size should be more than 1Kb and not larger than 5Mb`, validateMaxFileSize)
		.test('has-invalid-type', `File type is not accepted`, validateFileType),
	boardDirectorsImage: Yup.array()
		.min(1, 'Business address proof is required')
		.test('does-not-exceed-size', `File size should be more than 1Kb and not larger than 5Mb`, validateMaxFileSize)
		.test('has-invalid-type', `File type is not accepted`, validateFileType),
});
interface Props extends RouteProps {
	errorElement: JSX.Element | null;
	setErrorElement(element: JSX.Element | null): void;
}

export interface VerifyBusinessFields {
	ultimateBeneficialOwnerImage: File[];
	boardDirectorsImage: File[];
}

function VerifyBusinessProofOfSharesView(props: Props) {
	const [isActionLoading, setIsActionLoading] = useState<boolean>(false);

	const { verifyBusinessOwnership } = useStoreActions((actions) => ({
		...actions.verification,
	}));

	const { verifications, activeViewer } = useStoreState((state) => ({
		verifications: state.verification.verifications,
		activeViewer: state.viewer.activeViewer,
	}));

	const methods = useForm<VerifyBusinessFields>({
		resolver: yupResolver(schema),
		shouldFocusError: true,
		mode: 'onChange',
	});

	const rejectionReason =
		verifications && verifications.tiers && verifications.tiers.TIER3 && verifications.tiers.TIER3.rejectionReason
			? verifications.tiers.TIER3.rejectionReason
			: '';

	const handleVerifyBusinessOwnership = async (e: React.FormEvent<HTMLInputElement>) => {
		console.log('handled');
		// Remove image properties from input
		const { ultimateBeneficialOwnerImage, boardDirectorsImage } = methods.getValues();

		// Construct form data
		const formData = new FormData();
		ultimateBeneficialOwnerImage.forEach((file) => {
			formData.append('ultimateBeneficialOwner', file);
		});
		boardDirectorsImage.forEach((file) => {
			formData.append('boardMembers', file);
		});

		setIsActionLoading(true);
		const response = await verifyBusinessOwnership({ data: formData });

		if (response.success) {
			setIsActionLoading(false);
			props.history.push('/private');
			toast.success('Business address verification is sent');
			return;
		}

		if (response.error) {
			setIsActionLoading(false);
			toast.error(<>Something went wrong, please try again</>);
			return false;
		}
	};

	//redirect to dashboard if user is not eligible for TIER 3 application
	if (
		activeViewer?.businessType !== BusinessType.BUSINESS ||
		!verifications ||
		!(!!verifications.tiers.TIER2 && verifications.tiers.TIER2.state !== VerificationState.REJECTED)
	) {
		return <Redirect to={RoutesUrls.PRIVATE} />;
	}

	return (
		<View>
			<H1 center>Business verification - Tier 3 (unlimited)</H1>
			<BusinessVerificationFormDescription>
				To process unlimited transaction volumes, you must submit corporate documents of your company share certificate
				and the official list of all board directors. Note that we only accept scanned image(s) of an original paper
				document.{' '}
				<a
					href="http://help.dagpay.io/en/articles/5591451-company-legal-entity-business-verification"
					target="_blank"
					rel="noopener noreferrer"
				>
					Learn more about business verification requirements.
				</a>{' '}
			</BusinessVerificationFormDescription>
			{rejectionReason && <RejectionReason reasons={getRejectReasonStringArray(rejectionReason)} />}
			<FormProvider {...methods}>
				<WhiteHookForm onSubmit={methods.handleSubmit(handleVerifyBusinessOwnership)}>
					<SectionTitle>
						<ReactSVG src="/files/svg/private/Upload.svg" />
						Upload additional documents for proof of business ownership
					</SectionTitle>
					<Notification left>Please provide the following documents:</Notification>
					<DropzoneSection>
						<Form.Column withMargin>
							<HookDropZoneField
								name="ultimateBeneficialOwnerImage"
								label="Share certificate including Ultimate Beneficial Owner"
								maxFiles={3}
								multiple
								hasError={!!methods.errors?.ultimateBeneficialOwnerImage}
							/>
						</Form.Column>
						<ColumnedLeftCenterAlignedFlex flex={1}>
							<Notification>
								<p>
									Please submit <strong>at least one (1)</strong> of the following
									<br /> goverment issued business registration documents:
								</p>
								<p>- Share certificate must include the Ultimate Beneficial Owner (UBO) of the company;</p>
								<p>- Up to 3 files can be uploaded.</p>
							</Notification>
						</ColumnedLeftCenterAlignedFlex>
					</DropzoneSection>
					<DropzoneSection>
						<Form.Column withMargin>
							<HookDropZoneField
								name="boardDirectorsImage"
								label="Official list of all board directors"
								maxFiles={3}
								multiple
							/>
						</Form.Column>
						<ColumnedLeftCenterAlignedFlex flex={1}>
							<Notification>
								<p>
									Please submit <strong>at least one (1)</strong> of official document
									<br /> listing all board directors
								</p>
							</Notification>
						</ColumnedLeftCenterAlignedFlex>
					</DropzoneSection>
					<ReactHookForm.Row placement={RowPlacement.SPACE_BETWEEN}>
						<PreviousWrapper>
							<GoBackLink to={RoutesUrls.VERIFY_BUSINESS_TIERS}>
								<ReactSVG src="/files/svg/backArrow.svg" />
								<GoBackText>Back</GoBackText>
							</GoBackLink>
						</PreviousWrapper>
						{isActionLoading ? (
							<Loader />
						) : (
							<Button.Secondary green type="submit">
								Submit
							</Button.Secondary>
						)}
					</ReactHookForm.Row>
					<ReactHookForm.Error>{props.errorElement}</ReactHookForm.Error>
				</WhiteHookForm>
			</FormProvider>
		</View>
	);
}

export default withRouter(VerifyBusinessProofOfSharesView);
