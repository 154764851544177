import * as React from 'react';

import { UserVerificationStatus } from '../../../../api/verificationApi';
import DashboardSection from '../../../../components/dashboard-section/DashboardSection';
import { RoutesUrls } from '../../../../constants';

import { DashboardIconPath } from './DashboardViewTpl';

interface Props {
	identityVerificationStatus: UserVerificationStatus | null;
	expired?: boolean;
}

const sectionContent = {
	INITIATED: {
		icon: DashboardIconPath.PENDING,
		title: 'Identity verification started',
		text: 'Continue with your identity verification process',
	},
	PENDING: {
		icon: DashboardIconPath.PENDING,
		title: 'Identity verification pending',
		text: 'You will receive an email regarding the status of your verification. This may take up to 48h in most cases.',
	},
	VERIFIED: {
		icon: DashboardIconPath.SUCCESS,
		title: 'Verify your identity',
		text: 'Your identity has been successfully verified!',
	},
	REJECTED: {
		icon: DashboardIconPath.FAIL,
		title: 'Identity verification rejected',
		text: 'Please review your submission to learn more about the reject reason and try again.',
	},
	MANUALLY_REJECTED: {
		icon: DashboardIconPath.FAIL,
		title: 'Identity verification rejected',
		text: 'Please review your submission to learn more about the reject reason and try again.',
	},
	PROFILE_MISMATCH: {
		icon: DashboardIconPath.PENDING,
		title: 'Issue with identity verification and profile mismatch',
		text: 'There was an issue with the submitted document(s) and your account profile information not matching, which needs to be manually reviewed. This may take up to 48h in most cases.',
	},
	FRAUD: {
		icon: DashboardIconPath.PENDING,
		title: 'Issue with identity verification',
		text: 'There was an issue with the submitted document(s) which needs to be manually reviewed. This may take up to 48h in most cases.',
	},
	CANT_RETRY: {
		icon: DashboardIconPath.FAIL,
		title: 'Identity verification rejected',
		text: 'You should receive an email with more information about the reject reason.',
	},
	NOT_VERIFIED: {
		icon: DashboardIconPath.IDENTITY_VERIFICATION,
		title: 'Verify your identity',
		text: 'Please provide proof of identity',
	},
	EXPIRED: {
		icon: DashboardIconPath.FAIL,
		title: 'Identity verification expired',
		text: 'Update your identity verification in Verify Once to continue using Dagpay',
	},
};

export default ({ identityVerificationStatus, expired }: Props) => {
	const content = identityVerificationStatus
		? expired
			? sectionContent['EXPIRED']
			: sectionContent[identityVerificationStatus]
		: sectionContent[UserVerificationStatus.NOT_VERIFIED];

	return (
		<DashboardSection icon={content.icon} title={content.title} text={content.text} link={RoutesUrls.VERIFY_IDENTITY} />
	);
};
