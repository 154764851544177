import * as React from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import ReactSVG from 'react-svg';

import { CardStates } from '../constants';
import { Color } from '../gfx/constants';

export const ShowModalStates = [
	CardStates.CARD_NOT_FOUND_OR_ACTIVE,
	CardStates.CARD_NO_FUNDS,
	CardStates.CARD_OVER_DAILY_SPEND_LIMIT_ONLINE,
	CardStates.CARD_OVER_DAILY_SPEND_LIMIT_STORE,
	CardStates.CARD_HASH_INVALID,
	CardStates.CARD_PAYMENT_FAILURE,
	CardStates.OTHER_ERROR,
	CardStates.BAD_REQUEST,
];

export interface CardStateTexts {
	title: string;
	content: string;
	icon: JSX.Element | null;
}

export interface CardState {
	cardState: CardStates;
	texts: CardStateTexts;
}

export type CardStateOptions = { [key in CardStates]: CardStateTexts };

export const CardStateOptions: CardStateOptions = {
	DEFAULT: {
		title: '',
		content: '',
		icon: null,
	},
	SUCCESS: {
		title: 'Success',
		content: 'Sending payment',
		icon: <ClipLoader color={Color.GREEN_3} sizeUnit={'px'} size={20} />,
	},
	CONFIRMING: {
		title: 'Confirming',
		content: '',
		icon: <ClipLoader color={Color.GREEN_3} sizeUnit={'px'} size={20} />,
	},
	CARD_NOT_FOUND_OR_ACTIVE: {
		title: 'Card not found or activated',
		content: 'You will need to activate your Dagcoin card at https://card.dagcoin.org in order to complete the payment',
		icon: <ReactSVG src="/files/svg/private/Info.svg" />,
	},
	CARD_HASH_INVALID: {
		title: 'Invalid card number or pin code',
		content: 'It seems that you entered an invalid card number or PIN. Please try again',
		icon: <ReactSVG src="/files/svg/private/Info.svg" />,
	},
	CARD_PAYMENT_FAILURE: {
		title: 'Card payment failed',
		content: 'Please try again. If the issue remains, please contact the card provider for more help',
		icon: <ReactSVG src="/files/svg/private/Info.svg" />,
	},
	CARD_OVER_DAILY_SPEND_LIMIT_STORE: {
		title: 'Card limit has been exceeded',
		content:
			'You will need to wait until card limit has been reset or raise your card limit at https://card.dagcoin.org in order to continue with the payment',
		icon: <ReactSVG src="/files/svg/private/Info.svg" />,
	},
	CARD_OVER_DAILY_SPEND_LIMIT_ONLINE: {
		title: 'Card limit has been exceeded',
		content:
			'You will need to wait until card limit has been reset or raise your card limit at https://card.dagcoin.org in order to continue with the payment',
		icon: <ReactSVG src="/files/svg/private/Info.svg" />,
	},
	CARD_NO_FUNDS: {
		title: "Card doesn't have enough funds",
		content: 'You will need to top up your Dagcoin card at https://card.dagcoin.org in order to complete the payment',
		icon: <ReactSVG src="/files/svg/invoice/RedLock.svg" />,
	},
	BAD_REQUEST: {
		title: 'Oops!',
		content: 'Something went wrong, please try again.',
		icon: <ReactSVG src="/files/svg/private/Info.svg" />,
	},
	OTHER_ERROR: {
		title: 'Oops!',
		content: 'Something went wrong, please try again.',
		icon: <ReactSVG src="/files/svg/private/Info.svg" />,
	},
};

const getCardStateInfo = (state: string | Error): CardState => {
	const badRequest = /.*bad request.*/.test(typeof state === 'object' ? state.message : state)
		? CardStates.BAD_REQUEST
		: null;

	let stateOptionKey = typeof state === 'object' ? state.message : state;
	stateOptionKey = badRequest || stateOptionKey;

	const knownState = Object.values(CardStates).includes(stateOptionKey as CardStates);

	return knownState
		? { cardState: stateOptionKey as CardStates, texts: CardStateOptions[stateOptionKey as CardStates] }
		: { cardState: CardStates.OTHER_ERROR, texts: CardStateOptions.OTHER_ERROR };
};

export default getCardStateInfo;

// const cardStateOptions = {
// 	CONFIRMING: {
// 		modalTitle: '',
// 		modalContent: '',
// 		buttonTitle: '',
// 		message: 'Confirming card',
// 		icon: <ClipLoader color={Color.GREEN_3} sizeUnit={'px'} size={20} />,
// 	},

// 	INACTIVE: {
// 		modalTitle: 'Your card is not activated yet',
// 		modalContent: 'You will need to activate your Dagcoin card at [link] in order to complete the payment',
// 		buttonTitle: 'Activate card',
// 		message: 'Card is not activated',
// 		icon: <ReactSVG src="/files/svg/private/Info.svg" />,
// 	},

// 	INSUFFICIENT_FUNDS: {
// 		modalTitle: "Your card doesn't have enough funds",
// 		modalContent: 'You will need to top up your Dagcoin card at [link] in order to complete the payment',
// 		buttonTitle: 'Top up card',
// 		message: 'Insufficient funds',
// 		icon: <ReactSVG src="/files/svg/private/Info.svg" />,
// 	},

// 	LIMIT_REACHED: {
// 		modalTitle: 'Your card limit has been exceeded',
// 		modalContent: 'You will need to unlock your Dagcoin card at [link] in order to complete the payment',
// 		buttonTitle: 'Unlock card',
// 		message: 'Card limit has been exceeded',
// 		icon: <ReactSVG src="/files/svg/private/Info.svg" />,
// 	},

// 	VALID: {
// 		modalTitle: '',
// 		modalContent: '',
// 		buttonTitle: '',
// 		message: 'Success',
// 		icon: <ReactSVG src="/files/svg/private/Success.svg" />,
// 	},

// 	BLOCKED: {
// 		modalTitle: 'Your card is blocked!',
// 		modalContent: 'You will need to unblock your Dagcoin card at [link] in order to complete the payment',
// 		buttonTitle: 'Unblock card',
// 		message: 'Card is blocked',
// 		icon: <ReactSVG src="/files/svg/invoice/RedLock.svg" />,
// 	},
// };
