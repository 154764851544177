import axios from 'restyped-axios';
import { ApiArrayPayload, ApiGetRequest, ApiResponse } from '../typings';

import { User } from './userApi';
export const api = axios.create<EnvironmentApi>();

const SCHEMA_PATH = '/environments';

export interface EnvironmentApi {
	[SCHEMA_PATH]: {
		GET: {
			query: ApiGetRequest;
			response: ApiResponse<ApiArrayPayload<Environment>>;
		};

		PUT: {
			body: UpdateEnvironmentRequest;
			response: ApiResponse<Environment>;
		};

		POST: {
			body: CreateEnvironmentRequest;
			response: ApiResponse<Environment>;
		};
	};

	'/environments/:environmentId': {
		GET: {
			response: ApiResponse<Environment>;
		};
		DELETE: {
			body: DeleteEnvironmentRequest;
			response: ApiResponse<Environment>;
		};
	};
}

export enum EnvironmentType {
	CUSTOM = 'CUSTOM',
	MAGENTO = 'MAGENTO',
	WOOCOMMERCE = 'WOOCOMMERCE',
	EASY_DIGITAL_DOWNLOADS = 'EASY_DIGITAL_DOWNLOADS',
	OPENCART = 'OPENCART',
	PRESTASHOP = 'PRESTASHOP',
}

export interface CreateEnvironmentRequest {
	walletId: string;
	name: string;
	description: string;
	integrationType: EnvironmentType;
	email: string;
	statusUrl: string;
	redirectSuccessUrl: string;
	redirectCancelUrl: string;
	redirectFailUrl: string;
}

export interface UpdateEnvironmentRequest extends CreateEnvironmentRequest {
	id: string;
}

export interface GetEnvironmentRequest {
	environmentId: string;
}

export interface DeleteEnvironmentRequest {
	environmentId: string;
}

export interface Environment {
	id: string;
	userId: string;
	creator: User;
	creatorId: string;
	walletId: string;
	name: string;
	description: string;
	integrationType: EnvironmentType;
	email: string;
	secret: string;
	statusUrl: string;
	redirectSuccessUrl: string;
	redirectCancelUrl: string;
	redirectFailUrl: string;
}

export const createEnvironmentApi = async (params: CreateEnvironmentRequest) => {
	const createEnvironmentResponse = await api.post(SCHEMA_PATH, {
		...params,
	});

	return createEnvironmentResponse.data;
};

export const updateEnvironmentApi = async (params: UpdateEnvironmentRequest) => {
	const updateEnvironmentResponse = await api.put(SCHEMA_PATH, {
		...params,
	});

	return updateEnvironmentResponse.data;
};

export const deleteEnvironmentApi = async (params: DeleteEnvironmentRequest) => {
	const deleteEnvironmentResponse = await api.delete<'/environments/:environmentId'>(
		`${SCHEMA_PATH}/${params.environmentId}`,
	);

	return deleteEnvironmentResponse.data;
};

export const getEnvironmentsApi = async (params: ApiGetRequest) => {
	const getEnvironmentsResponse = await api.get(SCHEMA_PATH, {
		params: { ...params },
	});

	return getEnvironmentsResponse.data;
};

export const getEnvironmentApi = async (params: GetEnvironmentRequest) => {
	const response = await api.get<'/environments/:environmentId'>(`${SCHEMA_PATH}/${params.environmentId}`);

	return response.data;
};
