import { Wallet } from '../api/walletApi';
import { ApiArrayPayload } from '../typings';

export default function getDefaultWallet(wallets: ApiArrayPayload<Wallet> | null) {
	if (wallets && wallets.items.length >= 1) {
		// Sort array by default boolean value
		return wallets.items.sort((a, b) => Number(b.isDefault) - Number(a.isDefault))[0];
	}

	return null;
}
