import styled from 'styled-components/macro';
import { StyledPanel } from '../../../../components/time-line-progress-bar/TimeLineProgressBarStyle';
import { Color } from '../../../../gfx/constants';
import { H1 } from '../../../../gfx/globals';
import { media } from '../../../../services/media';

export const InvoiceWrapper = styled.div`
	position: relative;
	width: 100%;
`;

export const TitleWrapper = styled.div`
	width: 100%;
	height: 100%;

	${H1} {
		margin-bottom: 0 !important;
	}
`;

export const NotificationRowWrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 28px;

	${media.maxNewInvoiceTable`
		flex-direction: column;
		align-items: flex-start;
	`}
`;

export const NotificationText = styled.div`
	text-align: center;
	font-family: Secondary;
	font-size: 14px;
	line-height: 36px;
	color: ${Color.GRAY_10};

	a {
		text-decoration: none;
		color: ${Color.BLUE_2};
	}

	${media.maxSize800`
		text-align: start;
	`}
	${media.maxNewInvoiceTable`
		display:none;
	`}
`;

export const ListTimelineWrapper = styled.div`
	${media.fromSize800`
		${StyledPanel} {
			padding-top: 0;
		}
	`}
`;
