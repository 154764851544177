import { base64StringToBlob, createObjectURL } from 'blob-util';

export function downloadFile(fileString: string, filename: string) {
	const data = base64StringToBlob(btoa(fileString));
	const link = document.createElement('a');
	link.rel = 'alternate';
	link.type = 'application/octet-stream';
	link.download = filename;
	link.href = createObjectURL(data);
	document.body.appendChild(link);
	link.click();
}
