import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';

import { Color, Size } from '../../gfx/constants';

interface HeaderContainerProps {
	white?: boolean;
}
export const HeaderContainer = styled.header<HeaderContainerProps>`
	background-color: ${Color.BLUE_9};
	color: white;
	font-family: Secondary;
	top: 0;
	position: sticky;
	z-index: 1;
	${({ white }) =>
		white &&
		css`
			background-color: white;
			border-bottom: 1px solid ${Color.GRAY_5};
		`}
`;

export const StyledNavLink = styled(NavLink)`
	color: white;

	font-size: 14px;
	font-weight: 500;
	line-height: 16px;
	text-transform: uppercase;
	text-decoration: none;
	padding: 6px 12px;

	&:hover {
		cursor: pointer;
		color: #ffffff;
		text-decoration: none;
	}

	&:nth-child(1) {
		margin-left: auto;
		margin-right: 22px;

		@media (min-width: 1200px) {
			margin-left: 3vw;
		}
	}

	&:last-child {
		border: 1px solid #ffffff;
		border-radius: 4px;

		&:hover {
			border-bottom: 1px solid #ffffff;
			background-color: white;
			color: #dd0000;
		}
	}
`;

export const HeaderWrap = styled.div`
	align-items: center;
	position: relative;
	color: white;
	display: flex;
	margin: auto;
	max-height: ${Size.HEADER_HEIGHT};
	max-width: 1135px;
	padding: 20px;
`;

export const HeaderLinks = styled.nav`
	display: flex;
	flex: auto;

	@media (max-width: 1024px) {
		display: none;
	}
`;

export const PrivateBurgerMenu = styled.nav`
	display: inline-block;

	a {
		color: white;
		text-decoration: none;
	}
`;

export const PrivateBurgerMenuLink = styled.div`
	margin-bottom: -1px;
	padding: 8px;
	font-size: 15px;
`;
