import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { BusinessVerification } from '../../../api/verificationApi';
import Loader from '../../../components/loader/Loader';
import { WhiteHookForm } from '../../../components/react-hook-form/ReactHookFormStyle';
import RejectionReason from '../../../components/rejection-reason/RejectionReason';
import View from '../../../components/view/View';
import { Validations, VerificationState, BusinessType, RoutesUrls } from '../../../constants';
import Notification from '../../../components/notification/Notification';
import { H1 } from '../../../gfx/globals';
import { getServerValidationErrors } from '../../../services/input-error-utils';
import { useStoreActions, useStoreState } from '../../../services/store';
import { RouteProps } from '../../../typings';
import { BusinessVerificationFormDescription } from './VerifyStyle';
import ReactHookForm, {
	HookFormColumn,
	HookFormSection,
	RowPlacement,
	SectionTitle,
} from '../../../components/react-hook-form/ReactHookFormStyle';
import { GoBack, GoBackText } from '../../public/sign-up/FillDetailsStep';
import { PreviousWrapper } from '../../public/sign-up/SignUpViewStyle';
import Button, { ButtonTypes } from '../../../components/button/Button';
import styled from 'styled-components';
import HookBaseField from '../../../components/react-hook-form/HookBaseField';
import HookPhoneField from '../../../components/react-hook-form/HookPhoneField';
import ReactSVG from 'react-svg';
import HookSelectField from '../../../components/react-hook-form/hook-select-field/HookSelectField';
import { getCountryOptions } from '../../../services/get-country-options';
import { FieldOptionProps } from '../../../components/field/Field';

export type HandleDocumentChangeFn = (e: React.ChangeEvent<HTMLSelectElement>) => void;

export interface VerifyFreelanceFields {
	businessName: string;
	registrationNumber?: string;
	email: string;
	phone: string;
	address: string;
	countryCode: FieldOptionProps | null;
	city: string;
	county: string;
	postalCode: string;
	description: string;
	website: string;
}

const freelanceSchema = Yup.object<VerifyFreelanceFields>().shape(
	{
		businessName: Yup.string()
			.required('Business name is required')
			.max(100, 'The maximum length is 100 characters')
			.trim('Field cannot be left empty or filled with spaces')
			.strict(true),
		registrationNumber: Yup.string()
			.notRequired()
			.max(100, 'The maximum length is 100 characters')
			.trim('Field cannot be left empty or filled with spaces')
			.strict(true),
		email: Validations.INVALID_EMAIL,
		phone: Validations.INVALID_PHONE_NUMBER,
		description: Yup.string()
			.required('Description is required')
			.max(100, 'The maximum length is 100 characters')
			.trim('Field cannot be left empty or filled with spaces')
			.strict(true),
		address: Yup.lazy(() => {
			return Yup.string()
				.max(255, 'The maximum length is 255 characters')
				.trim('Field cannot be left empty or filled with spaces')
				.when('website', {
					is: (website) => !website || website.length === 0,
					then: Yup.string().required('you must fill in address or website'),
					otherwise: Yup.string().notRequired(),
				});
		}),
		countryCode: Yup.mixed()
			.when('address', {
				is: (value) => value.length > 0,
				then: Yup.mixed().required('Country is required'),
				otherwise: Yup.mixed().notRequired(),
			})
			.nullable(true),
		city: Yup.string()
			.when('address', {
				is: (value) => value.length > 0,
				then: Yup.string().required('City is required'),
				otherwise: Yup.string().notRequired(),
			})
			.max(100, 'The maximum length is 100 characters')
			.trim('Field cannot be left empty or filled with spaces')
			.strict(true),
		county: Yup.string()
			.when('address', {
				is: (value) => value.length > 0,
				then: Yup.string().required('County is required'),
				otherwise: Yup.string().notRequired(),
			})
			.max(100, 'The maximum length is 100 characters')
			.trim('Field cannot be left empty or filled with spaces')
			.strict(true),
		postalCode: Yup.string()
			.when('address', {
				is: (value) => value.length > 0,
				then: Yup.string().required('Postal code is required'),
				otherwise: Yup.string().notRequired(),
			})
			.max(100, 'The maximum length is 100 characters')
			.trim('Field cannot be left empty or filled with spaces')
			.strict(true),
		website: Yup.string()
			.notRequired()
			.max(100, 'The maximum length is 100 characters')
			.trim('Field cannot be left empty or filled with spaces')
			.when('address', {
				is: (address) => !address || address.length === 0,
				then: Yup.string().required('you must fill in address or website'),
				otherwise: Yup.string().notRequired(),
			}),
	},
	[['address', 'website']],
);

const StepFormSection = styled.div<{ hidden?: boolean }>`
	opacity: ${(props) => (props.hidden ? 0 : 1)};
`;

function VerifyFreelanceView(props: RouteProps) {
	const [errorMessage, setErrorMessage] = useState('');
	const [errorElement, setErrorElement] = useState<JSX.Element | null>(null);
	const [isActionLoading, setIsActionLoading] = useState(false);

	const { verifications, viewer, countries } = useStoreState((state) => ({
		verifications: state.verification.verifications,
		viewer: state.viewer.activeViewer,
		countries: state.country.countries,
	}));

	const { verifyFreelance, getCountries } = useStoreActions((actions) => ({
		...actions.verification,
		...actions.country,
	}));

	const defaultValues = {
		businessName: '',
		registrationNumber: '',
		vatNumber: '',
		registrationDate: Date(),
		email: '',
		phone: '',
		description: '',
		address: '',
		countryCode: null,
		city: '',
		county: '',
		postalCode: '',
	};

	const rejectionReason =
		verifications && verifications.business && verifications.business.state === VerificationState.REJECTED
			? verifications.business.rejectionReason
			: '';

	const methods = useForm<VerifyFreelanceFields>({
		resolver: yupResolver(freelanceSchema),
		defaultValues: defaultValues,
		shouldFocusError: true,
		mode: 'onChange',
	});

	const { trigger, errors, getValues } = methods;

	const handleVerifyFreelance = async (e: React.FormEvent<HTMLInputElement>) => {
		e.preventDefault();
		await trigger();

		// Prepare modified data
		if (Object.keys(errors).length > 0) {
			setErrorElement(<p>{JSON.stringify(errors).toString()}</p>);
			return false;
		}
		// Remove image properties from input
		const { countryCode, ...updatedInput } = getValues();

		const countryCodeInput = countryCode && typeof countryCode.value === 'string' ? countryCode.value : '';

		setIsActionLoading(true);
		const response = await verifyFreelance({
			...updatedInput,
			countryCode: countryCodeInput,
		});

		if (response.success) {
			setIsActionLoading(false);
			props.history.push('/private');
			toast.success('Business details and proof submitted');
			return;
		}

		if (response.error) {
			setIsActionLoading(false);
			const serverValidationErrors = getServerValidationErrors<BusinessVerification>(response);
			setErrorMessage(serverValidationErrors.errorMessage);
			toast.error(<>Something went wrong, please try again</>);
		}
	};

	const getRejectReasonStringArray = (rejectReason: string) => {
		let rejectReasonArray: string[] = new Array();
		//try to parse json and return array
		try {
			const rejectReasonObject = JSON.parse(rejectReason);
			const rejectReasonArray = Object.values(rejectReasonObject) as string[];
			return rejectReasonArray;
		} catch (_e) {
			rejectReasonArray.push(rejectReason);
			return rejectReasonArray;
		}
	};

	useEffect(() => {
		const fetchCountries = async () => {
			await getCountries();
		};
		fetchCountries();
	}, [getCountries]);

	if (!countries || !viewer || isActionLoading) {
		return <Loader />;
	}

	if (viewer && viewer.businessType !== BusinessType.FREELANCE) {
		toast.error('Wrong account type');
		props.history.push(RoutesUrls.VERIFY_BUSINESS);
	}

	return (
		<View>
			<H1 center>Unregistered business verification</H1>
			<BusinessVerificationFormDescription>
				To verify your unregistered business (freelance) account, you must at a minimum enter your company details and
				provide your business and/or website address in order to process transaction volumes up to 1 000 EUR per month.{' '}
				<a
					href="http://help.dagpay.io/en/articles/5591456-unregistered-freelance-business-verification"
					target="_blank"
					rel="noopener noreferrer"
				>
					Learn more about unregistered business verification requirements
				</a>
			</BusinessVerificationFormDescription>

			{rejectionReason && <RejectionReason reasons={getRejectReasonStringArray(rejectionReason)} />}

			<FormProvider {...methods}>
				<WhiteHookForm onSubmit={handleVerifyFreelance}>
					<StepFormSection>
						<SectionTitle>
							<ReactSVG src="/files/svg/private/CompanyDetails.svg" />
							Company details
						</SectionTitle>

						<HookFormSection>
							<HookFormColumn>
								<HookBaseField
									name="businessName"
									label="Business (brand) name"
									placeholder="Business (brand) name"
									tabIndex={1}
								/>
								<HookPhoneField name="phone" label="Phone number" />
							</HookFormColumn>
							<HookFormColumn>
								<HookBaseField
									name="registrationNumber"
									label="Company registration number"
									placeholder="0000000"
									tabIndex={2}
									optionalBubble
								/>
								<HookBaseField name="email" label="Email" placeholder="info@example.com" tabIndex={4} />
							</HookFormColumn>
						</HookFormSection>
						<HookBaseField
							name="description"
							label="Nature of business"
							placeholder="Describe your business in a few words"
							tabIndex={7}
						/>
						<Notification box>
							Depending on the nature of your business, please provide your business operating address details (for
							brick-and-mortar type business) and website (if applies).
						</Notification>
						<HookFormSection margin={'50px 0'}>
							<HookFormColumn>
								<SectionTitle>
									<ReactSVG src="/files/svg/private/BusinessAddress.svg" />
									Business address
								</SectionTitle>{' '}
								{/* replace with address field??? */}
								<HookBaseField
									name="address"
									label="Address"
									placeholder="Street address, house/apartment nr"
									tabIndex={8}
								/>
								<HookBaseField name="city" label="City/town" placeholder="Name of city or town" tabIndex={9} />
								<HookBaseField name="postalCode" label="Postal code" placeholder="Postal code" tabIndex={10} />
								<HookSelectField
									label="Country"
									name="countryCode"
									options={getCountryOptions(countries)}
									isSearchable
									tabIndex={11}
								/>
								<HookBaseField name="county" label="Province, Region or County" placeholder="County" tabIndex={10} />
							</HookFormColumn>
							<HookFormColumn>
								<SectionTitle>
									<ReactSVG src="/files/svg/private/BusinessWebsite.svg" />
									Business website
								</SectionTitle>{' '}
								<HookBaseField name="website" label="Address of website" placeholder="www.example.com" tabIndex={10} />
							</HookFormColumn>
						</HookFormSection>
						<ReactHookForm.Row placement={RowPlacement.SPACE_BETWEEN}>
							<PreviousWrapper>
								<GoBack type={ButtonTypes.BUTTON} onClick={props.history.goBack}>
									<ReactSVG src="/files/svg/backArrow.svg" />
									<GoBackText>Back</GoBackText>
								</GoBack>
							</PreviousWrapper>
							<Button.Secondary green type="submit" disabled={!methods.formState.isValid}>
								Submit
							</Button.Secondary>
						</ReactHookForm.Row>
					</StepFormSection>
					<ReactHookForm.Error>{errorElement}</ReactHookForm.Error>
				</WhiteHookForm>
			</FormProvider>
			<div>{errorMessage}</div>
		</View>
	);
}
export default withRouter(VerifyFreelanceView);
