import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { withRouter } from 'react-router-dom';
import ReactSVG from 'react-svg';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { CombinedVerificationsInfo, UserVerificationStatus, VerificationStatus } from '../../../api/verificationApi';
import Button, { ButtonTypes } from '../../../components/button/Button';
import { FieldOptionProps } from '../../../components/field/Field';
import Form from '../../../components/form/Form';
import Loader from '../../../components/loader/Loader';
import Notification from '../../../components/notification/Notification';
import HookSelectField from '../../../components/react-hook-form/hook-select-field/HookSelectField';
import HookBaseField from '../../../components/react-hook-form/HookBaseField';
import HookDatePicker from '../../../components/react-hook-form/HookDatePicker';
import { HookFormColumn, HookFormSection, WhiteHookForm } from '../../../components/react-hook-form/ReactHookFormStyle';
import View from '../../../components/view/View';
import { RoutesUrls } from '../../../constants';
import { Color } from '../../../gfx/constants';
import { CenteredFlex, H1, H2 } from '../../../gfx/globals';
import { getCountryOptions } from '../../../services/get-country-options';
import RpcClient, { SubscribeMethod } from '../../../services/invoice-rpc-client';
import { useStoreActions, useStoreState } from '../../../services/store';
import { Json } from '../../../services/ws-rpc-client';
import { RouteProps } from '../../../typings';
import { GoBack, GoBackText } from '../../public/sign-up/FillDetailsStep';
import { PreviousWrapper } from '../../public/sign-up/SignUpViewStyle';

import {
	IconWrapper,
	IdentityVerifyStatusBlock,
	KycNotification,
	ProcessNotification,
	StatusMessage,
	StatusMessageWrapper,
	StatusTitle,
	VerificationProgressBar,
	VerificationProgressBlock,
	VerificationProgressText,
	VerificationProgressUnit,
} from './VerifyStyle';

const ProgressUnits = [
	{
		text: 'Review profile details',
	},
	{
		text: 'Identity verification',
	},
	{
		text: 'Verification result',
	},
];

type CompletedStatuses = 'PENDING' | 'PROFILE_MISMATCH' | 'FRAUD' | 'VERIFIED' | 'CANT_RETRY';

type StatusMessagesType = {
	[key in CompletedStatuses]: {
		title: string;
		message: JSX.Element;
		color: Color;
	};
};

const statusMessages: StatusMessagesType = {
	PENDING: {
		title: 'Pending...',
		message: (
			<StatusMessage>
				<p>Thank you for submitting your application.</p>
				<p>
					We will review your case and we will get back at you as soon as possible. The process might take a few days.
				</p>
			</StatusMessage>
		),
		color: Color.ORANGE,
	},
	PROFILE_MISMATCH: {
		title: 'Profile mismatch',
		message: (
			<StatusMessage>
				<p>
					Submitting your identity documents can sometimes result in a "profile mismatch" due to any missing/incorrect
					data <br /> in your Dagpay profile compared with data retrieved from your identity documents. For example
					missing a second name, wrong letters in your name(s), incorrect date of birth, or even an extra blank space.
				</p>
				<p>
					In most of these cases we will manually verify your account using the documents you have provided to fix it
					for you. <br />
					In case we cannot fix it, we will contact you by email with additional information.
				</p>
			</StatusMessage>
		),
		color: Color.ORANGE,
	},
	FRAUD: {
		title: 'Document issue',
		message: (
			<StatusMessage>
				<p>We noticed that there is an issue with the document you submitted.</p>
				<p>Your account will be prompt for manual review.</p>
			</StatusMessage>
		),
		color: Color.ORANGE,
	},
	VERIFIED: {
		title: 'Verified',
		message: (
			<StatusMessage>
				<p>You have successfully verified your identity. Enjoy!</p>
			</StatusMessage>
		),
		color: Color.GREEN_3,
	},
	CANT_RETRY: {
		title: 'Rejected',
		message: (
			<StatusMessage>
				<p>Your verification is rejected. You can not submit new verification.</p>
			</StatusMessage>
		),
		color: Color.RED_ERROR,
	},
};

const finalStatuses = [
	UserVerificationStatus.PENDING,
	UserVerificationStatus.PROFILE_MISMATCH,
	UserVerificationStatus.FRAUD,
	UserVerificationStatus.VERIFIED,
	UserVerificationStatus.CANT_RETRY,
];

const VerificationSteps = {
	UNINITIATED: {
		numericStep: 0,
	},
	INITIATED: {
		numericStep: 1,
	},
	COMPLETED: {
		numericStep: 2,
	},
};

enum VerificationStep {
	UNINITIATED = 'UNINITIATED',
	INITIATED = 'INITIATED',
	COMPLETED = 'COMPLETED',
}

interface VerifyIdentityFields {
	firstName: string;
	lastName: string;
	countryCode: FieldOptionProps | null;
	dateOfBirth: Date | null;
}

function VerifyIdentityView(props: RouteProps) {
	let rpc: RpcClient;

	const [errorMessage, setErrorMessage] = useState('');

	const { countries, activeViewer, verifications } = useStoreState((state) => ({
		verifications: state.verification.verifications,
		countries: state.country.countries,
		activeViewer: state.viewer.activeViewer,
	}));

	const [activeStep, setActiveStep] = useState<VerificationStep>(checkVerificationStep(verifications));

	const { getCountries, initiateVerifyIdentity, getVerifications } = useStoreActions((actions) => ({
		...actions.verification,
		...actions.country,
	}));

	const setUpVerifyOnceRpcClient = useCallback(() => {
		// avoid trying to setup the rpc client more than once
		if (rpc !== undefined || !activeViewer || !verifications) {
			return null;
		}

		const protocol = process.env.REACT_APP_API_SSL === 'true' ? 'wss' : 'ws';
		const url = `${protocol}://${process.env.REACT_APP_API_URL}/ws`;

		rpc = new RpcClient(url, SubscribeMethod.VERIFY_ONCE_CALLBACK, activeViewer.id);

		rpc.handleVerifyOnceCallback = async (_data: Json) => {
			const response = await getVerifications({ overrideState: false });

			if (response.payload && response.payload.identity) {
				const verificationStep = checkVerificationStep(response.payload);

				const isRepeatedAttempt =
					activeStep === VerificationStep.COMPLETED &&
					response.payload.identity.verificationStatus === UserVerificationStatus.REJECTED;

				if (verificationStep === VerificationStep.COMPLETED || isRepeatedAttempt) {
					console.log('repeated');
				}

				setActiveStep(verificationStep);
			}
		};
	}, [getVerifications]);

	useEffect(() => {
		const fetchCountries = async () => {
			await getCountries();
		};
		if (activeStep === VerificationStep.UNINITIATED) {
			fetchCountries();
		}

		if (verifications && !verifications.isIdentityVerified) {
			setUpVerifyOnceRpcClient();
		}
	}, [activeStep, getCountries, verifications, setUpVerifyOnceRpcClient]);

	const getValidationSchema = () => {
		const validationSchema = Yup.object<VerifyIdentityFields>().shape({
			firstName: Yup.mixed().notRequired(),
			lastName: Yup.string().notRequired(),
			countryCode: Yup.mixed().nullable(true).required('Country is required'),
			dateOfBirth: Yup.date()
				.typeError('Date of birth is missing')
				.max(dayjs().subtract(18, 'year').endOf('day').toDate(), 'You must be older than 18'),
		});

		return validationSchema;
	};

	const defaultValues = {
		firstName: activeViewer ? activeViewer.firstName : '',
		lastName: activeViewer ? activeViewer.lastName : '',
		dateOfBirth: dayjs().subtract(18, 'year').endOf('day').toDate(),
		countryCode: null,
	};

	const methods = useForm<VerifyIdentityFields>({
		resolver: yupResolver(getValidationSchema()),
		defaultValues: defaultValues,
		shouldFocusError: true,
		mode: 'onChange',
	});

	if (activeStep === VerificationStep.UNINITIATED && !countries) {
		return <Loader />;
	}

	function checkVerificationStep(verifications: CombinedVerificationsInfo | null) {
		if (!verifications || !verifications.identity || !verifications.identity.verificationStatus) {
			return VerificationStep.UNINITIATED;
		}

		if (finalStatuses.includes(verifications.identity.verificationStatus)) {
			return VerificationStep.COMPLETED;
		}

		return VerificationStep.INITIATED;
	}

	const handleVerifyIdentity = async (input: VerifyIdentityFields) => {
		const { countryCode, dateOfBirth } = input;
		const countryCodeInput = countryCode && typeof countryCode.value === 'string' ? countryCode.value : '';
		if (!countryCodeInput || !dateOfBirth) {
			return null;
		}

		const response = await initiateVerifyIdentity({
			dateOfBirth: dayjs(dateOfBirth).format('YYYY-MM-DD'),
			countryCode: countryCodeInput,
		});

		if (response.success && response.payload) {
			const verifications = await getVerifications({});

			if (verifications && verifications.payload) {
				setActiveStep(checkVerificationStep(verifications.payload));
			}
			setUpVerifyOnceRpcClient();
		}

		if (response.error) {
			setErrorMessage(response.error);
			toast(response.error);
			return null;
		}
	};

	const renderVerificationForm = () => (
		<FormProvider {...methods}>
			<WhiteHookForm onSubmit={methods.handleSubmit(handleVerifyIdentity)}>
				<Form.Separator>
					<ReactSVG src="/files/svg/private/Sender.svg" />
					<H2>Review profile details</H2>
				</Form.Separator>
				<Notification>
					Before continuing, please review and confirm that the following details are{' '}
					<strong>exactly as they appear on your identity document.</strong>
					<br />
					If the following details do not match your identity document, please{' '}
					<a href="mailto:support@dagpay.io">contact support</a> for any changes.
					<br />
				</Notification>
				<HookFormSection>
					<HookFormColumn>
						<HookBaseField name="firstName" label="First Name" tabIndex={1} disabled />
						{countries && (
							<HookSelectField
								label="Country"
								name="countryCode"
								options={getCountryOptions(countries)}
								isSearchable
								tabIndex={3}
							/>
						)}
					</HookFormColumn>
					<HookFormColumn>
						<HookBaseField name="lastName" label="Last Name" tabIndex={2} disabled />
						<HookDatePicker name="dateOfBirth" label="Date of birth" tabIndex={4} />
					</HookFormColumn>
				</HookFormSection>
				<Notification box>
					When continuing, you will be required to capture or upload pictures of your identity document, capture a
					selfie, and upload a copy of your document proving your address. Make sure your identity document is up to
					date and your address document is not older than 3 months.
					<br />
					<a href="https://help.dagpay.io/en/articles/4210058-identity-verification">
						Learn more about identity verification requirements.
					</a>
				</Notification>
				<CenteredFlex marginTop={20}>
					<PreviousWrapper>
						<GoBack type={ButtonTypes.BUTTON} onClick={props.history.goBack}>
							<ReactSVG src="/files/svg/backArrowGreen.svg" />
							<GoBackText>Back</GoBackText>
						</GoBack>
					</PreviousWrapper>
					<Button isDisabled={!methods.formState.isValid} alignedRight type={ButtonTypes.SUBMIT}>
						Next
					</Button>
				</CenteredFlex>
			</WhiteHookForm>
		</FormProvider>
	);

	const processNotification = ({
		addressFailed,
		manuallyRejected,
	}: {
		addressFailed?: boolean;
		manuallyRejected?: boolean;
	}) => {
		return manuallyRejected ? (
			<ProcessNotification>
				<IconWrapper color={Color.ORANGE}>
					<div>Your previous verification is failed. You can start it anew.</div>
				</IconWrapper>
			</ProcessNotification>
		) : (
			<ProcessNotification>
				<IconWrapper color={Color.GREEN_3}>
					<ReactSVG src="/files/svg/private/Success.svg" />
					<div>{`Your ${addressFailed ? 'identity' : 'address'} is successfully verified`}</div>
				</IconWrapper>
				<IconWrapper color={Color.RED_ERROR}>
					<ReactSVG src="/files/svg/private/dashboard/Fail.svg" />
					<div>{`Please continue with ${addressFailed ? 'address' : 'identity'} verification`}</div>
				</IconWrapper>
			</ProcessNotification>
		);
	};

	const redirectToVerifyOnce = () => {
		if (!verifications || !verifications.identity) {
			return null;
		}

		const isManuallyRejected = verifications.identity.verificationStatus === UserVerificationStatus.MANUALLY_REJECTED;

		const isIdentityFailed =
			verifications.identity.identityVerificationStatus === VerificationStatus.FAILED &&
			verifications.identity.addressVerificationStatus === VerificationStatus.VERIFIED &&
			verifications.identity.verificationStatus !== UserVerificationStatus.MANUALLY_REJECTED;

		const isAddressFailed =
			verifications.identity.addressVerificationStatus === VerificationStatus.FAILED &&
			verifications.identity.identityVerificationStatus === VerificationStatus.VERIFIED &&
			verifications.identity.verificationStatus !== UserVerificationStatus.MANUALLY_REJECTED;

		setTimeout(() => {
			// at this point we have identity verification
			window.location.assign(
				`${process.env.REACT_APP_VERIFY_ONCE_URL}${verifications.identity && verifications.identity.transactionId}`,
			);
		}, 3000);

		return (
			<>
				{isManuallyRejected && processNotification({ manuallyRejected: true })}
				{isAddressFailed && processNotification({ addressFailed: true })}
				{isIdentityFailed && processNotification({})}
				<div style={{ marginTop: 75 }}>
					<Loader />
				</div>
			</>
		);
	};

	const renderStatusBlock = () => {
		if (!verifications || !verifications.identity || !verifications.identity.verificationStatus) {
			return null;
		}

		const statusString = verifications.identity.verificationStatus;
		const statusContent = statusMessages[statusString as CompletedStatuses];

		if (statusContent === undefined) {
			return null;
		}

		return (
			<IdentityVerifyStatusBlock>
				<Form.Separator>
					<ReactSVG src="/files/svg/private/Sender.svg" />
					<H2>Verification result</H2>
				</Form.Separator>
				<StatusMessageWrapper>
					<StatusTitle color={statusContent.color}>{statusContent.title}</StatusTitle>
					{statusContent.message}
					<Button onClick={() => props.history.push(RoutesUrls.PRIVATE)}>Return to dashboard</Button>
				</StatusMessageWrapper>
			</IdentityVerifyStatusBlock>
		);
	};

	return (
		<View>
			<H1 center>Identity verification</H1>
			<VerificationProgressBlock>
				{ProgressUnits.map((unit, index) => (
					<VerificationProgressUnit
						active={index === VerificationSteps[activeStep].numericStep}
						complete={index < VerificationSteps[activeStep].numericStep}
						key={`Progress-bar-unit-${index}`}
					>
						<VerificationProgressBar />
						<VerificationProgressText>
							<span>{index + 1}</span>
							<p>{unit.text}</p>
						</VerificationProgressText>
					</VerificationProgressUnit>
				))}
			</VerificationProgressBlock>
			<KycNotification>
				We have implemented KYC (Know-Your-Customer) procedure to ensure a safe environment for our customers. It is
				utmost important to prevent money laundering and any kind of support of terrorism.
			</KycNotification>
			{activeStep === VerificationStep.UNINITIATED && renderVerificationForm()}
			{activeStep === VerificationStep.INITIATED && redirectToVerifyOnce()}
			{activeStep === VerificationStep.COMPLETED && renderStatusBlock()}
			<div>{errorMessage}</div>
		</View>
	);
}

export default withRouter(VerifyIdentityView);
