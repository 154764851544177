// import * as owasp from 'owasp-password-strength-test';

export function getPasswordStrength(password: string) {
	const specials = /[ !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;

	const hasLowerCase = password.toUpperCase() !== password;
	const hasUpperCase = password.toLowerCase() !== password;
	const hasSpecials = specials.test(password);
	const hasTenChars = password.length >= 10;

	if (password.length === 0) {
		return { hasLowerCase: false, hasUpperCase: false, hasSpecials: false, hasTenChars: false };
	}

	//TODO: UI implementation of owasp: at least 1 number, sequence of three chars not allowed
	if (/* owasp.test(password).strong && */ hasTenChars && hasSpecials && hasUpperCase && hasLowerCase) {
		return { hasLowerCase: true, hasUpperCase: true, hasSpecials: true, hasTenChars: true };
	}

	return { hasLowerCase, hasUpperCase, hasSpecials, hasTenChars };
}
